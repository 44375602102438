/* eslint-disable */

import React from "react";
import Footer from "./footer";
import Header from "./header";
import Slider8 from "../assets/images/slider/8.jpg";
import "../assets/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faPaperclip,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import PDF_IMage from "../assets/images/pdf.png";
import PDF_1 from "../assets/PDF FILE/RAC/1 Mintues Final_3rd RAC Meeting_FINAL 27 Aug 2021.pdf";
import PDF_2 from "../assets/PDF FILE/RAC/2 Minutes of Directors Meeting held on 27th August 2021.pdf";
import PDF_3 from "../assets/PDF FILE/RAC/3 RAC Meeting (Workplan 2019-20).pdf";
import PDF_4 from "../assets/PDF FILE/RAC/4 CCCS meeting 2015.pdf";
import PDF_5 from "../assets/PDF FILE/RAC/5 CCOS Meeting 2013.pdf";
import { CSVLink } from "react-csv";

import { useState } from "react";
import Select from "react-select";

export default function Student_research() {
  const [Country, setcountry] = useState("");
  const [year, setyear] = useState("");
  const [major, setmajor] = useState("");
  const [degree, setdegree] = useState("");
  const [search, setsearch] = useState("");
  const country_opt = [
    { label: "select", value: "" },
    { label: "Madhya Pradesh", value: "Madhya Pradesh" },
    { label: "Uttar Pradesh", value: "Uttar Pradesh" },
    { label: "Maharastra", value: "Maharastra" },
    { label: "Gujarat", value: "Gujarat" },
    { label: "Odisa", value: "Odisa" },
    { label: "Rajasthan", value: "Rajasthan" },
    { label: "Chhattisgarh", value: "Chhattisgarh" },
    { label: "Karnataka", value: "Karnataka" },
    { label: "Afghanistan", value: "Afghanistan" },
    { label: "Kerala", value: "Kerala" },
    { label: "Andhra Pradesh", value: "Andhra Pradesh" },
  ];

  const Data = [
    {
      Name_of_Students: "Gour, Ram Gopal",
      Country_State: "Madhya Pradesh",
      Title_of_the_Research_Work:
        "Impact of crop loan on profitabilkty & adoption pattern of major crops in sehore block of sehore district",
      Year_of_Research_Work: "1990",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Impact Assessment & Policy Analysis",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Jain, Jitendra Kumar",
      Country_State: "Madhya Pradesh",
      Title_of_the_Research_Work:
        "Economics of marketing and processing of soybean in sehore district of Madhya Pradesh",
      Year_of_Research_Work: "1998",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Cost, Profitability and Production Efficiency",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Malviya, Chhagan Lal",
      Country_State: "Madhya Pradesh",
      Title_of_the_Research_Work:
        "A study on profitability of irrigated and rainfed farming systems in Ashta block of sehore district of Madhya Pradesh",
      Year_of_Research_Work: "2001",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Cost, Profitability and Production Efficiency",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Rajendra Kumar Jayaswal",
      Country_State: "Madhya Pradesh",
      Title_of_the_Research_Work:
        "Impact of Tractor use on Income and Employment in Sehore District of Madhya Pradesh",
      Year_of_Research_Work: "2001",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Impact Assessment & Policy Analysis",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Shekhar Vijayvargiya",
      Country_State: "Madhya Pradesh",
      Title_of_the_Research_Work:
        "Pace of Growth and Prospects of Biofertilizer Consumption in Madhya Pradesh",
      Year_of_Research_Work: "2001",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Agriculture Growth and Development",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Khare, Prashant",
      Country_State: "Madhya Pradesh",
      Title_of_the_Research_Work:
        "Economics of rural milk collection through bhopal sahakari dugdh Singh maryadit (M. P.)",
      Year_of_Research_Work: "2002",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Cost, Profitability and Production Efficiency",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Morya, Santosh Singh",
      Country_State: "Madhya Pradesh",
      Title_of_the_Research_Work:
        "Resource use efficiency of soybean cultivation at different levels of adoption in vindhyan plateau of Madhya Pradesh",
      Year_of_Research_Work: "2003",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Cost, Profitability and Production Efficiency",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Fuley, Vivek Bhushan",
      Country_State: "Madhya Pradesh",
      Title_of_the_Research_Work:
        "Harvest and post harvest losses of soybean as perceived by the producer of sehore district of Madhya Pradesh",
      Year_of_Research_Work: "2004",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Cost, Profitability and Production Efficiency",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Gupta, Manoj Kumar",
      Country_State: "Uttar Pradesh",
      Title_of_the_Research_Work:
        "An analysis of vegetable seed market and its potential in etawah, farrukhabad and mainpuri districts of Uttar Pradesh with special",
      Year_of_Research_Work: "2007",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Vegetable Seed Market",
      Filter_Degree: "MBA",
    },
    {
      Name_of_Students: "Manoj Kumar Gupta",
      Country_State: "Maharastra",
      Title_of_the_Research_Work:
        "An analysis of vegetable seed market and its potential in Etawah, farrukhabad and mainpuri district of Uttar Pradesh with special reference to Century seeds private Ltd.",
      Year_of_Research_Work: "2007",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Vegetable Seed Market",
      Filter_Degree: "MBA",
    },
    {
      Name_of_Students: "Ravi Singh Chouhan",
      Country_State: "Madhya Pradesh",
      Title_of_the_Research_Work:
        "Assessment of soil testing analysis in Bhopal district of Madhya Pradesh",
      Year_of_Research_Work: "2008",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Impact Assessment & Policy Analysis",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Sethi Neeraj Kumar",
      Country_State: "Madhya Pradesh",
      Title_of_the_Research_Work:
        "Assessment of Market Potential of Different Pesticides for Paddy Production in Jabalpur District of Madhya Pradesh with Special Reference to Devidayal Agro",
      Year_of_Research_Work: "2008",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Pesticides",
      Filter_Degree: "MBA",
    },
    {
      Name_of_Students: "Sharma, Vivek",
      Country_State: "Gujarat",
      Title_of_the_Research_Work:
        "Customer satisfaction measurement of hifield ag chem India limited in the Gujarat state of",
      Year_of_Research_Work: "2008",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Cost, Profitability and Production Efficiency",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Neeraj Kumar Sethi",
      Country_State: "Madhya Pradesh",
      Title_of_the_Research_Work:
        "Assessment of market potential of different pesticides for paddy production in Jabalpur District of MP with Spe. Ref.  to Devidayal Agro. Sales  Ltd.",
      Year_of_Research_Work: "2008",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Pesticide",
      Filter_Degree: "MBA",
    },
    {
      Name_of_Students: "Vivek Sharma",
      Country_State: "Gujarat",
      Title_of_the_Research_Work:
        "Customer Satisfaction measurement of Hifield Ag. Chem, India. Private Ltd. In Gujrat State of India.",
      Year_of_Research_Work: "2008",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Plant protection chemicals",
      Filter_Degree: "MBA",
    },
    {
      Name_of_Students: "Nagendra Sharma",
      Country_State: "Madhya Pradesh",
      Title_of_the_Research_Work:
        "Impact of crop loan on farm income w.s.r. to District Central co-operative Bank Jabalpur",
      Year_of_Research_Work: "2009",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Impact Assessment & Policy Analysis",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Soni, Dinesh Kumar",
      Country_State: "Maharastra",
      Title_of_the_Research_Work:
        "Problems and prospects of hybrid mustard seed marketing in Rajasthan state with special reference to Advanta India Limited",
      Year_of_Research_Work: "2009",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Hybrid Mustard",
      Filter_Degree: "MBA",
    },
    {
      Name_of_Students: "Mr. Dinesh Kumar Soni",
      Country_State: "Maharastra",
      Title_of_the_Research_Work:
        "Problems and prospect of Hybrid Mustard seed marketing in Rajasthan state with special reference to advanta India – Ltd.",
      Year_of_Research_Work: "2009",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Hybrid Mustard",
      Filter_Degree: "MBA",
    },
    {
      Name_of_Students: "Ganesh Rajne",
      Country_State: "Madhya Pradesh",
      Title_of_the_Research_Work:
        "Present Scenario of Market of Insecticide Herbicides and Fungicide in Ratam District of Madhya Pradesh",
      Year_of_Research_Work: "2010",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Herbicides",
      Filter_Degree: "MBA",
    },
    {
      Name_of_Students: "Manoj Mashih Xess",
      Country_State: "Madhya Pradesh",
      Title_of_the_Research_Work:
        "Processing of paddy in Sarguja district of Chhatisgarh: An economic analysis.",
      Year_of_Research_Work: "2010",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Cost, Profitability and Production Efficiency",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Pradhan, Parma Bhusan",
      Country_State: "Odisa",
      Title_of_the_Research_Work:
        "Brand and channel management of IFFCO in angul district of Odisha",
      Year_of_Research_Work: "2011",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "IFFCO",
      Filter_Degree: "MBA",
    },
    {
      Name_of_Students: "Vijay Yadav",
      Country_State: "Madhya Pradesh",
      Title_of_the_Research_Work:
        "Impact of Itc E-Choupal on Production and Marketing Efficiency of Soybean in Serore District (M.P)",
      Year_of_Research_Work: "2011",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Agriculture Growth and Development",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Mr. Ashok Mahakale",
      Country_State: "Madhya Pradesh",
      Title_of_the_Research_Work:
        "Instability, growth and projection of mustard production in different district of M P",
      Year_of_Research_Work: "2012",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Agriculture Growth and Development",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Koi Jam Jai Jai Gopal",
      Country_State: "-",
      Title_of_the_Research_Work:
        "Satisfaction level of trader and end user of soybean veedicide w.s.r. to Targer Super.",
      Year_of_Research_Work: "2012",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Weedicides",
      Filter_Degree: "MBA",
    },
    {
      Name_of_Students: "Sweety Pathak",
      Country_State: "Uttar Pradesh",
      Title_of_the_Research_Work:
        "Performance of IFFCO in marketing fertilizer in Jhanshi division (UP)",
      Year_of_Research_Work: "2013",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "IFFCO",
      Filter_Degree: "MBA",
    },
    {
      Name_of_Students: "Kale Utsav Sanjiv",
      Country_State: "Gujarat",
      Title_of_the_Research_Work:
        "Comparative cost and profitability of different vegetables over wheat in Ahmedabad district w.s.r. to Indo-US Agribiotech Pvt. Ltd.",
      Year_of_Research_Work: "2013",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Vegetable Production",
      Filter_Degree: "MBA",
    },
    {
      Name_of_Students: "Mukesh Kumar Bhati",
      Country_State: "Rajasthan",
      Title_of_the_Research_Work:
        "An economic analysis of sheep reasing in Bikaner district of Rajasthan",
      Year_of_Research_Work: "2014",
      Major_Advisor: "Dr. Deepak Rathi",
      Themetic_Area: "Cost, Profitability and Production Efficiency",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Vijay Kumar Baldodiya",
      Country_State: "Rajasthan",
      Title_of_the_Research_Work:
        "Dynamics of population and milk & meat production of goat in different agro climatic regions of Rajasthan",
      Year_of_Research_Work: "2014",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Agriculture Growth and Development",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Thakre Rohan Harendra",
      Country_State: "Maharastra",
      Title_of_the_Research_Work:
        "Market potential and market share of pesticide in orange. (citrus reticulate) of Amrawati district with reference to Bayer",
      Year_of_Research_Work: "2014",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Pesticide",
      Filter_Degree: "MBA",
    },
    {
      Name_of_Students: "Arun Kumar",
      Country_State: "Uttar Pradesh",
      Title_of_the_Research_Work:
        "Market share and market potential of micronutrients in Allahabad district of Uttar Pradesh w.s.r. to Microplex Agro Chemicals and Fertilizers Pvt. Ltd.",
      Year_of_Research_Work: "2014",
      Major_Advisor: "Dr.Deepak Rathi",
      Themetic_Area: "Micronutrients",
      Filter_Degree: "MBA",
    },
    {
      Name_of_Students: "Lakhan Kumar Kushre",
      Country_State: "Maharastra",
      Title_of_the_Research_Work:
        "Market share and market potential of fungicide for paddy crop in Dhamtari district of Chhattisgarh with reference to Syngenta India Ltd.",
      Year_of_Research_Work: "2015",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Fungicide",
      Filter_Degree: "MBA",
    },
    {
      Name_of_Students: "Devendra Sharma",
      Country_State: "Madhya Pradesh",
      Title_of_the_Research_Work:
        "An economic anlaysis of produciton and marketing of Garlic in Ratlam district of Madhya Pradesh",
      Year_of_Research_Work: "2016",
      Major_Advisor: "Dr. Deepak Rathi",
      Themetic_Area: "Agricultural Marketing and Price",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Prashant Baghel",
      Country_State: "Chhattisgarh",
      Title_of_the_Research_Work:
        "Impact of National Food Security Mission (NFSM) Pulses on input use, production, productivity and profitability of Gram in Bilaspur District of Chhattisgarh",
      Year_of_Research_Work: "2016",
      Major_Advisor: "Dr. Deepak Rathi",
      Themetic_Area: "Impact Assessment & Policy Analysis",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Shantilal Vasuniya",
      Country_State: "Madhya Pradesh",
      Title_of_the_Research_Work:
        "An economic anlaysis of produciton and marketing of Garlic in Ratlam district of Madhya Pradesh",
      Year_of_Research_Work: "2016",
      Major_Advisor: "Dr. Deepak Rathi",
      Themetic_Area: "Cost, Profitability and Production Efficiency",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Shobha Paul",
      Country_State: "Madhya Pradesh",
      Title_of_the_Research_Work:
        "Analysis of post harvest losses of vegetables with special reference to Jabalpur regulated market.",
      Year_of_Research_Work: "2016",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Agricultural Marketing and Price",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Yogita Kashyap",
      Country_State: "Chhattisgarh",
      Title_of_the_Research_Work:
        "Impact of self help groups on socio-economic development of women in Korba District of Chhattisgarh",
      Year_of_Research_Work: "2016",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Impact Assessment & Policy Analysis",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Bhartendu Kumar Dwivedi",
      Country_State: "Madhya Pradesh",
      Title_of_the_Research_Work:
        "An analysis of present status and consumer attitude towards Sanchi Packet Milk in Jabalpur city under Jabalpur Sahkari Dugdh Sangh Maryadit, Jabalpur district, Madhya Pradesh.",
      Year_of_Research_Work: "2016",
      Major_Advisor: "Dr. Deepak Rathi",
      Themetic_Area: "Sahkari Dugdh Sangh (Milk)",
      Filter_Degree: "MBA",
    },
    {
      Name_of_Students: "Sanjay Bhanwar",
      Country_State: "Madhya Pradesh",
      Title_of_the_Research_Work:
        "Market potential and market share of hybrid vegetable seed of Sakata seed India Pvt. Ltd. in Jabalpur district.",
      Year_of_Research_Work: "2016",
      Major_Advisor: "Dr. Deepak Rathi",
      Themetic_Area: "Hybrid vegetable seed",
      Filter_Degree: "MBA",
    },
    {
      Name_of_Students: "Chaurasiya, Mausam Rani",
      Country_State: "Madhya Pradesh",
      Title_of_the_Research_Work:
        "Consumer attitude towards packet milk in Jabalpur city under JSDSM, Jabalpur district, Madhya Pradesh",
      Year_of_Research_Work: "2017",
      Major_Advisor: "Dr. Deepak Rathi",
      Themetic_Area: "Mmilk in Jabalpur city under JSDSM",
      Filter_Degree: "MBA",
    },
    {
      Name_of_Students: "Prasanna Kolar",
      Country_State: "Karnataka",
      Title_of_the_Research_Work:
        "Dynamics of land use pattern and determinants of fallow land across various agro-climatic zones of Madhya Pradesh.",
      Year_of_Research_Work: "2017",
      Major_Advisor: "Dr. Deepak Rathi",
      Themetic_Area: "Agriculture Growth and Development",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Bharat Singh",
      Country_State: "Madhya Pradesh",
      Title_of_the_Research_Work:
        "Dynamics of Area, production and productivity of Soybean in Madhya Pradesh.",
      Year_of_Research_Work: "2018",
      Major_Advisor: "Dr. Deepak Rathi",
      Themetic_Area: "Agriculture Growth and Development",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Deepak Kumar Ahirwar",
      Country_State: "Madhya Pradesh",
      Title_of_the_Research_Work:
        "Determinants of farm income in different size of farms in Bhopal districts of Madhya Pradesh.",
      Year_of_Research_Work: "2018",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Cost, Profitability and Production Efficiency",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Vivek Sahu",
      Country_State: "Maharastra",
      Title_of_the_Research_Work:
        "Market share and market potential of plant protection chemicals in paddy with special reference to Syngenta India Ltd. in Dhamtatri district of Chhattisgarh.",
      Year_of_Research_Work: "2018",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Plant protection chemicals",
      Filter_Degree: "MBA",
    },
    {
      Name_of_Students: "Abdul Malik Samim",
      Country_State: "Afghanistan",
      Title_of_the_Research_Work:
        "Dynamics of rice productin in different Agro-climate zones of M.P.",
      Year_of_Research_Work: "2019",
      Major_Advisor: "Dr. Deepak Rathi",
      Themetic_Area: "Agriculture Growth and Development",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Bakht Amir Zadran",
      Country_State: "Afghanistan",
      Title_of_the_Research_Work:
        "Dynamics of maize production in different agro climate regions of M.P.",
      Year_of_Research_Work: "2019",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Agriculture Growth and Development",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Geetu George",
      Country_State: "Kerala",
      Title_of_the_Research_Work:
        "Dterminants of crop diversification in Kerala. A temporal analysis.",
      Year_of_Research_Work: "2019",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Agriculture Growth and Development",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Jwala Parte",
      Country_State: "Madhya Pradesh",
      Title_of_the_Research_Work:
        "Economics of paddy cultivation under different sowing techniques in Raipur district of Chattisgarh",
      Year_of_Research_Work: "2019",
      Major_Advisor: "Dr. Deepak Rathi",
      Themetic_Area: "Cost, Profitability and Production Efficiency",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Raunak Jain",
      Country_State: "Madhya Pradesh",
      Title_of_the_Research_Work:
        "Economic analysis of farm efficieny across different size of farm in Damoh district of M.P. a data envelopment analysis approach.",
      Year_of_Research_Work: "2019",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Cost, Profitability and Production Efficiency",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Tol Singh Ganava",
      Country_State: "Madhya Pradesh",
      Title_of_the_Research_Work:
        "Comparative economic analysis of major crops cultivated by tribal farmers in Jhabua distrit of M.P.",
      Year_of_Research_Work: "2019",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Cost, Profitability and Production Efficiency",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Varsha Kirar",
      Country_State: "Madhya Pradesh",
      Title_of_the_Research_Work:
        "Economic analysis of farm efficiency and profitability of adopte and non-adopted farm KVK in Shahpura block of Jabalpur district of M.P.",
      Year_of_Research_Work: "2019",
      Major_Advisor: "Dr. Deepak Rathi",
      Themetic_Area: "Cost, Profitability and Production Efficiency",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Vikas",
      Country_State: "Rajasthan",
      Title_of_the_Research_Work:
        "Resoruce use efficiency of Bt. Cotton in Hanumangarh district of Rajasthan",
      Year_of_Research_Work: "2019",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Cost, Profitability and Production Efficiency",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Vinit Kumar Jaiswal",
      Country_State: "Madhya Pradesh",
      Title_of_the_Research_Work:
        "An economic comparison of farm efficienmcy KVK adopted and non-adopted farmers of Umeria district of M.P.",
      Year_of_Research_Work: "2019",
      Major_Advisor: "Dr. Deepak Rathi",
      Themetic_Area: "Cost, Profitability and Production Efficiency",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Poiba Rabi Prasad",
      Country_State: "Andhra Pradesh",
      Title_of_the_Research_Work:
        "Evaluation of zero budget natural farming on tribals farmer’s welfare in Visakhapathannm district of Andhra Pradesh.",
      Year_of_Research_Work: "2020",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Cost, Profitability and Production Efficiency",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Puli Nageswara Rao",
      Country_State: "Andhra Pradesh",
      Title_of_the_Research_Work:
        "An economic analysis of flue cured Virginia (FCV) tobacco production in prkasam district of Andhra Pradesh.",
      Year_of_Research_Work: "2020",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Cost, Profitability and Production Efficiency",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Shivam Tiwari",
      Country_State: "Madhya Pradesh",
      Title_of_the_Research_Work:
        "Dynamics of oilseeds production in Madhya Pradesh.",
      Year_of_Research_Work: "2020",
      Major_Advisor: "Dr. Deepak Rathi",
      Themetic_Area: "Agriculture Growth and Development",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Swadhina Khuntia",
      Country_State: "Madhya Pradesh",
      Title_of_the_Research_Work:
        "Dynamics of pulses production in Madhya Pradesh.",
      Year_of_Research_Work: "2020",
      Major_Advisor: "Dr. Deepak Rathi",
      Themetic_Area: "Agriculture Growth and Development",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Veena Rathore",
      Country_State: "Madhya Pradesh",
      Title_of_the_Research_Work:
        "Dynamic production and profitability of soybean in different states of India.",
      Year_of_Research_Work: "2020",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Agriculture Growth and Development",
      Filter_Degree: "Ph.D",
    },
    {
      Name_of_Students: "Yogesh Tiwari",
      Country_State: "Madhya Pradesh",
      Title_of_the_Research_Work:
        "Impact of integrated farming system on farm income and employment generation in Madhya Pradesh.",
      Year_of_Research_Work: "2020",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Impact Assessment & Policy Analysis",
      Filter_Degree: "Ph.D",
    },
    {
      Name_of_Students: "Khagesh Gupta",
      Country_State: "Madhya Pradesh",
      Title_of_the_Research_Work:
        "Dynamics of chickpea production in different agro-clamatic zones of Madhya Pradesh.",
      Year_of_Research_Work: "2021",
      Major_Advisor: "Dr. Deepak Rathi",
      Themetic_Area: "Agriculture Growth and Development",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Pintu Paul",
      Country_State: "Madhya Pradesh",
      Title_of_the_Research_Work:
        "Dynamics of mustard production in different disrtrict of Madhya Pradesh.",
      Year_of_Research_Work: "2021",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Agriculture Growth and Development",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Rajkumar",
      Country_State: "Chhattisgarh",
      Title_of_the_Research_Work:
        "Dynamics of wheat production in different agro-climatic zones of Chhattisgarh.",
      Year_of_Research_Work: "2021",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Agriculture Growth and Development",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Shradha Nayak",
      Country_State: "Chhattisgarh",
      Title_of_the_Research_Work:
        "Dynamics of vegetables production in Chhattisgarh plains agro-climatic zone of Chhattisgarh.",
      Year_of_Research_Work: "2021",
      Major_Advisor: "Dr. Deepak Rathi",
      Themetic_Area: "Agriculture Growth and Development",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Umeshwar Kumar Rajwade",
      Country_State: "Chhattisgarh",
      Title_of_the_Research_Work:
        "Dynamcis of gram production in different agro-climatic zones of Chhattisgarh.",
      Year_of_Research_Work: "2021",
      Major_Advisor: "Dr. Deepak Rathi",
      Themetic_Area: "Agriculture Growth and Development",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Mukul Sharma",
      Country_State: "Madhya Pradesh",
      Title_of_the_Research_Work:
        "Dynamics of Soybean Production in different Agro-climatic zones of Madhya Pradesh",
      Year_of_Research_Work: "2021",
      Major_Advisor: "Dr. Deepak Rathi",
      Themetic_Area: "Agriculture Growth and Development",
      Filter_Degree: "M.Sc.",
    },
    {
      Name_of_Students: "Sanshkala Patel",
      Country_State: "Madhya Pradesh",
      Title_of_the_Research_Work:
        "Dynamics of Maize Production and Profitability Across states of India",
      Year_of_Research_Work: "2023",
      Major_Advisor: "Dr. H.O.Sharma",
      Themetic_Area: "Agriculture Growth and Development",
      Filter_Degree: "Ph.D",
    },
  ];
  const year_opt = [
    { value: "", label: "select" },
    { value: "1990", label: "1990" },
    { label: "1998", value: "1998" },
    { label: "2001", value: "2001" },
    { label: "2002", value: "2002" },
    { label: "2003", value: "2003" },
    { label: "2004", value: "2004" },
    { label: "2007", value: "2007" },
    { label: "2008", value: "2008" },
    { label: "2009", value: "2009" },
    { label: "2010", value: "2010" },
    { label: "2011", value: "2011" },
    { label: "2012", value: "2012" },
    { label: "2013", value: "2013" },
    { label: "2014", value: "2014" },
    { label: "2015", value: "2015" },
    { label: "2016", value: "2016" },
    { label: "2017", value: "2017" },
    { label: "2018", value: "2018" },
    { label: "2019", value: "2019" },
    { label: "2020", value: "2020" },
    { label: "2021", value: "2021" },
    { label: "2023", value: "2023" },
  ];
  const major_opt = [
    { value: "", label: "select" },
    { label: "Sharma", value: "Sharma" },
    { label: "Rathi", value: "Rathi" },
  ];
  const degree_opt = [
    { label: "select", value: "" },
    { label: "M.Sc.", value: "M.Sc." },
    { label: "MBA", value: "MBA" },
    { label: "Ph.D", value: "Ph.D" },
  ];

  const tabledata = Data.filter((item) => {
    const searchText = search.toLowerCase();
    const studentName =
      item.Name_of_Students.toLowerCase().includes(searchText);
    const title =
      item.Title_of_the_Research_Work.toLowerCase().includes(searchText);
    const themestic = item.Themetic_Area.toLowerCase().includes(searchText);
    return (
      (studentName || title || themestic) &&
      item.Country_State.includes(Country) &&
      item.Year_of_Research_Work.includes(year) &&
      item.Major_Advisor.includes(major) &&
      item.Filter_Degree.includes(degree)
    );
  });

  const newdata = tabledata.map((item, i) => {
    return (
      <tr key={i}>
        <td>{item.Name_of_Students}</td>
        <td>{item.Country_State}</td>
        <td>{item.Title_of_the_Research_Work}</td>
        <td>{item.Year_of_Research_Work}</td>
        <td>{item.Major_Advisor}</td>
        <td>{item.Themetic_Area}</td>
        <td>{item.Filter_Degree}</td>
      </tr>
    );
  });
  return (
    <div>
      <section class="about section">
        <div class="container">
          <div class="row">
            <div class="about-content">
              <h3>Student Research</h3>
              <section class="blog-area">
                <div class="container pt-20">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <div class="finder-journal">
                        <ul>
                          <li>
                            <Select
                              placeholder="Select Year "
                              onChange={(e) => {
                                setyear(e.value);
                                setcountry("");
                              }}
                              options={year_opt}
                            />
                          </li>

                          <li class="rating">
                            <Select
                              placeholder="Major Advisor"
                              onChange={(e) => {
                                setmajor(e.value);
                                setcountry("");
                              }}
                              options={major_opt}
                            />
                          </li>

                          <li class="rating">
                            <Select
                              placeholder="Select Degree "
                              onChange={(e) => {
                                setdegree(e.value);
                              }}
                              options={degree_opt}
                            />
                          </li>
                          <li class="rating">
                            <Select
                              placeholder="Select Country/State "
                              onChange={(e) => {
                                setcountry(e.value);
                                setmajor("");
                                setyear("");
                              }}
                              options={country_opt}
                            />
                          </li>
                          <li>
                            <input
                              onChange={(e) => {
                                setsearch(e.target.value);
                              }}
                              placeholder="search"
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="rac_meeting">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <table
                      style={{
                        width: "100%",
                        marginLeft: "3px",
                        marginTop: "9px",
                      }}
                    >
                      <tr>
                        <th>Name of Students</th>
                        <th>Country/State</th>
                        <th>Title of the Research Work</th>
                        <th>Year of Research Work</th>
                        <th>Major Advisor</th>
                        <th>Themetic Area</th>
                        <th>Degree</th>
                      </tr>
                      {newdata}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button className="btn ">
            <CSVLink data={tabledata} filename="test.csv" headers={header}>
              download
            </CSVLink>
          </button>
        </div>
      </section>
    </div>
  );
}
const header = [
  { label: "Name_of_Students", key: "Name_of_Students" },
  { label: "Country_State", key: "Country_State" },
  { label: "Title_of_the_Research_Work", key: "Title_of_the_Research_Work" },
  { label: "Year_of_Research_Work", key: "Year_of_Research_Work" },
  { label: "Major_Advisor", key: "Major_Advisor" },
  { label: "Themetic_Area", key: "Themetic_Area" },
  { label: "Filter_Degree", key: "Filter_Degree" },
];
