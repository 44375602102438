/* eslint-disable */

import React, { Component } from "react";

import { Link } from "react-router-dom";
import PDF_IMage from "../assets/images/pdf.png";
import A4 from "../assets/PDF FILE/AdvisoryBoardMeeting/A4.pdf";
import A3 from "../assets/PDF FILE/AdvisoryBoardMeeting/A3.pdf";
import A2 from "../assets/PDF FILE/AdvisoryBoardMeeting/A2.pdf";
import A1 from "../assets/PDF FILE/AdvisoryBoardMeeting/A1.pdf";
import A0 from "../assets/PDF FILE/AdvisoryBoardMeeting/A0.pdf";

function AdvBordMeeting() {
  const data = [
    {
      heldOn: "13-10-1958",
      hosting: "Dr. S.R. Sen",
      guest:
        "Shri R.C. Murab,IAS Secretary, Department of Agriculture, Govt. of Madhya Pradesh Bhopal",
      pdf: "Nil",
    },
    {
      heldOn: "28-09-1959",
      hosting: "Dr. R.N. Poduval",
      guest: "Dr. S.R. Sen, Jt. Secretary Planning Commission",
      pdf: "Nil",
    },
    {
      heldOn: "03-10-1960",
      hosting: "Dr. R.N. Poduval",
      guest: "Dr. S.R. Sen, Jt. Secretary Planning Commission",
      pdf: "Nil",
    },
    {
      heldOn: "05-08-1961",
      hosting: "Dr. R.N. Poduval",
      guest: "Shri Prem Narain, Deputy Financial Adviser, Govt of India",
      pdf: "Nil",
    },
    {
      heldOn: "07-12-1963",
      hosting: "Shri S.L. Verma",
      guest: "Shri Prem Narain, Deputy Financial Adviser, Govt of India",
      pdf: "Nil",
    },
    {
      heldOn: "21-11-1966",
      hosting: "Dr. J.S. Patel",
      guest: "Dr.T.S. Gill, Director Agriculture Madhya Pradesh",
      pdf: "Nil",
    },
    {
      heldOn: "02-06-1970",
      hosting: "Dr. L.S. Negi",
      guest: "Dr. K.B.L. Bhargava, Director Bureau of Eco.& Statistical",
      pdf: "Nil",
    },
    {
      heldOn: "13-07-1990",
      hosting: "Dr. G.R.Saini",
      guest: "Shri S.K. Mishra, Secretary (A&C)",
      pdf: "Nil",
    },
    {
      heldOn: "25-10-1991",
      hosting: "Dr. D.K. Sharma",
      guest: "Mr. R.S.Gaur, Deputy Economic & Statistical Adviser GoI",
      pdf: "Nil",
    },
    {
      heldOn: "11-11-1994",
      hosting: "Dr. K.S. Johar",
      guest: "Dr.M.S.Bhatia, Economic & Statistical Adviser  GoI",
      pdf: "Nil",
    },
    {
      heldOn: "24-01-1998",
      hosting: "Dr. Panjab Singh",
      guest:
        "Dr. Chitranjan , Additional Economic & Statistical Adviser, Directorate of Economics & Statistics, Ministry Of Agriculture, GoI",
      pdf: "Nil",
    },
    {
      heldOn: "09-09-1999.",
      hosting: "Dr. Punjab Singh",
      guest: "Mr. S.K.Ghoral, Adviser, Ministry of Agriculture, GoI",
      pdf: "Nil",
    },
    {
      heldOn: "09-09-1999",
      hosting: "Dr. Punjab Singh",
      guest: "Dr. A.K. Ray , Economist,Ministry of Agriculture, Govt. of India",
      pdf: "Nil",
    },
    {
      heldOn: "09-01-2002",
      hosting: "Dr. G.B. Singh",
      guest:
        "Dr. A.K. Ray , Economist, Ministry of Agriculture, Govt. of India",
      pdf: A4,
    },
    // {
    //   heldOn: "09-01-2002",
    //   hosting: "Dr. G.B. Singh",
    //   guest: "Dr. A.K. Ray, Economist, Ministry of Agriculture, GoI",
    //   pdf: "Nil",
    // },
    {
      heldOn: "14-05-2009",
      hosting: "Dr. Gautam Kalloo",
      guest: "Mr. P.C. Bodh, Additional Economic Advisor GoI",
      pdf: "Nil",
    },
    {
      heldOn: "26-05-2013",
      hosting: "Professor V. S. Tomar",
      guest: "Dr. B.S. Bhandari,Economic Adviser, Ministry of Agriculture, GOI",
      pdf: A3,
    },
    {
      heldOn: "06-11-2014",
      hosting: "Professor V. S. Tomar",
      guest:
        "Shri P. C. Bodh ,Economic Adviser, Ministry of Agriculture, GOI Shri Raghav Chandra , AS&FA, GOI",
      pdf: A2,
    },
    {
      heldOn: "19-01-2019",
      hosting: "Professor P. K. Bisen",
      guest: "Shri P. C. Bodh ,Economic Adviser, Ministry of Agriculture, GOI",
      pdf: A1,
    },
    {
      heldOn: "24-12-2021",
      hosting: "Professor P. K. Bisen",
      guest:
        "Dr. (Mrs.) Promodita Sathish, Adviser (AER) Division, Ministry of Agriculture, GOI",
      pdf: A0,
    },
    {
      heldOn: "14/02/2024",
      hosting: "Professor P. K. Mishra",
      guest:
        "Dr. (Mrs.) Promodita Sathish, Adviser, AER Section, (AE&E Division), Ministry of Agriculture, GOI, New Delhi",
      pdf: A0,
    },
  ];
  const newData = data.map((item, i) => {
    return (
      <tr key={i}>
        <td className="text-center">{item.heldOn}</td>
        <td className="text-center">{item.hosting}</td>
        <td className="text-center">{item.guest}</td>
        <td className="text-center">
          {item.pdf === "Nil" ? (
            "Nil"
          ) : (
            <a href={item.pdf}>
              <img src={PDF_IMage} alt="" />
            </a>
          )}
        </td>
      </tr>
    );
  });
  return (
    <div>
      {/* <Header /> */}

      <section class="about section">
        <div class="container">
          <div class="row">
            {/* <div class="col-lg-6 col-md-12 col-sm-12"> */}
            <div class="about-content">
              <h3>
                Meetings <span>Detail</span>
              </h3>
              {/* <table style={{ width: "100%" }}>
                <tr>
                  <th>Held on Dated</th>
                  <th>Hosting /Chairman</th>
                  <th>Guest Person</th>
                  <th>Attached PDF Files</th>
                </tr>
                <tr>
                  <td>09-09-1999.</td>
                  <td>Dr. Punjab Singh</td>
                  <td>
                    <span className="guest_name">Dr. A.K. Ray</span>, Economist,
                    Ministry of Agriculture, Govt. of India
                  </td>
                  <td>Nill</td>
                </tr>
                <tr>
                  <td>09-01-2002</td>
                  <td>Dr. G.B. Singh</td>
                  <td>
                    <span className="guest_name">Dr. A.K. Ray</span>, Economist,
                    Ministry of Agriculture, Govt. of India
                  </td>
                  <td>
                    <a href={A4}>
                      <img src={PDF_IMage} />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>26-05-2013</td>
                  <td>Professor V. S. Tomar</td>
                  <td>
                    <span className="guest_name">Dr. B.S. Bhandari</span>,
                    Economic Adviser, Ministry of Agriculture, GOI
                  </td>
                  <td>
                    <a href={A3}>
                      <img src={PDF_IMage} />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>06-11-2014</td>
                  <td>Professor V. S. Tomar</td>
                  <td>
                    <span className="guest_name">Shri P. C. Bodh</span>,
                    Economic Adviser, Ministry of Agriculture, GOI
                    <span className="guest_name">Shri Raghav Chandra</span>,
                    AS&FA, GOI
                  </td>
                  <td>
                    <a href={A2}>
                      <img src={PDF_IMage} />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>19-01-2019</td>
                  <td>Professor P. K. Bisen</td>
                  <td>
                    <span className="guest_name">Shri P. C. Bodh</span>,
                    Economic Adviser, Ministry of Agriculture, GOI{" "}
                  </td>
                  <td>
                    <a href={A1}>
                      <img src={PDF_IMage} />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>24-12-2021</td>
                  <td>Professor P. K. Bisen</td>
                  <td>
                    <span className="guest_name">
                      Dr. (Mrs.) Promodita Sathish
                    </span>
                    , Adviser (AER) Division, Ministry of Agriculture, GOI
                  </td>
                  <td>
                    <a href={A0}>
                      <img src={PDF_IMage} />
                    </a>
                  </td>
                </tr>
              </table> */}
              <table>
                <thead>
                  <th>Held on Dated</th>
                  <th>Hosting /Chairman</th>
                  <th>Guest Person</th>
                  <th>PDF</th>
                </thead>
                <tbody>{newData}</tbody>
              </table>
            </div>
            {/* </div> */}
          </div>
        </div>
      </section>

      {/* <!-- Start Clients --> */}
      {/* <Footer /> */}
    </div>
  );
}

export default AdvBordMeeting;
