/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import PDF_IMage from "../assets/images/pdf.png";
import PDF_1 from "../assets/PDF FILE/Staff_possition/Staffing Order.pdf";
import { CSVLink } from "react-csv";
export default function StaffingPatter() {
  // Before Return code >>>>>>>
  const header = [
    { label: "S NO", key: "s_no" },
    { label: "Name_of_Sanctioned", key: "Name_of_Sanctioned" },
    { label: "Name_of_the_Employee", key: "Name_of_the_Employee" },
    { label: "Sanction", key: "Sanction" },
    { label: "Nature_of_appointment", key: "Nature_of_appointment" },
    { label: "Present_Pay_Level", key: "Present_Pay_Level" },
    { label: "Basic_Pay_as_on_date", key: "Basic_Pay_as_on_date" },
    { label: "Consolidated_pay", key: "Consolidated_pay" },

    { label: "Qualification_Possessed", key: "Qualification_Possessed" },
    { label: "Work_Experience", key: "Work_Experience" },
    { label: "Date_of_Joining", key: "Date_of_Joining" },
    { label: "Date_of_superannuation", key: "Date_of_superannuation" },
    { label: "Period_of_Contract", key: "Period_of_Contract" },

    { label: "Date_of_Vacancy", key: "Date_of_Vacancy" },
    { label: "If_fallen_into_deemed", key: "If_fallen_into_deemed" },
    { label: "If_the_said_post", key: "If_the_said_post" },
    { label: "If_advertised", key: "If_advertised" },
  ];
  const Data = [
    {
      s_no: "1",
      Name_of_Sanctioned: "Director/ Professor",
      Name_of_the_Employee: "Dr. Deepak Rathi(Incharge)",
      Sanction: "1",
      Nature_of_appointment: "Regular",
      Present_Pay_Level: "37400-67000+ G.P.-10,000",
      Basic_Pay_as_on_date: "211800",
      Consolidated_pay: "",
      Qualification_Possessed: "M.Sc. (Ag. Economics), Ph.D",
      Work_Experience: "11",
      Date_of_Joining: "12/06/2012",
      Date_of_superannuation: "30/07/2038",
      Period_of_Contract: "",
      Date_of_Vacancy: "",
      If_fallen_into_deemed: "",
      If_the_said_post: "",
      If_advertised: "",
    },
    {
      s_no: "2",
      Name_of_Sanctioned: "Dy. Director/ Associate Professor/ Sr. Scientist",
      Name_of_the_Employee: "Vacant",
      Sanction: "1",
      Nature_of_appointment: "Regular",
      Present_Pay_Level: "37400-67000+ G.P -9000",
      Basic_Pay_as_on_date: "172200",
      Consolidated_pay: "",
      Qualification_Possessed: "M.Sc. (Ag. Economics), Ph.D",
      Work_Experience: "",
      Date_of_Joining: "",
      Date_of_superannuation: "31/08/2023",
      Period_of_Contract: "",
      Date_of_Vacancy: "",
      If_fallen_into_deemed: "",
      If_the_said_post: "",
      If_advertised: "",
    },
    {
      s_no: "3",
      Name_of_Sanctioned: "Research officer/ Assistant Professor / Scientist",
      Name_of_the_Employee: "Dr. H.K. Niranjan*",
      Sanction: "2 (1*)",
      Nature_of_appointment: "Contractual",
      Present_Pay_Level: "15600-39100+GP-6000",
      Basic_Pay_as_on_date: " ",
      Consolidated_pay: "59400",
      Qualification_Possessed: "M.Sc. (Ag. Economics), Ph.D",
      Work_Experience: "09",
      Date_of_Joining: "13/4/2023",
      Date_of_superannuation: "",
      Period_of_Contract: "11 Month",
      Date_of_Vacancy: "",
      If_fallen_into_deemed: "",
      If_the_said_post: "",
      If_advertised: "",
    },
    {
      s_no: "4",
      Name_of_Sanctioned: "Research officer/ Assistant Professor / Scientist",
      Name_of_the_Employee: "Deemed Abolish Under Revival",
      Sanction: "2 (1*)",
      Nature_of_appointment: "-",
      Present_Pay_Level: "15600-39100+GP-6000",
      Basic_Pay_as_on_date: " ",
      Consolidated_pay: "",
      Qualification_Possessed: "",
      Work_Experience: "",
      Date_of_Joining: "",
      Date_of_superannuation: "",
      Period_of_Contract: "",
      Date_of_Vacancy: "1/2/2019",
      If_fallen_into_deemed: "",
      If_the_said_post: "",
      If_advertised: "",
    },
    {
      s_no: "5",
      Name_of_Sanctioned: "	Research Associate/Sr.Research Investigator",
      Name_of_the_Employee: "Dr.P.R.Pandey*",
      Sanction: "4",
      Nature_of_appointment: "Contractual",
      Present_Pay_Level: "9300-34800+ G.P.-4200",
      Basic_Pay_as_on_date: " ",
      Consolidated_pay: "53900",
      Qualification_Possessed: "	M.Sc. (Ag. Economics), Ph.D",
      Work_Experience: "5	",
      Date_of_Joining: "13/4/2023",
      Date_of_superannuation: "",
      Period_of_Contract: "11 Month",
      Date_of_Vacancy: "",
      If_fallen_into_deemed: "",
      If_the_said_post: "",
      If_advertised: "",
    },
    {
      s_no: "6",
      Name_of_Sanctioned: "	Research Associate/Sr.Research Investigator",
      Name_of_the_Employee: "Mr. R.S. Bareliya*",
      Sanction: "4",
      Nature_of_appointment: "Contractual",
      Present_Pay_Level: "9300-34800+ G.P.-4200",
      Basic_Pay_as_on_date: " ",
      Consolidated_pay: "53900",
      Qualification_Possessed: "	M.Sc. (Ag. Economics),",
      Work_Experience: "07",
      Date_of_Joining: "13/4/23",
      Date_of_superannuation: "",
      Period_of_Contract: "11 Month",
      Date_of_Vacancy: "",
      If_fallen_into_deemed: "",
      If_the_said_post: "",
      If_advertised: "",
    },
    {
      s_no: "7",
      Name_of_Sanctioned: "	Research Associate/Sr.Research Investigator",
      Name_of_the_Employee: "Mr. Pradeep K. Patidar",
      Sanction: "4",
      Nature_of_appointment: "Contractual",
      Present_Pay_Level: "9300-34800+ G.P.-4200",
      Basic_Pay_as_on_date: " ",
      Consolidated_pay: "49000",
      Qualification_Possessed: "	M.Sc. (Ag. Economics),",
      Work_Experience: "04",
      Date_of_Joining: "1/2/2023",
      Date_of_superannuation: "",
      Period_of_Contract: "11 Month",
      Date_of_Vacancy: "",
      If_fallen_into_deemed: "",
      If_the_said_post: "",
      If_advertised: "",
    },
    {
      s_no: "8",
      Name_of_Sanctioned: "	Research Associate/Sr.Research Investigator",
      Name_of_the_Employee: "Mr. Akhilesh Kuril",
      Sanction: "4",
      Nature_of_appointment: "Contractual",
      Present_Pay_Level: "9300-34800+ G.P.-4200",
      Basic_Pay_as_on_date: " ",
      Consolidated_pay: "49000",
      Qualification_Possessed: "	M.Sc. (Ag. Economics),",
      Work_Experience: "04",
      Date_of_Joining: "13/4/2023",
      Date_of_superannuation: "",
      Period_of_Contract: "11 Month",
      Date_of_Vacancy: "",
      If_fallen_into_deemed: "",
      If_the_said_post: "",
      If_advertised: "",
    },
    {
      s_no: "9",
      Name_of_Sanctioned: "	Research Fellow-Temporary**",
      Name_of_the_Employee: "Mr. Rahul Birla",
      Sanction: "2",
      Nature_of_appointment: "Contractual",
      Present_Pay_Level: "31000+HRAFixed/Month",
      Basic_Pay_as_on_date: " ",
      Consolidated_pay: "31000",
      Qualification_Possessed: "	M.Sc. (Ag. Economics),",
      Work_Experience: "",
      Date_of_Joining: "01/06/2022",
      Date_of_superannuation: "",
      Period_of_Contract: "2 Year",
      Date_of_Vacancy: "",
      If_fallen_into_deemed: "",
      If_the_said_post: "",
      If_advertised: "",
    },
    {
      s_no: "10",
      Name_of_Sanctioned: "	Research Fellow-Temporary**",
      Name_of_the_Employee: "Mr. Bhanwar Lal Osari",
      Sanction: "2",
      Nature_of_appointment: "Contractual",
      Present_Pay_Level: "31000+HRAFixed/Month",
      Basic_Pay_as_on_date: " ",
      Consolidated_pay: "31000",
      Qualification_Possessed: "	M.Sc. (Ag. Economics),",
      Work_Experience: "",
      Date_of_Joining: "01/06/2022",
      Date_of_superannuation: "",
      Period_of_Contract: "2 Year",
      Date_of_Vacancy: "",
      If_fallen_into_deemed: "",
      If_the_said_post: "",
      If_advertised: "",
    },
    {
      s_no: "11",
      Name_of_Sanctioned: "	Research And Reference Assistant (Librarian.)",
      Name_of_the_Employee: "Deemed Abolish Under Revival",
      Sanction: "1",
      Nature_of_appointment: " ",
      Present_Pay_Level: "	Rs.9300-34800+2800 GP(Grade-1)",
      Basic_Pay_as_on_date: " ",
      Consolidated_pay: "",
      Qualification_Possessed: "",
      Work_Experience: "",
      Date_of_Joining: "",
      Date_of_superannuation: "",
      Period_of_Contract: "",
      Date_of_Vacancy: "31.12.2010",
      If_fallen_into_deemed: "Yes",
      If_the_said_post: "",
      If_advertised: "",
    },
    {
      s_no: "12",
      Name_of_Sanctioned: "Officer Superintend/ Section Office",
      Name_of_the_Employee: "Deemed Abolish Under Revival",
      Sanction: "1",
      Nature_of_appointment: " ",
      Present_Pay_Level: "	Rs.9300-34800+4200GP",
      Basic_Pay_as_on_date: " ",
      Consolidated_pay: "",
      Qualification_Possessed: "",
      Work_Experience: "",
      Date_of_Joining: "",
      Date_of_superannuation: "",
      Period_of_Contract: "",
      Date_of_Vacancy: "25.11.2002",
      If_fallen_into_deemed: "Yes",
      If_the_said_post: "",
      If_advertised: "",
    },
    {
      s_no: "13",
      Name_of_Sanctioned: "P.A. to Director",
      Name_of_the_Employee: "Deemed Abolish Under Revival",
      Sanction: "1",
      Nature_of_appointment: " ",
      Present_Pay_Level: "Rs.5200-20200+1900GP Assistant Grade-III LDC",
      Basic_Pay_as_on_date: " ",
      Consolidated_pay: "",
      Qualification_Possessed: "",
      Work_Experience: "",
      Date_of_Joining: "",
      Date_of_superannuation: "",
      Period_of_Contract: "",
      Date_of_Vacancy: "08.06.2012",
      If_fallen_into_deemed: "Yes",
      If_the_said_post: "",
      If_advertised: "",
    },
    {
      s_no: "14",
      Name_of_Sanctioned: "Account Assistant",
      Name_of_the_Employee: "Mr. Sachin Aske",
      Sanction: "1",
      Nature_of_appointment: "Regular ",
      Present_Pay_Level: "Rs. 5200 - 20200 + 2400 (Grade Pay)",
      Basic_Pay_as_on_date: " 26900(Under 7th Pay)",
      Consolidated_pay: "",
      Qualification_Possessed: "M.Sc (Ag. Agronomy)",
      Work_Experience: "04",
      Date_of_Joining: "15.01.2019",
      Date_of_superannuation: "Oct. 2054",
      Period_of_Contract: "",
      Date_of_Vacancy: " ",
      If_fallen_into_deemed: " ",
      If_the_said_post: "",
      If_advertised: "",
    },
    {
      s_no: "15",
      Name_of_Sanctioned: "Typist",
      Name_of_the_Employee: "	Mr. Sushil Kumar Lodhi",
      Sanction: "1",
      Nature_of_appointment: "Regular ",
      Present_Pay_Level: "Rs. 5200 - 20200 + 1900 (Grade Pay)",
      Basic_Pay_as_on_date: " 26200(Under 7th Pay)",
      Consolidated_pay: "",
      Qualification_Possessed: "12th",
      Work_Experience: "04",
      Date_of_Joining: "08.03.2019",
      Date_of_superannuation: "May, 2031",
      Period_of_Contract: "",
      Date_of_Vacancy: " ",
      If_fallen_into_deemed: " ",
      If_the_said_post: "",
      If_advertised: "",
    },
  ];
  const Newdata = Data.map((item, i) => {
    return (
      <tr key={i}>
        <td>{item.s_no}</td>
        <td>{item.Name_of_Sanctioned}</td>
        <td>{item.Name_of_the_Employee}</td>
        <td>{item.Sanction}</td>
        <td>{item.Nature_of_appointment}</td>
        <td>{item.Present_Pay_Level}</td>
        <td>{item.Basic_Pay_as_on_date}</td>
        <td>{item.Consolidated_pay}</td>
        <td>{item.Qualification_Possessed}</td>
        <td>{item.Work_Experience}</td>
        <td>{item.Date_of_Joining}</td>
        <td>{item.Date_of_superannuation}</td>
        <td>{item.Period_of_Contract}</td>
        <td>{item.Date_of_Vacancy}</td>
        <td>{item.If_fallen_into_deemed}</td>
        <td>{item.If_the_said_post}</td>
        <td>{item.If_advertised}</td>
      </tr>
    );
  });
  console.log(Newdata[0]);
  return (
    <>
      <section className="about section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="about-content">
                <h3>
                  Staff <span>Pattern</span>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div classNameName="staff_table">
                <table>
                  <tr>
                    <th colSpan="17">
                      Department of Economics & Statistics (AER Division)
                    </th>
                  </tr>
                  <tr>
                    <th colSpan="3">Core Staff Details</th>
                    <th colSpan="1">Detail of Post</th>
                    <th colSpan="10">
                      Staff Position Details of Agro Economic Research Centre,
                      JNKVV, Jabalpur, Madhya Pradesh
                    </th>
                    <th colSpan="3">As on 30 june 2023</th>
                  </tr>
                  <tr>
                    <th>S.No.</th>
                    <th>Name of Sanctioned Post as per Ministry</th>
                    <th>Name of the Employee</th>
                    <th>Sanction</th>
                    <th>
                      Nature of appointment (Regular/ Temporary/ contractual)
                    </th>
                    <th>
                      Present Pay <br /> Level
                    </th>
                    <th>Basic Pay as on date</th>
                    <th>
                      Consolidated <br></br> pay (for <br></br> temporary/
                      <br></br>contractual employees)
                    </th>
                    <th>Qualification Possessed</th>
                    <th>
                      Work Experience <br /> (Year/Month)
                    </th>
                    <th>Date of Joining/Re-Joining</th>
                    <th>Date of superannuation (for regular employees</th>
                    <th>
                      Period of Contract (for temporary/contractual employees)
                      (From date to date)
                    </th>
                    <th>Date of Vacancy (if vacant)</th>
                    <th>
                      If fallen into deemed abolished (not filled for more than
                      two years) category? (yes/no)
                    </th>
                    <th>
                      If the said post has been advertised (if yes, details
                      thereof)
                    </th>
                    <th>
                      If advertised, whether approval taken? If yes, from whom?
                    </th>
                  </tr>

                  {/* </tr>
                 
                  <tr>
                    <td>1</td>
                    <td>Director/ Professor</td>
                    <td>Dr. H.O. Sharma</td>
                    <td>1</td>
                    <td>Regular</td>
                    <td>37400-67000+ G.P.-10,000</td>
                    <td>211800</td>
                    <td>-</td>
                    <td>M.Sc. (Ag. Economics), Ph.D</td>
                    <td>16</td>
                    <td>09/05/2005</td>
                    <td>31 Aug. 2023</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Dy. Director/ Associate Professor/ Sr. Scientist</td>
                    <td>Dr. Deepak Rathi</td>
                    <td>1</td>
                    <td>Regular</td>
                    <td>37400-67000+ G.P -9000</td>
                    <td>172200</td>
                    <td>-</td>
                    <td>M.Sc. (Ag. Economics), Ph.D</td>
                    <td>10</td>
                    <td>12/06/2012</td>
                    <td>30 Jun. 2038</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td rowSpan="">3</td>
                    <td rowSpan="">
                      {" "}
                      Research officer/ Assistant Professor / Scientist
                    </td>
                    <td>Dr. H.K. Niranjan*</td>
                    <td rowSpan="">2 (1*)</td>
                    <td>Contractual</td>
                    <td>15600-39100+GP-6000</td>
                    <td>-</td>
                    <td>59400</td>
                    <td>M.Sc. (Ag. Economics), Ph.D</td>
                    <td>09</td>
                    <td>13/4/2023</td>
                    <td>-</td>
                    <td>11 Month</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>Research officer/ Assistant Professor / Scientist</td>
                    <td>Deemed Abolish Under Revival</td>
                    <td>2 (1*)</td>
                    <td>-</td>
                    <td>15600-39100+GP-6000</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>1/2/2019</td>
                    <td></td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Research Associate/Sr.Research Investigator</td>
                    <td>Dr.P.R.Pandey*</td>
                    <td>4</td>
                    <td>Contractual</td>
                    <td>9300-34800+ G.P.-4200</td>
                    <td></td>
                    <td>53900</td>
                    <td>M.Sc. (Ag. Economics), Ph.D</td>
                    <td>5</td>
                    <td>13/4/2023</td>
                    <td></td>
                    <td>11 Month</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>Research Associate/Sr.Research Investigator</td>
                    <td>Mr. R.S. Bareliya*</td>
                    <td>4</td>
                    <td>Contractual</td>
                    <td>9300-34800+ G.P.-4200</td>
                    <td>-</td>
                    <td>53900</td>
                    <td>M.Sc. (Ag. Economics)</td>
                    <td>07</td>
                    <td>13/4/23</td>
                    <td></td>
                    <td>11 Month</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>Research Associate/Sr.Research Investigator</td>
                    <td>Mr. Pradeep K. Patidar</td>
                    <td>4</td>
                    <td>Contractual</td>
                    <td>9300-34800+ G.P.-4200</td>
                    <td>-</td>
                    <td>49000</td>
                    <td>M.Sc. (Ag. Economics)</td>
                    <td>04</td>
                    <td>1/2/2023</td>
                    <td>-</td>
                    <td>11 Month</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>Research Associate/Sr.Research Investigator</td>
                    <td>Mr. Akhilesh Kuril</td>
                    <td>4</td>
                    <td>Contractual</td>
                    <td>9300-34800+ G.P.-4200</td>
                    <td>-</td>
                    <td>49000</td>
                    <td>M.Sc. (Ag. Economics)</td>
                    <td>04</td>
                    <td>13/4/2023</td>
                    <td></td>
                    <td>11 Month</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>

                  <tr>
                    <td rowSpan>9</td>
                    <td>Research Fellow-Temporary**</td>
                    <td>Mr. Rahul Birla</td>
                    <td>2</td>
                    <td>Contractual</td>
                    <td>
                      31000+HRA <br></br>Fixed/Month
                    </td>
                    <td>-</td>
                    <td>31000</td>
                    <td>M.Sc. (Ag. Economics)</td>
                    <td>-</td>
                    <td>01/06/2022</td>
                    <td>-</td>
                    <td>2 Year</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td rowSpan>10</td>
                    <td>Research Fellow-Temporary**</td>
                    <td>Mr. Bhanwar Lal Osari</td>
                    <td>2</td>
                    <td>Contractual</td>
                    <td>
                      {" "}
                      31000+HRA <br></br>Fixed/Month
                    </td>
                    <td>-</td>
                    <td>31000</td>
                    <td>M.Sc. (Ag. Economics)</td>
                    <td>-</td>
                    <td>01/06/2022</td>
                    <td>-</td>
                    <td>2 Year</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>11</td>
                    <td>Research And Reference Assistant (Librarian.)</td>
                    <td>Deemed Abolish Under Revival </td>
                    <td>1</td>
                    <td>-</td>
                    <td>Rs.9300-34800+2800 GP(Grade-1)</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>31.12.2010</td>
                    <td>Yes</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>12</td>
                    <td>Officer Superintend/ Section Officer</td>
                    <td>Deemed Abolish Under Revival </td>
                    <td>1</td>
                    <td>-</td>
                    <td>Rs.9300-34800+4200GP</td>
                    <td>-</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>-</td>
                    <td>-</td>
                    <td>25.11.2002</td>
                    <td>Yes</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>13</td>
                    <td>P.A. to Director</td>
                    <td>Deemed Abolish Under Revival </td>
                    <td>1</td>
                    <td>-</td>
                    <td>Rs.5200-20200+1900GP Assistant Grade-III LDC</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>08.06.2012</td>
                    <td>Yes</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>14</td>
                    <td>Account Assistant</td>
                    <td>Mr. Sachin Aske</td>
                    <td>1</td>
                    <td>Regular</td>
                    <td>Rs. 5200 - 20200 + 2400 (Grade Pay)</td>
                    <td>26900(Under 7th Pay)</td>
                    <td>-</td>
                    <td>M.Sc (Ag. Agronomy)</td>
                    <td>04</td>
                    <td>15.01.2019</td>
                    <td>Oct. 2054</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>15</td>
                    <td>Typist</td>
                    <td>Mr. Sushil Kumar Lodhi</td>
                    <td>1</td>
                    <td>Regular</td>
                    <td>Rs. 5200 - 20200 + 1900 (Grade Pay)</td>
                    <td>26200(Under 7th Pay)</td>
                    <td>-</td>
                    <td>12th</td>
                    <td>04</td>
                    <td>08.03.2019</td>
                    <td>May, 2031</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr> */}

                  {Newdata}
                </table>
                <div>
                  <button className="btn ">
                    <CSVLink data={Data} filename="test.csv" headers={header}>
                      download data
                    </CSVLink>
                  </button>
                  <button></button>

                  <div className="pdf-container mt-2">
                    <a href={PDF_1}>
                      <img
                        style={{ width: "50px" }}
                        src={PDF_IMage}
                        className="pdf-image"
                      />
                    </a>
                    <h6 className="pdf-title">Download Order</h6>
                  </div>

                  {/* <table classNameName="table ">
                  <tr>
                    <th>Title</th>
                    <th>PDF</th>
                  </tr>

                  <tr>
                    <td classNameName="text-center">Order </td>
                    <td>
                      {PDF_1 !== "Nill" ? (
                        <td>
                          <a href={PDF_1}>
                            <img src={PDF_IMage} />
                          </a>
                        </td>
                      ) : (
                        <td>{PDF_1}</td>
                      )}
                    </td>
                  </tr>
                </table> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
