/* eslint-disable */

import React from "react";
import Footer from "./footer";
import Header from "./header";
import Slider8 from "../assets/images/slider/8.jpg";
import "../assets/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faPaperclip,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import PDF_IMage from "../assets/images/pdf.png";
import PDF_1 from "../assets/PDF FILE/RAC/1 Mintues Final_3rd RAC Meeting_FINAL 27 Aug 2021.pdf";
import PDF_2 from "../assets/PDF FILE/RAC/2 Minutes of Directors Meeting held on 27th August 2021.pdf";
import PDF_3 from "../assets/PDF FILE/RAC/3 RAC Meeting (Workplan 2019-20).pdf";
import PDF_4 from "../assets/PDF FILE/RAC/4 CCCS meeting 2015.pdf";
import PDF_5 from "../assets/PDF FILE/RAC/5 CCOS Meeting 2013.pdf";
import { CSVLink } from "react-csv";
import { useId } from "react";

import { useState } from "react";
import Select from "react-select";

export default function Communication_AERC() {
  //   const [teacher, setteacher] = useState("");
  //   const [fil_class, setclass] = useState("");
  //   const [session, setsession] = useState("");
  const [search, setsearch] = useState("");

  const Data = [
    {
      State: "New Delhi",
      AERC_Research:
        "Dr. P. BABU  IES, MA. PhD. Assistant Director, AER Division, Directorate of Economics & StatisticsDepartment of Agriculture and Farmers Welfare, Ministry of Agriculture and Farmers Welfare, Government of India, Room No.102, F-Wing, Shastri Bhawan, New Delhi-110 001 email: babu.p@nic.in",
    },
    {
      State: "New Delhi",
      AERC_Research: `Dr. (Mrs.) Promodita Sathish 
        Adviser (AER/Publication/Commercial Crops),  Directorate of Economics & Statistics , Room No. 449 A,C-Wing, Ministry of Agriculture & Farmers Welfare Krishi Bhawan, New Delhi-110001
        email: promodita@nic.in 
        `,
    },
    {
      State: "New Delhi",
      AERC_Research: `Shri Kumar Madhukar Rajpoot
      Sr. Statistical Officer
      A.E.R. Division, Directorate of Economics & Statistics
      Department of Agriculture, Cooperation & Farmers Welfare, Ministry of Agriculture & Farmers Welfare, Room No.103, F-wing, Shastri Bhawan, New Delhi 110001
      email: madhukar.rajput@gov.in, Mobile: +91-9891440441,
      
        `,
    },
    {
      State: "New Delhi",
      AERC_Research: ` Shri Jeewan Singh 
      Technical Assistant (Economics)
      Directorate of Economics & Statistics
      Department of Agriculture & Farmers Welfare, Ministry of Agriculture & Farmers Welfare
      Shastri Bhawan, New Delhi - 110001.
      email: jeewan.singh@gov.in (Mob.9811545018)
         `,
    },
    {
      State: "New Delhi",
      AERC_Research: `  Mrs. Divya Sharma 
      Junior Statistical Officer
      Agro-Economics Research (AER) Division,
      Directorate of Economics and Statistics, Department of Agriculture, Cooperation and Farmers' Welfare, Ministry of Agriculture and Farmers' Welfare, GoI
      Room No. 102A, F-Wing, Shastri Bhawan, Rajendra Prasad Road, New Delhi- 110 001
      email: divya.sharma94@gov.in
      
         `,
    },
    {
      State: "New Delhi",
      AERC_Research: ` Shri Vaibhav Gupta
      Technical Assistant (Economics)
      Agro-Economics Research (AER) Division,
      Directorate of Economics and Statistics, Department of Agriculture and Farmers' Welfare,
      Ministry of Agriculture and Farmers' Welfare, Government of India.
      Room No. 103, F-Wing, Shastri Bhawan, Rajendra Prasad Road, New Delhi- 110 001
      email: vkg.vaibhav@gov.in
      `,
    },
    {
      State: "New Delhi",
      AERC_Research: ` Shri Ramesh Yadav 
      Assistant Economic Adviser, 
      Agro-Economics Research (AER) Division,
      Directorate of Economics and Statistics,
      Department of Agriculture, Cooperation and Farmers' Welfare,
      Ministry of Agriculture and Farmers' Welfare, GoI
      119, F-Wing, Shastri Bhawan, New Delhi- 110 001
      email: ramesh.y@nic.in
      
      `,
    },
    {
      State: "New Delhi",
      AERC_Research: `  Shri Yogesh Ashok Raundal 
      Assistant Commissioner (RFS)
      Department of Agriculture & Farmers Welfare, 
      Ministry of Agriculture & Farmers Welfare,
      118, B-Wing, Shastri Bhawan, New Delhi
      Tel- 011-23385981, 8750019900
      (PDMC Scheme)
      email: yogesh.raundal@gov.in
       `,
    },
    {
      State: "New Delhi",
      AERC_Research: ` Shri Shadabul Haque
      Jr. Statistical Officer
      Agro-Economics Research (AER) Section,
      Economics and Statistics Division,
      Department of Agriculture and Farmers' Welfare,
      Room No. 102-A, F-Wing, Shastri Bhawan,
      Rajendra Prasad Road, New Delhi- 110 001
      Mobile: 9612663096
      email: h.shadabul@gov.in `,
    },
    {
      State: "New Delhi",
      AERC_Research: ` Mrs. Tanu Khurana
      Senior Statistical Officer
      Directorate of Economics and Statistics,
      Ministry of Agriculture and Farmers Welfare , Room No. …….., Shastri Bhawan,
      Rajendra Prasad Road, New Delhi- 110 001
      email: tanu.khurana@nic.in
       `,
    },
    {
      State: "New Delhi",
      AERC_Research: ` Shri  L.Sanjoy Meitei,
      Economic Officer (AER),
      Economics, Statistics & Evaluation Div.,
      Department of Agriculture and Farmers Welfare
      Ministry of Agriculture and Farmers Welfare
      Room No. ……………., Shastri Bhawan,
      Rajendra Prasad Road, New Delhi- 110 001
      email: sanjay.meitie@gov.in `,
    },
    {
      State: "New Delhi",
      AERC_Research: ` Mrs. Neha Arora           
      Technical Assistant (Economics)
      AER Section
      Economics, Statistics & Evaluation Division
      Ministry of Agriculture and Farmers Welfare
      Krishi Bhawan, New Delhi
      Room No. …………..., Shastri Bhawan,
      Rajendra Prasad Road, New Delhi- 110 001
      email: nehaa.2628@gov.in
      Tel: +91-11-23380598
      `,
    },
    {
      State: " Karnataka",
      AERC_Research: ` Dr. Ramappa K. B 
      Professor and Head
      Agriculture Development and Rural Transformation Centre (ADRTC)
      Institute for Social and Economic Change (ISEC)
      Nagarabhavi, Bengaluru, Karnataka, India - 560072
      email: ramappa@isec.ac.in; ramskb@gmail.com
      Mobile: 7829862766
      
      `,
    },
    {
      State: "Ahmedabad",
      AERC_Research: `Ranjan Kumar Ghosh 
      Assistant Professor,
      Center for Management in Agriculture Indian Institute of Management Ahmedabad 380015, India
      Phone : +91-79 6632 4913 (O) 
      ranjang@iima.ac.in
      
      `,
    },

    {
      State: "Delhi",
      AERC_Research: `  Dr. Brijesh Jha
      Professor and Head, 
      AERU, Institute of Economic Growth 
      Honorary Director, Agricultural Economics Research Centre, University of Delhi 
      email: brajesh@iegindia.org
      
      `,
    },
    {
      State: "Ahmedabad",
      AERC_Research: ` Prof. Poornima Varma
      Chairman, 
      Centre for Management in Agriculture, Indian Institute of Management, Ahmadabad  380015, India
      email: chr-cma@iima.ac.in `,
    },
    {
      State: "Bihar",
      AERC_Research:
        "DirectorAgro-Economic Research Centre for Bihar & Jharkhand T M Bhagalpur University, Bhagalpur – 812007 (BIHAR)e-mails: sanjayjhaaerc2022@gmail.com, director@aercbhagalpur.orginfo@aercbhagalpur.org, Mob: 9931467656",
    },
    {
      State: "Punjab",
      AERC_Research:
        "Director Agro-Economic Research Centre Dept. of Economics & Sociology Punjab Agricultural University, Ludhiana 141004 (Punjab) Mob: +91 9872301032 hodaes@pau.edu",
    },
    {
      State: "Maharashtra",
      AERC_Research:
        "Director Agro- Economic Research Centre,Pune Gokhale Institute of Politics and Economics  BMCC Road, DECCAN Gymkhana,Pune- 411004, Maharashtra sangeetashroff@hotmail.com gokhaleinstitute@gipe.ac.in ",
    },
    {
      State: "Shimla",
      AERC_Research:
        "Director Agro Economic Research Center Himachal Pradesh Unversity, Summer Hill Shimla - 171005, H.P. (India). aerchpushimla@gmail.com, shimlaaerc@yahoo.in",
    },
    {
      State: "Chennai",
      AERC_Research:
        "Director Agro Economic Research Centre, Madras University of Madras, Chepauk, Chennai- 600 005 (Tamil Nadu) aercchennai@gmail.com",
    },
    {
      State: "Delhi",
      AERC_Research:
        "Director Agro- Economic Research Centre Delhi School of Economic Campus University of Delhi Delhi-110 007 aercdu@rediffmail.com",
    },
    {
      State: "Andhra Pradesh",
      AERC_Research:
        "Director Agro Economic Research Centre, Andhra Pradesh Andhra University, Waltair, Visakhapatnam -530 003 Andhra Pradesh h.purushotham@gmail.com",
    },
    {
      State: "Allahabad",
      AERC_Research:
        "Director Agro- Economic Research Centre, Allahabad University of Allahabad-211004 (U.P.) aercau_alld@rediffmail.com Contact- 09415310739",
    },
    {
      State: "Assam",
      AERC_Research:
        "Director Agro- Economic Research Centre, Jhorhat Assam Agriculture University Campus, Jhorhat-13 -785013- Assam Mob.No. 8134994342 anup_aau@yahoo.com",
    },
    {
      State: "Gujarat",
      AERC_Research:
        " Director Agro- Economic Research Centre, For the states of Gujarat and Rajasthan H.M. Patel Institute of Rural Development, Opp. Nanadalaya Temple, Post Box No. 24, Sardar Patel University, Vallabh Vidyanagar Anand-388120, Dist. Anand, Gujarat.director.aerc@gmail.com, directoraercgujarat@gmail.com Mobile- 09822437451; 7383554616",
    },
    {
      State: "West Bengal",
      AERC_Research:
        " Director Agro-Economic Research Centre,Visva-Bharati Santiniketan,Pin-731235, West Bengaldir.aerc@visva-bharati.ac.in",
    },
    {
      State: "Madhya Pradesh",
      AERC_Research:
        "Director Agro-Economic Research Centre for Madhya Pradesh and Chhattisgarh Jawaharlal Nehru Krishi Viswa Vidyalay, Adhartal, Jabalpur Madhya Pradesh-482004 aerc_jbp@yahoo.co.in",
    },
  ];

  const tabledata = Data.filter((item) => {
    const searchText = search.toLowerCase();
    const state = item.State.toLowerCase().includes(searchText);
    const AERC = item.AERC_Research.toLowerCase().includes(searchText);

    return state || AERC;
  });
  const newdata = tabledata.map((item, i) => {
    return (
      <tr key={i}>
        <td>{item.State}</td>
        <td>{item.AERC_Research}</td>
      </tr>
    );
  });

  return (
    <div>
      <section class="about section">
        <div class="container">
          <div class="row">
            <div class="about-content">
              <h3>Communication With AERC </h3>
              <section class="blog-area">
                <div class="container pt-20">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <div class="finder-journal">
                        <ul>
                          <input
                            placeholder="Search "
                            type="text"
                            onChange={(e) => setsearch(e.target.value)}
                          />
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="rac_meeting">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <table
                      style={{
                        width: "100%",
                        marginLeft: "3px",
                        marginTop: "9px",
                      }}
                    >
                      <tr>
                        <th>State</th>
                        <th>AGRO ECONOMIC RESEARCH CENTRE ACROSS IN INDIA</th>
                      </tr>
                      {newdata}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button className="btn ">
            <CSVLink data={tabledata} filename="test.csv" headers={header}>
              download
            </CSVLink>
          </button>
        </div>
      </section>
    </div>
  );
}
const header = [
  { label: "State", key: "State" },
  { label: "AERC_Research", key: "AERC_Research" },
];
