/* eslint-disable */
import React, { Component } from "react";
import Select from "react-select";
import Papa from "papaparse";
import { CSVLink, CSVDownload } from "react-csv";
// import villageFile from "../assets/PDF FILE/Research/Report 134 Village Study.pdf"
import PDF_IMage from "../assets/images/pdf.png";
import Image126 from "../assets/PDF FILE/Research/AERC_completed/125 Final.pdf";
import Image116 from "../assets/PDF FILE/Research/AERC_completed/116 IWMP.pdf";
import Image134 from "../assets/PDF FILE/Research/AERC_completed/Report 134 Village Study.pdf";
import Image125 from "../assets/PDF FILE/Research/AERC_completed/125 Final.pdf";
import Image127 from "../assets/PDF FILE/Research/AERC_completed/127 Final.pdf";
import PDF_32 from "../assets/PDF FILE/Research/AERC_completed/PDF_32.pdf";
import PDF_34 from "../assets/PDF FILE/Research/AERC_completed/PDF_34.pdf";
import PDF_35 from "../assets/PDF FILE/Research/AERC_completed/PDF_35.pdf";
import PDF_37 from "../assets/PDF FILE/Research/AERC_completed/PDF_37.pdf";
import PDF_38 from "../assets/PDF FILE/Research/AERC_completed/PDF_38.pdf";
import PDF_39 from "../assets/PDF FILE/Research/AERC_completed/PDF_39.pdf";
import PDF_40 from "../assets/PDF FILE/Research/AERC_completed/PDF_40.pdf";
import PDF_41 from "../assets/PDF FILE/Research/AERC_completed/PDF_41.pdf";
import PDF_42 from "../assets/PDF FILE/Research/AERC_completed/PDF_42.pdf";
import PDF_43 from "../assets/PDF FILE/Research/AERC_completed/PDF_43.pdf";
import PDF_44 from "../assets/PDF FILE/Research/AERC_completed/PDF_44.pdf";
import PDF_45 from "../assets/PDF FILE/Research/AERC_completed/PDF_45.pdf";
import PDF_46 from "../assets/PDF FILE/Research/AERC_completed/PDF_46.pdf";
import PDF_47 from "../assets/PDF FILE/Research/AERC_completed/PDF_47.pdf";
import PDF_48 from "../assets/PDF FILE/Research/AERC_completed/PDF_48.pdf";
import PDF_49 from "../assets/PDF FILE/Research/AERC_completed/PDF_49.pdf";
import PDF_50 from "../assets/PDF FILE/Research/AERC_completed/PDF_50.pdf";
import PDF_51 from "../assets/PDF FILE/Research/AERC_completed/PDF_51.pdf";
import PDF_52 from "../assets/PDF FILE/Research/AERC_completed/PDF_52.pdf";
import PDF_53 from "../assets/PDF FILE/Research/AERC_completed/PDF_53.pdf";
import PDF_54 from "../assets/PDF FILE/Research/AERC_completed/PDF_54.pdf";
import PDF_55 from "../assets/PDF FILE/Research/AERC_completed/PDF_55.pdf";
import PDF_56 from "../assets/PDF FILE/Research/AERC_completed/PDF_56.pdf";
import PDF_57 from "../assets/PDF FILE/Research/AERC_completed/PDF_57.pdf";
import PDF_58 from "../assets/PDF FILE/Research/AERC_completed/PDF_58.pdf";
import PDF_59 from "../assets/PDF FILE/Research/AERC_completed/PDF_59.pdf";
import PDF_60 from "../assets/PDF FILE/Research/AERC_completed/PDF_60.pdf";
import PDF_61 from "../assets/PDF FILE/Research/AERC_completed/PDF_61.pdf";
import PDF_62 from "../assets/PDF FILE/Research/AERC_completed/PDF_62.pdf";
import PDF_63 from "../assets/PDF FILE/Research/AERC_completed/PDF_63.pdf";
import PDF_64 from "../assets/PDF FILE/Research/AERC_completed/PDF_64.pdf";
import PDF_65 from "../assets/PDF FILE/Research/AERC_completed/PDF_65.pdf";
import PDF_66 from "../assets/PDF FILE/Research/AERC_completed/PDF_66.pdf";
import PDF_67 from "../assets/PDF FILE/Research/AERC_completed/PDF_67.pdf";
import PDF_68 from "../assets/PDF FILE/Research/AERC_completed/PDF_68.pdf";
import PDF_69 from "../assets/PDF FILE/Research/AERC_completed/PDF_69.pdf";
import PDF_70 from "../assets/PDF FILE/Research/AERC_completed/PDF_70.pdf";
import PDF_71 from "../assets/PDF FILE/Research/AERC_completed/PDF_71.pdf";
import PDF_72 from "../assets/PDF FILE/Research/AERC_completed/PDF_72.pdf";
import PDF_73 from "../assets/PDF FILE/Research/AERC_completed/PDF_73.pdf";
import PDF_74 from "../assets/PDF FILE/Research/AERC_completed/PDF_74.pdf";
import PDF_75 from "../assets/PDF FILE/Research/AERC_completed/PDF_75.pdf";
import PDF_76 from "../assets/PDF FILE/Research/AERC_completed/PDF_76.pdf";
import PDF_77 from "../assets/PDF FILE/Research/AERC_completed/PDF_77.pdf";
import PDF_78 from "../assets/PDF FILE/Research/AERC_completed/PDF_78.pdf";
import PDF_79 from "../assets/PDF FILE/Research/AERC_completed/PDF_79.pdf";
import PDF_80 from "../assets/PDF FILE/Research/AERC_completed/PDF_80.pdf";
import PDF_81 from "../assets/PDF FILE/Research/AERC_completed/PDF_81.pdf";
import PDF_82 from "../assets/PDF FILE/Research/AERC_completed/PDF_82.pdf";
import PDF_83 from "../assets/PDF FILE/Research/AERC_completed/PDF_83.pdf";
import PDF_84 from "../assets/PDF FILE/Research/AERC_completed/PDF_84.pdf";
import PDF_85 from "../assets/PDF FILE/Research/AERC_completed/PDF_85.pdf";
import PDF_86 from "../assets/PDF FILE/Research/AERC_completed/PDF_86.pdf";
import PDF_87 from "../assets/PDF FILE/Research/AERC_completed/PDF_87.pdf";
import PDF_88 from "../assets/PDF FILE/Research/AERC_completed/PDF_88.pdf";
import PDF_89 from "../assets/PDF FILE/Research/AERC_completed/PDF_89.pdf";
import PDF_90 from "../assets/PDF FILE/Research/AERC_completed/PDF_90.pdf";
import PDF_91 from "../assets/PDF FILE/Research/AERC_completed/PDF_91.pdf";
import PDF_92 from "../assets/PDF FILE/Research/AERC_completed/PDF_92.pdf";
import PDF_93 from "../assets/PDF FILE/Research/AERC_completed/PDF_93.pdf";
import PDF_94 from "../assets/PDF FILE/Research/AERC_completed/PDF_94.pdf";
import PDF_95 from "../assets/PDF FILE/Research/AERC_completed/PDF_95.pdf";
import PDF_96 from "../assets/PDF FILE/Research/AERC_completed/PDF_96.pdf";
import PDF_97 from "../assets/PDF FILE/Research/AERC_completed/PDF_97.pdf";
import PDF_98 from "../assets/PDF FILE/Research/AERC_completed/PDF_98.pdf";
import PDF_99 from "../assets/PDF FILE/Research/AERC_completed/PDF_99.pdf";
import PDF_100 from "../assets/PDF FILE/Research/AERC_completed/PDF_100.pdf";
import PDF_101 from "../assets/PDF FILE/Research/AERC_completed/PDF_101.pdf";
import PDF_102 from "../assets/PDF FILE/Research/AERC_completed/PDF_102.pdf";
import PDF_103 from "../assets/PDF FILE/Research/AERC_completed/PDF_103.pdf";
import PDF_104 from "../assets/PDF FILE/Research/AERC_completed/PDF_104.pdf";
import PDF_105 from "../assets/PDF FILE/Research/AERC_completed/PDF_105.pdf";
import PDF_106 from "../assets/PDF FILE/Research/AERC_completed/PDF_106.pdf";
import PDF_107 from "../assets/PDF FILE/Research/AERC_completed/PDF_107.pdf";
import PDF_108 from "../assets/PDF FILE/Research/AERC_completed/PDF_108.pdf";
import PDF_109 from "../assets/PDF FILE/Research/AERC_completed/PDF_109.pdf";
import PDF_110 from "../assets/PDF FILE/Research/AERC_completed/PDF_110.pdf";
import PDF_111 from "../assets/PDF FILE/Research/AERC_completed/PDF_111.pdf";
import PDF_112 from "../assets/PDF FILE/Research/AERC_completed/PDF_112.pdf";
import PDF_113 from "../assets/PDF FILE/Research/AERC_completed/PDF_113.pdf";
import PDF_114 from "../assets/PDF FILE/Research/AERC_completed/PDF_114.pdf";
import PDF_115 from "../assets/PDF FILE/Research/AERC_completed/PDF_115.pdf";
import PDF_117 from "../assets/PDF FILE/Research/AERC_completed/PDF_117.pdf";
import PDF_118 from "../assets/PDF FILE/Research/AERC_completed/PDF_118.pdf";
import PDF_119 from "../assets/PDF FILE/Research/AERC_completed/PDF_119.pdf";
import PDF_120 from "../assets/PDF FILE/Research/AERC_completed/PDF_120.pdf";
import PDF_121 from "../assets/PDF FILE/Research/AERC_completed/PDF_121.pdf";
import PDF_122 from "../assets/PDF FILE/Research/AERC_completed/PDF_122.pdf";
import PDF_123 from "../assets/PDF FILE/Research/AERC_completed/PDF_123.pdf";
import PDF_124 from "../assets/PDF FILE/Research/AERC_completed/PDF_124.pdf";
import PDF_128 from "../assets/PDF FILE/Research/AERC_completed/PDF_128.pdf";
import PDF_129 from "../assets/PDF FILE/Research/AERC_completed/PDF_129.pdf";
import PDF_130 from "../assets/PDF FILE/Research/AERC_completed/PDF_130.pdf";
import PDF_131 from "../assets/PDF FILE/Research/AERC_completed/PDF_131.pdf";
import PDF_132 from "../assets/PDF FILE/Research/AERC_completed/PDF_132.pdf";
import PDF_133 from "../assets/PDF FILE/Research/AERC_completed/PDF_133.pdf";
import PDF_135 from "../assets/PDF FILE/Research/AERC_completed/PDF_135.pdf";

import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { useState } from "react";

function AERCCompleted() {
  const [work, setwork] = useState("");
  const [date, setdate] = useState("");
  const [search, setsearch] = useState("");
  const Work_plan_option = [
    { value: "", label: "select" },
    { value: "2013-15", label: "2013-15" },
    { value: "2016-17", label: "2016-17" },
    { value: "2017-18", label: "2017-18" },
    { value: "2019-20", label: "2019-20" },
    { value: "2021-22", label: "2021-22" },
  ];
  const Date_of_completion = [
    { value: "", label: "select" },
    { value: "1976", label: "1976" },
    { value: "1977", label: "1977" },
    { value: "1980", label: "1980" },
    { value: "1981", label: "1981" },
    { value: "1982", label: "1982" },
    { value: "1984", label: "1984" },
    { value: "1985", label: "1985" },
    { value: "1986", label: "1986" },
    { value: "1987", label: "1987" },
    { value: "1988", label: "1988" },
    { value: "1989", label: "1989" },
    { value: "1990", label: "1990" },
    { value: "1991", label: "1991" },
    { value: "1992", label: "1992" },
    { value: "1993", label: "1993" },
    { value: "1994", label: "1994" },
    { value: "1995", label: "1995" },
    { value: "1996", label: "1996" },
    { value: "1997", label: "1997" },
    { value: "1998", label: "1998" },
    { value: "1999", label: "1999" },
    { value: "2000", label: "2000" },
    { value: "2001", label: "2001" },
    { value: "2002", label: "2002" },
    { value: "2003", label: "2003" },
    { value: "2004", label: "2004" },
    { value: "2006", label: "2006" },
    { value: "2009", label: "2009" },
    { value: "2010", label: "2010" },
    { value: "2011", label: "2011" },
    { value: "2012", label: "2012" },
    { value: "2013", label: "2013" },
    { value: "2014", label: "2014" },
    { value: "2015", label: "2015" },
    { value: "2016", label: "2016" },
    { value: "2017", label: "2017" },
    { value: "2018", label: "2018" },
    { value: "2020", label: "2020" },
    { value: "2022", label: "2022" },
  ];
  const Data = [
    {
      id: 1,
      Report_No: "32",
      Name_of_study:
        "Reasons for Slow Growth Rates of Production and Yields of Wheat in Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1976",
      Pdf_attached: PDF_32,
    },
    {
      id: 2,
      Report_No: "34",
      Name_of_study:
        "Supply of Irrigation Pumps in Indore district of Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1976",
      Pdf_attached: PDF_34,
    },

    {
      id: 3,
      Report_No: "35",
      Name_of_study:
        "Inter- District Comparison of Agricultural Development in Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1977",
      Pdf_attached: PDF_35,
    },
    {
      id: 4,
      Report_No: "37",
      Name_of_study: "Impact of Bailadila Project on the Adivasis of Bastar",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1980",
      Pdf_attached: PDF_37,
    },
    {
      id: 5,
      Report_No: "38",
      Name_of_study:
        "Performance of High Yielding Varieties of Wheat in Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1980",
      Pdf_attached: PDF_38,
    },
    {
      id: 6,
      Report_No: "39",
      Name_of_study:
        "Evaluation of Intensive Development Programme for Pulses in Narsinghpur District, Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1980",
      Pdf_attached: PDF_39,
    },
    {
      id: 7,
      Report_No: "40",
      Name_of_study:
        "A Study on the Economics of Dryland Farming in Rewa District, Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1981",
      Pdf_attached: PDF_40,
    },
    {
      id: 8,
      Report_No: "41",
      Name_of_study:
        "Evaluation of Integrated Dryland Agricultural Development Programme (A Study in Rewa District of Madhya Pradesh)",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1982",
      Pdf_attached: PDF_41,
    },
    {
      id: 9,
      Report_No: "42",
      Name_of_study:
        "Economics of Soybean Cultivation: A Study in Indore District of Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1982",
      Pdf_attached: PDF_42,
    },
    {
      id: 10,
      Report_No: "43",
      Name_of_study:
        "Some Aspects of Irrigation Wells: A Study in Mohgaon T.D. Block of Mandla District, Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1982",
      Pdf_attached: PDF_43,
    },
    {
      id: 11,
      Report_No: "44",
      Name_of_study:
        "Economics of Production of Banana in Khandwa District, Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1984",
      Pdf_attached: PDF_44,
    },
    {
      id: 12,
      Report_No: "45",
      Name_of_study:
        "Fallow land in Madhya Pradesh- Extent and Reasons (Study conducted in Chhatarpur and Mandla District of Madhya Pradesh)",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1984",
      Pdf_attached: PDF_45,
    },
    {
      id: 13,
      Report_No: "46",
      Name_of_study:
        "	Benefits derived by the scheduled castes from Development Programmes in Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1984",
      Pdf_attached: PDF_46,
    },
    {
      id: 14,
      Report_No: "47",
      Name_of_study:
        "Constraints of increasing Rice Production (A Study conducted in Block under Pilot Programme of increasing rice production in Madhya Pradesh)",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1984",
      Pdf_attached: PDF_47,
    },
    {
      id: 15,
      Report_No: "48",
      Name_of_study:
        "A Study of Agricultural Financing by Nationalized Banks in Jabalpur District in Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1985",
      Pdf_attached: PDF_48,
    },
    {
      id: 16,
      Report_No: "49",
      Name_of_study:
        "Agricultural Development in Madhya Pradesh, A District-wise Analysis (1950-51 to 1979-80)",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1986",
      Pdf_attached: PDF_49,
    },
    {
      id: 17,
      Report_No: "50",
      Name_of_study:
        "Underutilization of Irrigation Potential created by Tubewells (A Study in Narsinghpur and Morena District of in Madhya Pradesh)",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1986",
      Pdf_attached: PDF_50,
    },
    {
      id: 18,
      Report_No: "51",
      Name_of_study: "Management of Social Forestry in Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1986",
      Pdf_attached: PDF_51,
    },
    {
      id: 19,
      Report_No: "52",
      Name_of_study:
        "Working of Public Distribution System in Interior areas of Backward districts of Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1987",
      Pdf_attached: PDF_52,
    },
    {
      id: 20,
      Report_No: "53",
      Name_of_study: "Study of Fertilizer Consumption in Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1987",
      Pdf_attached: PDF_53,
    },
    {
      id: 21,
      Report_No: "54",
      Name_of_study:
        "A study on Demands, Supply and Impact of Certified Seeds in Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1988",
      Pdf_attached: PDF_54,
    },
    {
      id: 22,
      Report_No: "55",
      Name_of_study:
        "Evaluation of Special Project on Soybean in Indore district, Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1988",
      Pdf_attached: PDF_55,
    },
    {
      id: 23,
      Report_No: "56",
      Name_of_study:
        "Impact of Training and Visit System in Madhya Pradesh: A Study in Chhindwara and Vidisha districts of Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1988",
      Pdf_attached: PDF_56,
    },
    {
      id: 24,
      Report_No: "57",
      Name_of_study:
        "Evaluation of Wheat Mini kit Programme in Madhya Pradesh (A Study in Morena and Sagar districts)",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1988",
      Pdf_attached: PDF_57,
    },
    {
      id: 25,
      Report_No: "58",
      Name_of_study:
        "A Study on Impact Drought of 1987-88 on Rural Population in Balaghat district, Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1989",
      Pdf_attached: PDF_58,
    },
    {
      id: 26,
      Report_No: "59",
      Name_of_study:
        "Prospects of Changing Cropping Pattern in favour of Pulses and Oilseeds in Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1990",
      Pdf_attached: PDF_59,
    },
    {
      id: 27,
      Report_No: "60",
      Name_of_study:
        "Evaluation of Soil Conservation Programme in Chambal Valley of Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1991",
      Pdf_attached: PDF_60,
    },
    {
      id: 28,
      Report_No: "61",
      Name_of_study:
        "Evaluation of National Pulse Development Project in Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1992",
      Pdf_attached: PDF_61,
    },
    {
      id: 29,
      Report_No: "62",
      Name_of_study:
        "The effect of supply of fertilizers in small bags especially in low consumption rain fed areas to increase fertilizer consumption: A study in Raigarh district, Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1992",
      Pdf_attached: PDF_62,
    },
    {
      id: 30,
      Report_No: "63",
      Name_of_study:
        "	Decentralised Planning in Agriculture and Rural Development: A study in Chhindwara district, Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1993",
      Pdf_attached: PDF_63,
    },
    {
      id: 31,
      Report_No: "64",
      Name_of_study:
        "Transportation of Agricultural Products through Bullock Carts and other modes: A Study in Kundam and Katni Blocks of Jabalpur district, Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1993",
      Pdf_attached: PDF_64,
    },
    {
      id: 32,
      Report_No: "65",
      Name_of_study:
        "Impact of Subsidies on Agricultural Development in Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1994",
      Pdf_attached: PDF_65,
    },
    {
      id: 33,
      Report_No: "66",
      Name_of_study:
        "Economic Viability of Small and Marginal Farms potentialities for increasing Employment and Income: A Study in Jabalpur and Balaghat District, Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1994",
      Pdf_attached: PDF_66,
    },
    {
      id: 34,
      Report_No: "67",
      Name_of_study:
        "Emerging Problems of Agricultural Marketing with Special Focus on Processing and Input Supply",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1995",
      Pdf_attached: PDF_67,
    },
    {
      id: 35,
      Report_No: "68",
      Name_of_study:
        "Impact of National Watershed Development Project for Rainfed Areas (NWDPRA): A Study in Raipur and Khargon Districts, Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1995",
      Pdf_attached: PDF_68,
    },
    {
      id: 36,
      Report_No: "69",
      Name_of_study:
        "Decentralised Planning in Agriculture and Rural Development: A Consolidated Report of AERC Studies",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1996",
      Pdf_attached: PDF_69,
    },
    {
      id: 37,
      Report_No: "70",
      Name_of_study:
        "Emerging Problems of Agricultural Marketing with Special Focus on Processing and Input Supply: A Consolidated Report of AERC Studies",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1996",
      Pdf_attached: PDF_70,
    },
    {
      id: 38,
      Report_No: "71",
      Name_of_study:
        "Analysis of Trend in Operational Holdings: A Study in Tikamgarh and Jhabua District, Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1996",
      Pdf_attached: PDF_71,
    },
    {
      id: 39,
      Report_No: "72",
      Name_of_study:
        "Variations of estimates of Cotton production: A Study in Khandwa, Khargon and Dhar Districts of Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1997",
      Pdf_attached: PDF_72,
    },
    {
      id: 40,
      Report_No: "73",
      Name_of_study:
        "Evaluation of Fish Farmers Development Agencies in Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1997",
      Pdf_attached: PDF_73,
    },
    {
      id: 41,
      Report_No: "74",
      Name_of_study:
        "Promotion of Oilseed Crop (SAFFLOWER) in Non- Traditional Areas of Madhya Pradesh.",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1998",
      Pdf_attached: PDF_74,
    },
    {
      id: 42,
      Report_No: "75",
      Name_of_study:
        "Economics of Poultry and Marketing in Jabalpur District,(M.P.)",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1999",
      Pdf_attached: PDF_75,
    },
    {
      id: 43,
      Report_No: "76",
      Name_of_study:
        "Economics of Pulses Production and Identification of constraints in Raising their Production in Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1999",
      Pdf_attached: PDF_76,
    },
    {
      id: 44,
      Report_No: "77",
      Name_of_study:
        "	Prospects of Value Addition of Forest Produce in Tribal Areas of Madhya Pradesh.",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "1999",
      Pdf_attached: PDF_77,
    },
    {
      id: 45,
      Report_No: "78",
      Name_of_study:
        "	Evaluation of Management of Seed Supply in Oilseeds and Pulses in Madhya Pradesh.",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "2000",
      Pdf_attached: PDF_78,
    },
    {
      id: 46,
      Report_No: "79",
      Name_of_study:
        "		Economics of Pulses production and identification of constraints in raising their Production (A Consolidated Report of AER Centers)",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "19.2.2001",
      Pdf_attached: PDF_79,
    },
    {
      id: 47,
      Report_No: "80",
      Name_of_study:
        "Identification of factors responsible for declining status of Soybean in Madhya Pradesh.",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "2001",
      Pdf_attached: PDF_80,
    },
    {
      id: 48,
      Report_No: "81",
      Name_of_study:
        "	Evaluation of Soft Loan Scheme for the Development of Post Harvest Infrastructure for Horticultural Crops in Madhya Pradesh.",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "2001",
      Pdf_attached: PDF_81,
    },
    {
      id: 49,
      Report_No: "82",
      Name_of_study:
        "	Rapid Impact Evaluation of National Watershed Development Project for Rainfed Areas (NWDPRA) in Raipur district, Madhya Pradesh.",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "29.5.2001",
      Pdf_attached: PDF_82,
    },
    {
      id: 50,
      Report_No: "83",
      Name_of_study:
        "	Evaluation of Central Sector Scheme in Development of Spices in Madhya Pradesh – Impact on Production and Export.",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "July 2001",
      Pdf_attached: PDF_83,
    },
    {
      id: 51,
      Report_No: "84",
      Name_of_study:
        "	Likely Impact of Liberalised Imports and low tariff on edible oils Sector in M.P.(Quick Study)",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: " 2001",
      Pdf_attached: PDF_84,
    },
    {
      id: 52,
      Report_No: "85",
      Name_of_study:
        "Assessing the Existing Training and Testing Facilities for Farm Machinery in Madhya Pradesh.",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: " Aug 2001	",
      Pdf_attached: PDF_85,
    },
    {
      id: 53,
      Report_No: "86",
      Name_of_study:
        "Flow of Credit to Small and Marginal Farmers in Madhya Pradesh.",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: " 15.02.2002	",
      Pdf_attached: PDF_86,
    },
    {
      id: 54,
      Report_No: "87",
      Name_of_study:
        "Impact of Minimum Support Prices on the Agricultural Economy of Madhya Pradesh..",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "Sep 2002	 ",
      Pdf_attached: PDF_87,
    },
    {
      id: 55,
      Report_No: "88",
      Name_of_study:
        "Evaluation of Management of Seed Supply in Oilseeds and Pulses ( A Consolidated Report of 5 AER Centers Studies)",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "June, 2003	",
      Pdf_attached: PDF_88,
    },
    {
      id: 56,
      Report_No: "89",
      Name_of_study:
        "Agricultural Policy in Madhya Pradesh : A Policy Matrix in a Federal Structure",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "2004",
      Pdf_attached: PDF_89,
    },
    {
      id: 57,
      Report_No: "90",
      Name_of_study:
        "Agricultural Input Subsidies in India : Quantum of Subsidies to SC/ST Farmers in Madhya Pradesh and Chhattisgarh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "26.06.2004	",
      Pdf_attached: PDF_90,
    },
    {
      id: 58,
      Report_No: "91",
      Name_of_study: "	Non Farm Employment in Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "2004	",
      Pdf_attached: PDF_91,
    },
    {
      id: 59,
      Report_No: "92",
      Name_of_study:
        "Building up of an Efficient Marketing System to Obviate the Need for a Large Scale State Intervention in Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "July 2004	",
      Pdf_attached: PDF_92,
    },
    {
      id: 60,
      Report_No: "93",
      Name_of_study:
        "	Cultivation of Medicinal crops and Aromatic crops as a means of Diversification in Agriculture.",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: " 2006	",
      Pdf_attached: PDF_93,
    },
    {
      id: 61,
      Report_No: "94",
      Name_of_study:
        "	Success stories from Organic Farming: Do yields and / or returns increase or decrease on organic farms?",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: " 28.3.2006		",
      Pdf_attached: PDF_94,
    },
    {
      id: 62,
      Report_No: "95",
      Name_of_study:
        "	Estimation of Seed, Feed and Wastage ratios for major Foodgrains Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "29.4.2006",
      Pdf_attached: PDF_95,
    },
    {
      id: 63,
      Report_No: "96",
      Name_of_study:
        "		An Economic Analysis of Chickpea and its Value Added Products in Agri Export Zone for Pulses in Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "2009",
      Pdf_attached: PDF_96,
    },
    {
      id: 64,
      Report_No: "97",
      Name_of_study:
        "State Budgetary Resources and Agriculture Development in Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "December 2009",
      Pdf_attached: PDF_97,
    },
    {
      id: 65,
      Report_No: "98/97",
      Name_of_study:
        "Market Access and Constraints in Marketing of Goats and their Products",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "2009",
      Pdf_attached: PDF_98,
    },
    {
      id: 66,
      Report_No: "99",
      Name_of_study:
        "Economics of Fodder Cultivation &amp; its Processing and Marketing in Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "	30.12.2010",
      Pdf_attached: PDF_99,
    },
    {
      id: 67,
      Report_No: "100",
      Name_of_study:
        "Impact of Emerging Marketing Channels in Agricultural Marketing–Benefits to Producer-Sellers and Marketing Cost and Margins of Agricultural Commodities in Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "January 2011",
      Pdf_attached: PDF_100,
    },
    {
      id: 68,
      Report_No: "101",
      Name_of_study:
        "Possibilities and Constraints in increasing Pulses Production in Madhya Pradesh and the Impact of NFSM on Pulses",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "20.7.2012",
      Pdf_attached: PDF_101,
    },
    {
      id: 69,
      Report_No: "102",
      Name_of_study: "Impact of Soil Testing Analysis In Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "10.10.2012",
      Pdf_attached: PDF_102,
    },
    {
      id: 70,
      Report_No: "103",
      Name_of_study: "Bringing Green Revolution in Eastern India 2013",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "2013",
      Pdf_attached: PDF_103,
    },
    {
      id: 71,
      Report_No: "104",
      Name_of_study:
        "Impact of NREGA on wage rates, cost of production, food security, and rural urban migration in Chhattisgarh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "24.12.2012",
      Pdf_attached: PDF_104,
    },
    {
      id: 72,
      Report_No: "105",
      Name_of_study:
        "Impact of NREGA on wage rates, cost of production, food security, and rural urban migration in Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "	26.12.2012",
      Pdf_attached: PDF_105,
    },
    {
      id: 73,
      Report_No: "106",
      Name_of_study: "	Hulling and Milling Ratio of Rice in Chhattisgarh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "12.12.2012",
      Pdf_attached: PDF_106,
    },
    {
      id: 74,
      Report_No: "107",
      Name_of_study: "	Hulling and Milling Ratio of Rice in Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "27.12.2012",
      Pdf_attached: PDF_107,
    },
    {
      id: 75,
      Report_No: "108",
      Name_of_study:
        "Spread of New Varieties of Hybrid Rice and their Impact on the Overall Production and Productivity in Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "22.03.2013",
      Pdf_attached: PDF_108,
    },
    {
      id: 76,
      Report_No: "109",
      Name_of_study:
        "	Assessment of Pre and Post Harvest Losses of wheat and soybean in Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "	 2013",
      Pdf_attached: PDF_109,
    },

    {
      id: 77,
      Report_No: "110",
      Name_of_study:
        "	Assessment of Marketed and Marketable Surplus of wheat, gram &amp; tur in Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "	14.1.2014",
      Pdf_attached: PDF_110,
    },
    {
      id: 78,
      Report_No: "111",
      Name_of_study: "		Problems and Prospects of Oilseeds in Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "		31.5.2014",
      Pdf_attached: PDF_111,
    },
    {
      id: 79,
      Report_No: "112",
      Name_of_study: "State of Agriculture in Madhya Pradesh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "29.10.2013",
      Pdf_attached: PDF_112,
    },
    {
      id: 80,
      Report_No: "113",
      Name_of_study: "State of Agriculture in Chhattisgarh",
      work_plan: "",
      Study_allotted_date: "",
      Time_line_for_completion: "",
      Date_of_completion: "2014",
      Pdf_attached: PDF_113,
    },
    {
      id: 81,
      Report_No: "114",
      Name_of_study:
        "Adoption of Recommended Doses of Fertilizers on Soil Test Basis by Farmers",
      work_plan: "2013-15",
      Study_allotted_date: "04 Oct. 2013(RAC Meeting)",
      Time_line_for_completion: "Nill",
      Date_of_completion: "30.1.2015",
      Pdf_attached: PDF_114,
    },
    {
      id: 82,
      Report_No: "115",
      Name_of_study:
        "Impact of National Food Security Mission (NFSM) on Input Use, Production, Yield and Income in Madhya Pradesh",
      work_plan: "2013-15",
      Study_allotted_date: "04 Oct. 2013(RAC Meeting)",
      Time_line_for_completion: "Nill",
      Date_of_completion: "30.1.2015",
      Pdf_attached: PDF_115,
    },
    {
      id: 83,
      Report_No: "117",
      Name_of_study:
        "Impact of Neem-Coated Urea on Production, Productivity and Soil Health in Madhya Pradesh",
      work_plan: "2016-17",
      Study_allotted_date: "18 Dec. 2015 (RAC Meeting)",
      Time_line_for_completion: "Nill",
      Date_of_completion: "27.1.2017",
      Pdf_attached: PDF_117,
    },
    {
      id: 84,
      Report_No: "118",
      Name_of_study: "	Farmer Suicides in Madhya Pradesh",
      work_plan: "2016-17",
      Study_allotted_date: "18 Dec. 2015 (RAC Meeting)",
      Time_line_for_completion: "Nill",
      Date_of_completion: "13.2.2017",
      Pdf_attached: PDF_118,
    },
    {
      id: 85,
      Report_No: "119",
      Name_of_study: "Farmer Suicides in Chhattisgarh",
      work_plan: "2016-17",
      Study_allotted_date: "18 Dec. 2015 (RAC Meeting)",
      Time_line_for_completion: "Nill",
      Date_of_completion: "05.01.2017",
      Pdf_attached: PDF_119,
    },
    {
      id: 86,
      Report_No: "120",
      Name_of_study:
        "Impact of Soil Health Card Scheme on Production, Productivity and Soil Health in Madhya Pradesh",
      work_plan: "2016-17",
      Study_allotted_date: "18 Dec. 2015 (RAC Meeting)",
      Time_line_for_completion: "Nill",
      Date_of_completion: "31.03.2017",
      Pdf_attached: PDF_120,
    },
    {
      id: 87,
      Report_No: "121",
      Name_of_study:
        "	Problems and Prospects of Production of Small Millets and their Value Added Products in Madhya Pradesh",
      work_plan: "2016-17",
      Study_allotted_date: "18 Dec. 2015 (RAC Meeting)",
      Time_line_for_completion: "Nill",
      Date_of_completion: "10.04.2017",
      Pdf_attached: PDF_121,
    },
    {
      id: 88,
      Report_No: "122",
      Name_of_study: "	Dynamics and Revival of Fallow Lands in Madhya Pradesh.",
      work_plan: "2016-17",
      Study_allotted_date: "18 Dec. 2015 (RAC Meeting)",
      Time_line_for_completion: "Nill",
      Date_of_completion: "24.11.2017",
      Pdf_attached: PDF_122,
    },
    {
      id: 89,
      Report_No: "123",
      Name_of_study:
        "	Assessment of the Status of Dairying and Potential to Improve Socio-economic Status of the Milk Producers and Convergence of all the Central and State Schemes in Chhattisgarh.",
      work_plan: "2016-17",
      Study_allotted_date: "18 Dec. 2015 (RAC Meeting)",
      Time_line_for_completion: "Nill",
      Date_of_completion: "22.08.2016",
      Pdf_attached: PDF_123,
    },
    {
      id: 90,
      Report_No: "124",
      Name_of_study:
        "	Performance Evaluation of Pradhan Mantri Fasal Bima Yojana (PMFBY) in Madhya Pradesh.",
      work_plan: "2017-18",
      Study_allotted_date: "18/08/2017 (RAC Meeting)",
      Time_line_for_completion: "",
      Date_of_completion: "19.3.2018",
      Pdf_attached: PDF_124,
    },
    {
      id: 91,
      Report_No: "128",
      Name_of_study:
        "Strategies to Bridge Yield Gap of Major Crops in Bundelkhand Region of Madhya Pradesh",
      work_plan: "2019-20",
      Study_allotted_date: "11 April 2019(2 nd RAC Meeting",
      Time_line_for_completion: "March 2020",
      Date_of_completion: "29.01.2020",
      Pdf_attached: PDF_128,
    },
    {
      id: 92,
      Report_No: "129",
      Name_of_study:
        "Relevance and Distribution Efficiency of Seed Mini-Kits of Pulses in Madhya Pradesh",
      work_plan: "2019-20",
      Study_allotted_date: "11 April 2019(2 nd RAC Meeting",
      Time_line_for_completion: "March 2020",
      Date_of_completion: "20.10.2020",
      Pdf_attached: PDF_129,
    },
    {
      id: 93,
      Report_No: "130",
      Name_of_study:
        "Strategies to Bridge Yield Gap of Major Crops in Bundelkhand Region of India",
      work_plan: "2019-20",
      Study_allotted_date: "11 April 2019(2 nd RAC Meeting",
      Time_line_for_completion: "March 2020",
      Date_of_completion: "26.06.2020",
      Pdf_attached: PDF_130,
    },
    {
      id: 94,
      Report_No: "131",
      Name_of_study:
        "Improving Water Use Efficiency in India&#39;s Agriculture: The Impact, Benefits and Challenges of Micro-Irrigation under the Pradhan Mantri Krishi Sinchayee Yojana - Per Drop More Crop (PMKSY -PDMC) in Madhya Pradesh",
      work_plan: "2019-20",
      Study_allotted_date: "11 April 2019(2 nd RAC Meeting",
      Time_line_for_completion: "March 2020",
      Date_of_completion: "23.10.2020",
      Pdf_attached: PDF_131,
    },
    {
      id: 95,
      Report_No: "132",
      Name_of_study:
        "Market Imperfection and Farm Profitability in Madhya Pradesh",
      work_plan: "2019-20",
      Study_allotted_date: "11 April 2019(2 nd RAC Meeting",
      Time_line_for_completion: "March 2020",
      Date_of_completion: "12.11.2020",
      Pdf_attached: PDF_132,
    },
    {
      id: 96,
      Report_No: "133",
      Name_of_study: "Village Survey Study in Madhya Pradesh (Piprodh Village)",
      work_plan: "2019-20",
      Study_allotted_date: "11 April 2019(2 nd RAC Meeting",
      Time_line_for_completion: "March 2020",
      Date_of_completion: "20.10.2020",
      Pdf_attached: PDF_133,
    },
    {
      id: 97,
      Report_No: "135",
      Name_of_study:
        "Farmers Perception regarding Production and Marketing of Quality Seed in Madhya Pradesh",
      work_plan: "2021-22",
      Study_allotted_date: "28 th May, 20213 rd RAC meeting",
      Time_line_for_completion: "March 2022",
      Date_of_completion: "11.04.2022",
      Pdf_attached: PDF_135,
    },
  ];

  const newData = Data.filter(
    (item) =>
      item.work_plan.includes(search) ||
      item.Date_of_completion.includes(search) ||
      item.Name_of_study.includes(search)
  );

  const tableData =
    newData &&
    newData.map((item) => {
      return (
        <tr key={item.id}>
          <td>{item.Report_No}</td>
          <td>{item.Name_of_study}</td>
          <td>{item.work_plan}</td>
          <td>{item.Study_allotted_date}</td>
          <td>{item.Time_line_for_completion}</td>
          <td>{item.Date_of_completion}</td>
          {Data.Pdf_attached !== "Nill" ? (
            <td>
              <a href={item.Pdf_attached}>
                <img src={PDF_IMage} />
              </a>
            </td>
          ) : (
            <td>{item.Pdf_attached}</td>
          )}
        </tr>
      );
    });
  // const New_data = Papa.unparse(tableData);
  return (
    <div>
      {/* <Header /> */}

      <section className="about section">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-6 col-md-12 col-sm-12"> */}
            <div className="about-content">
              <h3>AERC complete research</h3>
              <section className="blog-area">
                <div className="container pt-20">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12  ">
                      <div className=" finder-Funded ">
                        <ul>
                          <li>
                            <Select
                              className=""
                              placeholder="Work Plan"
                              options={Work_plan_option}
                              onChange={(e) => {
                                // setwork(e.value);
                                setsearch(e.value);
                              }}
                            />
                          </li>

                          <li className="rating">
                            <Select
                              placeholder="Date of Completion"
                              options={Date_of_completion}
                              onChange={(e) => {
                                setsearch(e.value);
                              }}
                            />
                          </li>
                          <li>
                            <input
                              onChange={(e) => {
                                setsearch(e.target.value);
                              }}
                              placeholder="search"
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="completed_table">
                <table style={{ width: "100%" }}>
                  <tr>
                    <th>
                      Report No./
                      <br />
                      Fil e No.
                    </th>
                    <th>Name of Study</th>
                    <th>work plan</th>
                    <th>Study allotted on Dated</th>
                    <th>Time line given for completion of study</th>
                    <th>Date of Completion</th>
                    <th>Pdf Attached </th>
                  </tr>
                  {tableData}
                </table>
              </div>
            </div>
            <button className="btn ">
              <CSVLink data={newData} headers={header} filename="test.csv">
                Download
              </CSVLink>
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AERCCompleted;

const header = [
  { label: "Report_No", key: "Report_No" },
  { label: "Name_of_study", key: "Name_of_study" },
  { label: "work_plan", key: "work_plan" },
  { label: "Study_allotted_date", key: "Study_allotted_date" },
  { label: "Time_line_for_completion", key: "Time_line_for_completion" },
  { label: "Date_of_completion", key: "Date_of_completion" },
];
