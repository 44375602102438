/* eslint-disable */

import React, { Component } from "react";
import { Link } from "react-router-dom";
import HO from "../assets/images/Teachers/HO.jpg";
import DP from "../assets/images/Teachers/DP.jpg";
import HK from "../assets/images/Teachers/HK.jpg";
import PP from "../assets/images/Teachers/PP.jpeg";
import R from "../assets/images/Teachers/R.jpg";
import PK from "../assets/images/Teachers/PK.jpg";
import A from "../assets/images/Teachers/A.jpeg";
import RB from "../assets/images/Teachers/RB.jpg";
import O from "../assets/images/Teachers/O.jpg";
import SA from "../assets/images/Teachers/SA.jpg";
import SL from "../assets/images/Teachers/SL.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

function ContactDetails() {
  return (
    <div>
      {/* <Header /> */}

      <section class="about section">
        <div class="container">
          <div class="row">
            {/* <div class="col-lg-6 col-md-12 col-sm-12"> */}
            <div class="about-content">
              <h3>
                Contact <span>Details</span>
              </h3>
            </div>
          </div>
        </div>
      </section>
      <div className="contact_details">
        <strong>Dr. Deepak Rathi</strong>
        <br />
        Professor and Director <br />
        Agro-Economic Research Centre for MP & CG
        <br />
        (Ministry of Agriculture, Govt. of India)
        <br />
        2nd Floor College of Agriculture, <br />
        Jawaharlal Nehru Krishi Vishwa Vidyalaya, Jabalpur (M.P.)-482004
        <br />
        <FontAwesomeIcon icon={faPhone} /> 9424601211
        <br />
        <FontAwesomeIcon icon={faEnvelope} /> drathi@jnkvv.org
        <br />
        <br></br>
        <br></br>
      </div>
      {/* </div> */}

      {/* <!-- Start Clients --> */}
      {/* <Footer /> */}
    </div>
  );
}

export default ContactDetails;
