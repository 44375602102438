import React, { useState } from "react";
import Select from "react-select";
import PDF_IMage from "../assets/images/pdf.png";
import PDF_1 from "../assets/PDF FILE/externalfundPDF/PDF_1.pdf";
import PDF_125 from "../assets/PDF FILE/externalfundPDF/PDF_125.pdf";
import PDF_126 from "../assets/PDF FILE/externalfundPDF/PDF_126.pdf";
import PDF_127 from "../assets/PDF FILE/externalfundPDF/PDF_127.pdf";
import PDF_116 from "../assets/PDF FILE/externalfundPDF/PDF_116.pdf";

const ExternalFundedList = () => {
  const [work, setwork] = useState("");
  const [search, setSerch] = useState("");
  const data = [
    {
      Report_No_File_No: 134,
      Name_of_Study:
        "Impact Evaluation of 15 Farmer Producers Organization Projects in Madhya Pradesh (External Funded))",
      work_plan: "2020-21",
      Study_allotted_on_Dated: "-",
      Time_line_given_for_completion_of_study: "-",
      Date_of_Completion: "24.03.2021",
      Pdf_Attached: PDF_1,
    },
    {
      Report_No_File_No: 125,
      Name_of_Study:
        "Impact of Tejaswini rural Woman Empowerment programme on Empowerment of Rural Woman through Cultivation, Processing, and Marketing of Kodo- kutki in Dindori district of Madhya Pradesh (External Funded))",
      work_plan: "2018-19",
      Study_allotted_on_Dated: "-",
      Time_line_given_for_completion_of_study: "-",
      Date_of_Completion: "15.09.2018",
      Pdf_Attached: PDF_125,
    },
    {
      Report_No_File_No: 126,
      Name_of_Study:
        "Impact of Tejaswini rural Woman Empowerment programme on Empowerment of Rural Woman through Vegetable Production in Dindori and Chatarpur district of Madhya Pradesh(External Funded))",
      work_plan: "22-9-2018",
      Study_allotted_on_Dated: "",
      Time_line_given_for_completion_of_study: "",
      Date_of_Completion: "22.9.2018",
      Pdf_Attached: PDF_126,
    },
    {
      Report_No_File_No: 127,
      Name_of_Study:
        "Impact of Tejaswini rural Woman Empowerment programme on Empowerment of Rural Woman through System of Rice Intensification (SRI) in Mandla and Balaghat district of Madhya Pradesh (External Funded))",
      work_plan: "24-09-2018",
      Study_allotted_on_Dated: "",
      Time_line_given_for_completion_of_study: "",
      Date_of_Completion: "24.09.2018",
      Pdf_Attached: PDF_127,
    },
    {
      Report_No_File_No: 116,
      Name_of_Study:
        "Impact of Pradhan Mantri Krishi Sinchai Yojana (Watershed Development) on Land Use and Cropping Pattern in Madhya Pradesh (External Funded)",
      work_plan: "2015-16",
      Study_allotted_on_Dated: "-",
      Time_line_given_for_completion_of_study: "-",
      Date_of_Completion: "18.4.2016",
      Pdf_Attached: PDF_116,
    },
  ];
  const year_option = [
    { value: "", label: "select" },
    { value: "2020-21", label: "2020-21" },
    { value: "2018-19", label: "2018-19" },
    { value: "2015-16", label: "2015-16" },
  ];
  const tabledata = data
    .filter((item) => {
      const searchText = search.toLowerCase();
      const study = item.Name_of_Study.toLowerCase().includes(searchText);
      return study && item.work_plan.includes(work);
    })
    .map((item) => {
      return (
        <tr key={item.Report_No_File_No}>
          <td>{item.Report_No_File_No}</td>
          <td>{item.Name_of_Study}</td>
          <td>{item.work_plan}</td>
          <td>{item.Study_allotted_on_Dated}</td>
          <td>{item.Time_line_given_for_completion_of_study}</td>
          <td>{item.Date_of_Completion}</td>
          {data.Pdf_Attached !== "" ? (
            <td>
              <a href={item.Pdf_Attached}>
                <img src={PDF_IMage} />
              </a>
            </td>
          ) : (
            <td>{item.Pdf_Attached}</td>
          )}
        </tr>
      );
    });
  return (
    <div>
      {/* <Header /> */}

      <section className="about section">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-6 col-md-12 col-sm-12"> */}
            <div className="about-content">
              <h3>External fundlist studies</h3>
              <section className="blog-area">
                <div className="container pt-20">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12  ">
                      <div className="finder-journal  ">
                        <ul>
                          <li>
                            <Select
                              className=""
                              placeholder="Work Plan"
                              //   defaultValue={}

                              onChange={(e) => {
                                setwork(e.value);
                              }}
                              options={year_option}
                            />
                          </li>
                          <input
                            placeholder="Search study "
                            type="text"
                            onChange={(e) => setSerch(e.target.value)}
                          />
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="completed_table">
                <table style={{ width: "100%" }}>
                  <tr>
                    <th>
                      Report No./
                      <br />
                      Fil e No.
                    </th>
                    <th>Name of Study</th>
                    <th>work plan</th>
                    <th>Study allotted on Dated</th>
                    <th>Time line given for completion of study</th>
                    <th>Date of Completion</th>
                    <th>Pdf Attached </th>
                  </tr>
                  {tabledata}

                  {/* </tr>
                      <tr>
                        <td>134</td>
                        <td>
                          Impact Evaluation of 15 Farmer Producers' Organization
                          Projects in Madhya Pradesh (External Funded))
                        </td>
                        <td>2020-21</td>
                        <td>-</td>
                        <td>-</td>
                        <td>24.03.2021</td>
                        <td>
                          <img
                            src={PDF_IMage}
                            alt=""
                            onClick={() => pdfOperner("134")}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>125</td>
                        <td>
                          Impact of Tejaswini rural Woman Empowerment programme on
                          Empowerment of Rural Woman through Cultivation,
                          Processing, and Marketing of Kodo- kutki in Dindori
                          district of Madhya Pradesh (External Funded))
                        </td>
                        <td rowSpan={3}>2018-19</td>
                        <td rowSpan={3}>-</td>
                        <td rowSpan={3}> -</td>
                        <td>15.09.2018</td>
                        <td>
                          <img
                            src={PDF_IMage}
                            alt=""
                            onClick={() => pdfOperner("125")}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>126</td>
                        <td>
                          Impact of Tejaswini rural Woman Empowerment programme on
                          Empowerment of Rural Woman through Vegetable Production in
                          Dindori and Chatarpur district of Madhya Pradesh (External
                          Funded))
                        </td>
                        <td>22.9.2018</td>
                        <td>
                          <img
                            src={PDF_IMage}
                            alt=""
                            onClick={() => pdfOperner("126")}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>127</td>
                        <td>
                          Impact of Tejaswini rural Woman Empowerment programme on
                          Empowerment of Rural Woman through System of Rice
                          Intensification (SRI) in Mandla and Balaghat district of
                          Madhya Pradesh (External Funded))
                        </td>
                        <td>24.09.2018</td>
                        <td>
                          <img
                            src={PDF_IMage}
                            alt=""
                            onClick={() => pdfOperner("127")}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>116</td>
                        <td>
                          Impact of Pradhan Mantri Krishi Sinchai Yojana (Watershed
                          Development) on Land Use and Cropping Pattern in Madhya
                          Pradesh (External Funded)
                        </td>
                        <td>2015-16</td>
                        <td>-</td>
                        <td>-</td>
                        <td>18.4.2016</td>
                        <td>
                          <img
                            src={PDF_IMage}
                            alt=""
                            onClick={() => pdfOperner("116")}
                          />
                        </td>
                      </tr> */}
                </table>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </section>

      {/* <!-- Start Clients --> */}
      {/* <Footer /> */}
    </div>
  );
};

export default ExternalFundedList;
