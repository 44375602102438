/* eslint-disable */

import React from "react";
import Footer from "./footer";
import Header from "./header";
import Slider8 from "../assets/images/slider/8.jpg";
import "../assets/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faPaperclip,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import PDF_IMage from "../assets/images/pdf.png";
import PDF_1 from "../assets/PDF FILE/RAC/1 Mintues Final_3rd RAC Meeting_FINAL 27 Aug 2021.pdf";
import PDF_2 from "../assets/PDF FILE/RAC/2 Minutes of Directors Meeting held on 27th August 2021.pdf";
import PDF_3 from "../assets/PDF FILE/RAC/3 RAC Meeting (Workplan 2019-20).pdf";
import PDF_4 from "../assets/PDF FILE/RAC/4 CCCS meeting 2015.pdf";
import PDF_5 from "../assets/PDF FILE/RAC/5 CCOS Meeting 2013.pdf";
import { CSVLink } from "react-csv";
import { useId } from "react";

import { useState } from "react";
import Select from "react-select";

export default function Course_alloted() {
  const [teacher, setteacher] = useState("");
  const [fil_class, setclass] = useState("");
  const [session, setsession] = useState("");
  const [search, setsearch] = useState("");
  //   const Data = [
  //     {
  //       id: 1,
  //       heldOn: "28-05-2021",
  //       chaimanship: "Shri Sanjay Agarwal, Secretary (DAC&amp;FW)",
  //       meet_name: "Research Advisory Committee (RAC)",
  //       pdf: PDF_1,
  //     },
  //     {
  //       id: 2,
  //       heldOn: "27-08-2021",
  //       chaimanship:
  //         "Sh. Lal Sanglur, Sr. Economic and Statistical Adviser, Directorate of Economics &amp; Statistics, DA&amp;FW, Ministry of Agriculture and Farmers&#39;",
  //       meet_name: "Directors Meeting",
  //       pdf: PDF_2,
  //     },
  //     {
  //       id: 3,
  //       heldOn: "27-02-2019",
  //       chaimanship: "Shri Sanjay Agarwal, Secretary (DAC&amp;FW)",
  //       meet_name: "Research Advisory Committee (RAC)",
  //       pdf: PDF_3,
  //     },
  //     {
  //       id: 4,
  //       heldOn: "17/11/2015",
  //       chaimanship:
  //         "Prof. S. M. Jharwal, Chancellor, Indira Gandhi National Tribal Univeresity, Amarkantak (M.P.) and Chairman of Review Committee of AERC functioning under Plan Scheme of DES, MoA, GoI, New Delhi",
  //       meet_name: "Review Committee",
  //       pdf: "Nill",
  //     },
  //     {
  //       id: 5,
  //       heldOn: "18-11-2015",
  //       chaimanship:
  //         "Shri P.C. Bodh, Adviser, Agro Economic Research Division, Directorate of Economics and Statistics (DES)",
  //       meet_name: "Research Advisory Committee (RAC)/CCOS",
  //       pdf: PDF_4,
  //     },
  //     {
  //       id: 6,
  //       heldOn: "16-09-2013",
  //       chaimanship: "Details Awaited",
  //       meet_name: "Research Advisory Committee (RAC)/CCOS",
  //       pdf: PDF_5,
  //     },
  //   ];

  const yearOptions = [
    { value: "", label: "select" },
    { value: "2022-23", label: "2022-23" },
  ];
  //   const Data = [
  //     {
  //       s_no: "1",
  //       Filter_Authors_Name: "Mr.R.S.Bareliya",
  //       Title:
  //         "Impact of Rajiv Gandhi Integrated Watershed area Management Mission on land use and cropping pattern in Madhya Pradesh",
  //       Organization:
  //         "Agro-Economic Research Center, Department of Agricultural Economics and Farm Management, JNKVV, Jabalpur",
  //       Mode_of_Training: "off-Line",
  //       Place: "Jabalpur",
  //       FROM: "20 Aug.",
  //       To: "21 Aug.",
  //       Filter_Duration_Days: "2",
  //       Filter_Year: "2015",
  //     },
  //     {
  //       s_no: "2",
  //       Filter_Authors_Name: "Dr. Deepak Rathi",
  //       Title:
  //         "Winter School on “Advanced Analytics in Developing Market Intelligence",
  //       Organization:
  //         "G.B. Pant University of Agriculture & Technology, Pabtnagar (Uttranchal).",
  //       Mode_of_Training: "off-Line",
  //       Place: "Pabtnagar, Uttranchal",
  //       FROM: "14 Nov.",
  //       To: "04 Dec.",
  //       Filter_Duration_Days: "20",
  //       Filter_Year: "2017",
  //     },
  //     {
  //       s_no: "3",
  //       Filter_Authors_Name: "Dr. Deepak Rathi",
  //       Title: "Business Planning for Developing New Agro-Technology Enterprises",
  //       Organization:
  //         "ICAR-Central Tuber Crops Research Institute, Sreekariyam,.",
  //       Mode_of_Training: "off-Line",
  //       Place: "Thiruvanathapuram, Kerala ",
  //       FROM: "02 SEP.",
  //       To: "11 SEP.",
  //       Filter_Duration_Days: "10",
  //       Filter_Year: "2015",
  //     },
  //     {
  //       s_no: "4",
  //       Filter_Authors_Name: "Dr. Deepak Rathi",
  //       Title:
  //         "Capacity Building Programme for University Teachers on Essential Teaching Skills",
  //       Organization: "G.B. Pant University of Agriculture & Technology",
  //       Mode_of_Training: "off-Line",
  //       Place: "Pabtnagar, Uttranchal ",
  //       FROM: "13 JULY.",
  //       To: "19 JULY.",
  //       Filter_Duration_Days: "7",
  //       Filter_Year: "2015",
  //     },
  //     {
  //       s_no: "5",
  //       Filter_Authors_Name: "Dr. Deepak Rathi",
  //       Title: "Exploring opportunities for agriculture under WTO",
  //       Organization:
  //         "National Institute of Agricultural Extension Management (MANAGE)",
  //       Mode_of_Training: "off-Line",
  //       Place: "Hyderabad, Telangana ",
  //       FROM: "12 JUNE.",
  //       To: "15 JUNE.",
  //       Filter_Duration_Days: "4",
  //       Filter_Year: "2017    ",
  //     },
  //     {
  //       s_no: "7",
  //       Filter_Authors_Name: "Mr.S.S. Thakur",
  //       Title:
  //         "Impact of Rajiv Gandhi Integrated Watershed area Management Mission on land use and cropping pattern in Madhya Pradesh",
  //       Organization:
  //         "Agro-Economic Research Center, Department of Agricultural Economics and Farm Management, JNKVV, Jabalpur",
  //       Mode_of_Training: "off-Line",
  //       Place: "Jabalpur",
  //       FROM: "20 AUG.",
  //       To: "21 AUG.",
  //       Filter_Duration_Days: "2",
  //       Filter_Year: "2015",
  //     },
  //     {
  //       s_no: "10",
  //       Filter_Authors_Name: "Dr. Deepak Rathi",
  //       Title:
  //         "Remote Sensing & GIS Technology and Applications for University Teachers & Government Officials",
  //       Organization: "Indian Institute of Remote Sensing (IIRS), ISRO Dehradun ",
  //       Mode_of_Training: "Online",
  //       Place: "Dehradun",
  //       FROM: "13 JUNE.",
  //       To: "01-JULY.",
  //       Filter_Duration_Days: "18",
  //       Filter_Year: "2020",
  //     },
  //     {
  //       s_no: "11",
  //       Filter_Authors_Name: "Dr.H. K. Niranjan",
  //       Title: "T4D: Digital Data Collection for Monitoring & Evaluation",
  //       Organization: "FICCI Aditya Birla CSR Centre For Excellence",
  //       Mode_of_Training: "Online",
  //       Place: "FICCI Federation House, Tansen Marg New Delhi",
  //       FROM: "27 SEP.",
  //       To: "01 OCT.",
  //       Filter_Duration_Days: "5",
  //       Filter_Year: "2021",
  //     },
  //     {
  //       s_no: "12",
  //       Filter_Authors_Name: "Dr.H. K. Niranjan",
  //       Title: "Mobile Data Collection Using Survey CTO",
  //       Organization: "Trestle Research and Consulting Pvt. Ltd.",
  //       Mode_of_Training: "Online",
  //       Place: "SurveyCTO, Dobility India, Ahmadabad",
  //       FROM: "22 JUNE.",
  //       To: "26 JUNE.",
  //       Filter_Duration_Days: "5",
  //       Filter_Year: "2020",
  //     },
  //     {
  //       s_no: "13",
  //       Filter_Authors_Name: "Dr.H. K. Niranjan",
  //       Title: "Teaching Tools to Knockdown the Lockdown",
  //       Organization: "College of Agriculture, Balaghat",
  //       Mode_of_Training: "Online",
  //       Place: "JNKVV, Jabalpur (M.P.)",
  //       FROM: "16 MAY.",
  //       To: "20 MAY.",
  //       Filter_Duration_Days: "5",
  //       Filter_Year: "2020",
  //     },
  //     {
  //       s_no: "14",
  //       Filter_Authors_Name: "Dr.H. K. Niranjan",
  //       Title:
  //         "Impact of Rajiv Gandhi Integrated Watershed Area Management Mission on Land Use and Cropping Pattern in Madhya Pradesh",
  //       Organization:
  //         "Agro-Economic Research Centre, Deptt. of Agricultural Economics",
  //       Mode_of_Training: "Off-line",
  //       Place: "JNKVV, Jabalpur (M.P.)",
  //       FROM: "20 AUG.",
  //       To: "21 AUG.",
  //       Filter_Duration_Days: "2",
  //       Filter_Year: "2015",
  //     },
  //     {
  //       s_no: "15",
  //       Filter_Authors_Name: "Dr.H. K. Niranjan",
  //       Title: "Microwave (SAR) Remote Sensing for Natural Resource",
  //       Organization: "Department of space, Govt. of India, Dehradun",
  //       Mode_of_Training: "Off-line",
  //       Place: "JNKVV, Jabalpur (M.P.)",
  //       FROM: "03 FEB.",
  //       To: "29 MARCH.",
  //       Filter_Duration_Days: "",
  //       Filter_Year: "2014",
  //     },
  //     {
  //       s_no: "16",
  //       Filter_Authors_Name: "Dr.H. K. Niranjan",
  //       Title: "Mathematical Modeling",
  //       Organization: "MPCST, Bhopal",
  //       Mode_of_Training: "Off-line",
  //       Place: "MGCGVV,Chitrakoot, Satna (M.P)",
  //       FROM: "02 MARCH.",
  //       To: "3 MARCH.",
  //       Filter_Duration_Days: "2",
  //       Filter_Year: "2013",
  //     },
  //     {
  //       s_no: "17",
  //       Filter_Authors_Name: "Dr.H. K. Niranjan",
  //       Title: "Commodity Futures Market",
  //       Organization: "Deptt. of Agricultural Economics",
  //       Mode_of_Training: "Off-line",
  //       Place: "JNKVV, Jabalpur (M.P.)",
  //       FROM: "24 FEB.",
  //       To: "25 FEB.",
  //       Filter_Duration_Days: "1",
  //       Filter_Year: "2012",
  //     },
  //     {
  //       s_no: "18",
  //       Filter_Authors_Name: "Dr.H. K. Niranjan",
  //       Title: "spatial data analysis using R and GeoDA",
  //       Organization:
  //         "Department of Agricultural Economics and Farm Management and Department of Extension Education,  JNKVV, Jabalpur",
  //       Mode_of_Training: "Off-line",
  //       Place: "NAHEP",
  //       FROM: "24 JAN.",
  //       To: "14 FEB",
  //       Filter_Duration_Days: "21",
  //       Filter_Year: "2023",
  //     },
  //     {
  //       s_no: "19",
  //       Filter_Authors_Name: "Dr.H. K. Niranjan",
  //       Title: "Hands-on Training on RS and GIS using QGIS",
  //       Organization: "College of Agricultural Engineering. JNKVV, Jabalpur",
  //       Mode_of_Training: "Online",
  //       Place: "NAHEP",
  //       FROM: "14 FEB.",
  //       To: "16 MARCH",
  //       Filter_Duration_Days: "21",
  //       Filter_Year: "2022",
  //     },
  //   ];

  const Data = [
    {
      Filter_Class: "M.Sc. Ag 1st Sem.",
      Code: "Ag.Econ.-501 (Old)",
      Subject_Name: "Macro Economics",
      Credit: "2(2+0)",
      Filter_Teacher: "Dr. Deepak Rathi",
      Filter_Session: "2022-23",
      Search: "",
    },
    {
      Filter_Class: "M.Sc. Ag 1st Sem.",
      Code: "AEC-504",
      Subject_Name: "Macro Economics and Policy",
      Credit: "2(2+0",
      Filter_Teacher: "Dr. Deepak Rathi",
      Filter_Session: "2022-23",
      Search: "",
    },
    {
      Filter_Class: "M.Sc. Ag 1st Sem.",
      Code: "AEC-511",
      Subject_Name: "International Economics",
      Credit: "3(2+1)",
      Filter_Teacher: "Dr. Deepak Rathi and Dr. R.S.Chouhan",
      Filter_Session: "2022-23",
      Search: "",
    },
    {
      Filter_Class: "MBA (Ag) 1stSem.",
      Code: "ABM-509",
      Subject_Name: "International Trade for Agriculture Products",
      Credit: "2(2+0)",
      Filter_Teacher: "Dr. Deepak Rathi",
      Filter_Session: "2022-23",
      Search: "",
    },
    {
      Filter_Class: "Ph.D. 1st Sem. (Old)",
      Code: "AEC-602",
      Subject_Name: "Advanced Macro Economic Analysis",
      Credit: "2(2+0)",
      Filter_Teacher: "Dr. Deepak Rathi",
      Filter_Session: "2022-23",
      Search: "",
    },
    {
      Filter_Class: "Ph.D. 2nd Sem.",
      Code: "AEC-604",
      Subject_Name: "Advanced Production Economics",
      Credit: "3(2+1)",
      Filter_Teacher: "Dr. Deepak Rathi",
      Filter_Session: "2022-23",
      Search: "",
    },
    {
      Filter_Class: "MBA(Ag) 2ndSem.",
      Code: "ABM-504",
      Subject_Name: "Managerial Accounting and Control",
      Credit: "2(2+0)",
      Filter_Teacher: "Dr. Deepak Rathi and Dr. Deepak Par",
      Filter_Session: "2022-23",
      Search: "",
    },
    {
      Filter_Class: "M.Sc. Ag 2nd Sem.",
      Code: "AEC-502",
      Subject_Name: "Agricultural Production Economics",
      Credit: "2(1+1)",
      Filter_Teacher: "Dr. Deepak Rathi",
      Filter_Session: "2022-23",
      Search: "",
    },
    {
      Filter_Class: "Ph.D. 2nd Sem.",
      Code: "AEC-604",
      Subject_Name: "Advanced Production Economics",
      Credit: "3(2+1)",
      Filter_Teacher: "Dr. Deepak Rathi",
      Filter_Session: "2022-23",
      Search: "",
    },
    {
      Filter_Class: "M.Sc. Seminar",
      Code: "Ag. Econ 591",
      Subject_Name: "Seminar In-Charge",
      Credit: "0+1",
      Filter_Teacher: "Dr. H.O.Sharma",
      Filter_Session: "2022-23",
      Search: "",
    },
    {
      Filter_Class: "Ph.D. Seminar",
      Code: "Ag. Econ 691",
      Subject_Name: "Seminar In-Charge",
      Credit: "0+1",
      Filter_Teacher: "Dr. H.O.Sharma",
      Filter_Session: "2022-23",
      Search: "",
    },
    {
      Filter_Class: "MBA Seminar",
      Code: "Mba 591",
      Subject_Name: "Seminar In-Charge",
      Credit: "0+1",
      Filter_Teacher: "Dr. H.O.Sharma",
      Filter_Session: "2022-23",
      Search: "",
    },
  ];
  const Teacher = [
    { label: "select", value: "" },
    {
      value: "Rathi",
      label: "Rathi",
    },
    {
      value: "Chouhan",
      label: "Chouhan",
    },
    {
      value: "Par",
      label: "Par",
    },
    {
      value: "Sharma",
      label: "Sharma",
    },
  ];
  const class_opt = [
    { label: "select", value: "" },
    { label: "M.Sc. Ag 1st Sem.", value: "M.Sc. Ag 1st Sem." },
    { label: "MBA (Ag) 1stSem.", value: "MBA (Ag) 1stSem." },
    { label: "Ph.D. 1st Sem. (Old)", value: "Ph.D. 1st Sem. (Old)" },
    { label: "Ph.D. 2nd Sem.", value: "Ph.D. 2nd Sem." },
    { label: "MBA(Ag) 2ndSem.", value: "MBA(Ag) 2ndSem." },
    { label: "M.Sc. Ag 2nd Sem.", value: "M.Sc. Ag 2nd Sem." },
    { label: "M.Sc. Seminar", value: "M.Sc. Seminar" },
    { label: "Ph.D. Seminar", value: "Ph.D. Seminar" },
    { label: "MBA Seminar", value: "MBA Seminar" },
  ];
  const tabledata = Data.filter((item) => {
    const searchText = search.toLowerCase();
    const subjectMatches = item.Subject_Name.toLowerCase().includes(searchText);
    const classMatches = item.Filter_Class.toLowerCase().includes(searchText);

    return (
      (subjectMatches || classMatches) &&
      item.Filter_Teacher.includes(teacher) &&
      item.Filter_Session.includes(session) &&
      item.Filter_Teacher.includes(teacher) &&
      item.Filter_Class.includes(fil_class)
    );
  });
  const newdata = tabledata.map((item, i) => {
    return (
      <tr key={i}>
        <td>{item.Filter_Class}</td>
        <td>{item.Code}</td>
        <td>{item.Subject_Name}</td>
        <td>{item.Credit}</td>
        <td>{item.Filter_Teacher}</td>
        <td>{item.Filter_Session}</td>
      </tr>
    );
  });

  return (
    <div>
      <section class="about section">
        <div class="container">
          <div class="row">
            <div class="about-content">
              <h3>Course Alloted </h3>
              <section class="blog-area">
                <div class="container pt-20">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <div class="finder-journal">
                        <ul>
                          <li>
                            <Select
                              placeholder="Select Teacher "
                              onChange={(e) => {
                                setteacher(e.value);
                              }}
                              options={Teacher}
                            />
                          </li>

                          <li class="rating">
                            <Select
                              placeholder="Select Class"
                              onChange={(e) => {
                                setclass(e.value);
                              }}
                              options={class_opt}
                            />
                          </li>
                          <li class="rating">
                            <Select
                              placeholder="Select Session"
                              onChange={(e) => {
                                setsession(e.value);
                              }}
                              options={yearOptions}
                            />
                          </li>
                          {/* <li class="rating">
                            <Select
                              placeholder="Search "
                              onChange={(e) => {
                                setsearch(e.value);
                              }}
                            />
                          </li> */}
                          <input
                            placeholder="Search "
                            type="text"
                            onChange={(e) => setsearch(e.target.value)}
                          />
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="rac_meeting">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <table
                      style={{
                        width: "100%",
                        marginLeft: "3px",
                        marginTop: "9px",
                      }}
                    >
                      <tr>
                        <th>Class</th>
                        <th>Code</th>
                        <th>Subject Name</th>
                        <th>Credit</th>
                        <th> Teacher</th>
                        <th>Session</th>
                      </tr>
                      {newdata}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button className="btn ">
            <CSVLink data={tabledata} filename="test.csv" headers={header}>
              download
            </CSVLink>
          </button>
        </div>
      </section>
    </div>
  );
}
const header = [
  { label: "Filter-Class", key: "Filter_Class" },
  { label: "Code", key: "Code" },
  { label: "Subject Name", key: "Subject_Name" },
  { label: "Credit", key: "Credit" },
  { label: "Filter-Teacher", key: "Filter_Teacher" },
  { label: "Filter-Session", key: "Filter_Session" },
  { label: "Search", key: "Search" },
];
