

import React from 'react';
import axios from 'axios';
// import logo from '../../assets/images/logo.png';
// import 'bootstrap/dist/js/bootstrap.js';
// import Header from '../common/header.js';
// import Footer from "../common/footer.js";
// import '../../assets/css/style.css';
// import '../../assets/css/bootstrap.min.css';
// import { API_BASE_URL } from '../../config/api.js'
import { Link } from "react-router-dom";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
// import { io } from 'socket.io-client';
// import firebase from "../common/firbase"


import swal from 'sweetalert';

// import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'

// var socket = null

export default class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      visible: false,
      username: '',
      password: '',
      input: {},
      errors: {},
      MessageUsername: '',
      MessagePassword: '',
      type: 'password',

      SocialUserinfo: {},
      accessToken: "",
      isLoggedIn: false,
      socialType: '',
      useridSocial: '',
      nameSocial: '',
      emailSocial: '',
      userImage: '',
      social_id: '',
      fullNameuser: '',
      verifiedUser: '',
      CountryCodeValue: '',
      // phone : '',
      phonecode: ''

    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showHide = this.showHide.bind(this);
  }


  // componentDidMount(){
  //   const messaging = firebase.messaging();
  //     if ("serviceWorker" in navigator) {
  //       navigator.serviceWorker
  //         .register("../firebase-messaging-sw.js")
  //         .then((registration) => {
  //           console.log("Registration successful, scope is:", registration);
  //           messaging.getToken({
  //             vapidKey: 'BKdy_wyu7jDF5zPKUhxPy_gUCgq61t0QScIrUVAH5QzndUx53g1Z6uZwhb1gRdrFDuogRbHFEaBRZ0CX-xFeoTs'

  //           })
  //             .then((currentToken) => {
  //               if (currentToken) {
  //                 // this.onBackgroundMessage(this.props)
  //                 console.log('current token for client: ', currentToken);
  //                 this.setState({ NotificationID: currentToken })
  //               } else {
  //                 console.log('No registration token available. Request permission to generate one.');
  //               }
  //             }).catch((err) => {
  //               console.log('An error occurred while retrieving token. ', err);
  //               // catch error while creating client token
  //             });
  //         })
  //         .catch(function (err) {
  //           console.log("Service worker registration failed, error:", err);
  //         });
  //     }
  // }

  Somethingwrong = () => {
    swal({
      text: "Something Went Wrong",
      icon: "warning",
      dangerMode: true,
    })
  }

  handleChange(evt) {
    this.setState({ [evt.target.name]: evt.target.value });
    let input = this.state.input;
    input[evt.target.name] = evt.target.value;
    this.setState({ input });
    this.setState({ phonecode: evt.target.value });
  }

  handleSubmit = event => {
    event.preventDefault();
    event.target.reset();
    // if (this.validate()) {
    let input = {};
    input["username"] = "";
    input["password"] = "";
    // this.setState({input:input});


    const sendData = new FormData();
    sendData.append('username', this.state.username)
    sendData.append('password', this.state.password);
    sendData.append('device_type', "web");

    this.setState({ visible: true })
    axios({
      method: 'post',
      // url: "https://foncab.plazaetc.com/login",
      url: "http://127.0.0.1:3003/api/login",
      data: sendData

    }).then(response => {

      console.log('res', response)
      this.setState({ visible: false })
        if (response.data.message === "Login Successfull.") {
          if (response.data.result.role_name === "Parent") {
            console.log("message", response.data.message)
            console.log("message", response.data.token)
            localStorage.setItem('UserToken', response.data.token)
            localStorage.setItem('email', response.data.result.email)
            // localStorage.setItem('firstname', response.data.result.email)
            // localStorage.setItem('usertype', response.data.user_type)
            this.props.history.push("/parentProfile")
          }
          else if (response.data.result.role_name === "Instructor") {
            console.log("message", response.data.message)
            console.log("message", response.data.token)
            localStorage.setItem('UserToken', response.data.token)
            localStorage.setItem('email', response.data.result.email)
            // localStorage.setItem('firstname', response.data.result.email)
            // localStorage.setItem('usertype', response.data.user_type)
            this.props.history.push("/Inst_Profile")
          }
          else if (response.data.result.role_name === "Student") {
            console.log("message", response.data.message)
            console.log("message", response.data.token)
            localStorage.setItem('UserToken', response.data.token)
            localStorage.setItem('email', response.data.result.email)
            // localStorage.setItem('firstname', response.data.result.email)
            // localStorage.setItem('usertype', response.data.user_type)
            this.props.history.push("/")
          }
        }
    })
      .catch(error => {
        // alert("Something Went Wrong")
        this.Somethingwrong()
      })
    // }
  }


  // validateEmail(email) {
  //   var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   return re.test(email);
  // }


  validatePhone(phone) {
    var re = /^[0-9\b]+$/;
    return re.test(phone);
  }


  validate() {

    let input = this.state.input;

    let errors = {};

    let isValid = true;

    if (!input["username"]) {
      isValid = false;
      errors["username"] = "Email/Phone Number is required.";
    }

    // if (typeof input["username"] !== "undefined") {
    //   if (!this.validateEmail(input["username"]) && !this.validatePhone(input["username"])) {
    //     var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //     isValid = false;
    //     errors["username"] = "Please enter valid email.";
    //   }
    // }

    if (!input["password"]) {

      isValid = false;

      errors["password"] = "Password is required.";

    }


    // if (typeof input["password"] !== "undefined") {
    //   var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

    //   if (!strongRegex.test(input["password"])) {

    //     isValid = false;

    //     errors["password"] = "Password must contain at least One Number One Upper Case Letter One Lower Case Letter One Special Character.";

    //   }

    // }


    this.setState({ errors: errors });
    return isValid;

  }
  showHide(e) {
    this.setState({
      type: this.state.type === 'input' ? 'password' : 'input'
    })
  }


  responseFacebook = (response) => {
    console.log("full res : : ", response)
    console.log(response.name)
    // console.log(response.type)
    console.log(response.accessToken)
    this.setState({ accessToken: response.accessToken })
    // console.log(response.picture.data)
    console.log(response.userID)
    console.log(response.graphDomain)


    if (this.state.accessToken != null) {
      this.setState({ isLoggedIn: true });

      this.setState({ fullNameuser: response.name });
      this.setState({ socialType: "facebook" });
      this.setState({ nameSocial: response.name });
      this.setState({ userImage: response.picture.data });
      this.setState({ useridSocial: response.userID });
      this.socialLogin(this.state.nameSocial, this.state.useridSocial, this.state.socialType)
    }
  }


  componentClicked = (res) => {
    console.log("clickeed result : ", res);
  }






  PhoneCode = (id, countryCode) => {
    this.setState({ CountryCodeValue: id })
    this.setState({ phonecode: countryCode.countryCode })
    console.log("gurubhai", countryCode.countryCode)
  }

  render() {

    return (
      <main>
        {/* <Header></Header> */}
        <div class="main login_main">
          <div class="container-fluid custome_container">
            <div class="login_all">
              <div class="row">

                <div className="row">
                  <div className="col-md-8 col-sm-12 bg_banner_login">
                  </div>

                  <div class="col-sm-6">
                    <div class="login_aside">
                      <div class="aside_cont">
                        <div class="aside_logo">
                          {/* <img src={logo} alt=""/> */}
                        </div>
                        <div class="aside_deta">
                          <h4>Welcome to</h4>
                          <h2>FONCAB</h2>
                          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua.
                          </p>
                        </div>
                        <div class="aside_footer">
                          <p>Don't have an account?</p>
                          <p><b><Link to="signup">Register</Link></b></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="login_bside">
                      <h2>Log In</h2>
                      <p>Log In to try our amazing servuces</p>

                      <form onSubmit={this.handleSubmit} method="post" >
                        <div class="form-group">
                          <label for="email" className="fxt-label" >Email Address</label>
                          <input type="email" className="form-control" name="username" onChange={this.handleChange}
                            value={this.state.input.username} placeholder="Enter your email" required="" />
                        </div>
                        <div class="form-group">
                          <label for="Password" className="fxt-label">Password</label>
                          <input type="Password" className="form-control" onChange={this.handleChange} value={this.state.password} name="password" placeholder="Password" required="" />
                        </div>
                        <div class="form-group">
                          <Link to="forget_password"><p className="frgt_pass">Forgot password?</p></Link>
                        </div>

                        <div class="form-group mb-3">
                          <button type="submit" class="fxt-btn-fill">Sign In</button>
                        </div>
                        <div class="fxt-divider-text">Dont have an account?</div>
                        <div class="form-group mb-3">
                          <Link to="signup"><button type="submit" class="fxt-btn-fill">Sign Up</button></Link>
                        </div>

                      </form>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <Footer></Footer> */}
        </div>
      </main>

    )

  }
}





