/* eslint-disable */

import React from "react";

import Slider8 from "../assets/images/slider/8.jpg";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperclip,
  faPlay,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";

function AboutAerc() {
  const data = [
    {
      s_no: 1,
      Particulars: "Delhi",

      institute: "Delhi University, New Delhi",
      year: 1954,
      Coverage: "Delhi, Haryana, Uttranchal",
    },
    {
      s_no: 2,
      Particulars: "Chennai",

      institute: "Madras University",
      year: 1954,
      Coverage: "T.N., Kerala, Pondicheri, Minicoy & Lakshdweep",
    },
    {
      s_no: 3,
      Particulars: "Pune",

      institute: "Gokhle Institute of Politics and Economics",
      year: 1954,
      Coverage: "Maharashtra, Karnataka, Goa, Daman & Diu",
    },
    {
      s_no: 4,
      Particulars: "Shantiniketan",

      institute: "Vishwa Bharti",
      year: 1954,
      Coverage: "W.B., Slkkim, Andman & Nicobar",
    },
    {
      s_no: 5,
      Particulars: "Jabalpur",

      institute: "Jawaharlal Nehru Krishi Vishwa Vidyalaya, Jabalpur",
      year: 1958,
      Coverage: "Madhya Pradesh and Chhattisgarh",
    },
    {
      s_no: 6,
      Particulars: "Jorhat",

      institute: "Assam Agricultural University",
      year: 1960,
      Coverage:
        "Assam, Meghalaya, Nagaland, Manipur, Tripura, Arunachal Pradesh & Mizoram",
    },
    {
      s_no: 7,
      Particulars: "Vallabh Vidyanagar",

      institute: "Sardar Patel University",
      year: 1961,
      Coverage: "Gujarat, Rajasthan, Dadar & Nagar Haveli",
    },
    {
      s_no: 8,
      Particulars: "Allahabad",

      institute: "Allahabad University",
      year: 1963,
      Coverage: "Uttar Pradesh",
    },
    {
      s_no: 9,
      Particulars: "Waltair",

      institute: "Andhra Univeresity",
      year: 1967,
      Coverage: "A.P. & Orissa",
    },
    {
      s_no: 10,
      Particulars: "Shimla",

      institute: "H. P. University",
      year: 1972,
      Coverage: "H.P. & J&K",
    },
    {
      s_no: 11,
      Particulars: "Ludhiana",

      institute: "Punjab Agriculture University",
      year: 1996,
      Coverage: "Punjab",
    },
    {
      s_no: 12,
      Particulars: "Bhagalpur",

      institute: "Bhagalpur University",
      year: 1996,
      Coverage: "Bihar, Jharkhand",
    },
  ];

  const newData = data.map((item, i) => (
    <tr key={i}>
      <td>{item.s_no}</td>
      <td>{item.Particulars}</td>
      <td>{item.institute}</td>
      <td>{item.year}</td>
      <td>{item.Coverage}</td>
    </tr>
  ));
  return (
    <div>
      {/* <Header /> */}
      <div className="breadcrumbs overlay" data-stellar-background-ratio="0.7">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="content">
                <h2>About The AERC</h2>
                <ul className="list">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <a href="#">Pages</a>
                  </li>
                  <li>
                    <Link to="aboutAerc">About Us</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="about section">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-6 col-md-12 col-sm-12"> */}
            <div className="about-content">
              <h3>
                About <span>AERC</span>
              </h3>
              {/* <div className="about-img overlay image_float">
                <div className="button">
                  <a href={Slider8} className="video video-popup mfp-iframe">
                    <i className="fa fa-play">
                      <FontAwesomeIcon icon={faPlay} />
                    </i>
                  </a>
                </div>
                <img src={Slider8} alt="#" />
              </div> */}
              <p>
                Way back in 1952 i.e., almost with the inception of planning in
                India, the idea was mooted to establish Agro-Economic Research
                Centre for each state to serve as the catalyst of region
                specific agricultural transformation. It was one of the earliest
                institutional efforts to propagate and interweave knowledge
                based farming to a specific region. The year 1954 was a landmark
                in the history of agricultural economics in India as in that
                year the Ministry of Food and Agriculture, Government of India,
                established 4 Agro- Economic Research Centres at Delhi, Pune,
                Chennai and Santiniketan. 2 more centres were established in
                1958, one at Jorhat (Assam) and another at Sehore (Madhya
                Pradesh). In 1961 one more Agro Economic Research Centre was
                established at VallabhVidyaNagar.In the later years three more
                centres were established at Allahabad, Waltair and Shimla
                bringing the total number of centres in the country to 10. In
                1966 two more centres were established at Ludhiana and
                Bhagalpur. Thus, at present there are 12 AER Centres functioning
                in the country in coordination of three institute i.e. IEG New
                Delhi, IIM, Ahmadabad and ADRTC, Bangalore.{" "}
              </p>
              <br></br>
              <p>
                The Agro Economic Research Centres are autonomous bodies. These
                function as separate departments under the administrative and
                functional control of the university / institute where these are
                located. These centres are totally financed by Government of
                India through grant-in-aid. Agro Economic Research Centre for
                central India (M.P. and Rajasthan) was established at Sehore on
                22nd December, 1958. In May, 1960 it was shifted from Sehore to
                Gwalior. The jurisdiction of Gwalior centre was confined to
                Madhya Pradesh. With the establishment of J. N. Krishi Vishwa
                Vidyalaya the administrative control of the Centre was
                transferred to J.N.K.V.V. from 1st April, 1965. After division
                of Madhya Pradesh to Chhattisgarh, the Centre also looks after
                the Agro Economic Problems of Chhattisgarh State.
              </p>
              <div

              // className="about-img overlay image_float"
              >
                <div className="text-center">
                  <b style={{ fontSize: 18 }}>
                    {" "}
                    Economic Research Centre in India
                  </b>
                </div>
                <table className="mt-2">
                  <tr>
                    <th>S. No.</th>
                    <th>Particulars</th>
                    <th>Institute Where Located</th>
                    <th>Year of Setup</th>
                    <th>Coverage</th>
                  </tr>
                  {newData}
                  <tr>
                    <th colSpan={5}>CO-ORDINATING UNITS IN INDIA</th>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>Delhi</td>
                    <td>Institute of Economic Growth (IEG)</td>
                    <td>1961</td>
                    <td rowSpan={3}>ALL INDIA</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Ahamdabad</td>
                    <td>Indian Institute of Management (IIM)</td>
                    <td>1965</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Banglore</td>
                    <td>
                      "Institute for Social & Economic Change (ISEC)
                      Agricultural Development and Rural Transformation Centre
                      (ADRTC)"
                    </td>
                    <td>1972</td>
                  </tr>
                </table>
              </div>

              {/* <p>sunt in culpa qui officiccaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. sed ut perspiciatis unde sunt in culpa qui officia deserunt mollitm id est laborum. sed ut perspi. sunt in culpa
                            qui officia deserunt mollit anim id est laborum. sed ut perspiciatis Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. sed ut perspi.</p> */}

              {/* </div> */}
              {/* <div className="col-lg-6 col-md-12 col-sm-12"> */}
            </div>
            {/* </div> */}
          </div>
        </div>
      </section>

      {/* <!-- Start Clients --> */}
      {/* <Footer /> */}
    </div>
  );
}

export default AboutAerc;
