import React from "react";

const Staff_engaging = () => {
  return (
    <>
      <div className>
        <section className="about section">
          <div className="container">
            <div className="">
              <div className="about-content">
                <h3>Staff Attached</h3>
                <table>
                  <tr>
                    <th>Staff Engaged in other Activities </th>
                  </tr>
                  <tr>
                    <td>
                      The staff of the centre also performed teaching activity
                      such as Chairmen/member of the students Advisory Committee
                      & involved in teaching and research work of PG students of
                      the Department of Agricultural Economics and Farm
                      Management and Department of Extension Education,
                      Delivered Lectures in training programme, Nodal officer
                      (EAP-NAHEP), Member of various committees constituted for
                      performing different activities, performed examination
                      work and other curricular activities.
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Staff_engaging;
