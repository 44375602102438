import React from "react";
import Directory_IGKV_2023 from "../assets/PDF FILE/directorypdf/Directory_IGKV_2023.pdf";
import Directory_JNKVV from "../assets/PDF FILE/directorypdf/Directory_JNKVV.pdf";
import Directory_RVSKV from "../assets/PDF FILE/directorypdf/Directory_RVSKV.pdf";
import PDF_IMage from "../assets/images/pdf.png";

function Directory() {
  const data = [
    {
      university: "Directory Of IGKV(Indira Gandhi Krishi Vishwavidyalaya)",
      pdf: Directory_IGKV_2023,
    },
    {
      university: "Directory of JNKVV(Jawaharlal Nehru Krishi Vishwavidyalaya)",
      pdf: Directory_JNKVV,
    },
    {
      university:
        " Directory of RVSKV(Rajmata Vijayaraje Scindia Krishi Vishwavidyalaya)",
      pdf: Directory_RVSKV,
    },
  ];
  const tabledata = data.map((item) => {
    return (
      <tr>
        <td className="text-center">{item.university}</td>
        {data.pdf !== "Nill" ? (
          <td className="text-center">
            <a href={item.pdf}>
              <img src={PDF_IMage} style={{ cursor: "pointer" }} />
            </a>
          </td>
        ) : (
          <td>{data.pdf}</td>
        )}
      </tr>
    );
  });
  return (
    <>
      {" "}
      <div className>
        <section className="about section">
          <div className="container">
            <div className="">
              <div className="about-content">
                <h3>Directories</h3>
                <table>
                  <tr>
                    <th>UNIVERSITY </th>
                    <th>ATTACHED PDF</th>
                  </tr>
                  {tabledata}
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Directory;
