/* eslint-disable */
import React, { Component } from "react";
import { CSVLink, CSVDownload } from "react-csv";
import Footer from "./footer";
import Header from "./header";
import Slider8 from "../assets/images/slider/8.jpg";
import "../assets/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faPaperclip,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import PDF_IMage from "../assets/images/pdf.png";
import PDF_1 from "../assets/PDF FILE/otherOnlineMeeting/1 Minutes Ramesh Yadav.docx";
import PDF_2 from "../assets/PDF FILE/otherOnlineMeeting/2 Minutes-18 Nove. 2021 DCP IEG.docx";
import PDF_3 from "../assets/PDF FILE/otherOnlineMeeting/3 Minutes Dairy Study.doc";
import PDF_5 from "../assets/PDF FILE/otherOnlineMeeting/5 Minutes  24Feb2022 with AERCUs.pdf";
import PDF_6 from "../assets/PDF FILE/otherOnlineMeeting/6 Minutes  28 Feb2022.pdf";
import PDF_7 from "../assets/PDF FILE/otherOnlineMeeting/7 Minuts  06th May 2022.pdf";
import PDF_8 from "../assets/PDF FILE/otherOnlineMeeting/8 Minuts 12th May 2022.pdf";
import PDF_10 from "../assets/PDF FILE/otherOnlineMeeting/10 Minuts Review Meeting 18 May.pdf";
import PDF_11 from "../assets/PDF FILE/otherOnlineMeeting/11 Minuts 02 June 22 (1).pdf";
import PDF_12 from "../assets/PDF FILE/otherOnlineMeeting/01 Minutes of Meeting 11 April 2023.pdf";
import PDF_13 from "../assets/PDF FILE/otherOnlineMeeting/02 Minutes of Meeting 08 JUNE 2023.pdf";
import PDF_14 from "../assets/PDF FILE/otherOnlineMeeting/03 Minutes of meeting 11 July 2023.pdf";
import PDF_15 from "../assets/PDF FILE/otherOnlineMeeting/04 Mintutes of Meeting  26 July 2023.pdf";
import PDF_16 from "../assets/PDF FILE/otherOnlineMeeting/05 Minutes of Meeting 01 Aug 2023.pdf";
import PDF_17 from "../assets/PDF FILE/otherOnlineMeeting/06 Minutes of Meeting 07 Aug 2023.pdf";
import PDF_18 from "../assets/PDF FILE/otherOnlineMeeting/11 Minutes of CFB Meeting held on 22.08.2023.pdf";
import PDF_8080 from "../assets/PDF FILE/otherOnlineMeeting/8080 On Mobile App CSIF.pdf";
import PDF_8081 from "../assets/PDF FILE/otherOnlineMeeting/8081 Directors Meeting 6 Dec 2023 (New).pdf";
import PDF_24 from "../assets/PDF FILE/otherOnlineMeeting/024 - Minutes Final 1st Workshop.pdf";
import { useState } from "react";
import Select from "react-select";

export default function OtherMeeting() {
  const [year, setYear] = useState("");
  const [unit, setUnit] = useState("");

  const Data = [
    {
      id: 1,
      year: "2022",
      unit: "Ministry of Agriculture and Farmers Welfare, Govt. of India",
      host: "Ministry",
      title: "Review Meeting on progress of studies of work-plan (2021-22)",
      participated:
        "IEG Study_Report (Final)- AERC Pune_Draft_AERC VV Nagar Study AERC Jabalpur Draft Report",
      pdf: PDF_11,
    },
    {
      id: 2,
      year: "2022",
      unit: "Ministry of Agriculture and Farmers Welfare, Govt. of India",
      host: "Ministry",
      title: "Review Meeting on progress of studies of work-plan (2021-22)",
      participated: "AERU (ADRTC ISEC) of Workplan 2021-22 (Part 4)",
      pdf: PDF_10,
    },
    {
      id: 3,
      year: "2022",
      unit: "Institute of Economic Growth: IEG, New Delh",
      host: "Dr. Brijesh Jha",
      title: "Status of the Study",
      participated:
        "Decentralized Procurement Scheme for procurement of Wheat and Paddy",
      pdf: "Nill",
    },
    {
      id: 4,
      year: "2022",
      unit: "Ministry of Agriculture and Farmers Welfare, Govt. of India",
      host: "Ministry",
      title: "Review Meeting on progress of studies of work-plan (2021-22)",
      participated: "AER Unit (CMA) [Part 2]  AER Unit (IEG) [Part 3]",
      pdf: PDF_8,
    },
    {
      id: 5,
      year: "2022",
      unit: "Ministry of Agriculture and Farmers Welfare, Govt. of India",
      host: "Ministry",
      title: "Review Meeting on progress of studies of work-plan (2021-22)",
      participated: "All AERC and Units (Part 1)",
      pdf: PDF_7,
    },
    {
      id: 6,
      year: "2022",
      unit: "Agricultural Development and Rural Transformation Centre, Bengluru",
      host: "Dr. I Maruti",
      title: "Meeting for tables Discussion of the SHC",
      participated: "All Centre Related to Study",
      pdf: PDF_6,
    },
    {
      id: 7,
      year: "2021",
      unit: "Ministry of Agriculture and Farmers Welfare, Govt. of India",
      host: "Ministry",
      title: "Review Meeting on progress of studies of work-plan (2021-22)",
      participated: "IEG-Delhi and CMA-Ahmedabad with related AERC",
      pdf: PDF_5,
    },
    {
      id: 8,
      year: "2021",
      unit: "Indian Institute of Management Ahmedabad, Gujarat, India.",
      host: "Poornima Verma",
      title: "Meeting for proposal and questionnaire discussion",
      participated:
        "An analysis of Factors Contributing to the Production and Productivity of Pulses",
      pdf: "Nill",
    },
    {
      id: 9,
      year: "2021",
      unit: "Sardar Patel University, Anand, Gujarat",
      host: "Dr.S.S.Kalamkar VV, Nagar Gujarat",
      title: "Virtual Workshop on questionnaire",
      participated:
        "Cost of Milk Production and Gross Return to Milk Producers’",
      pdf: PDF_3,
    },
    {
      id: 10,
      year: "2021",
      unit: "Institute of Economic Growth: IEG, New Delhi",
      host: "Prof.Brajesh Jha",
      title: "Virtual Workshop on proposal and questionnaire discussion",
      participated:
        "Procurement of Wheat and Paddy under Minimum Support Price (MSP)",
      pdf: PDF_2,
    },
    {
      id: 11,
      year: "2021",
      unit: "Ministry of Agriculture and Farmers Welfare, Govt. of India",
      host: "Office Visit AERC, Jabalpur",
      title: "Dr. Ramesh Kumar Yadav, IES,",
      participated: "Official Visit for financial and administrative issue",
      pdf: PDF_1,
    },
    {
      year: "11/05/2023",
      unit: "Ministry of Agriculture and Farmers Welfare, Govt. of India",
      host: "Sr. ESA, Economics & Statistics Division",
      title:
        "Online meeting on Pre-testing of models of periodic evaluation of schemes of DA&FW",
      participated: "4 Unit with Prayagraj AERC)",
      pdf: "",
    },
    {
      year: "11/04/2023",
      unit: "Ministry of Agriculture and Farmers Welfare, Govt. of India",
      host: "Sr. ESA, Economics & Statistics Division",
      title: "Online/Hybrid mode Meeting on Evaluation/feedback on Schemes",
      participated:
        "Decided to put in place a mechanism for concurrent evaluation of schemes at regular periodicity with ISEC,IEG,Pune, Prayagraj & Ludhiyana",
      pdf: PDF_12,
    },
    {
      year: "08/06/2023",
      unit: "Ministry of Agriculture and Farmers Welfare, Govt. of India",
      host: "Shri Manoj Ahuja Secretary, DA&FW",
      title:
        "In person meeting for Strenghtened & Staffing issues of AERC/Us would be resolved at earliest",
      participated: "All AERC & Us",
      pdf: PDF_13,
    },
    {
      year: "11/07/2023",
      unit: "Ministry of Agriculture and Farmers Welfare, Govt. of India",
      host: "Shri Rakesh Ranjan, Additional Secretary, DA&FW",
      title:
        "VC Meeting on concurrent feedback system/ impact assessment of major schemes of DA&",
      participated: "All AERC/Us",
      pdf: PDF_14,
    },
    {
      year: "26/07/2023",
      unit: "Ministry of Agriculture and Farmers Welfare, Govt. of India",
      host: "Dr. (Smt.) Pramotita Satish) Advisor AER Division, DA&FW",
      title:
        "VC Meeting on concurrent feedback system/ impact assessment on submit of sampling size and Budget estimate",
      participated: "All AERC/Us",
      pdf: PDF_15,
    },
    {
      year: "01/08/2023",
      unit: "Ministry of Agriculture and Farmers Welfare, Govt. of India",
      host: "Dr. (Smt.) Pramotita Satish) Advisor AER Division, DA&FW",
      title:
        "VC Meeting to discuss concurrent feedback system/ impact assessment of scheme to be completed in 2-3 Months",
      participated: "All AERC/Us",
      pdf: PDF_16,
    },
    {
      year: "07/08/2023",
      unit: "Ministry of Agriculture and Farmers Welfare, Govt. of India",
      host: "Dr. (Smt.) Pramotita Satish) Advisor AER Division, DA&FW",
      title: "Online Meeting for finalize sample frame",
      participated: "AERC Jabalpur with Commissioner  of RFS Division",
      pdf: PDF_17,
    },
    {
      year: "11/08/2023",
      unit: "Ministry of Agriculture and Farmers Welfare, Govt. of India",
      host: "Sr. ESA, ES&S Div, DA&FW",
      title:
        "Discuss the status, sampling framework & sample size of each of the schemes selected for  Concurrent Feedback/Impact evaluation",
      participated: "All AERC",
      pdf: "Nill",
    },
    {
      year: "22/08/2023",
      unit: "Ministry of Agriculture and Farmers Welfare, Govt. of India",
      host: "Shri Manoj Ahuja, Secretary (DA&FW), Ministry of Agriculture and Farmers' New Delhi",
      title:
        "Online meeting on feedback survey, inter-alia, include target population, state coverage, sample size, questionnaire. The selected sample size adequately represents the target population",
      participated: "All AERC/AUs",
      pdf: PDF_18,
    },
    {
      year: "08 Dec. 2023",
      unit: "VC mode Division(DA&FW)",
      host: "Chairpersonship of Adviser, AER, ES&E Division (DA&FW) ",
      title:
        "Discuss issues and feedback on Demo version 1&2 of the mobile application interface for Concurrent Feedback of Schemes of DA&FW",
      participated: "All AERCs/Us and  IT Team",
      pdf: PDF_8080,
    },
    {
      year: "06 Dec 2023",
      unit: "Institute of Economic Growth: IEG, New Delh (Hybrid Mode)",
      host: "Chairpersonship of Sr. ESA, ES&E Division (DA&FW) ",
      title:
        "Sr.ESA  discuss, finalize and allocate Research Advisory Committee (RAC) approved study proposals  and requested to fix a timeframe for completion of studies allotted in the work plan 2023-24, ",
      participated: "All AERCs/Us",
      pdf: PDF_8081,
    },
    {
      year: "15 to 16 February 2024",
      unit: "AER Centre, JNKVV, Jabalpur (M.P.) (Off-line)",
      host: "Chairpersonship of Dr. (Smt.) Pramotita Satish) Advisor AER Section (ES&E Division) (DA&FW)  ",
      title:
        "1st Workshop for Finalization of Methodology and Questionnaire of the Studies Finalized in RAC for the Work Plan 2023-24 ",
      participated: "All AERCs/Us/Ministry of Agriculture, New Delhi",
      pdf: PDF_24,
    },
  ];
  const yearOptions = [
    { value: "", label: "Select" },
    { value: "2022", label: "2022" },
    { value: "2021", label: "2021" },
    { value: "2023", label: "2023" },
  ];

  const unitOptions = [
    { value: "", label: "Select" },
    {
      value: "Ministry of Agriculture and Farmers Welfare, Govt. of India",
      label: "Ministry of Agriculture and Farmers Welfare, Govt. of India",
    },
    {
      value:
        "Agricultural Development and Rural Transformation Centre, Bengluru",
      label:
        "Agricultural Development and Rural Transformation Centre, Bengluru",
    },
    {
      value: "Institute of Economic Growth: IEG, New Delh",
      label: "Institute of Economic Growth: IEG, New Delh",
    },
    {
      value: "Sardar Patel University, Anand, Gujarat",
      label: "Sardar Patel University, Anand, Gujarat",
    },
    {
      value: "Indian Institute of Management Ahmedabad,Gujarat, India.",
      label: "Indian Institute of Management Ahmedabad,Gujarat, India.",
    },
  ];
  const tableData = Data.filter(
    (item) => item.year.includes(year) && item.unit.includes(unit)
  );
  const New_data = tableData.map((item) => {
    return (
      <tr id={item.id}>
        <td>{item.year}</td>
        <td>{item.unit}</td>
        <td>{item.host}</td>
        <td>{item.title}</td>
        <td>{item.participated}</td>
        {item.pdf !== "Nill" ? (
          <td>
            <a href={item.pdf}>
              <img src={PDF_IMage} />
            </a>
          </td>
        ) : (
          <td>{item.pdf}</td>
        )}
      </tr>
    );
  });
  return (
    <div>
      <section class="about section">
        <div class="container">
          <div class="row">
            <div class="about-content">
              <h3>
                Meetings <span>Detail</span>
              </h3>
              <section class="blog-area">
                <div class="container pt-20">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <div class="finder">
                        <ul>
                          <li>
                            <Select
                              onChange={(e) => {
                                setYear(e.value);
                              }}
                              options={yearOptions}
                            />
                          </li>

                          <li class="rating">
                            <Select
                              onChange={(e) => {
                                setUnit(e.value);
                              }}
                              options={unitOptions}
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <table
                    style={{
                      width: "100%",
                      marginLeft: "3px",
                      marginTop: "9px",
                    }}
                  >
                    <tr>
                      <th>Held on</th>
                      <th>Unit</th>
                      <th>Hosting /Chairman</th>
                      <th>Title of the Meeting</th>
                      <th>Participated Centre/Unit</th>
                      <th>Attached PDF Files</th>
                    </tr>

                    {New_data}
                  </table>
                  <button className="btn">
                    <CSVLink data={tableData} headers={head}>
                      Download
                    </CSVLink>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
const head = [
  { label: "Held on", key: "year" },
  { label: "Unit", key: "unit" },
  { label: "Hosting /Chairman", key: "host" },
  { label: "Title of the Meeting", key: "title" },
  { label: "Participated Centre/Unit", key: "participated" },
];
