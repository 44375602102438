/* eslint-disable */
import React, { Component } from "react";
import Select from "react-select";
import Papa from "papaparse";
import { CSVLink, CSVDownload } from "react-csv";
// import villageFile from "../assets/PDF FILE/Research/Report 134 Village Study.pdf"
import PDF_IMage from "../assets/images/pdf.png";
import Image126 from "../assets/PDF FILE/Research/AERC_completed/125 Final.pdf";
import Image116 from "../assets/PDF FILE/Research/AERC_completed/116 IWMP.pdf";
import Image134 from "../assets/PDF FILE/Research/AERC_completed/Report 134 Village Study.pdf";
import Image125 from "../assets/PDF FILE/Research/AERC_completed/125 Final.pdf";
import Image127 from "../assets/PDF FILE/Research/AERC_completed/127 Final.pdf";
import PDF_32 from "../assets/PDF FILE/Research/AERC_completed/PDF_32.pdf";
import PDF_34 from "../assets/PDF FILE/Research/AERC_completed/PDF_34.pdf";
import PDF_35 from "../assets/PDF FILE/Research/AERC_completed/PDF_35.pdf";
import PDF_37 from "../assets/PDF FILE/Research/AERC_completed/PDF_37.pdf";
import PDF_38 from "../assets/PDF FILE/Research/AERC_completed/PDF_38.pdf";
import PDF_39 from "../assets/PDF FILE/Research/AERC_completed/PDF_39.pdf";
import PDF_40 from "../assets/PDF FILE/Research/AERC_completed/PDF_40.pdf";
import PDF_41 from "../assets/PDF FILE/Research/AERC_completed/PDF_41.pdf";
import PDF_42 from "../assets/PDF FILE/Research/AERC_completed/PDF_42.pdf";
import PDF_43 from "../assets/PDF FILE/Research/AERC_completed/PDF_43.pdf";
import PDF_44 from "../assets/PDF FILE/Research/AERC_completed/PDF_44.pdf";
import PDF_45 from "../assets/PDF FILE/Research/AERC_completed/PDF_45.pdf";
import PDF_46 from "../assets/PDF FILE/Research/AERC_completed/PDF_46.pdf";
import PDF_47 from "../assets/PDF FILE/Research/AERC_completed/PDF_47.pdf";
import PDF_48 from "../assets/PDF FILE/Research/AERC_completed/PDF_48.pdf";
import PDF_49 from "../assets/PDF FILE/Research/AERC_completed/PDF_49.pdf";
import PDF_50 from "../assets/PDF FILE/Research/AERC_completed/PDF_50.pdf";
import PDF_51 from "../assets/PDF FILE/Research/AERC_completed/PDF_51.pdf";
import PDF_52 from "../assets/PDF FILE/Research/AERC_completed/PDF_52.pdf";
import PDF_53 from "../assets/PDF FILE/Research/AERC_completed/PDF_53.pdf";
import PDF_54 from "../assets/PDF FILE/Research/AERC_completed/PDF_54.pdf";
import PDF_55 from "../assets/PDF FILE/Research/AERC_completed/PDF_55.pdf";
import PDF_56 from "../assets/PDF FILE/Research/AERC_completed/PDF_56.pdf";
import PDF_57 from "../assets/PDF FILE/Research/AERC_completed/PDF_57.pdf";
import PDF_58 from "../assets/PDF FILE/Research/AERC_completed/PDF_58.pdf";
import PDF_59 from "../assets/PDF FILE/Research/AERC_completed/PDF_59.pdf";
import PDF_60 from "../assets/PDF FILE/Research/AERC_completed/PDF_60.pdf";
import PDF_61 from "../assets/PDF FILE/Research/AERC_completed/PDF_61.pdf";
import PDF_62 from "../assets/PDF FILE/Research/AERC_completed/PDF_62.pdf";
import PDF_63 from "../assets/PDF FILE/Research/AERC_completed/PDF_63.pdf";
import PDF_64 from "../assets/PDF FILE/Research/AERC_completed/PDF_64.pdf";
import PDF_65 from "../assets/PDF FILE/Research/AERC_completed/PDF_65.pdf";
import PDF_66 from "../assets/PDF FILE/Research/AERC_completed/PDF_66.pdf";
import PDF_67 from "../assets/PDF FILE/Research/AERC_completed/PDF_67.pdf";
import PDF_68 from "../assets/PDF FILE/Research/AERC_completed/PDF_68.pdf";
import PDF_69 from "../assets/PDF FILE/Research/AERC_completed/PDF_69.pdf";
import PDF_70 from "../assets/PDF FILE/Research/AERC_completed/PDF_70.pdf";
import PDF_71 from "../assets/PDF FILE/Research/AERC_completed/PDF_71.pdf";
import PDF_72 from "../assets/PDF FILE/Research/AERC_completed/PDF_72.pdf";
import PDF_73 from "../assets/PDF FILE/Research/AERC_completed/PDF_73.pdf";
import PDF_74 from "../assets/PDF FILE/Research/AERC_completed/PDF_74.pdf";
import PDF_75 from "../assets/PDF FILE/Research/AERC_completed/PDF_75.pdf";
import PDF_76 from "../assets/PDF FILE/Research/AERC_completed/PDF_76.pdf";
import PDF_77 from "../assets/PDF FILE/Research/AERC_completed/PDF_77.pdf";
import PDF_78 from "../assets/PDF FILE/Research/AERC_completed/PDF_78.pdf";
import PDF_79 from "../assets/PDF FILE/Research/AERC_completed/PDF_79.pdf";
import PDF_80 from "../assets/PDF FILE/Research/AERC_completed/PDF_80.pdf";
import PDF_81 from "../assets/PDF FILE/Research/AERC_completed/PDF_81.pdf";
import PDF_82 from "../assets/PDF FILE/Research/AERC_completed/PDF_82.pdf";
import PDF_83 from "../assets/PDF FILE/Research/AERC_completed/PDF_83.pdf";
import PDF_84 from "../assets/PDF FILE/Research/AERC_completed/PDF_84.pdf";
import PDF_85 from "../assets/PDF FILE/Research/AERC_completed/PDF_85.pdf";
import PDF_86 from "../assets/PDF FILE/Research/AERC_completed/PDF_86.pdf";
import PDF_87 from "../assets/PDF FILE/Research/AERC_completed/PDF_87.pdf";
import PDF_88 from "../assets/PDF FILE/Research/AERC_completed/PDF_88.pdf";
import PDF_89 from "../assets/PDF FILE/Research/AERC_completed/PDF_89.pdf";
import PDF_90 from "../assets/PDF FILE/Research/AERC_completed/PDF_90.pdf";
import PDF_91 from "../assets/PDF FILE/Research/AERC_completed/PDF_91.pdf";
import PDF_92 from "../assets/PDF FILE/Research/AERC_completed/PDF_92.pdf";
import PDF_93 from "../assets/PDF FILE/Research/AERC_completed/PDF_93.pdf";
import PDF_94 from "../assets/PDF FILE/Research/AERC_completed/PDF_94.pdf";
import PDF_95 from "../assets/PDF FILE/Research/AERC_completed/PDF_95.pdf";
import PDF_96 from "../assets/PDF FILE/Research/AERC_completed/PDF_96.pdf";
import PDF_97 from "../assets/PDF FILE/Research/AERC_completed/PDF_97.pdf";
import PDF_98 from "../assets/PDF FILE/Research/AERC_completed/PDF_98.pdf";
import PDF_99 from "../assets/PDF FILE/Research/AERC_completed/PDF_99.pdf";
import PDF_100 from "../assets/PDF FILE/Research/AERC_completed/PDF_100.pdf";
import PDF_101 from "../assets/PDF FILE/Research/AERC_completed/PDF_101.pdf";
import PDF_102 from "../assets/PDF FILE/Research/AERC_completed/PDF_102.pdf";
import PDF_103 from "../assets/PDF FILE/Research/AERC_completed/PDF_103.pdf";
import PDF_104 from "../assets/PDF FILE/Research/AERC_completed/PDF_104.pdf";
import PDF_105 from "../assets/PDF FILE/Research/AERC_completed/PDF_105.pdf";
import PDF_106 from "../assets/PDF FILE/Research/AERC_completed/PDF_106.pdf";
import PDF_107 from "../assets/PDF FILE/Research/AERC_completed/PDF_107.pdf";
import PDF_108 from "../assets/PDF FILE/Research/AERC_completed/PDF_108.pdf";
import PDF_109 from "../assets/PDF FILE/Research/AERC_completed/PDF_109.pdf";
import PDF_110 from "../assets/PDF FILE/Research/AERC_completed/PDF_110.pdf";
import PDF_111 from "../assets/PDF FILE/Research/AERC_completed/PDF_111.pdf";
import PDF_112 from "../assets/PDF FILE/Research/AERC_completed/PDF_112.pdf";
import PDF_113 from "../assets/PDF FILE/Research/AERC_completed/PDF_113.pdf";
import PDF_114 from "../assets/PDF FILE/Research/AERC_completed/PDF_114.pdf";
import PDF_115 from "../assets/PDF FILE/Research/AERC_completed/PDF_115.pdf";
import PDF_117 from "../assets/PDF FILE/Research/AERC_completed/PDF_117.pdf";
import PDF_118 from "../assets/PDF FILE/Research/AERC_completed/PDF_118.pdf";
import PDF_119 from "../assets/PDF FILE/Research/AERC_completed/PDF_119.pdf";
import PDF_120 from "../assets/PDF FILE/Research/AERC_completed/PDF_120.pdf";
import PDF_121 from "../assets/PDF FILE/Research/AERC_completed/PDF_121.pdf";
import PDF_122 from "../assets/PDF FILE/Research/AERC_completed/PDF_122.pdf";
import PDF_123 from "../assets/PDF FILE/Research/AERC_completed/PDF_123.pdf";
import PDF_124 from "../assets/PDF FILE/Research/AERC_completed/PDF_124.pdf";
import PDF_128 from "../assets/PDF FILE/Research/AERC_completed/PDF_128.pdf";
import PDF_129 from "../assets/PDF FILE/Research/AERC_completed/PDF_129.pdf";
import PDF_130 from "../assets/PDF FILE/Research/AERC_completed/PDF_130.pdf";
import PDF_131 from "../assets/PDF FILE/Research/AERC_completed/PDF_131.pdf";
import PDF_132 from "../assets/PDF FILE/Research/AERC_completed/PDF_132.pdf";
import PDF_133 from "../assets/PDF FILE/Research/AERC_completed/PDF_133.pdf";
import PDF_135 from "../assets/PDF FILE/Research/AERC_completed/PDF_135.pdf";

import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { useState } from "react";

function Lecture() {
  const [expert, setexpert] = useState("");
  const [year, setyear] = useState("");
  const [search, setsearch] = useState("");
  const expert_opt = [
    { value: "", label: "select" },
    { value: "Rathi", label: "Rathi" },
  ];
  const year_opt = [
    { value: "", label: "select" },

    { value: "2014", label: "2014" },
    { value: "2015", label: "2015" },
    { value: "2016", label: "2016" },
    { value: "2017", label: "2017" },
    { value: "2018", label: "2018" },
    { value: "2023", label: "2023" },
  ];

  const Data = [
    {
      s_no: "1",
      Topic_of_Lecture: "World Trade Organisation",
      Date_and_Day_of_Lecture: "28-Jun",
      Year: "2016",
      Title_of_Lecture: "Intellectual Property Rights",
      Organized_by: "State Agriculture Extension and Training Institute",
      Name_of_Expert: "Dr. D.Rathi",
    },
    {
      s_no: "2",
      Topic_of_Lecture:
        "Seed policy reforms and its implications in seed business",
      Date_and_Day_of_Lecture: "09 Aug.",
      Year: "2016",
      Title_of_Lecture: "Seed Production and Marketing Management",
      Organized_by:
        "Business Planning and Development Unit (BPD), JNKVV, Jabalpur (M.P.)",
      Name_of_Expert: "Dr. D.Rathi",
    },
    {
      s_no: "3",
      Topic_of_Lecture: "Seed Regulatory systems in PVPFRA/ Biodiversity Act",
      Date_and_Day_of_Lecture: "10 Aug.",
      Year: "2016",
      Title_of_Lecture: "Seed Production and Marketing Management",
      Organized_by:
        "Business Planning and Development Unit (BPD), JNKVV, Jabalpur (M.P.)",
      Name_of_Expert: "Dr. D.Rathi",
    },
    {
      s_no: "4",
      Topic_of_Lecture:
        "Acts, Rules and Regulations related to Agricultural Inputs – Seed Act, Insecticide Act & Fertiliser Act",
      Date_and_Day_of_Lecture: "11 Nov.",
      Year: "2016",
      Title_of_Lecture: "Agri-Clinic & Agri. Business Training Programme",
      Organized_by:
        "Business Planning and Development Unit (BPD), JNKVV, Jabalpur (M.P.)",
      Name_of_Expert: "Dr. D.Rathi",
    },
    {
      s_no: "5",
      Topic_of_Lecture: "National Food Security Mission",
      Date_and_Day_of_Lecture: "18 Dec.",
      Year: "2016",
      Title_of_Lecture:
        "Agri-Clinic & Agri. Business Centre Training Programme",
      Organized_by:
        "Business Planning and Development Unit (BPD), JNKVV, Jabalpur (M.P.)",
      Name_of_Expert: "Dr. D.Rathi",
    },
    {
      s_no: "6",
      Topic_of_Lecture: "Cost Concepts and Profitability Measures",
      Date_and_Day_of_Lecture: "10 Feb.",
      Year: "2017",
      Title_of_Lecture: "Certificate Course on Organic Farming",
      Organized_by:
        "Business Planning and Development Unit (BPD), JNKVV, Jabalpur (M.P.)",
      Name_of_Expert: "Dr. D.Rathi",
    },
    {
      s_no: "7",
      Topic_of_Lecture: "बीज विपणन एवं प्रबन्धन",
      Date_and_Day_of_Lecture: "05 Dec.",
      Year: "2017",
      Title_of_Lecture:
        "Capacity building program on incubation opportunities for Agribusiness in FPOs",
      Organized_by:
        "Business Planning and Development Unit (BPD), JNKVV, Jabalpur (M.P.)",
      Name_of_Expert: "Dr. D.Rathi",
    },
    {
      s_no: "8",
      Topic_of_Lecture: "ई-मार्केटिंग (e-NAM)",
      Date_and_Day_of_Lecture: "07 Dec.",
      Year: "2018",
      Title_of_Lecture:
        "Capacity building program on incubation opportunities for Agribusiness in FPOs",
      Organized_by:
        "Business Planning and Development Unit (BPD), JNKVV, Jabalpur (M.P.)",
      Name_of_Expert: "Dr. D.Rathi",
    },
    {
      s_no: "9",
      Topic_of_Lecture: "बीज विपणन एवं प्रबन्धन",
      Date_and_Day_of_Lecture: "03-Jan",
      Year: "2018",
      Title_of_Lecture:
        "Capacity building program on incubation opportunities for Agribusiness in FPOs",
      Organized_by:
        "Business Planning and Development Unit (BPD), JNKVV, Jabalpur (M.P.)",
      Name_of_Expert: "Dr. D.Rathi",
    },
    {
      s_no: "10",
      Topic_of_Lecture: "ई-मार्केटिंग (e-NAM)",
      Date_and_Day_of_Lecture: "05-Jan",
      Year: "2018",
      Title_of_Lecture:
        "Capacity building program on incubation opportunities for Agribusiness in FPOs",
      Organized_by:
        "Business Planning and Development Unit (BPD), JNKVV, Jabalpur (M.P.)",
      Name_of_Expert: "Dr. D.Rathi",
    },
    {
      s_no: "11",
      Topic_of_Lecture: "बीज विपणन एवं प्रबन्धन",
      Date_and_Day_of_Lecture: "22-Jan",
      Year: "2018",
      Title_of_Lecture:
        "Capacity building program on incubation opportunities for Agribusiness in FPOs",
      Organized_by:
        "Business Planning and Development Unit (BPD), JNKVV, Jabalpur (M.P.)",
      Name_of_Expert: "Dr. D.Rathi",
    },
    {
      s_no: "12",
      Topic_of_Lecture: "ई-मार्केटिंग (e-NAM)",
      Date_and_Day_of_Lecture: "24-Jan",
      Year: "2018",
      Title_of_Lecture:
        "Capacity building program on incubation opportunities for Agribusiness in FPOs",
      Organized_by:
        "Business Planning and Development Unit (BPD), JNKVV, Jabalpur (M.P.)",
      Name_of_Expert: "Dr. D.Rathi",
    },
    {
      s_no: "13",
      Topic_of_Lecture: "बीज विपणन एवं प्रबन्धन",
      Date_and_Day_of_Lecture: "02-Feb",
      Year: "2017",
      Title_of_Lecture:
        "Capacity building program on incubation opportunities for Agribusiness in FPOs",
      Organized_by:
        "Business Planning and Development Unit (BPD), JNKVV, Jabalpur (M.P.)",
      Name_of_Expert: "Dr. D.Rathi",
    },
    {
      s_no: "14",
      Topic_of_Lecture: "ई-मार्केटिंग (e-NAM)",
      Date_and_Day_of_Lecture: "04-Feb",
      Year: "2018",
      Title_of_Lecture:
        "Capacity building program on incubation opportunities for Agribusiness in FPOs",
      Organized_by:
        "Business Planning and Development Unit (BPD), JNKVV, Jabalpur (M.P.)",
      Name_of_Expert: "Dr. D.Rathi",
    },
    {
      s_no: "15",
      Topic_of_Lecture: "बीज विपणन एवं प्रबन्धन",
      Date_and_Day_of_Lecture: "22-Feb",
      Year: "2018",
      Title_of_Lecture:
        "Capacity building program on incubation opportunities for Agribusiness in FPOs",
      Organized_by:
        "Business Planning and Development Unit (BPD), JNKVV, Jabalpur (M.P.)",
      Name_of_Expert: "Dr. D.Rathi",
    },
    {
      s_no: "16",
      Topic_of_Lecture: "ई-मार्केटिंग (e-NAM)",
      Date_and_Day_of_Lecture: "24-Feb",
      Year: "2018",
      Title_of_Lecture:
        "Capacity building program on incubation opportunities for Agribusiness in FPOs",
      Organized_by:
        "Business Planning and Development Unit (BPD), JNKVV, Jabalpur (M.P.)",
      Name_of_Expert: "Dr. D.Rathi",
    },
    {
      s_no: "17",
      Topic_of_Lecture: "बीज विपणन एवं प्रबन्धन",
      Date_and_Day_of_Lecture: "14-Mar",
      Year: "2018",
      Title_of_Lecture:
        "Capacity building program on incubation opportunities for Agribusiness in FPOs",
      Organized_by:
        "Business Planning and Development Unit (BPD), JNKVV, Jabalpur (M.P.)",
      Name_of_Expert: "Dr. D.Rathi",
    },
    {
      s_no: "18",
      Topic_of_Lecture: "ई-मार्केटिंग (e-NAM)",
      Date_and_Day_of_Lecture: "16-Mar",
      Year: "2018",
      Title_of_Lecture:
        "Capacity building program on incubation opportunities for Agribusiness in FPOs",
      Organized_by:
        "Business Planning and Development Unit (BPD), JNKVV, Jabalpur (M.P.)",
      Name_of_Expert: "Dr. D.Rathi",
    },
    {
      s_no: "19",
      Topic_of_Lecture:
        "Acts, Rules and Regulations related to Agricultural Inputs – Seed Act, Insecticide Act & Fertilizer Act",
      Date_and_Day_of_Lecture: "27-Jan",
      Year: "2018",
      Title_of_Lecture:
        "Agri-preunership under the Agri-Clinic & Agri-Business Centre Scheme",
      Organized_by:
        "Business Planning and Development Unit (BPD), JNKVV, Jabalpur (M.P.)",
      Name_of_Expert: "Dr. D.Rathi",
    },
    {
      s_no: "20",
      Topic_of_Lecture: "National Food Security Mission",
      Date_and_Day_of_Lecture: "07-Mar",
      Year: "2018",
      Title_of_Lecture:
        "Agri-preunership under the Agri-Clinic & Agri-Business Centre Scheme",
      Organized_by:
        "Business Planning and Development Unit (BPD), JNKVV, Jabalpur (M.P.)",
      Name_of_Expert: "Dr. D.Rathi",
    },
    {
      s_no: "21",
      Topic_of_Lecture:
        "Planning in Extension : Participation and Operationalisation of Comprehensive District Agricultural Plan (CDAP)",
      Date_and_Day_of_Lecture: "05 Nov.",
      Year: "2014",
      Title_of_Lecture:
        "Agri-preunership under the Agri-Clinic & Agri-Business Centre Scheme",
      Organized_by:
        "Business Planning and Development Unit (BPD), JNKVV, Jabalpur (M.P.)",
      Name_of_Expert: "Dr. D.Rathi",
    },
    {
      s_no: "22",
      Topic_of_Lecture: "Rural Credits and Crop Insurance",
      Date_and_Day_of_Lecture: "04 Dec.",
      Year: "2014",
      Title_of_Lecture:
        "Agri-preunership under the Agri-Clinic & Agri-Business Centre Scheme",
      Organized_by:
        "Business Planning and Development Unit (BPD), JNKVV, Jabalpur (M.P.)",
      Name_of_Expert: "Dr. D.Rathi",
    },
    {
      s_no: "23",
      Topic_of_Lecture: "Small Enterprises Management : SWOT Analysis",
      Date_and_Day_of_Lecture: "12 Dec.",
      Year: "2014",
      Title_of_Lecture:
        "Agri-preunership under the Agri-Clinic & Agri-Business Centre Scheme",
      Organized_by:
        "Business Planning and Development Unit (BPD), JNKVV, Jabalpur (M.P.)",
      Name_of_Expert: "Dr. D.Rathi",
    },
    {
      s_no: "24",
      Topic_of_Lecture:
        "Role of public-private partnerships in seed business and supply system",
      Date_and_Day_of_Lecture: "20-Mar",
      Year: "2015",
      Title_of_Lecture: "Seed Business Management Training Programme",
      Organized_by:
        "Business Planning and Development Unit (BPD), JNKVV, Jabalpur (M.P.)",
      Name_of_Expert: "Dr. D.Rathi",
    },
    {
      s_no: "25",
      Topic_of_Lecture:
        "India and World Tread Organization (WTO): WTO Agreement on Agriculture (State Institute Of Agricultural Extension And Training )",
      Date_and_Day_of_Lecture: "10 & 28 Feb and 21 March",
      Year: "2023",
      Title_of_Lecture: "World Trade Organisation",
      Organized_by: "State Agriculture Extension and Training Institute",
      Name_of_Expert: "Dr. D.Rathi",
    },
    {
      s_no: "26",
      Topic_of_Lecture:
        "Agri-Exports: Challenges and Prospects (State Institute Of Agricultural Extension And Training )",
      Date_and_Day_of_Lecture: "10 & 28 Feb and 21 March",
      Year: "2023",
      Title_of_Lecture: "World Trade Organisation",
      Organized_by: "State Agriculture Extension and Training Institute",
      Name_of_Expert: "Dr. D.Rathi",
    },
  ];
  const newData = Data.filter((item) => {
    const searchText = search.toLowerCase();
    const topic_lecture =
      item.Topic_of_Lecture.toLowerCase().includes(searchText);
    const title_lecture =
      item.Title_of_Lecture.toLowerCase().includes(searchText);
    const organize = item.Organized_by.toLowerCase().includes(searchText);

    return (
      (topic_lecture || title_lecture || organize) &&
      item.Name_of_Expert.includes(expert) &&
      item.Year.includes(year)
    );
  });

  const tableData =
    newData &&
    newData.map((item) => {
      return (
        <tr key={item.id}>
          <td>{item.s_no}</td>
          <td>{item.Topic_of_Lecture}</td>
          <td>{item.Date_and_Day_of_Lecture}</td>
          <td>{item.Year}</td>
          <td>{item.Title_of_Lecture}</td>
          <td>{item.Organized_by}</td>
          {/* {Data.Pdf_attached !== "Nill" ? (
            <td>
              <a href={item.Pdf_attached}>
                <img src={PDF_IMage} />
              </a>
            </td>
          ) : (
            <td>{item.Pdf_attached}</td>
          )} */}
          <td>{item.Name_of_Expert}</td>
        </tr>
      );
    });
  // const New_data = Papa.unparse(tableData);
  return (
    <div>
      {/* <Header /> */}

      <section className="about section">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-6 col-md-12 col-sm-12"> */}
            <div className="about-content">
              <h3>Guest Lecture</h3>
              <section className="blog-area">
                <div className="container pt-20">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12  ">
                      <div className=" finder-Funded ">
                        <ul>
                          <li>
                            <Select
                              className=""
                              placeholder="Select Expert"
                              options={expert_opt}
                              onChange={(e) => {
                                // setwork(e.value);
                                setexpert(e.value);
                              }}
                            />
                          </li>

                          <li className="rating">
                            <Select
                              placeholder="Select Year"
                              options={year_opt}
                              onChange={(e) => {
                                setyear(e.value);
                              }}
                            />
                          </li>
                          <li>
                            <input
                              onChange={(e) => {
                                setsearch(e.target.value);
                              }}
                              placeholder="search"
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="completed_table">
                <table style={{ width: "100%" }}>
                  <tr>
                    <th>S.no</th>
                    <th>Topic of Lecture</th>
                    <th>Date and Day of Lecture</th>
                    <th>Year</th>
                    <th>Title of Lecture</th>
                    <th>Organized by</th>
                    <th>Name of Expert</th>
                  </tr>
                  {tableData}
                </table>
              </div>
            </div>
            <button className="btn ">
              <CSVLink data={newData} headers={header} filename="test.csv">
                Download
              </CSVLink>
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Lecture;

const header = [
  { label: "S.No ", key: "s_no" },
  { label: "Topic_of_Lecture", key: "Topic_of_Lecture" },
  { label: "Date_and_Day_of_Lecture", key: "Date_and_Day_of_Lecture" },
  { label: "Year", key: "Year" },
  { label: "Title_of_Lecture", key: "Title_of_Lecture" },
  { label: "Organized_by", key: "Organized_by" },
  { label: "Name_of_Expert", key: "Name_of_Expert" },
];
