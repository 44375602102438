import React, { useState } from "react";
import Select from "react-select";
import PDF_IMage from "../assets/images/pdf.png";
import bundelkhand_region from "../assets/PDF FILE/journalpdf/bundelkhand_region.pdf";
import dynamic_maize_production from "../assets/PDF FILE/journalpdf/dynamic_maize_production.pdf";
import pharma_innovation from "../assets/PDF FILE/journalpdf/pharma_innovation.pdf";
import market_imperfection from "../assets/PDF FILE/journalpdf/market_imperfection.pdf";
import Frontiers_in_Crop from "../assets/PDF FILE/journalpdf/Frontiers_in_Crop.pdf";
import Marketing_Efficiency_of_Major_Spices from "../assets/PDF FILE/journalpdf/Marketing_Efficiency_of_Major_Spices.pdf";
import Niranjan from "../assets/PDF FILE/journalpdf/Niranjan et al 2023 (MI).pdf";
import RP_17 from "../assets/PDF FILE/journal new/RP-17.pdf";
import RP_18 from "../assets/PDF FILE/journal new/RP-18.pdf";
import RP_20 from "../assets/PDF FILE/journal new/RP-20.pdf";
import RP_21 from "../assets/PDF FILE/journal new/RP-21.pdf";
import RP_22 from "../assets/PDF FILE/journal new/RP-22.pdf";
import RP_23 from "../assets/PDF FILE/journal new/RP-23.pdf";
import RP_26 from "../assets/PDF FILE/journal new/RP-26.pdf";
import RP_27 from "../assets/PDF FILE/journal new/RP-27.pdf";
import RP_28 from "../assets/PDF FILE/journal new/RP-28.pdf";
import RP_29 from "../assets/PDF FILE/journal new/RP-29.pdf";
import RP_30 from "../assets/PDF FILE/journal new/RP-30.pdf";
import RP_31 from "../assets/PDF FILE/journal new/RP-31.pdf";
import RP_33 from "../assets/PDF FILE/journal new/RP-33.pdf";
import RP_34 from "../assets/PDF FILE/journal new/RP-34.pdf";
import RP_35 from "../assets/PDF FILE/journal new/RP-35.pdf";
import RP_36 from "../assets/PDF FILE/journal new/RP-36.pdf";
import RP_37 from "../assets/PDF FILE/journal new/RP-37.pdf";
import RP_39 from "../assets/PDF FILE/journal new/RP-39.pdf";
import RP_40 from "../assets/PDF FILE/journal new/RP-40.pdf";
import RP_41 from "../assets/PDF FILE/journal new/RP-41.pdf";
import RP_43 from "../assets/PDF FILE/journal new/RP-43.pdf";
import RP_44 from "../assets/PDF FILE/journal new/RP-44.pdf";
import RP_45 from "../assets/PDF FILE/journal new/RP-45.pdf";
import RP_46 from "../assets/PDF FILE/journal new/RP-46.pdf";
import RP_47 from "../assets/PDF FILE/journal new/RP-47.pdf";
import RP_48 from "../assets/PDF FILE/journal new/RP-48.pdf";
import RP_49 from "../assets/PDF FILE/journal new/RP-49.pdf";
import RP_50 from "../assets/PDF FILE/journal new/RP-50.pdf";
import RP_51 from "../assets/PDF FILE/journal new/RP-51.pdf";
import RP_52 from "../assets/PDF FILE/journal new/RP-52.pdf";
import RP_53 from "../assets/PDF FILE/journal new/RP-53.pdf";
import RP_54 from "../assets/PDF FILE/journal new/RP-54.pdf";
import RP_55 from "../assets/PDF FILE/journal new/RP-55.pdf";
import RP_56 from "../assets/PDF FILE/journal new/RP-56.pdf";
import RP_57 from "../assets/PDF FILE/journal new/RP-57.pdf";
import RP_58 from "../assets/PDF FILE/journal new/RP-58.pdf";
import RP_59 from "../assets/PDF FILE/journal new/RP-59.pdf";
import RP_60 from "../assets/PDF FILE/journal new/RP-60.pdf";
// import RP_60 from "../assets/PDF FILE/journal new/RP-60.pdf";
import RP_61 from "../assets/PDF FILE/journal new/RP-61.pdf";
import RP_63 from "../assets/PDF FILE/journal new/RP-63.pdf";
import RP_64 from "../assets/PDF FILE/journal new/RP-64.pdf";
import RP_65 from "../assets/PDF FILE/journal new/RP-65.pdf";
import RP_66 from "../assets/PDF FILE/journal new/RP-66.pdf";
import RP_67 from "../assets/PDF FILE/journal new/RP-67.pdf";
import RP_68 from "../assets/PDF FILE/journal new/RP-68.pdf";
import RP_69 from "../assets/PDF FILE/journal new/RP-69.pdf";
import RP_70 from "../assets/PDF FILE/journal new/RP-70.pdf";
import RP_71 from "../assets/PDF FILE/journal new/RP-71.pdf";
import RP_72 from "../assets/PDF FILE/journal new/RP-72.pdf";
import RP_73 from "../assets/PDF FILE/journal new/RP-73.pdf";
import RP_74 from "../assets/PDF FILE/journal new/RP-74.pdf";
import RP_75 from "../assets/PDF FILE/journal new/RP-75.pdf";
import RP_76 from "../assets/PDF FILE/journal new/RP-76.pdf";
import RP_77 from "../assets/PDF FILE/journal new/RP-77.pdf";
import RP_78 from "../assets/PDF FILE/journal new/RP-78.pdf";
import RP_79 from "../assets/PDF FILE/journal new/RP-79.pdf";
import RP_80 from "../assets/PDF FILE/journal new/RP-80.pdf";
import RP_81 from "../assets/PDF FILE/journal new/RP-81.pdf";
import RP_82 from "../assets/PDF FILE/journal new/RP-82.pdf";
import RP_83 from "../assets/PDF FILE/journal new/RP-83.pdf";
import RP_84 from "../assets/PDF FILE/journal new/RP-84.pdf";
import RP_85 from "../assets/PDF FILE/journal new/RP-85.pdf";
import RP_86 from "../assets/PDF FILE/journal new/RP-86.pdf";
import RP_87 from "../assets/PDF FILE/journal new/RP-87.pdf";
import RP_88 from "../assets/PDF FILE/journal new/RP-88.pdf";
import RP_89 from "../assets/PDF FILE/journal new/RP-89.pdf";
import RP_90 from "../assets/PDF FILE/journal new/RP-90.pdf";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import { CSVLink, CSVDownload } from "react-csv";

function JournalArticle() {
  const [rating, setrating] = useState("");
  const [year, setyear] = useState("");
  const [faculty, setfaculty] = useState("");
  const [search, setsearch] = useState("");
  const nass_opt = [
    { value: "", label: "select" },
    { value: "3.", label: "3." },
    { value: "4.", label: "4." },
    { value: "5.", label: "5." },
    { value: "6.", label: "6." },
  ];
  const data = [
    {
      Title_of_Publication_Work:
        "Performance and instability of Rapeseed and Mustard in M. P.",
      Journal_Name: "M.P. Agricultural Economic Journal",
      Month_year_of_publication_volume_no_page_nos: "1(1): 20-21.",
      Name_of_Faculty_Authors: "Gupta, M.K., Gupta, J.K. and Rathi, Deepak.",
      Date_Year_of_Publication: "1999",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "Other than AER/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: "Nill",
      NAAS_Rating_2020: "$",
    },
    {
      Title_of_Publication_Work:
        "Economics of farm technologies and equity in growth production in Narsinghpur district of M. P.",
      Journal_Name: "M.P. Agricultural Economic Journal",
      Month_year_of_publication_volume_no_page_nos: "1(1):65-66.",
      Name_of_Faculty_Authors: "Gupta, M.K., Sharma, Sanjay and Rathi, Deepak",
      Date_Year_of_Publication: "1999",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than: "AER/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: "Nill",
      NAAS_Rating_2020: "$",
    },
    {
      Title_of_Publication_Work:
        "Working and Impact of self help group on economic status of women in Watershed area of Madhya Pradesh.",
      Journal_Name: "Indian Journ al of Agricultural Economics",
      Month_year_of_publication_volume_no_page_nos: "56(3) : 475-76.",
      Name_of_Faculty_Authors:
        "Awasthi, P.K., Rathi, Deepakeepak and Sahu, Vimla",
      Date_Year_of_Publication: "2001",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "Other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: "Nill",
      NAAS_Rating_2020: "5.3",
    },
    {
      Title_of_Publication_Work:
        "Economic analysis of processing of lentil and constraints to future development of agro-processing unit in Central Madhya Pradesh",
      Journal_Name: "Indian Journal of Agricultural Economics",
      Month_year_of_publication_volume_no_page_nos: "58 (3) : 627-628",
      Name_of_Faculty_Authors: "Awasthi, P.K., Rathi, Deepak. and Gupta, J.K.",
      Date_Year_of_Publication: "2003",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "Other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: "Nill",
      NAAS_Rating_2020: "5.3",
    },
    {
      Title_of_Publication_Work:
        "Impact of domestic price policy on agricultural production, cropping pattern and productivity of cotton in Madhya Pradesh.",
      Journal_Name: "Indian Journal of Agricultural Economics",
      Month_year_of_publication_volume_no_page_nos: "58 (3) : 404",
      Name_of_Faculty_Authors: "Rathi, Deepak. and Awasthi, P.K",
      Date_Year_of_Publication: "2003",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "Other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: "Nill",
      NAAS_Rating_2020: "5.3",
    },
    {
      Title_of_Publication_Work:
        "Socio-economic factors associated with adoption of dairy innovations in draught-prone area of West Bengal. (In)",
      Journal_Name: "Indian Journal of Agricultural Economics",
      Month_year_of_publication_volume_no_page_nos: "59 (3) : 633-634.",
      Name_of_Faculty_Authors: "Awasthi, P.K., Rathi, Deepak. and Gupta, J.K.",
      Date_Year_of_Publication: "2004",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "Other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: "Nill",
      NAAS_Rating_2020: "5.3",
    },
    {
      Title_of_Publication_Work:
        "Profitable pisciculture production through resource management in central region of Madhya Pradesh. (In)",
      Journal_Name: "Indian Journal of Agricultural Economics",
      Month_year_of_publication_volume_no_page_nos: "59 (3) : 482-483.",
      Name_of_Faculty_Authors: "Rathi, Deepak; Awasthi, P.K. and Gupta, J.K.",
      Date_Year_of_Publication: "2004",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "Other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: "Nill",
      NAAS_Rating_2020: "5.3",
    },
    {
      Title_of_Publication_Work:
        "Profit and yield maximizing doses of fertilizers for paddy.",
      Journal_Name: "International . Journal of Agricultural Sciencel",
      Month_year_of_publication_volume_no_page_nos: "02 (01) : 95-99",
      Name_of_Faculty_Authors: "Rathi, Deepak;, Awasthi, P.K. and Mishra, B.L.",
      Date_Year_of_Publication: "2006",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "Other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: "Nill",
      NAAS_Rating_2020: "$",
    },
    {
      Title_of_Publication_Work:
        "Scenario of export-import in oilseed sector during pre and post liberalization period calls for policy options.",
      Journal_Name: "Journal Oilseeds Research",
      Month_year_of_publication_volume_no_page_nos:
        "Vol. 26 (Special Issue) pp. 579-583.",
      Name_of_Faculty_Authors: "Rathi, Deepak. and Tomar, R.K.S",
      Date_Year_of_Publication: "2009",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "Other than AER/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: "Nill",
      NAAS_Rating_2020: "4.59",
    },
    {
      Title_of_Publication_Work:
        "Microfinance in India : New approaches to financing the rural poor.",
      Journal_Name: "Indian Journal of Extension Education",
      Month_year_of_publication_volume_no_page_nos: "XV (1 & 2): 176-184",
      Name_of_Faculty_Authors:
        "Chand, Prem; Rathi, Deepak; Yogi, R.K. and Rai, R.K.",
      Date_Year_of_Publication: "2010",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "Other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: "Nill",
      NAAS_Rating_2020: "5.95",
    },
    {
      Title_of_Publication_Work:
        "Farmers' Perception about Krishi Vigyan Kendra in Satna district of Madhya Pradesh.",
      Journal_Name: "The Journal of Rural and Agricultural Research.",
      Month_year_of_publication_volume_no_page_nos: "10 (2) : 56-59.",
      Name_of_Faculty_Authors: "Rathi, Deepak. and Singh, S.P.",
      Date_Year_of_Publication: "2010",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "Other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: "Nill",
      NAAS_Rating_2020: "4.19",
    },
    {
      Title_of_Publication_Work:
        "Econmics of production performance of maize in tribal area of Madhya Pradesh.",
      Journal_Name: "JNKVV Research Journal",
      Month_year_of_publication_volume_no_page_nos: "44 (2) : 209-212.",
      Name_of_Faculty_Authors: "Rathi, Deepak; Awasthi, P.K. and Singh, V.K.",
      Date_Year_of_Publication: "2010",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "Other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: "Nill",
      NAAS_Rating_2020: "#",
    },
    {
      Title_of_Publication_Work:
        "Impact assessment of national agricultural insurance scheme on stabilizing farm Income in Nimar Valley of Madhya Pradesh.",
      Journal_Name: "JNKVV Research Journal",
      Month_year_of_publication_volume_no_page_nos: "44 (2) : 213-216.",
      Name_of_Faculty_Authors: "Rathi, Deepak; Awasthi, P.K. and Singh, V.K.",
      Date_Year_of_Publication: "2010",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "Other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: "Nill",
      NAAS_Rating_2020: "#",
    },
    {
      Title_of_Publication_Work:
        "Scenario of cotton seed (non-conventional oil) in India.",
      Journal_Name: "Journal Oilseeds Research (Short Communication)",
      Month_year_of_publication_volume_no_page_nos: "27 (1) : 92-94.",
      Name_of_Faculty_Authors:
        "Rathi, Deepak; Gupta, J.K., Awasthi, P.K. and Pathak, R.K.",
      Date_Year_of_Publication: "2010",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "Other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: "Nill",
      NAAS_Rating_2020: "4.59",
    },
    {
      Title_of_Publication_Work:
        "Effect of paclobutrazol on growth and yield of mango.",
      Journal_Name: "JNKVV Research Journal",
      Month_year_of_publication_volume_no_page_nos: "44 (2) : 163-166.",
      Name_of_Faculty_Authors:
        "Singh, V.K., Singh, A.K. Rathi, Deepak. and Shukla, K.C.",
      Date_Year_of_Publication: "2010",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "Other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: "Nill",
      NAAS_Rating_2020: "#",
    },
    {
      Title_of_Publication_Work:
        "Factors responsible for post harvest losses of paddy in Rewa district in Madhya Pradesh",
      Journal_Name: "Journal of Progressive Agriculture",
      Month_year_of_publication_volume_no_page_nos: "2(3): 58-61",
      Name_of_Faculty_Authors:
        "Meena S.C., Shrivastava, S. N. and Niranjan, H.K.",
      Date_Year_of_Publication: "2011",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than: "AERC/DES",
      Indicate_Name_of_study:
        "Assessment of Pre and Post Harvest Losses of wheat and soybean in Madhya Pradesh",
      Pdf_Attached: RP_17,
      NAAS_Rating_2020: "3.21",
    },
    {
      Title_of_Publication_Work:
        "Economic changes among Women Self Help Groups of Rewa District M.P",
      Journal_Name: "Environment and Ecology",
      Month_year_of_publication_volume_no_page_nos: "29 (4): 1726-1728",
      Name_of_Faculty_Authors: "Niranjan, H.K.; Singh, Sanjay; and Meena, S. C",
      Date_Year_of_Publication: "2011",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: RP_18,
      NAAS_Rating_2020: "5.25",
    },
    {
      Title_of_Publication_Work:
        "Assessment of women empowerment in dairying : A study of semi-arid Rajasthan.",
      Journal_Name:
        "Indian Journal Dryland Agricultural Research & Development",
      Month_year_of_publication_volume_no_page_nos: "26 (2) : 28-32.",
      Name_of_Faculty_Authors: "Prem Chand, Sirohi, Smita and Rathi, Deepak.",
      Date_Year_of_Publication: "2011",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "Other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: "Nill",
      NAAS_Rating_2020: "4.88",
    },
    {
      Title_of_Publication_Work:
        "Impact of Post-Harvest Management Techniques on Price of Onion in Madhya Pradesh.",
      Journal_Name: "Environment and Ecology",
      Month_year_of_publication_volume_no_page_nos: "2011, 29 (3B): 1622-1625",
      Name_of_Faculty_Authors:
        "Sisodiya, M. S; Singh, Rajesh; Meena, S. C; Gurjar, P. S. and Niranjan, H.K.",
      Date_Year_of_Publication: "2011",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "Other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: RP_20,
      NAAS_Rating_2020: "5.25",
    },
    {
      Title_of_Publication_Work:
        "Role of Information and Communication Technology for agriculture: A case study of Kisan Call Center of Indian Society of Agribusiness Professionals Bhopal, Madhya Pradesh",
      Journal_Name: "JNKVV Research Journal",
      Month_year_of_publication_volume_no_page_nos: "46(1): 115-119",
      Name_of_Faculty_Authors:
        "Chouhan, R.S.; Kumar Dushyant ; and Sharma, H. O.",
      Date_Year_of_Publication: "2012",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "Other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: RP_21,
      NAAS_Rating_2020: "#",
    },
    {
      Title_of_Publication_Work:
        "Past Trends and Future Prospects in Production, and Export Scenario of Tea in India",
      Journal_Name: "International Review of Business and Finance",
      Month_year_of_publication_volume_no_page_nos: "4: (1). 25-33",
      Name_of_Faculty_Authors:
        "Mishra, Pradeep; Sahu, Pradip Kumar; Bajpa, Pragati and Niranjan, H.K.",
      Date_Year_of_Publication: "2012",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: RP_22,
      NAAS_Rating_2020: "$",
    },
    {
      Title_of_Publication_Work:
        "Economic Study of resource use efficiency for cabbage cultivation in Rewa District of Madhya Pradesh",
      Journal_Name: "Environment and Ecology",
      Month_year_of_publication_volume_no_page_nos: "2012, 30 (3): 517-522",
      Name_of_Faculty_Authors:
        "Patel, I.P; Niranjan, H.K.; Singh Rajesh and Gurja P. S.",
      Date_Year_of_Publication: "2012",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: RP_23,
      NAAS_Rating_2020: "5.25",
    },
    {
      Title_of_Publication_Work:
        "Scenario of Safflower (Carthamus tinctorius L.) during the era of TMO and WTO : Calls for policy options.",
      Journal_Name: "Journal Oilseeds Research",
      Month_year_of_publication_volume_no_page_nos:
        "29 (Special Issue) : 480-483",
      Name_of_Faculty_Authors: "D. Rathi; Sharma, A.K. and Mishra, P.K.",
      Date_Year_of_Publication: "2012",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "Other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: "Nill",
      NAAS_Rating_2020: "4.59",
    },
    {
      Title_of_Publication_Work:
        "Use of light trap for recording insect fauna in safflower ecosystem.",
      Journal_Name: "Journal Oilseeds Research",
      Month_year_of_publication_volume_no_page_nos: "pp. 382-386.",
      Name_of_Faculty_Authors: "Sharma, A.K., D. Rathi and Bisen, U.K.",
      Date_Year_of_Publication: "2012",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "Other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: "Nill",
      NAAS_Rating_2020: "4.59",
    },
    {
      Title_of_Publication_Work:
        "Income generation through potato cultivation in Rewa district of Madhya Pradesh",
      Journal_Name: "Environment and Ecology",
      Month_year_of_publication_volume_no_page_nos: "2013, 31 (2B): 984-987",
      Name_of_Faculty_Authors:
        "Rajput, L.S; Niranjan, H.K.; Singh, K. C and Shrivastava, S. N",
      Date_Year_of_Publication: "2013",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "Other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: RP_26,
      NAAS_Rating_2020: "5.25",
    },
    {
      Title_of_Publication_Work:
        "Status and profitability of fodder crops in Madhya Pradesh",
      Journal_Name: "JNKVV Research Journal",
      Month_year_of_publication_volume_no_page_nos: "47(2): 217-223",
      Name_of_Faculty_Authors: "Sharma H.O., Chouhan, R.S. and Agrwal, G.P.",
      Date_Year_of_Publication: "2013",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than: "AERC/DES",
      Indicate_Name_of_study:
        "Eco,nomics of Fodder Cultivation & its Processing and Marketing in Madhya PradeshEconomics of Production Processing and Marketing of Fodder Crops in Madhya Pradesh",
      Pdf_Attached: RP_27,
      NAAS_Rating_2020: "#",
    },
    {
      Title_of_Publication_Work:
        "SWOT analysis for lac cultivation in Madhya Pradesh",
      Journal_Name: "JNKVV Research Journal",
      Month_year_of_publication_volume_no_page_nos: "47(3): 337-340",
      Name_of_Faculty_Authors:
        "Thakur, Arvind Dangi , Meena, S. C. and Shrivastava, Ashutosh",
      Date_Year_of_Publication: "2013",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "Other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: RP_28,
      NAAS_Rating_2020: "#",
    },
    {
      Title_of_Publication_Work:
        "Dynamics of Profitability from Soybean in Central India",
      Journal_Name: "Soybean Research",
      Month_year_of_publication_volume_no_page_nos:
        "(Special Issue Number 2): 277-282",
      Name_of_Faculty_Authors: "Chouhan, R.S., D. Rathi & Sharma, H.O.",
      Date_Year_of_Publication: "2014",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than: "AERC/DES",
      Indicate_Name_of_study:
        "Ass,essment of Pre and Post Harvest Losses of wheat and soybean in Madhya Pradesh",
      Pdf_Attached: RP_29,
      NAAS_Rating_2020: "4.49",
    },
    {
      Title_of_Publication_Work:
        "Dynamics of Soybean Production in Different Districts of Madhya Pradesh",
      Journal_Name: "Soybean Research",
      Month_year_of_publication_volume_no_page_nos: "Vol 12 (2): 101-110",
      Name_of_Faculty_Authors: "Meena, S.C., D. Rathi & Sharma, H.O.",
      Date_Year_of_Publication: "2014",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than: "AERC/DES",
      Indicate_Name_of_study:
        "Ass,essment of Pre and Post Harvest Losses of wheat and soybean in Madhya Pradesh",
      Pdf_Attached: RP_30,
      NAAS_Rating_2020: "4.49",
    },
    {
      Title_of_Publication_Work:
        "Magnitude of Crop Losses due to Insect-Pest, Disease & Weeds in Soybean-An Economic Analysis",
      Journal_Name: "Soybean Research",
      Month_year_of_publication_volume_no_page_nos:
        "(Special Issue Number 2): 241-248",
      Name_of_Faculty_Authors: "Meena, S.C., D. Rathi & Sharma, H.O.",
      Date_Year_of_Publication: "2014",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than: "AERC/DES",
      Indicate_Name_of_study:
        "Ass,essment of Pre and Post Harvest Losses of wheat and soybean in Madhya Pradesh",
      Pdf_Attached: RP_31,
      NAAS_Rating_2020: "4.49",
    },
    {
      Title_of_Publication_Work:
        "Dynamics of Production and Profitability of Soybean in M.P.",
      Journal_Name: "Soybean Research",
      Month_year_of_publication_volume_no_page_nos:
        "(Special Issue Number 2): 271-276",
      Name_of_Faculty_Authors: "Sharma, H.O., D. Rathi & Meena, S.C.",
      Date_Year_of_Publication: "2014",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than: "AERC/DES",
      Indicate_Name_of_study:
        "Pro,blems and Prospects of Oilseeds in Madhya Pradesh",
      Pdf_Attached: "Nill",
      NAAS_Rating_2020: "4.49",
    },
    {
      Title_of_Publication_Work:
        "Constraints in Adoption of Soybean Production Technologies in Northern Hill Region of Chhattisgarh Agro-Climatic Zone of Madhya Pradesh",
      Journal_Name: "Economic Affairs",
      Month_year_of_publication_volume_no_page_nos: "60(4): 769-775",
      Name_of_Faculty_Authors:
        "Kumar, Santosh, D. Rathi; Nahatkar, S.B. and Masuda, Tadayoshi",
      Date_Year_of_Publication: "2015",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: RP_33,
      NAAS_Rating_2020: "5.08",
    },
    {
      Title_of_Publication_Work:
        "Extent Of Post Harvest Losses Of Different Stages Of Wheat In Madhya Pradesh",
      Journal_Name:
        "ZENITH International Journal of Business Economics & Management Research,",
      Month_year_of_publication_volume_no_page_nos: "Vol.5 (4), 45-52",
      Name_of_Faculty_Authors: "Meena, S.C., D. Rathi & Sharma,H.O.",
      Date_Year_of_Publication: "2015",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than: "AERC/DES",
      Indicate_Name_of_study:
        "Assessment of Pre and Post Harvest Losses of wheat and soybean in Madhya Pradesh",
      Pdf_Attached: RP_34,
      NAAS_Rating_2020: "$",
    },
    {
      Title_of_Publication_Work:
        "Study of Production of Major Food Grain Crops in Madhya Pradesh and their Yield Sustainability",
      Journal_Name: "Life Science Bulletin",
      Month_year_of_publication_volume_no_page_nos: "12 (2): 155-159",
      Name_of_Faculty_Authors: "Niranjan, H.K.; Gupta, J. K. and D. Rathi",
      Date_Year_of_Publication: "2015",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: RP_35,
      NAAS_Rating_2020: "#",
    },
    {
      Title_of_Publication_Work: "Impact of Soil Testing in Madhya Pradesh",
      Journal_Name: "JNKVV Research Journal",
      Month_year_of_publication_volume_no_page_nos: "49 (2): 158-165",
      Name_of_Faculty_Authors: "Sharma H.O., Mishra P.K. and Chouhan, R.S.",
      Date_Year_of_Publication: "2015",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than: "AERC/DES",
      Indicate_Name_of_study:
        "Adoption of Recommended Doses of Fertilizers on Soil Test Basis by Farmers",
      Pdf_Attached: RP_36,
      NAAS_Rating_2020: "#",
    },
    {
      Title_of_Publication_Work:
        "Determinants of Productivity of Hybrid Rice in National Food Security Mission Districts of Madhya Pradesh",
      Journal_Name: "ORYZA An International Journal on Rice",
      Month_year_of_publication_volume_no_page_nos: "Vol. 52 (2): 148-152",
      Name_of_Faculty_Authors: "Sharma H.O., D. Rathi and Meena S.C.",
      Date_Year_of_Publication: "2015",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than: "AERC/DES",
      Indicate_Name_of_study:
        "Impact of National Food Security Mission (NFSM) on Input Use, Production, Yield and Income in Madhya Pradesh",
      Pdf_Attached: RP_37,
      NAAS_Rating_2020: "5.03",
    },
    {
      Title_of_Publication_Work:
        "Proverbial Vagaries Causing Agrarian Distress",
      Journal_Name: "Agricultural Situation in India",
      Month_year_of_publication_volume_no_page_nos: "72 (9): 67-74",
      Name_of_Faculty_Authors: "Tomar V.S. & D. Rathi",
      Date_Year_of_Publication: "2015",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "Other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: "Nill",
      NAAS_Rating_2020: "4.53",
    },
    {
      Title_of_Publication_Work:
        "Pattern of Arrivals and Prices of Wheat in Different Grade Regulated Markets of Madhya Pradesh",
      Journal_Name: "International Journal of Agriculture Sciences",
      Month_year_of_publication_volume_no_page_nos: "8 (17): 1297-1299.",
      Name_of_Faculty_Authors: "Chouhan, R.S. and Gupta Jayant Kumar",
      Date_Year_of_Publication: "2016",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "Other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: RP_39,
      NAAS_Rating_2020: "4.58",
    },
    {
      Title_of_Publication_Work:
        "Marketing Efficiency in Different Grade of Regulated Markets of Wheat in Madhya Pradesh",
      Journal_Name: "International Journal of Agriculture Sciences",
      Month_year_of_publication_volume_no_page_nos: "8 (53): 2729-2732",
      Name_of_Faculty_Authors: "Chouhan, R.S. and Gupta Jayant Kumar",
      Date_Year_of_Publication: "2016",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "Other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: RP_40,
      NAAS_Rating_2020: "4.58",
    },
    {
      Title_of_Publication_Work:
        "Rice Productivity, Growth Rate and Yield Sustainability Under Aerobic and submerged Environments in Various Rice Ecosystems of Madhya Pradesh (INDIA)",
      Journal_Name: "Bangladesh Journal of Botany",
      Month_year_of_publication_volume_no_page_nos: "2016, 45 (5): 957-962",
      Name_of_Faculty_Authors:
        "Gautam, U.S; Singh, A.K; Singh, Jai; Mishra, P and Niranjan, H.K.",
      Date_Year_of_Publication: "2016",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: RP_41,
      NAAS_Rating_2020: "6.31",
    },
    {
      Title_of_Publication_Work:
        "Watershed approach for sustainable management of natural resources and enhancing rural livelihood security",
      Journal_Name: "Agricultural Situation in India",
      Month_year_of_publication_volume_no_page_nos:
        "Vol. LXXII (1). Pg : 13-17",
      Name_of_Faculty_Authors: "Sharma, H.O;, S. B. Nahatkar and D. Rathi",
      Date_Year_of_Publication: "2016",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "other then AERC/DES",
      Indicate_Name_of_study:
        "Impact of Pradhan Mantri Krishi Sinchai Yojana (Watershed Development) on Land Use and Cropping Pattern in Madhya Pradesh (Ad-hoc)",
      Pdf_Attached: "Nill",
      NAAS_Rating_2020: "4.53",
    },
    {
      Title_of_Publication_Work:
        "Yield gap and constraints in adoption of Soybean production technologies in central Narmada valley agro-climatic zone of Madhya Pradesh",
      Journal_Name: "International Journal of Agriculture Sciences",
      Month_year_of_publication_volume_no_page_nos:
        "Volume 8, Issue 61, Pg :3463-3467",
      Name_of_Faculty_Authors: "Kumar, Santosh, D. Rathi and Nahatkar, S.B.",
      Date_Year_of_Publication: "2016",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: RP_43,
      NAAS_Rating_2020: "4.58",
    },
    {
      Title_of_Publication_Work:
        "Impact of National Food Security Mission-Wheat in Madhya Pradesh - A Time Series Analysis;",
      Journal_Name:
        "International Journal of Envt, Ecology, Family and Urban Studies",
      Month_year_of_publication_volume_no_page_nos: "6 (2) : 1-8",
      Name_of_Faculty_Authors: "Meena S.C., Niranjan, H.K. & D. Rathi",
      Date_Year_of_Publication: "2016",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than: "AERC/DES",
      Indicate_Name_of_study:
        "Impact of National Food Security Mission (NFSM) on Input Use, Production, Yield and Income in Madhya Pradesh",
      Pdf_Attached: RP_44,
      NAAS_Rating_2020: "#",
    },
    {
      Title_of_Publication_Work:
        "Pre-Harvest Losses as Perceived by the Wheat Growers in Madhya Pradesh– An Economic Analysis",
      Journal_Name: "Agropadology",
      Month_year_of_publication_volume_no_page_nos: "26 (02), 172-177",
      Name_of_Faculty_Authors: "Meena, S.C., D. Rathi & Sharma, H.O.",
      Date_Year_of_Publication: "2016",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than: "AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: RP_45,
      NAAS_Rating_2020: "#",
    },
    {
      Title_of_Publication_Work:
        "Past and Future Trends using ARIMA Model in Area, Production and Productivity of Onion in Madhya Pradesh",
      Journal_Name:
        "International Journal of Agricultural Science and Research (IJASR)",
      Month_year_of_publication_volume_no_page_nos: "6 (2): 189-194.",
      Name_of_Faculty_Authors: "Niranjan, H.K. and Chouhan, R.S.",
      Date_Year_of_Publication: "2016",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: RP_46,
      NAAS_Rating_2020: "#",
    },
    {
      Title_of_Publication_Work:
        "Trend and Sustainability of Soybean Production in Madhya Pradesh",
      Journal_Name: "Advances in Life Sciences",
      Month_year_of_publication_volume_no_page_nos: "5 (8): 3124-3126",
      Name_of_Faculty_Authors: "Niranjan, H.K. and Gupta, J. K.",
      Date_Year_of_Publication: "2016",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: RP_47,
      NAAS_Rating_2020: "#",
    },
    {
      Title_of_Publication_Work:
        "Impact of National Food Security Mission (NFSM) on Annual Usage And Benefit Derived From Farm Equipment in Cultivation of Wheat in Madhya Pradesh.",
      Journal_Name: "International Journal of Agriculture Sciences",
      Month_year_of_publication_volume_no_page_nos: "8 (1) : 1116-1118",
      Name_of_Faculty_Authors: "Niranjan, H.K.; Sharma, H.O. and D. Rathi",
      Date_Year_of_Publication: "2016",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than: "AERC/DES",
      Indicate_Name_of_study:
        "Impact of National Food Security Mission (NFSM) on Input Use, Production, Yield and Income in Madhya Pradesh",
      Pdf_Attached: RP_48,
      NAAS_Rating_2020: "4.58",
    },
    {
      Title_of_Publication_Work:
        "Resource Use Efficiency of Chickpea Production in Sagar District of Madhya Pradesh",
      Journal_Name:
        "International Journal of Agricultural Science and Research (IJASR)",
      Month_year_of_publication_volume_no_page_nos: "6 (2): 101-106",
      Name_of_Faculty_Authors: "Thakur, S.S.; , Kumar, Santosh and D. Rathi",
      Date_Year_of_Publication: "2016",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "Other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: RP_49,
      NAAS_Rating_2020: "#",
    },
    {
      Title_of_Publication_Work:
        "Impact of Soil Health Card Scheme on farmers' income- A case study of kharif crops in Madhya Pradesh",
      Journal_Name: "Agricultural Economics Research Review",
      Month_year_of_publication_volume_no_page_nos:
        "30(Conference number) : 139-141",
      Name_of_Faculty_Authors:
        "Chouhan, R.S., Sharma, H.O., D. Rathi. and Niranjan, H.K.",
      Date_Year_of_Publication: "2017",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than: "AERC/DES",
      Indicate_Name_of_study:
        "Impact of Soil Health Card Scheme on Production, Productivity and Soil Health in Madhya Pradesh",
      Pdf_Attached: RP_50,
      NAAS_Rating_2020: "5.84",
    },
    {
      Title_of_Publication_Work:
        "Dynamic of Area Production and Productivity of Gram in Madhya Pradesh",
      Journal_Name: "Trends in Biosciences",
      Month_year_of_publication_volume_no_page_nos: "10 (3): 1071-1073",
      Name_of_Faculty_Authors: "Niranjan, H.K. and Gupta, J. K.",
      Date_Year_of_Publication: "2017",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: RP_51,
      NAAS_Rating_2020: "#",
    },
    {
      Title_of_Publication_Work:
        "Crop Response to Application of NCU in Major Kharif Crops- An Impact Assessment in Central India",
      Journal_Name: "Economic Affairs",
      Month_year_of_publication_volume_no_page_nos: "62 (4): 1-5",
      Name_of_Faculty_Authors:
        "Niranjan, H.K.; Chouhan, R.S.; Rathi, Deepak. and Sharma, H.O.",
      Date_Year_of_Publication: "2017",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than: "AERC/DES",
      Indicate_Name_of_study:
        "Impact of Neem-Coated Urea on Production, Productivity and Soil Health in Madhya Pradesh",
      Pdf_Attached: RP_52,
      NAAS_Rating_2020: "5.08",
    },
    {
      Title_of_Publication_Work:
        "Constraints in Adoption of Recommended Package of Practices of Rainfed Wheat Cultivation in Madhya Pradesh",
      Journal_Name: "Indian Journal of Extension Education",
      Month_year_of_publication_volume_no_page_nos: "53 (3): 81-84",
      Name_of_Faculty_Authors:
        "Niranjan, H.K.; Chouhan, R.S.; Sharma, H.O. and Rathi, Deepak.",
      Date_Year_of_Publication: "2017",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: RP_53,
      NAAS_Rating_2020: "5.95",
    },
    {
      Title_of_Publication_Work:
        "Total Factor Productivity Growth of Wheat in Madhya Pradesh",
      Journal_Name: "Bulletin of Environment, Pharmacology and Life Sciences",
      Month_year_of_publication_volume_no_page_nos: "6 (11): 43-47",
      Name_of_Faculty_Authors:
        "Niranjan, H.K.; Mishra, Pradeep and Chouhan, R.S.",
      Date_Year_of_Publication: "2017",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: RP_54,
      NAAS_Rating_2020: "**",
    },
    {
      Title_of_Publication_Work:
        "Impact of IWMP on Land Use and Cropping Pattern in Central India",
      Journal_Name: "Indian Journal of Economics and Developmen",
      Month_year_of_publication_volume_no_page_nos: "13 (2a): 591-595",
      Name_of_Faculty_Authors: "Niranjan, H.K.; Sharma, H.O. and D. Rathi",
      Date_Year_of_Publication: "2017",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "other then AERC/DES",
      Indicate_Name_of_study:
        "Impact of Pradhan Mantri Krishi Sinchai Yojana (Watershed Development) on Land Use and Cropping Pattern in Madhya Pradesh (Ad-hoc)",
      Pdf_Attached: RP_55,
      NAAS_Rating_2020: "5.15",
    },
    {
      Title_of_Publication_Work:
        "Constraint in Adoption of Neem Coated Urea (NCU) in Madhya Pradesh",
      Journal_Name:
        "International Journal of Bio resource and Stress Management",
      Month_year_of_publication_volume_no_page_nos: "2018, 9 (1): 173-177",
      Name_of_Faculty_Authors:
        "Chouhan, R.S.; Niranjan, H.K.; Sharma, H. O; Rathi, Deepak. and Kurmi, H. S.",
      Date_Year_of_Publication: "2018",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than: "AERC/DES",
      Indicate_Name_of_study:
        "Impact of Neem-Coated Urea on Production, Productivity and Soil Health in Madhya Pradesh",
      Pdf_Attached: RP_56,
      NAAS_Rating_2020: "5.11",
    },
    {
      Title_of_Publication_Work:
        "Analysis of Marketing Efficiency of Wheat in Different Grade Regulated Markets in Madhya Pradesh",
      Journal_Name: "Economic Affairs",
      Month_year_of_publication_volume_no_page_nos: "2018, 63 (1): 113-118",
      Name_of_Faculty_Authors:
        "Chouhan, R.S.; Niranjan, H.K.; Sharma, H. O.and Raghuwanshi, R.S.",
      Date_Year_of_Publication: "2018",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "Other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: RP_57,
      NAAS_Rating_2020: "5.08",
    },
    {
      Title_of_Publication_Work:
        "Adoption of Recommended Dose of Fertilizers by Soil Test Farmers in Wheat: An Economic Analysis.",
      Journal_Name: "Multilogic in Science",
      Month_year_of_publication_volume_no_page_nos:
        "7 (Special Issue) -485-488",
      Name_of_Faculty_Authors:
        "Chouhan, R.S., Niranjan, H.K., Sharma, H.O. and Rathi, Deepak.",
      Date_Year_of_Publication: "2018",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than: "AERC/DES",
      Indicate_Name_of_study:
        "Adoption of Recommended Doses of Fertilizers on Soil Test Basis by Farmers",
      Pdf_Attached: RP_58,
      NAAS_Rating_2020: "4.51",
    },
    {
      Title_of_Publication_Work:
        "Awareness and performance of soil health card scheme in central India",
      Journal_Name: "Journal of Crop and Weed",
      Month_year_of_publication_volume_no_page_nos: "14 (1): 99-103",
      Name_of_Faculty_Authors:
        "Niranjan, H.K.; Chouhan, R.S.; Sharma, H.O. and Rathi, Deepak.",
      Date_Year_of_Publication: "2018",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than: "AERC/DES",
      Indicate_Name_of_study:
        "Impact of Soil Health Card Scheme on Production, Productivity and Soil Health in Madhya Pradesh",
      Pdf_Attached: RP_59,
      NAAS_Rating_2020: "5.46",
    },
    {
      Title_of_Publication_Work:
        "Total factor productivity growth and trend in production of gram in Central India",
      Journal_Name: "Journal of Pharmacognosy and Phytochemistry",
      Month_year_of_publication_volume_no_page_nos: "SP2: 21-24",
      Name_of_Faculty_Authors:
        "Niranjan, H.K.; Gupta, J. K.; Mishra, P and Chouhan, R.S.",
      Date_Year_of_Publication: "2018",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: RP_60,
      NAAS_Rating_2020: "**",
    },
    {
      Title_of_Publication_Work:
        "Total Factor Productivity Growth of soybean in Madhya Pradesh",
      Journal_Name: "Indian Journal of Economics and Development",
      Month_year_of_publication_volume_no_page_nos: "14 (1): 182-186",
      Name_of_Faculty_Authors:
        "Niranjan, H.K.; Mishra, Pradeep; Chouhan, R.S. and Shukla, Bhasker",
      Date_Year_of_Publication: "2018",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: RP_61,
      NAAS_Rating_2020: "5.15",
    },
    {
      Title_of_Publication_Work:
        "Marketable Surplus and Post- Harvest Losses of wheat crops in Jabalpur district of Madhya Pradesh",
      Journal_Name: "Life Science Bulletin",
      Month_year_of_publication_volume_no_page_nos: "15 (1) 55-57.",
      Name_of_Faculty_Authors: "Pandey P.R., Gautam A.N. and Rajak S.K.",
      Date_Year_of_Publication: "2018",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: "Nill",
      NAAS_Rating_2020: "#",
    },
    {
      Title_of_Publication_Work:
        "Integrated farming system: only way to increase farmer’s Income in a sustainable manner.",
      Journal_Name: "Journal of Pharmacognosy and Phytochemistry",
      Month_year_of_publication_volume_no_page_nos: "Sp2 :210-214",
      Name_of_Faculty_Authors:
        "Pandey, P. R., Sharma, H.O., Gupta, J.K., Mishra, P. and Chaurasiya Rajkumar",
      Date_Year_of_Publication: "2018",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: RP_63,
      NAAS_Rating_2020: "**",
    },
    {
      Title_of_Publication_Work:
        "Marketing Channels of Garlic: A Case of Ratlam District of Madhya Pradesh",
      Journal_Name: "International Journal of Current Advanced Research",
      Month_year_of_publication_volume_no_page_nos: "7, 6(I): 13669-13672",
      Name_of_Faculty_Authors: "Patidar, P.K., Khan, N. and Santosh Kumar",
      Date_Year_of_Publication: "2018",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "Other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: RP_64,
      NAAS_Rating_2020: "**",
    },
    {
      Title_of_Publication_Work:
        "An Economic Analysis of Garlic Cultivation in Ratlam District of Madhya Pradesh",
      Journal_Name:
        "International Journal of Agriculture, Environment and Biotechnology",
      Month_year_of_publication_volume_no_page_nos: "11(2): 371-377",
      Name_of_Faculty_Authors: "Patidar, P.K., Khan, N. and Santosh Kumar",
      Date_Year_of_Publication: "2018",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "Other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: RP_65,
      NAAS_Rating_2020: "4.54",
    },
    {
      Title_of_Publication_Work:
        "Instability and sustainability analysis of mustard in wind and relationship with factors of production and productivity in India",
      Journal_Name: "Indian Journal of Economics and Development",
      Month_year_of_publication_volume_no_page_nos: "2018, 14 (1a): 175-179",
      Name_of_Faculty_Authors:
        "Soni, Ankit; Mishra Pradeep; Singh, R.B; Niranjan, H.K.; Gupta, J. K. and Supriya",
      Date_Year_of_Publication: "2018",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "Other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: RP_66,
      NAAS_Rating_2020: "5.15",
    },
    {
      Title_of_Publication_Work:
        "Trend and Growth of Small Millets Production in Madhya Pradesh as Compared to India.",
      Journal_Name: "International Journal of Agriculture Sciences",
      Month_year_of_publication_volume_no_page_nos: "10, (1): 4983-4986",
      Name_of_Faculty_Authors: "Thakur, S.S. and Sharma H.O.",
      Date_Year_of_Publication: "2018",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than: "AER/DES",
      Indicate_Name_of_study:
        "Problems and Prospects of Production of Small Millets and their Value Added Products in Madhya Pradesh",
      Pdf_Attached: RP_67,
      NAAS_Rating_2020: "4.58",
    },
    {
      Title_of_Publication_Work:
        "Cost of Cultivation and Profitability Situation of Cowpea Vegetable in Dindori district of Madhya Pradesh under Tejaswini Rural Woman Empowerment Programme,",
      Journal_Name: "Bhartiya Krishi Anushandhan Patrika",
      Month_year_of_publication_volume_no_page_nos: "34(1) :28-32",
      Name_of_Faculty_Authors: "Bareliya, R.S; Thakur, S.S. and Sharma,H.O",
      Date_Year_of_Publication: "2019",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "Other then AERC/DES",
      Indicate_Name_of_study:
        "Impact of Tejaswini rural Woman Empowerment programme on Empowerment of Rural Woman through Vegetable Production in Dindori and Chatarpur districts",
      Pdf_Attached: RP_68,
      NAAS_Rating_2020: "#*",
    },
    {
      Title_of_Publication_Work:
        "Economics of Value Added Products of Kodo and Kutki",
      Journal_Name: "Indian Journal of Economics and Development",
      Month_year_of_publication_volume_no_page_nos: "2019, 15 (3), 465-469",
      Name_of_Faculty_Authors:
        "Chouhan, R.S.; Niranjan, H.K.; and Sharma, H. O.",
      Date_Year_of_Publication: "2019",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "Other then AERC/DES",
      Indicate_Name_of_study:
        "Impact of Tejaswini rural Woman Empowerment programme on Empowerment of Rural Woman through Cultivation, Processing, and Marketing of Kodo- kutki in Dindori district of Madhya Pradesh (Ad-hoc)",
      Pdf_Attached: RP_69,
      NAAS_Rating_2020: "5.15",
    },
    {
      Title_of_Publication_Work: "Farmer Suicides in Madhya Pradesh",
      Journal_Name: "Economic Affairs",
      Month_year_of_publication_volume_no_page_nos: "2019, 64 (3), 487-494",
      Name_of_Faculty_Authors:
        "Chouhan, R.S.; Niranjan, H.K.; Sharma, H. O. and Rathi, Deepak.",
      Date_Year_of_Publication: "2019",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than: "AERC/DES",
      Indicate_Name_of_study: "Farmer Suicides in Madhya Pradesh",
      Pdf_Attached: RP_70,
      NAAS_Rating_2020: "5.08",
    },
    {
      Title_of_Publication_Work:
        "Relationship between Arrivals and Prices of Wheat in different Regulated market of Madhya Pradesh.",
      Journal_Name: "Progressive Research: An International Journal",
      Month_year_of_publication_volume_no_page_nos: "14 (1): 33-37",
      Name_of_Faculty_Authors: "Chouhan, R.S., Gupta, J. K. and Niranjan, H.K.",
      Date_Year_of_Publication: "2019",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "Other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: RP_71,
      NAAS_Rating_2020: "3.78",
    },
    {
      Title_of_Publication_Work:
        "Insurance Behaviour of Insured Farmers under Pradhan Mantri Fasal Bima Yojna (PMFBY) in Central India",
      Journal_Name:
        "Asian Journal of Agricultural Extension, Economics & Sociology",
      Month_year_of_publication_volume_no_page_nos: "2019, 37(2): 1-6",
      Name_of_Faculty_Authors:
        "Niranjan, H.K.; Chouhan, R.S.; Sharma, H.O; Kuril, Akhilesh and Thakur, S.S.",
      Date_Year_of_Publication: "2019",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than: "AERC/DES",
      Indicate_Name_of_study:
        "Performance Evaluation of Pradhan Mantri Fasal Bima Yojana (PMFBY) in Madhya Pradesh",
      Pdf_Attached: RP_72,
      NAAS_Rating_2020: "4.86",
    },
    {
      Title_of_Publication_Work:
        "Estimation of Total Factor Productivity Growth of Maize Production in Central India",
      Journal_Name: "Progressive Research: An International Journal",
      Month_year_of_publication_volume_no_page_nos: "14 (1): 68-71",
      Name_of_Faculty_Authors:
        "Niranjan, H.K.; Gupta, J. K.; Mishra, P and Chouhan, R.S.",
      Date_Year_of_Publication: "2019",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: RP_73,
      NAAS_Rating_2020: "3.78",
    },
    {
      Title_of_Publication_Work:
        "Constraints Faced by Farmers in Adoption of Integrated Farming System in Vindhyan Plateau of Madhya Pradesh",
      Journal_Name: "Plant Archives",
      Month_year_of_publication_volume_no_page_nos: "19 (2):512-514",
      Name_of_Faculty_Authors:
        "Pandey, P.R.; Gupta, J.K.; Narvariya, R.K.; Meena S.C. and Narwariya, D.",
      Date_Year_of_Publication: "2019",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: RP_74,
      NAAS_Rating_2020: "4.73",
    },
    {
      Title_of_Publication_Work:
        "Dynamics of Maize Production in different Agro-climatic Regions of Madhya Pradesh",
      Journal_Name: "AGRICULTURAL SITUATION IN INDIA",
      Month_year_of_publication_volume_no_page_nos: "LXXVI (2) : 9-18",
      Name_of_Faculty_Authors: "Zadran, Bakht Amir and Sharma, H.O",
      Date_Year_of_Publication: "2019",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "Other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: RP_75,
      NAAS_Rating_2020: "4.53",
    },
    {
      Title_of_Publication_Work:
        "Impact of Sprinkler Irrigation System on Production and Profitability of Wheat in Sagar District of Madhya Pradesh, India",
      Journal_Name: "Current Journal of Applied Science and Technology",
      Month_year_of_publication_volume_no_page_nos: "39(48):183-188",
      Name_of_Faculty_Authors: "Bareliya, R.S; Thakur, S.S. and Rathi, Deepak.",
      Date_Year_of_Publication: "2020",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than: "AERC/DES",
      Indicate_Name_of_study:
        "Improving Water Use Efficiency in India's Agriculture: The Impact, Benefits and Challenges of Micro-Irrigation under the Pradhan Mantri Krishi Sinchayee Yojana - Per Drop More Crop (PMKSY -PDMC) in Madhya Pradesh",
      Pdf_Attached: RP_76,
      NAAS_Rating_2020: "4.71",
    },
    {
      Title_of_Publication_Work:
        "An Economic Analysis of Brinjal Cultivation in Chhattarpur District of Madhya Pradesh, Under Tejaswini Rural Women Empowerment Program",
      Journal_Name: "Advances in Agriculture for doubling of farmers income",
      Month_year_of_publication_volume_no_page_nos: "? (?): 110-115",
      Name_of_Faculty_Authors: "Bareliya, R.S.; Gupta, J.K. and Patidar,P.K.",
      Date_Year_of_Publication: "2020",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "Other then AERC/DES",
      Indicate_Name_of_study:
        "Impact of Tejaswini rural Woman Empowerment programme on Empowerment of Rural Woman through Vegetable Production in Dindori and Chatarpur",
      Pdf_Attached: RP_77,
      NAAS_Rating_2020: "$",
    },
    {
      Title_of_Publication_Work:
        "Determinants of crop diversification in Kerala- a temporal analysis",
      Journal_Name: "Journal of Tropical Agriculture",
      Month_year_of_publication_volume_no_page_nos: "58 (1): 99-106",
      Name_of_Faculty_Authors: "George, Geethu P. and Sharma, H.O",
      Date_Year_of_Publication: "2020",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: RP_78,
      NAAS_Rating_2020: "4.85",
    },
    {
      Title_of_Publication_Work:
        "Modelling and Forecasting of Milk Production in Chhattisgarh and India",
      Journal_Name: "Indian Journal of Animal Research",
      Month_year_of_publication_volume_no_page_nos: "54 (7) 912-917",
      Name_of_Faculty_Authors:
        "Mishra, P; Fatih, Chellai; Niranjan, H.K.; Tiwari, Shiwani; Devi, Monika and Dubey, Anurag",
      Date_Year_of_Publication: "2020",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: RP_79,
      NAAS_Rating_2020: "6.44",
    },
    {
      Title_of_Publication_Work:
        "Cause and Effect of Farmers’ Suicides in Chhattisgarh, India",
      Journal_Name: "Current Journal of Applied Science and Technology",
      Month_year_of_publication_volume_no_page_nos: "39(14): 98-107",
      Name_of_Faculty_Authors:
        "Niranjan, H. K.; Chouhan, R.S.; Sharma, H. O and Thakur, S. S.",
      Date_Year_of_Publication: "2020",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than: "AERC/DES",
      Indicate_Name_of_study: "Farmer Suicides in Chhattisgarh",
      Pdf_Attached: RP_80,
      NAAS_Rating_2020: "4.71",
    },
    {
      Title_of_Publication_Work:
        "Impact of SRI Method of rice cultivation on profitability and socio-economic status of tribal woman SHGs in Madhya Pradesh",
      Journal_Name: "Multilogic in Science",
      Month_year_of_publication_volume_no_page_nos: "10 (36): 1471-1475",
      Name_of_Faculty_Authors:
        "Niranjan, H.K., Nahatkar, S. B.; Chouhan, R.S.; Sharma, H.O and Thaku, S.S",
      Date_Year_of_Publication: "2020",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "other then AERC/DES",
      Indicate_Name_of_study:
        "Impact of Tejaswini rural Woman Empowerment programme on Empowerment of Rural Woman through System of Rice Intensification (SRI) in Mandla and Balaghat district of Madhya Pradesh (Ad-hoc)",
      Pdf_Attached: RP_81,
      NAAS_Rating_2020: "4.51",
    },
    {
      Title_of_Publication_Work:
        "An Economic Estimation of Capsicum Production in Shajapur District of Madhya Pradesh, India",
      Journal_Name: "International Journal Current Microbiol. Applied Sciencel",
      Month_year_of_publication_volume_no_page_nos: "9(6): 1796-1802",
      Name_of_Faculty_Authors:
        "Patidar, P.K., Pandey, P.R., Gupta, J.K., and Pawaiya Trapti",
      Date_Year_of_Publication: "2020",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "Other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: RP_82,
      NAAS_Rating_2020: "**",
    },
    {
      Title_of_Publication_Work:
        "Comparative Economic Analysis of Rice in Kharif and Rabi Season in Guntur District of Andhra Pradesh",
      Journal_Name: "Journal of Plant Development Sciences",
      Month_year_of_publication_volume_no_page_nos: "12 (2): 81-85",
      Name_of_Faculty_Authors:
        "Patidar, P.K., R.L., Priyanka, Khan, N. and Dharmendra",
      Date_Year_of_Publication: "2020",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "Other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: RP_83,
      NAAS_Rating_2020: "4.13",
    },
    {
      Title_of_Publication_Work:
        "Performance of Difference Agencies Under Pradhan Mantri Fasal Bima Yojna in Madhya Pradesh",
      Journal_Name: "Indian Journal of Economics and Development",
      Month_year_of_publication_volume_no_page_nos: "16 (1):157-160",
      Name_of_Faculty_Authors: "Thakur, S.S.;Bareliya, R.S. and Sharma,H.O",
      Date_Year_of_Publication: "2020",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than: "AER/DES",
      Indicate_Name_of_study:
        "Performance Evaluation of Pradhan Mantri Fasal Bima Yojana (PMFBY) in Madhya Pradesh",
      Pdf_Attached: RP_84,
      NAAS_Rating_2020: "5.15",
    },
    {
      Title_of_Publication_Work:
        "Constraints faced by the respondents in existing farming systems in Madhya Pradesh.",
      Journal_Name: "Journal of Pharmacognosy and Phytochemistry.",
      Month_year_of_publication_volume_no_page_nos: "9 (5): 771-775",
      Name_of_Faculty_Authors: "Tiwari, Y; Sharma, H. O and Awasthi, P.K",
      Date_Year_of_Publication: "2020",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "Other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: RP_85,
      NAAS_Rating_2020: "**",
    },
    {
      Title_of_Publication_Work:
        "Determinants of Fallow Land in Different Districts of Madhya Pradesh",
      Journal_Name: "Current Journal of Applied Science and Technology",
      Month_year_of_publication_volume_no_page_nos: "40 (3): 33-40",
      Name_of_Faculty_Authors:
        "Bareliya, R.S; Thakur, S.S.; Sharma,H.O;Pandey,P.R. and Mehta,V.",
      Date_Year_of_Publication: "2021",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than: "AERC/DES",
      Indicate_Name_of_study:
        "Dynamics and Revival of Fallow Lands in Madhya Pradesh.",
      Pdf_Attached: RP_86,
      NAAS_Rating_2020: "4.71",
    },
    {
      Title_of_Publication_Work:
        "Factors Affecting Farm Income of Different Farming System in Madhya Pradesh.",
      Journal_Name:
        "Asian Journal of Agricultural Extension, Economics & Sociology.",
      Month_year_of_publication_volume_no_page_nos: "39 (2): 107-110",
      Name_of_Faculty_Authors:
        "Tiwari, Y; Sharma, H. O; Awasthi, P.K and Kolar P",
      Date_Year_of_Publication: "2021",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "Other then AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: RP_87,
      NAAS_Rating_2020: "4.86",
    },
    {
      Title_of_Publication_Work:
        "Relationship between Arrivals and Prices of Onion in Madhya Pradesh",
      Journal_Name: "Multilogic in Science",
      Mont__year_of_publication_volume_no_page_nos: "12 (41): 48-50",
      Name_of_Faculty_Authors:
        "Chouhan, R.S. , Sharma, H.O. , Gurnani, Kaustubh and Niranjan, H.K.",
      Date_Year_of_Publication: "2022",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than: "AERC/DES",
      Indicate_Name_of_study: "-",
      Pdf_Attached: RP_88,
      NAAS_Rating_2020: "4.51",
    },
    {
      Title_of_Publication_Work:
        "Improving Water Use Efficiency in India’s Agriculture: Impact, Benefits and Challenges of Micro Irrigation under PMKSY-PDMC in Madhya Pradesh",
      Journal_Name: "Agricultural Situation in India",
      Month_year_of_publication_volume_no_page_nos: "LXXVIII (8): 35-46",
      Name_of_Faculty_Authors:
        "Sharma, H.O; D. Rathi; Patidar, Pradeep and Niranjan, H.K.",
      Date_Year_of_Publication: "2021",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than: "AERC/DES",
      Indicate_Name_of_study:
        "Improving Water Use Efficiency in India's Agriculture: The Impact, Benefits and Challenges of Micro-Irrigation under the Pradhan Mantri Krishi Sinchayee Yojana - Per Drop More Crop (PMKSY -PDMC) in Madhya Pradesh",
      Pdf_Attached: RP_89,
      NAAS_Rating_2020: "4.53",
    },
    {
      Title_of_Publication_Work:
        "Dynamics of Mustard Production in Different Districts of Madhya Pradesh",
      Journal_Name: "Agricultural Situation in India",
      Month_year_of_publication_volume_no_page_nos: "LXXVIII (10): 11-22",
      Name_of_Faculty_Authors: "Paul, Pintu ; Sharma, H.O and Niranjan, H.K.",
      Date_Year_of_Publication: "2022",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "other then AERC/DES",
      Indicate_Name_of_study: "Student Research",
      Pdf_Attached: RP_90,
      NAAS_Rating_2020: "4.53",
    },
    // *******************************************************************************************************

    {
      Title_of_Publication_Work:
        "Strategies to Bridge Yield Gap of Major Crops in Bundelkhand Region of India",
      Journal_Name: "Agricultural Situation in India",
      Month_year_of_publication_volume_no_page_nos: "79 (2): 34-49",
      Name_of_Faculty_Authors: "Sharma, H.O; Niranjan, H.K. and Bareliya, R.S.",
      Date_Year_of_Publication: "2022",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than: "AERC/DES",
      Indicate_Name_of_study:
        "Strategies to Bridge Yield Gap of Major Crops in Bundelkhand Region of India",
      Pdf_Attached: bundelkhand_region,
      NAAS_Rating_2020: "4.53",
    },
    {
      Title_of_Publication_Work:
        "Dynamics of Maize Production across Major Producing States of India ",
      Journal_Name: "Agricultural Situation in India",
      Month_year_of_publication_volume_no_page_nos: "79 (4): 22-32",
      Name_of_Faculty_Authors:
        "Patel, Sanskala; Sharma, Hari Om;Khan, N. and Laxkar, Harshita ",
      Date_Year_of_Publication: "2022",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "other then AERC/DES",
      Indicate_Name_of_study: "Student Research",
      Pdf_Attached: dynamic_maize_production,
      NAAS_Rating_2020: "4.53",
    },
    {
      Title_of_Publication_Work:
        "Cost, return and profitability analysis of Maize in Madhya Pradesh",
      Journal_Name: "The Pharma Innovation",
      Month_year_of_publication_volume_no_page_nos: "11 (9): 358-361",
      Name_of_Faculty_Authors:
        "Patel, Sanskala; Sharma, Hari Om; Mishra, Shruti., and Vani, Gourav Kumar",
      Date_Year_of_Publication: "2022",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than:
        "other then AERC/DES",
      Indicate_Name_of_study: "Student Research",
      Pdf_Attached: pharma_innovation,
      NAAS_Rating_2020: "##",
    },
    {
      Title_of_Publication_Work:
        "Market Imperfection and Farm Profitability in Madhya Pradesh",
      Journal_Name: "Agricultural Situation in India",
      Month_year_of_publication_volume_no_page_nos: "79 (6): 40-55",
      Name_of_Faculty_Authors: "Sharma, H.O; D. Rathi and Niranjan, H.K. ",
      Date_Year_of_Publication: "2022",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than: "AERC/DES",
      Indicate_Name_of_study:
        "Market Imperfection and Farm Profitability in Madhya Pradesh",
      Pdf_Attached: market_imperfection,
      NAAS_Rating_2020: "4.53",
    },
    {
      Title_of_Publication_Work:
        "En sure Better Har vest and Better In come and Dis tri bu tion Ef fi ciency of Seedminikit in Madhya Pradesh",
      Journal_Name: "Frontiers in Crop Improvement",
      Month_year_of_publication_volume_no_page_nos:
        "10 (Special Issue-V): 2297-2301",
      Name_of_Faculty_Authors:
        "Rathi, Deepak.; Chouhan, R.S. Pandey,; P.R. Aske, Sachin and Niranjan, H.K.",
      Date_Year_of_Publication: "2022",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than: "AERC/DES",
      Indicate_Name_of_study:
        "Relevance and Distribution Efficiency of Seed Mini-Kits of Pulses in Madhya Pradesh",
      Pdf_Attached: Frontiers_in_Crop,
      NAAS_Rating_2020: "4.67",
    },
    {
      Title_of_Publication_Work:
        "Marketing Efficiency of Major Spices in Madhya Pradesh with Special References to Farmer Producer Organization (FPOs)",
      Journal_Name:
        "Asian Journal of Agricultural Extension, Economics & Sociology",
      Month_year_of_publication_volume_no_page_nos: "40(12):284-289",
      Name_of_Faculty_Authors:
        "Bareliya,R.S.; Patidar, P.K; Pandey, P.R. and Rathi, Deepak.",
      Date_Year_of_Publication: "2022",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than: "AERC",
      Indicate_Name_of_study: "NABARD Study FPO",
      Pdf_Attached: Marketing_Efficiency_of_Major_Spices,
      NAAS_Rating_2020: "4.86",
    },
    {
      Title_of_Publication_Work:
        "Impact of Micro Irrigation and Challenges Faced by the Farmers in adoption of Micro Irrigation in Central India",
      Journal_Name: "Progressive Research : An International Journal",
      Month_year_of_publication_volume_no_page_nos: "1(1): 20-21.",
      Name_of_Faculty_Authors:
        "Niranjan, H.K.; Bareliya,R.S. Akhilesh, Kuril and Rathi, D.",
      Date_Year_of_Publication: "2023",
      Research_Work_is_based_on_AER_DES_alloted_or_other_than: "AERC/DES",
      Indicate_Name_of_study:
        "Improving Water Use Efficiency in Indian Agriculture: The Impact, Benefits and Challenges of Micro-Irrigation under the Pradhan Mantri Krishi Sinchayee Yojana - Per Drop More Crop (PMKSY -PDMC) in Madhya Pradesh",
      Pdf_Attached: Niranjan,
      NAAS_Rating_2020: "3.78",
    },
  ];
  const header = [
    { label: "Title_of_Publication_Work", key: "Title_of_Publication_Work" },
    { label: "Journal_Name ", key: "Journal_Name" },
    {
      label: "Month_year_of_publication_volume_no_page_nos",
      key: "Month_year_of_publication_volume_no_page_nos",
    },
    { label: "Name_of_Faculty_Authors", key: "Name_of_Faculty_Authors" },
    { label: "Date_Year_of_Publication", key: "Date_Year_of_Publication" },
    {
      label: "Research_Work_is_based_on_AER_DES_alloted_or_other_than",
      key: "Research_Work_is_based_on_AER_DES_alloted_or_other_than",
    },
    { label: "Indicate_Name_of_study", key: "Indicate_Name_of_study" },
    { label: "NAAS_Rating_2020", key: "NAAS_Rating_2020" },
  ];
  const year_option = [
    { value: "", label: "select" },
    { value: "1999", label: "1999" },
    { value: "2001", label: "2001" },
    { value: "2003", label: "2003" },
    { value: "2004", label: "2004" },
    { value: "2006", label: "2006" },
    { value: "2009", label: "2009" },
    { value: "2010", label: "2010" },
    { value: "2011", label: "2011" },
    { value: "2012", label: "2012" },
    { value: "2013", label: "2013" },
    { value: "2014", label: "2014" },
    { value: "2015", label: "2015" },
    { value: "2016", label: "2016" },
    { value: "2017", label: "2017" },
    { value: "2018", label: "2018" },
    { value: "2019", label: "2019" },
    { value: "2020", label: "2020" },
    { value: "2021", label: "2021" },
    { value: "2021", label: "2021" },
    { value: "2022", label: "2022" },
  ];
  const faculty_opt = [
    { value: "", label: "select" },
    { value: "Sharma", label: "Sharma" },
    { value: "Rathi", label: "Rathi" },
    { value: "Niranjan", label: "Niranjan" },
    { value: "Pandey", label: "Pandey" },
    { value: "Patidar", label: "Patidar" },
    { value: "Bareliya", label: "Bareliya" },
    { value: "Kuril", label: "Kuril" },
    { value: "Meena", label: "Meena" },
    { value: "Chouhan", label: "Chouhan" },
    { value: "Thakur", label: "Thakur" },
    { value: "Aske", label: "Aske" },
    { value: "Gupta", label: "Gupta" },
    { value: "Gupta", label: "Gupta" },
    { value: "Sharma", label: "Sharma" },

    { value: "Awasthi", label: "Awasthi" },

    { value: "Sahu", label: "Sahu" },
    { value: "Mishra", label: "Mishra" },
    { value: "Tomar", label: "Tomar" },
    { value: "Chand", label: "Chand" },
    { value: "Yogi", label: "Yogi" },
    { value: "Rai", label: "Rai" },
    { value: "Singh", label: "Singh" },

    { value: "Shukla", label: "Shukla" },
    { value: "Sirohi", label: "Sirohi" },
    { value: "Smita", label: "Smita" },
    { value: "Dushyant", label: "Kumar" },
    // { value: "Sisodiya, M. S; Singh", label: "Sisodiya, M. S; Singh" },
    // { value: "Rajesh; Meena,", label: "Rajesh; Meena," },
    { value: "Paul", label: "Paul" },
    { value: "Patel", label: "Patel" },
    { value: "Shrivastava", label: "Shrivastava" },
    { value: "Gurjar", label: "Gurjar" },
    { value: "Sharma", label: "Sharma" },
    { value: "Bisen", label: "Bisen" },
    { value: "Kumar", label: "Kumar" },
    { value: "Nahatkar", label: "Nahatkar" },
    { value: "Masuda", label: "Masuda" },
    { value: "Rajput", label: "Rajput" },
    { value: "Agrwal", label: "Agrwal" },
    // { value: "Sahu", label: "Mishra, Pradeep; Sahu" },
    { value: "Bajpa", label: "Bajpa" },
    // { value: "Thakur, Arvind Dangi ", label: "Thakur, Arvind Dangi " },
    { value: "Kurmi", label: "Kurmi" },
    { value: "Raghuwanshi", label: "Raghuwanshi" },
    // { value: "Pandey P.R", label: "Pandey P.R" },
    { value: "Gautam", label: "Gautam" },
    { value: "Rajak", label: "Rajak" },
    { value: "Chaurasiya", label: "Chaurasiya" },
  ];

  const tabledata = data.filter(
    (item) =>
      item.NAAS_Rating_2020.includes(rating) &&
      item.Name_of_Faculty_Authors.includes(faculty) &&
      item.Date_Year_of_Publication.includes(year) &&
      item.Title_of_Publication_Work.includes(search)
  );
  const table = tabledata.map((item) => {
    return (
      <tr>
        <td>{item.Title_of_Publication_Work}</td>
        <td>{item.Journal_Name}</td>
        <td>{item.Month_year_of_publication_volume_no_page_nos}</td>
        <td>{item.Name_of_Faculty_Authors}</td>
        <td>{item.Date_Year_of_Publication}</td>
        <td>{item.Research_Work_is_based_on_AER_DES_alloted_or_other_than}</td>
        <td>{item.Indicate_Name_of_study}</td>
        <td>{item.NAAS_Rating_2020}</td>
        {item.Pdf_Attached === "Nill" ? (
          <td>No Pdf</td>
        ) : (
          // <td>{item.Pdf_Attached}</td>
          <td>
            <a href={item.Pdf_Attached}>
              <img src={PDF_IMage} />
            </a>
          </td>
        )}
      </tr>
    );
  });
  return (
    <div>
      <section className="about section">
        <div className="container">
          <div className="row">
            <div className="about-content">
              <h3>Journal Article</h3>
              <section className="blog-area">
                <div className="container pt-20">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12  ">
                      <div className="finder-journal ">
                        <ul>
                          <li>
                            <Select
                              className=""
                              placeholder="Date/Year of Publication"
                              onChange={(e) => {
                                setyear(e.value);

                                setrating("");
                              }}
                              options={year_option}
                            />
                          </li>

                          <li className="rating">
                            <Select
                              placeholder="NAAS Rating 2020"
                              onChange={(e) => {
                                setrating(e.value);
                                setyear("");
                                setfaculty("");
                              }}
                              options={nass_opt}
                            />
                          </li>
                          <li className="rating">
                            <Select
                              placeholder="Name of Faculty/Authors"
                              onChange={(e) => {
                                setfaculty(e.value);
                                setrating("");
                              }}
                              options={faculty_opt}
                            />
                          </li>
                          <li>
                            <input
                              onChange={(e) => {
                                setsearch(e.target.value);
                              }}
                              placeholder="Search Title Of Publication "
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="completed_table">
                <table style={{ width: "100%" }}>
                  <tr>
                    <th style={{ width: "40%" }}>Title of Publication Work</th>
                    <th>Journal Name</th>
                    <th>Month & year of publication, volume no. & page nos.</th>
                    <th>Name of Faculty/Authors</th>
                    <th>Date/Year of Publication</th>
                    <th>
                      Research Work is based on AER/DES alloted or other than
                    </th>
                    <th style={{ width: "" }}> Indicate Name of study </th>
                    <th>NAAS Rating 2020 </th>
                    <th>Pdf Attached </th>
                  </tr>
                  {table}
                </table>
                <button className="btn ">
                  {" "}
                  <CSVLink data={tabledata} filename="new.csv" headers={header}>
                    download
                  </CSVLink>
                </button>

                <p>
                  * Journals could not be assigned NAAS Score as they do not
                  have Thomson Reuters impect factors for the last four
                  consecutive years
                </p>
                <p>
                  ** Jounals could not be assigned NAAS Score as they did not
                  fulfil the eligibility criteria or were listed as predatory
                  journals
                </p>
                <p>
                  # Journals could not be evaluated due to non-receipt of
                  required information or incomplete information
                </p>
                <p>$ Journal is not listed in NAAS list 2022</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default JournalArticle;
