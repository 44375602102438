import React from "react";
import { useState } from "react";
import Select from "react-select";
import PDF_IMage from "../assets/images/pdf.png";
import { CSVLink, CSVDownload } from "react-csv";
import PDF_1 from "../assets/PDF FILE/policy_Brief_PDF's/PDF/1 Sharma & Vani Organic Brief.pdf";
import PDF_2 from "../assets/PDF FILE/policy_Brief_PDF's/PDF/2 Chouhan Millet Brief.pdf";
import PDF_3 from "../assets/PDF FILE/policy_Brief_PDF's/PDF/3 Chouhan Fallow Land Brief.pdf";
import PDF_4 from "../assets/PDF FILE/policy_Brief_PDF's/PDF/4 Rajendra 2019 MI Alert.pdf";
import PDF_5 from "../assets/PDF FILE/policy_Brief_PDF's/PDF/5 Niranjan SRI Alerts_.pdf";
import PDF_6 from "../assets/PDF FILE/policy_Brief_PDF's/PDF/6 Niranjan YG Brief.pdf";
import PDF_7 from "../assets/PDF FILE/policy_Brief_PDF's/PDF/7 Sharma SMK 2020_Brief.pdf";
import PDF_8 from "../assets/PDF FILE/policy_Brief_PDF's/PDF/8 Patidar 2020 YGBKD Brief.pdf";
import PDF_9 from "../assets/PDF FILE/policy_Brief_PDF's/PDF/9 Rajendra 2021 FPO Alert.pdf";
import PDF_10 from "../assets/PDF FILE/policy_Brief_PDF's/PDF/10 Niranjan MIR Brief.pdf";
import PDF_11 from "../assets/PDF FILE/policy_Brief_PDF's/PDF/11 Sharma and thakut.pdf";

function Policy_Brief_Alert() {
  const [journal, setjournal] = useState("");
  const [research, setresearch] = useState("");
  const [year, setyear] = useState("");
  const [search, setsearch] = useState("");
  const [faculty, setfaculty] = useState("");
  const head = [
    { label: "S_No", key: "S_No" },
    { label: "Title_of_Publication_Work", key: "Title_of_Publication_Work" },
    { label: "Name_of_Faculty_Authors", key: "Name_of_Faculty_Authors" },
    { label: "Year_of_Publication", key: "Year_of_Publication" },
    {
      label: "Research_Work_is_based_on_AERDES_allotted_or_other_than",
      key: "Research_Work_is_based_on_AERDES_allotted_or_other_than",
    },
    { label: "Indicate_Name_of_study", key: "Indicate_Name_of_study" },
    { label: "Journal_Name", key: "Journal_Name" },
    { label: "Issue_Month_Page_No", key: "Issue_Month_Page_No" },
  ];
  const journal_opt = [
    { value: "", label: "select" },
    {
      value: "Agro Economic Policy Briefs",
      label: "Agro Economic Policy Briefs",
    },
    { value: "Agro Economic Alerts", label: "Agro Economic Alerts" },
  ];
  const research_opt = [
    { value: "", label: "select" },
    { value: "Other than AER/DES", label: "Other than AER/DES" },
    { value: "AER/DES", label: "AER/DES" },
  ];
  const year_opt = [
    { value: "", label: "select" },
    { value: "2018", label: "2018" },
    { value: "2019", label: "2019" },
    { value: "2020", label: "2020" },
    { value: "2021", label: "2021" },
  ];
  const author = [
    { value: "", label: "select" },
    { value: "Sharma", label: "Sharma" },
    { value: "Vani", label: "Vani" },
    { value: "Rathi", label: "Rathi" },
    { value: "Chouhan", label: "Chouhan" },
    { value: "Thakur", label: "Thakur" },
    { value: "Bareliya", label: "Bareliya" },
    { value: "Niranjan", label: "Niranjan" },
    { value: "Patidar", label: "Patidar" },
  ];
  const data = [
    {
      S_No: "1",
      Title_of_Publication_Work:
        "Managing Soil Fertility including Carbon & micronutrients for Doubling Farmers’ Income",
      Name_of_Faculty_Authors: "Sharma H.O. and Vani Gaurav Kumar.",
      Year_of_Publication: "2018",
      Research_Work_is_based_on_AERDES_allotted_or_other_than:
        "Other than AER/DES",
      Indicate_Name_of_study: "-",
      Journal_Name: "Agro Economic Policy Briefs",
      Issue_Month_Page_No: "2(10) ; 4-6.",
      Pdf_Attached: PDF_1,
    },
    {
      S_No: "2",
      Title_of_Publication_Work: "Revival of Fallow land in Madhya Pradesh",
      Name_of_Faculty_Authors: "Sharma H.O. Rathi D and Chouhan R.S.",
      Year_of_Publication: "2018",
      Research_Work_is_based_on_AERDES_allotted_or_other_than: "AER/DES",
      Indicate_Name_of_study:
        "Problems and Prospects of Production of Small Millets and their Value Added Products in Madhya Pradesh",
      Journal_Name: "Agro Economic Alerts",
      Issue_Month_Page_No: "1(3) ; 5-7",
      Pdf_Attached: PDF_2,
    },
    {
      S_No: "3",
      Title_of_Publication_Work:
        "Managing Soil Fertility including Carbon & micronutrients for Doubling Farmers’ Income",
      Name_of_Faculty_Authors: "Sharma H.O. and Vani Gaurav Kumar",
      Year_of_Publication: "2018",
      Research_Work_is_based_on_AERDES_allotted_or_other_than: "AER/DES",
      Indicate_Name_of_study:
        "Dynamics and Revival of Fallow Lands in Madhya Pradesh.",
      Journal_Name: "Agro Economic Policy Briefs",
      Issue_Month_Page_No: "1(2) ; 7-9",
      Pdf_Attached: PDF_3,
    },
    {
      S_No: "4",
      Title_of_Publication_Work:
        "Market Imperfections in Major Agricultural Commodities of Rabi Season in Madhya Pradesh",
      Name_of_Faculty_Authors:
        "Sharma H.O. Rathi D; Thakur, S.S. and Bareliya, R.S.",
      Year_of_Publication: "2019",
      Research_Work_is_based_on_AERDES_allotted_or_other_than: "AER/DES",
      Indicate_Name_of_study:
        "Market Imperfection and Farm Profitability in Madhya Pradesh",
      Journal_Name: "Agro Economic Alerts",
      Issue_Month_Page_No: "14 (11): 5-7",
      Pdf_Attached: PDF_4,
    },
    {
      S_No: "5",
      Title_of_Publication_Work:
        "Challenges and Opportunities of the System of Rice Intensification in Madhya Pradesh.",
      Name_of_Faculty_Authors: "Sharma H.O. Rathi D and Niranjan, H.K.",
      Year_of_Publication: "2019",
      Research_Work_is_based_on_AERDES_allotted_or_other_than: "AER/DES",
      Indicate_Name_of_study:
        "Impact of Tejaswini rural Woman Empowerment programme on Empowerment of Rural Woman through System of Rice Intensification (SRI) in Mandla and Balaghat district of Madhya Pradesh",
      Journal_Name: "Agro Economic Alerts",
      Issue_Month_Page_No: "2 (10) ; 6-8",
      Pdf_Attached: PDF_5,
    },
    {
      S_No: "6",
      Title_of_Publication_Work:
        "Bridging the Yield Gap in Wheat in Bundelkhand Region of Madhya Pradesh",
      Name_of_Faculty_Authors: "Niranjan, H.K., Rathi D and Sharma H.O.",
      Year_of_Publication: "2019",
      Research_Work_is_based_on_AERDES_allotted_or_other_than: "AER/DES",
      Indicate_Name_of_study:
        "Strategies to Bridge Yield Gap of Major Crops in Bundelkhand Region of Madhya Pradesh",
      Journal_Name: "Agro Economic Policy Briefs",
      Issue_Month_Page_No: "13 (9): 8-11",
      Pdf_Attached: PDF_6,
    },
    {
      S_No: "7",
      Title_of_Publication_Work:
        "Nutritional Food Security at Household Level calls for Distribution Efficiency of Seed Minikits of Pulses",
      Name_of_Faculty_Authors: "Sharma H.O. Rathi D and Niranjan, H.K.",
      Year_of_Publication: "2020",
      Research_Work_is_based_on_AERDES_allotted_or_other_than: "AER/DES",
      Indicate_Name_of_study:
        "Relevance and Distribution Efficiency of Seed Mini-Kits of Pulses in Madhya Pradesh",
      Journal_Name: "Agro Economic Policy Briefs",
      Issue_Month_Page_No: "19 (9): 9-12",
      Pdf_Attached: PDF_7,
    },
    {
      S_No: "8",
      Title_of_Publication_Work:
        "Strategic to Bridging Yield Gap of Major Crops in Bundelkhand Region of Madhya Pradesh",
      Name_of_Faculty_Authors: "Sharma H.O. Rathi D and Patidar, P.K.",
      Year_of_Publication: "2020",
      Research_Work_is_based_on_AERDES_allotted_or_other_than: "AER/DES",
      Indicate_Name_of_study:
        "Strategies to Bridge Yield Gap of Major Crops in Bundelkhand Region of India",
      Journal_Name: "Agro Economic Policy Briefs",
      Issue_Month_Page_No: "20 (12): 2-5",
      Pdf_Attached: PDF_8,
    },
    {
      S_No: "9",
      Title_of_Publication_Work:
        "Promotion of Farmer Producer Organizations Related to Cattle Feeds",
      Name_of_Faculty_Authors: "Sharma H.O. Bareliya, R.S. and Thakur, S.S.",
      Year_of_Publication: "2021",
      Research_Work_is_based_on_AERDES_allotted_or_other_than:
        "Other than AER/DES",
      Indicate_Name_of_study:
        "mpact Evaluation of 15 Farmer Producers' Organization Projects in Madhya Pradesh",
      Journal_Name: "Agro Economic Alerts",
      Issue_Month_Page_No: "22 (5): 2-3",
      Pdf_Attached: PDF_9,
    },
    {
      S_No: "10",
      Title_of_Publication_Work:
        "Problems Perceived by Farmers in adoption of Micro-irrigation systems in Madhya Pradesh",
      Name_of_Faculty_Authors: "Sharma H.O. Niranjan, H.K., and Rathi D",
      Year_of_Publication: "2021",
      Research_Work_is_based_on_AERDES_allotted_or_other_than: "AER/DES",
      Indicate_Name_of_study:
        "Improving Water Use Efficiency in India's Agriculture: The Impact, Benefits and Challenges of Micro-Irrigation under the Pradhan Mantri Krishi Sinchayee Yojana - Per Drop More Crop (PMKSY -PDMC) in Madhya Pradesh",
      Journal_Name: "Agro Economic Policy Briefs",
      Issue_Month_Page_No: "23 (7): 4-6",
      Pdf_Attached: PDF_10,
    },
    {
      S_No: "11",
      Title_of_Publication_Work: "Promotion of Nutri-Cereals: Need of the Hour",
      Name_of_Faculty_Authors: "Sharma H.O. Thakur, S.S. and Bareliya, R.S.",
      Year_of_Publication: "2021",
      Research_Work_is_based_on_AERDES_allotted_or_other_than:
        "Other than AER/DES",
      Indicate_Name_of_study: "Secondary data",
      Journal_Name: "Agro Economic Policy Briefs",
      Issue_Month_Page_No: "24 (10): 4-6",
      Pdf_Attached: PDF_11,
    },
  ];
  const tabledata = data.filter((item) => {
    //     item.Journal_Name.includes(journal) &&
    //     item.Research_Work_is_based_on_AERDES_allotted_or_other_than.includes(
    //       research
    //     ) &&
    //     item.Year_of_Publication.includes(year) &&
    //     item.Title_of_Publication_Work.includes(search) &&
    //     item.Name_of_Faculty_Authors.includes(faculty)
    // );
    const searchText = search.toLowerCase();
    const titleMatch =
      item.Title_of_Publication_Work.toLowerCase().includes(searchText);
    const authorMatch =
      item.Name_of_Faculty_Authors.toLowerCase().includes(searchText);

    return (
      (titleMatch || authorMatch) &&
      item.Journal_Name.includes(journal) &&
      item.Research_Work_is_based_on_AERDES_allotted_or_other_than.includes(
        research
      ) &&
      item.Year_of_Publication.includes(year) &&
      item.Name_of_Faculty_Authors.includes(faculty)
    );
  });
  const Newdata =
    tabledata &&
    tabledata.map((item) => {
      return (
        <tr key={item.S_No}>
          <td>{item.S_No}</td>
          <td>{item.Title_of_Publication_Work}</td>
          <td>{item.Name_of_Faculty_Authors}</td>
          <td>{item.Year_of_Publication}</td>
          <td>
            {item.Research_Work_is_based_on_AERDES_allotted_or_other_than}
          </td>
          <td>{item.Indicate_Name_of_study}</td>
          <td>{item.Journal_Name}</td>
          <td>{item.Issue_Month_Page_No}</td>
          {item.Pdf_Attached !== "Nill" ? (
            <td>
              <a href={item.Pdf_Attached}>
                <img src={PDF_IMage} />
              </a>
            </td>
          ) : (
            <td>{item.Pdf_Attached}</td>
          )}
        </tr>
      );
    });
  return (
    <div>
      <section class="about section">
        <div class="container">
          <div class="row">
            <div class="about-content">
              <h3>Policy Brief/Alerts</h3>
              <section class="blog-area">
                <div class="container pt-20">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <div class="finder-journal">
                        <ul>
                          <li>
                            <Select
                              placeholder="Select Journal"
                              onChange={(e) => {
                                setjournal(e.value);
                                setfaculty("");
                                setyear("");
                              }}
                              options={journal_opt}
                            />
                          </li>
                          <li class="rating">
                            <Select
                              placeholder="Select Research "
                              onChange={(e) => {
                                setresearch(e.value);
                                setfaculty("");
                                setyear("");
                              }}
                              options={research_opt}
                            />
                          </li>
                          <li class="rating">
                            <Select
                              placeholder="Select Year"
                              onChange={(e) => {
                                setyear(e.value);
                                setjournal("");
                                setresearch("");
                              }}
                              options={year_opt}
                            />
                          </li>
                          <li>
                            <Select
                              placeholder="Select Authors "
                              onChange={(e) => {
                                setfaculty(e.value);
                                setjournal("");
                                setresearch("");
                              }}
                              options={author}
                            />
                          </li>
                          <li class="rating">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Search"
                              onChange={(e) => {
                                setsearch(e.target.value);
                              }}
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="rac_meeting">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <table
                      style={{
                        width: "100%",
                        marginLeft: "3px",
                        marginTop: "9px",
                      }}
                    >
                      <tr>
                        <th>S.No.</th>
                        <th style={{ width: "30%" }}>
                          Title of Publication Work
                        </th>
                        <th>Name of Faculty/Authors</th>
                        <th>Year of Publication</th>
                        <th>
                          Research Work is <br /> based on AERDES allotted{" "}
                          <br /> or other than
                        </th>
                        <th style={{ width: "30%" }}>Indicate Name of study</th>
                        <th>Journal Name</th>
                        <th>Issue (Month): Page No</th>
                        <th>Pdf Attached</th>
                      </tr>

                      {Newdata}
                    </table>
                    <button className="btn">
                      {" "}
                      <CSVLink
                        data={tabledata}
                        filename="new.csv"
                        headers={head}
                      >
                        download
                      </CSVLink>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Policy_Brief_Alert;
