/* eslint-disable */
import "../assets/style.css";
import React, { Component } from "react";
import { Link } from "react-router-dom";

import { useState } from "react";

function ReviewStructure() {
  const [year, setYear] = useState("");
  const [unit, setUnit] = useState("");
  // const [selectedOption, setSelectedOption] = useState(null);

  return (
    <div>
      <section class="about section">
        <div class="container">
          <div class="row">
            {/* <div class="col-lg-6 col-md-12 col-sm-12"> */}
            <div class="about-content">
              <h3>
                Technical <span>Committee</span>
              </h3>
            </div>
          </div>
        </div>
      </section>
      <div>
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div className="review_table">
                {/* <h3 className="abm_table">Advisory Body</h3> */}
                <p>
                  While taking up a subject for research a technical committee
                  on the concerned subject constituted as follows
                </p>

                <table>
                  <tr>
                    <td>1.</td>
                    <td>Director of the Agro- Economic Research Centre</td>
                    <td>Chairman</td>
                  </tr>
                  <tr>
                    <td>2.</td>
                    <td>
                      {" "}
                      Representative of the concerned Division of Ministry{" "}
                    </td>
                    <td>Member</td>
                  </tr>
                  <tr>
                    <td>3.</td>
                    <td>
                      Representative of the Directorate Of Economics and
                      Statistics
                    </td>
                    <td>Member</td>
                  </tr>
                  <tr>
                    <td>4.</td>
                    <td>
                      Representative of the concerned Department of State Govt.
                    </td>
                    <td>Member</td>
                  </tr>
                  <tr>
                    <td>5.</td>
                    <td> Two expert of on the subject</td>
                    <td>Chairman</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Start Clients --> */}
      {/* <Footer /> */}
    </div>
  );
}

export default ReviewStructure;
