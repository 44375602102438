/* eslint-disable */

import React from "react";
import Footer from "./footer";
import Header from "./header";
import Slider8 from "../assets/images/slider/8.jpg";
import "../assets/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faPaperclip,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import PDF_IMage from "../assets/images/pdf.png";
import PDF_1 from "../assets/PDF FILE/RAC/1 Mintues Final_3rd RAC Meeting_FINAL 27 Aug 2021.pdf";
import PDF_2 from "../assets/PDF FILE/RAC/2 Minutes of Directors Meeting held on 27th August 2021.pdf";
import PDF_3 from "../assets/PDF FILE/RAC/3 RAC Meeting (Workplan 2019-20).pdf";
import PDF_4 from "../assets/PDF FILE/RAC/4 CCCS meeting 2015.pdf";
import PDF_5 from "../assets/PDF FILE/RAC/5 CCOS Meeting 2013.pdf";
import { CSVLink } from "react-csv";
import { useId } from "react";

import { useState } from "react";
import Select from "react-select";

export default function Credit_Seminar() {
  const [search, setsearch] = useState("");
  const [year, setyear] = useState("");
  const [class_, setclass] = useState("");
  const Year_OPT = [
    { value: "", label: "Select" },
    { value: "22-09-2018", label: "22-09-2018" },
    { value: "24-09-2018", label: "24-09-2018" },
    { value: "26-09-2018", label: "26-09-2018" },
    { value: "27-09-2018", label: "27-09-2018" },
    { value: "28-09-2018", label: "28-09-2018" },
    { value: "29-09-2018", label: "29-09-2018" },
    { value: "03-09-2018", label: "03-09-2018" },
    { value: "04-10-2018", label: "04-10-2018" },
    { value: "30-10-2022", label: "30-10-2022" },
    { value: "31-10-2018", label: "31-10-2018" },
    { value: "05-12-2018", label: "05-12-2018" },
    { value: "05-02-2019", label: "05-02-2019" },
    { value: "08-02-2019", label: "08-02-2019" },
    { value: "04-10-2019", label: "04-10-2019" },
    { value: "05-10-2019", label: "05-10-2019" },
    { value: "18-10-2019", label: "18-10-2019" },
    { value: "19-10-2019", label: "19-10-2019" },
    { value: "22-10-2019", label: "22-10-2019" },
    { value: "24-10-2019", label: "24-10-2019" },
    { value: "04-11-2019", label: "04-11-2019" },
    { value: "15-11-2019", label: "15-11-2019" },
    { value: "18-01-2021", label: "18-01-2021" },
    { value: "20-11-2019", label: "20-11-2019" },
    { value: "21-11-2019", label: "21-11-2019" },
    { value: "23-11-2019", label: "23-11-2019" },
    { value: "25-11-2019", label: "25-11-2019" },
    { value: "26-06-2020", label: "26-06-2020" },
    { value: "28-12-2020", label: "28-12-2020" },
    { value: "02-01-2021", label: "02-01-2021" },
    { value: "25-01-2021", label: "25-01-2021" },
    { value: "05-02-2021", label: "05-02-2021" },
    { value: "16-02-2021", label: "16-02-2021" },
    { value: "08-03-2021", label: "08-03-2021" },
    { value: "09-03-2021", label: "09-03-2021" },
    { value: "12-03-2021", label: "12-03-2021" },
    { value: "17-03-2021", label: "17-03-2021" },
    { value: "18-03-2021", label: "18-03-2021" },
    { value: "18-06-2021", label: "18-06-2021" },
    { value: "23-11-2021", label: "23-11-2021" },
    { value: "09-12-2021", label: "09-12-2021" },
    { value: "17-12-2021", label: "17-12-2021" },
    { value: "22-12-2021", label: "22-12-2021" },
    { value: "04-01-2022", label: "04-01-2022" },
    { value: "05-01-2022", label: "05-01-2022" },
    { value: "06-01-2022", label: "06-01-2022" },
    { value: "07-04-2022", label: "07-04-2022" },
    { value: "10-01-2022", label: "10-01-2022" },
    { value: "11-01-2022", label: "11-01-2022" },
    { value: "12-01-2022", label: "12-01-2022" },
    { value: "13-01-2022", label: "13-01-2022" },
    { value: "14-01-2022", label: "14-01-2022" },
    { value: "19-01-2022", label: "19-01-2022" },
    { value: "06-06-2022", label: "06-06-2022" },
    { value: "02-12-2022", label: "02-12-2022" },
    { value: "05-12-2022", label: "05-12-2022" },
    { value: "07-12-2022", label: "07-12-2022" },
    { value: "12-12-2022", label: "12-12-2022" },
    { value: "14-12-2022", label: "14-12-2022" },
    { value: "16-12-2022", label: "16-12-2022" },
    { value: "20-12-2022", label: "20-12-2022" },
    { value: "22-12-2022", label: "22-12-2022" },
    { value: "23-12-2022", label: "23-12-2022" },
    { value: "26-12-2022", label: "26-12-2022" },
    { value: "27-12-2022", label: "27-12-2022" },
    { value: "28-12-2022", label: "28-12-2022" },
    { value: "29-12-2022", label: "29-12-2022" },
    { value: "30-12-2022", label: "30-12-2022" },
    { value: "10-01-2023", label: "10-01-2023" },
    { value: "24-02-2023", label: "24-02-2023" },
  ];
  const class_opt = [
    { value: "", label: "Select" },
    { value: "M.Sc.", label: "M.Sc." },
    { value: "PhD.", label: "PhD." },
    { value: "M.B.A", label: "M.B.A" },
  ];

  const Data = [
    {
      S_No: 1,
      Name_of_Student: "Mr. Rounak Jain",
      Topic_of_Credit_Seminar:
        "Central sector schemes for Agril. & Rural Development",
      Filter_Class: "M.Sc.",
      FilterYear: "22-09-2018",
    },
    {
      S_No: 2,
      Name_of_Student: "Mr.Abdul Malik",
      Topic_of_Credit_Seminar: "Impact- Export sector of Rice Production",
      Filter_Class: "M.Sc.",
      FilterYear: "22-09-2018",
    },
    {
      S_No: 3,
      Name_of_Student: "Mr. Bakht Amir Zordon",
      Topic_of_Credit_Seminar: "Global scenario of Maize Production",
      Filter_Class: "M.Sc.",
      FilterYear: "22-09-2018",
    },
    {
      S_No: 4,
      Name_of_Student: "Miss Bharti Pendram",
      Topic_of_Credit_Seminar: "Agriculture trade: Scope and Import for India",
      Filter_Class: "M.Sc.",
      FilterYear: "24-09-2018",
    },
    {
      S_No: 5,
      Name_of_Student: "Mr. Farhad Hamdard",
      Topic_of_Credit_Seminar: "Performance of oilseeds in India",
      Filter_Class: "M.Sc.",
      FilterYear: "24-09-2018",
    },
    {
      S_No: 6,
      Name_of_Student: "Miss Geetu P. George",
      Topic_of_Credit_Seminar:
        "Crop diversification & its measuring techniques",
      Filter_Class: "M.Sc.",
      FilterYear: "24-09-2018",
    },
    {
      S_No: 7,
      Name_of_Student: "Mr. Nazar Taraki",
      Topic_of_Credit_Seminar:
        "Export oriented commercial Agriculture: Scope and Importance for India",
      Filter_Class: "M.Sc.",
      FilterYear: "24-09-2018",
    },
    {
      S_No: 8,
      Name_of_Student: "Miss Prabhas Kiran",
      Topic_of_Credit_Seminar: "JEEVIKA- Rural livelihood Programme",
      Filter_Class: "M.Sc.",
      FilterYear: "26-09-2018",
    },
    {
      S_No: 9,
      Name_of_Student: "Mr. Shantilal Patel",
      Topic_of_Credit_Seminar:
        "Impact of urbanization on Agriculture Sector & allieed sector",
      Filter_Class: "M.Sc.",
      FilterYear: "26-09-2018",
    },
    {
      S_No: 10,
      Name_of_Student: "Miss Shruti Mishra",
      Topic_of_Credit_Seminar: "e-Shakti",
      Filter_Class: "M.Sc.",
      FilterYear: "27-09-2018",
    },
    {
      S_No: 11,
      Name_of_Student: "Miss Sneha Pandey",
      Topic_of_Credit_Seminar: "Blue Revolution in India",
      Filter_Class: "M.Sc.",
      FilterYear: "27-09-2018",
    },
    {
      S_No: 12,
      Name_of_Student: "Miss Varsha Kirar",
      Topic_of_Credit_Seminar: "Minimum Support Price",
      Filter_Class: "M.Sc.",
      FilterYear: "28-09-2018",
    },
    {
      S_No: 13,
      Name_of_Student: "Mr. Vikas",
      Topic_of_Credit_Seminar: "Pradhamantri Krishi Sinchai Yojana",
      Filter_Class: "M.Sc.",
      FilterYear: "28-09-2018",
    },
    {
      S_No: 14,
      Name_of_Student: "Mr. Vinit Kumar Jaiswal",
      Topic_of_Credit_Seminar: "Silk Production Scenario in Madhya Pradesh",
      Filter_Class: "M.Sc.",
      FilterYear: "28-09-2018",
    },
    {
      S_No: 15,
      Name_of_Student: "Mr. Dharmendra Parmar",
      Topic_of_Credit_Seminar:
        "Global Scenario of Soybean Production and its value added product",
      Filter_Class: "M.Sc.",
      FilterYear: "29-09-2018",
    },
    {
      S_No: 16,
      Name_of_Student: "Mr. Sachin Yadav",
      Topic_of_Credit_Seminar: "Paramparagat Krishi Vikas Yojana",
      Filter_Class: "M.Sc.",
      FilterYear: "29-09-2018",
    },
    {
      S_No: 17,
      Name_of_Student: "Mr. Yadalpuram Ramana  Kumari",
      Topic_of_Credit_Seminar:
        "Production and Export Scenario of chilli in India",
      Filter_Class: "M.Sc.",
      FilterYear: "29-09-2018",
    },
    {
      S_No: 18,
      Name_of_Student: "Mrs. Manne Sandhya",
      Topic_of_Credit_Seminar: "Advances in food Processing",
      Filter_Class: "M.Sc.",
      FilterYear: "29-09-2018",
    },
    {
      S_No: 19,
      Name_of_Student: "Mr. Kuver Singh",
      Topic_of_Credit_Seminar: "Non-performing assets of Indian Banks",
      Filter_Class: "M.Sc.",
      FilterYear: "29-09-2018",
    },
    {
      S_No: 20,
      Name_of_Student: "Mr. Nimesh Patidar",
      Topic_of_Credit_Seminar: "Food Laws in India",
      Filter_Class: "M.Sc.",
      FilterYear: "03-09-2018",
    },
    {
      S_No: 21,
      Name_of_Student: "Mr. Sunil Birla",
      Topic_of_Credit_Seminar: "F.P.O.(Farmer Producer's Organization)",
      Filter_Class: "M.Sc.",
      FilterYear: "03-09-2018",
    },
    {
      S_No: 22,
      Name_of_Student: "Mr. Subham Soni",
      Topic_of_Credit_Seminar: "e-NAM",
      Filter_Class: "M.Sc.",
      FilterYear: "03-09-2018",
    },
    {
      S_No: 23,
      Name_of_Student: "Miss Shivani Gupta",
      Topic_of_Credit_Seminar:
        "Role of information & communication technology in Agriculture",
      Filter_Class: "M.Sc.",
      FilterYear: "04-10-2018",
    },
    {
      S_No: 24,
      Name_of_Student: "Mr. Akash Umale",
      Topic_of_Credit_Seminar: "Mega food park Scheme",
      Filter_Class: "M.Sc.",
      FilterYear: "04-10-2018",
    },
    {
      S_No: 25,
      Name_of_Student: "Mr. Prasanna Kolar",
      Topic_of_Credit_Seminar:
        "Impact of Rupee-dollar fluctuations on Indian Economy",
      Filter_Class: "PhD.",
      FilterYear: "30-10-2022",
    },
    {
      S_No: 26,
      Name_of_Student: "Miss Ankita Sahu",
      Topic_of_Credit_Seminar:
        "Causes & consequences of Great depression in National Economy",
      Filter_Class: "PhD.",
      FilterYear: "31-10-2018",
    },
    {
      S_No: 27,
      Name_of_Student: "Mr. Amarteet Gupta",
      Topic_of_Credit_Seminar: "SFAC(Small Farmers Agribusiness Consortium)",
      Filter_Class: "M.Sc.",
      FilterYear: "04-10-2018",
    },
    {
      S_No: 28,
      Name_of_Student: "Miss. Sarita Sharma",
      Topic_of_Credit_Seminar: "JEVONS PARADOX",
      Filter_Class: "PhD.",
      FilterYear: "05-12-2018",
    },
    {
      S_No: 29,
      Name_of_Student: "Mr. Shah Parth",
      Topic_of_Credit_Seminar:
        "Principal Componenets Analysis- Baiscs and its appllications",
      Filter_Class: "PhD.",
      FilterYear: "05-12-2018",
    },
    {
      S_No: 30,
      Name_of_Student: "Mr. Yogesh Tiwari",
      Topic_of_Credit_Seminar: "Dairy Developent in Madhya Pradesg",
      Filter_Class: "PhD.",
      FilterYear: "05-02-2019",
    },
    {
      S_No: 31,
      Name_of_Student: "Miss Neha Dwivedi",
      Topic_of_Credit_Seminar: "Dumping & Anti dumping in the era of WTO",
      Filter_Class: "PhD.",
      FilterYear: "08-02-2019",
    },
    {
      S_No: 32,
      Name_of_Student: "Miss Payal Sewakram Rode",
      Topic_of_Credit_Seminar: "Total factor Productivity",
      Filter_Class: "PhD.",
      FilterYear: "08-02-2019",
    },
    {
      S_No: 33,
      Name_of_Student: "Miss Richa Bindra",
      Topic_of_Credit_Seminar:
        "Farm mechanization & Potential role of Custom hiring centre",
      Filter_Class: "M.Sc.",
      FilterYear: "04-10-2019",
    },
    {
      S_No: 34,
      Name_of_Student: "Miss Asawari Joshi",
      Topic_of_Credit_Seminar: "Scenario of Pigeon Pea Production",
      Filter_Class: "M.Sc.",
      FilterYear: "04-10-2019",
    },
    {
      S_No: 35,
      Name_of_Student: "Mr. Arun Kumar",
      Topic_of_Credit_Seminar: "Scenario of Pigeon Pea Production",
      Filter_Class: "M.Sc.",
      FilterYear: "04-10-2019",
    },
    {
      S_No: 36,
      Name_of_Student: "Mr. Vishnu Kumar",
      Topic_of_Credit_Seminar: "Online food ordering System",
      Filter_Class: "M.Sc.",
      FilterYear: "05-10-2019",
    },
    {
      S_No: 37,
      Name_of_Student: "Mr. B. Ashok Kumar",
      Topic_of_Credit_Seminar: "Digitalization in retail sector",
      Filter_Class: "M.Sc.",
      FilterYear: "05-10-2019",
    },
    {
      S_No: 38,
      Name_of_Student: "Miss Ekta Thakre",
      Topic_of_Credit_Seminar: "AI in Agriulture",
      Filter_Class: "M.Sc.",
      FilterYear: "05-10-2019",
    },
    {
      S_No: 39,
      Name_of_Student: "Mr. Amit Swami",
      Topic_of_Credit_Seminar: "Micro small and medium enterprises development",
      Filter_Class: "M.Sc.",
      FilterYear: "05-10-2019",
    },
    {
      S_No: 40,
      Name_of_Student: "Miss Neha Dwivedi",
      Topic_of_Credit_Seminar:
        "Digital Innovation in Agriculture: Agriculture 4.0",
      Filter_Class: "PhD.",
      FilterYear: "18-10-2019",
    },
    {
      S_No: 41,
      Name_of_Student: "Ajeet Yadav",
      Topic_of_Credit_Seminar: "FEI- Rains Model of Economic Growth",
      Filter_Class: "M.Sc.",
      FilterYear: "18-10-2019",
    },
    {
      S_No: 42,
      Name_of_Student: "Subham Birl",
      Topic_of_Credit_Seminar: "Pradhamantri Samman Nidhi Yojana",
      Filter_Class: "M.Sc.",
      FilterYear: "18-10-2019",
    },
    {
      S_No: 43,
      Name_of_Student: "Aditya Singh",
      Topic_of_Credit_Seminar: "Union Budget 2019-20 on Agricuture",
      Filter_Class: "M.Sc.",
      FilterYear: "19-10-2019",
    },
    {
      S_No: 44,
      Name_of_Student: "Sushmita K.S.",
      Topic_of_Credit_Seminar: "Universal basic Income",
      Filter_Class: "M.Sc.",
      FilterYear: "19-10-2019",
    },
    {
      S_No: 45,
      Name_of_Student: "Poonam Chaturvedi",
      Topic_of_Credit_Seminar:
        "Significance of carbon and water footprint in Agriculture",
      Filter_Class: "M.Sc.",
      FilterYear: "19-10-2019",
    },
    {
      S_No: 46,
      Name_of_Student: "P. Lakhna",
      Topic_of_Credit_Seminar:
        "Emerging trends in Green supply chain management and block chain technology",
      Filter_Class: "M.Sc.",
      FilterYear: "22-10-2019",
    },
    {
      S_No: 47,
      Name_of_Student: "Ramakrishna S.",
      Topic_of_Credit_Seminar: "Zero Budget natural farming",
      Filter_Class: "M.Sc.",
      FilterYear: "22-10-2019",
    },
    {
      S_No: 48,
      Name_of_Student: "Mr. Raydu Phanindra Kumar",
      Topic_of_Credit_Seminar: "Big data in Agriuclture",
      Filter_Class: "M.Sc.",
      FilterYear: "22-10-2019",
    },
    {
      S_No: 49,
      Name_of_Student: "Mr. Swadesh Kurmi",
      Topic_of_Credit_Seminar:
        "Problem & Pospect of capsicum Production in Jabalpur for domestic & Internation Market",
      Filter_Class: "M.Sc.",
      FilterYear: "24-10-2019",
    },
    {
      S_No: 50,
      Name_of_Student: "Miss Tiwnkle Raghuwanshi",
      Topic_of_Credit_Seminar: "Customar relationship management",
      Filter_Class: "M.Sc.",
      FilterYear: "24-10-2019",
    },
    {
      S_No: 51,
      Name_of_Student: "Miss Anjali Bele",
      Topic_of_Credit_Seminar: "Agricultural Startup",
      Filter_Class: "M.Sc.",
      FilterYear: "24-10-2019",
    },
    {
      S_No: 52,
      Name_of_Student: "Mr. Ujjawala Bammindi",
      Topic_of_Credit_Seminar: "Innovative mechanisams to finance agriculture",
      Filter_Class: "M.Sc.",
      FilterYear: "04-11-2019",
    },
    {
      S_No: 53,
      Name_of_Student: "Mrs. Prachi Baghel",
      Topic_of_Credit_Seminar: "Agricultural export and economic growth",
      Filter_Class: "M.Sc.",
      FilterYear: "04-11-2019",
    },
    {
      S_No: 54,
      Name_of_Student: "Mr. Poiba Ravi Prasad",
      Topic_of_Credit_Seminar:
        "Integrated Farming System: A solution for doubling farmers income",
      Filter_Class: "M.Sc.",
      FilterYear: "04-11-2019",
    },
    {
      S_No: 55,
      Name_of_Student: "Mr. Krishan Kant Pal",
      Topic_of_Credit_Seminar: "Doubling Farmer income by 2022",
      Filter_Class: "M.Sc.",
      FilterYear: "15-11-2019",
    },
    {
      S_No: 56,
      Name_of_Student: "Mr. Akanksha Singh",
      Topic_of_Credit_Seminar: "Food processing industry",
      Filter_Class: "M.Sc.",
      FilterYear: "15-11-2019",
    },
    {
      S_No: 57,
      Name_of_Student: "Mr. Nirmal Prajapat",
      Topic_of_Credit_Seminar: "Seed, Seed business & Challenges",
      Filter_Class: "M.Sc.",
      FilterYear: "15-11-2019",
    },
    {
      S_No: 58,
      Name_of_Student: "Miss  Shraddha Nayak",
      Topic_of_Credit_Seminar: "Migration & its consequences",
      Filter_Class: "M.Sc.",
      FilterYear: "18-01-2021",
    },
    {
      S_No: 59,
      Name_of_Student: "Mr. Jhalendra Chandrakar",
      Topic_of_Credit_Seminar: "Farmers Sucide in India",
      Filter_Class: "M.Sc.",
      FilterYear: "20-11-2019",
    },
    {
      S_No: 60,
      Name_of_Student: "Mr. Bharti Yadav",
      Topic_of_Credit_Seminar:
        "Exportable production and value addition in horticultural crops",
      Filter_Class: "M.Sc.",
      FilterYear: "20-11-2019",
    },
    {
      S_No: 61,
      Name_of_Student: "Mr. Himsikha Yadav",
      Topic_of_Credit_Seminar: "Pradhan Mantri Jandhan Yojana",
      Filter_Class: "M.Sc.",
      FilterYear: "20-11-2019",
    },
    {
      S_No: 62,
      Name_of_Student: "Miss Sushma Chandra",
      Topic_of_Credit_Seminar:
        "Pradhan Mantri Antyoda Aay Sankhan Abiyan (PM-AASHA)",
      Filter_Class: "M.Sc.",
      FilterYear: "21-11-2019",
    },
    {
      S_No: 63,
      Name_of_Student: "Mr.Nihal Gurjar",
      Topic_of_Credit_Seminar: "Farmers first programme",
      Filter_Class: "M.Sc.",
      FilterYear: "21-11-2019",
    },
    {
      S_No: 64,
      Name_of_Student: "Mr. Manoj Devanda",
      Topic_of_Credit_Seminar:
        "Scope of vertical farming in changing Agriculture",
      Filter_Class: "M.Sc.",
      FilterYear: "21-11-2019",
    },
    {
      S_No: 65,
      Name_of_Student: "Miss Pooja Verma",
      Topic_of_Credit_Seminar: "Data envelopment analysis",
      Filter_Class: "M.Sc.",
      FilterYear: "23-11-2019",
    },
    {
      S_No: 66,
      Name_of_Student: "Miss Ankita Rajput",
      Topic_of_Credit_Seminar: "Total factor Productivity",
      Filter_Class: "M.Sc.",
      FilterYear: "23-11-2019",
    },
    {
      S_No: 67,
      Name_of_Student: "Mr.Pulli Nageshwar Rao",
      Topic_of_Credit_Seminar: "Regional Comprehensive economic partership",
      Filter_Class: "M.Sc.",
      FilterYear: "23-11-2019",
    },
    {
      S_No: 68,
      Name_of_Student: "Miss. Swadhina Khuntiya",
      Topic_of_Credit_Seminar:
        "Effeciency of minimum support Price V/s Input subsidy and direct income support",
      Filter_Class: "M.Sc.",
      FilterYear: "25-11-2019",
    },
    {
      S_No: 69,
      Name_of_Student: "Mr. Deepak Kumar",
      Topic_of_Credit_Seminar: "Aspirational district programme",
      Filter_Class: "M.Sc.",
      FilterYear: "25-11-2019",
    },
    {
      S_No: 70,
      Name_of_Student: "Mr. Shivam Tiwari",
      Topic_of_Credit_Seminar:
        "Problems & prospects of internatonal trade in India with emphasis to agriculture sector",
      Filter_Class: "M.Sc.",
      FilterYear: "25-11-2019",
    },
    {
      S_No: 71,
      Name_of_Student: "Miss Harsh Laxkaw",
      Topic_of_Credit_Seminar: "Role of MSME's in Economic Growth of India",
      Filter_Class: "M.Sc.",
      FilterYear: "26-06-2020",
    },
    {
      S_No: 72,
      Name_of_Student: "Miss Sanskala Patel",
      Topic_of_Credit_Seminar:
        "Adoption of water Management technologies in agriculture",
      Filter_Class: "M.Sc.",
      FilterYear: "26-06-2020",
    },
    {
      S_No: 73,
      Name_of_Student: "Miss Payal Sewakram Rodhe",
      Topic_of_Credit_Seminar:
        "Crop based susitability of cropping system & sustanability assessement",
      Filter_Class: "M.Sc.",
      FilterYear: "26-06-2020",
    },
    {
      S_No: 74,
      Name_of_Student: "Miss Shruti Mishra",
      Topic_of_Credit_Seminar: "Climate Change afraid to food security",
      Filter_Class: "PhD.",
      FilterYear: "26-06-2020",
    },
    {
      S_No: 75,
      Name_of_Student: "Miss Sambhu Sindhuja",
      Topic_of_Credit_Seminar: "Farm laws/Acts-2020",
      Filter_Class: "M.Sc.",
      FilterYear: "28-12-2020",
    },
    {
      S_No: 76,
      Name_of_Student: "Miss Preethi B.V.",
      Topic_of_Credit_Seminar:
        "Drip irrigation in Commercial Crops: Economics and government scheme",
      Filter_Class: "M.Sc.",
      FilterYear: "02-01-2021",
    },
    {
      S_No: 77,
      Name_of_Student: "Miss  Reena Paikra",
      Topic_of_Credit_Seminar: "Rural tourism An asset to non farm sector",
      Filter_Class: "M.Sc.",
      FilterYear: "25-01-2021",
    },
    {
      S_No: 78,
      Name_of_Student: "Miss Sushma Yadav",
      Topic_of_Credit_Seminar:
        "Role of Primary Agriculture Cooperative credit Society",
      Filter_Class: "M.Sc.",
      FilterYear: "05-02-2021",
    },
    {
      S_No: 79,
      Name_of_Student: "Miss Shivani Ahirwar",
      Topic_of_Credit_Seminar: "Micro small and medium enterprises development",
      Filter_Class: "M.Sc.",
      FilterYear: "05-02-2021",
    },
    {
      S_No: 80,
      Name_of_Student: "Mr. Nagesh Vaishnav",
      Topic_of_Credit_Seminar: "Economic impact of Covid-19 in India",
      Filter_Class: "M.Sc.",
      FilterYear: "05-02-2021",
    },
    {
      S_No: 81,
      Name_of_Student: "Miss Shivani Chouksey",
      Topic_of_Credit_Seminar: "Food safety and Authority of India",
      Filter_Class: "M.Sc.",
      FilterYear: "16-02-2021",
    },
    {
      S_No: 82,
      Name_of_Student: "Mr. Vijay Kumar Bochalya",
      Topic_of_Credit_Seminar: "Prospectus of sericulture Industry in India",
      Filter_Class: "M.Sc.",
      FilterYear: "16-02-2021",
    },
    {
      S_No: 83,
      Name_of_Student: "Mr. Khagesh Gupta",
      Topic_of_Credit_Seminar:
        "Importance of contract Farming in 'Farmings (empowerment & protection ) Agreement on Price assurance and Farm Service Act 2020",
      Filter_Class: "M.Sc.",
      FilterYear: "08-03-2021",
    },
    {
      S_No: 84,
      Name_of_Student: "Mr. Pratik Lal",
      Topic_of_Credit_Seminar: "Socio-economic Impact on organic farming",
      Filter_Class: "M.Sc.",
      FilterYear: "09-03-2021",
    },
    {
      S_No: 85,
      Name_of_Student: "Mr. Mukul Sharma",
      Topic_of_Credit_Seminar: "Export Performance of cereal crop",
      Filter_Class: "M.Sc.",
      FilterYear: "12-03-2021",
    },
    {
      S_No: 86,
      Name_of_Student: "Mr. Rajesh Kumar",
      Topic_of_Credit_Seminar: "Digital Platform During COVID-19",
      Filter_Class: "M.Sc.",
      FilterYear: "12-03-2021",
    },
    {
      S_No: 87,
      Name_of_Student: "Mr. Kamlesh Kumar Choudhary",
      Topic_of_Credit_Seminar: "Farmers Producer Organization",
      Filter_Class: "M.Sc.",
      FilterYear: "12-03-2021",
    },
    {
      S_No: 88,
      Name_of_Student: "Miss Poonam Chaturvedi",
      Topic_of_Credit_Seminar: "Replace theory in Agriculture",
      Filter_Class: "PhD.",
      FilterYear: "17-03-2021",
    },
    {
      S_No: 89,
      Name_of_Student: "Miss Seema Arya",
      Topic_of_Credit_Seminar: "Impact of COVID-19 on Indian Economy",
      Filter_Class: "PhD.",
      FilterYear: "17-03-2021",
    },
    {
      S_No: 90,
      Name_of_Student: "Mr. G. K. Vani",
      Topic_of_Credit_Seminar: "Economics of Scope",
      Filter_Class: "PhD.",
      FilterYear: "17-03-2021",
    },
    {
      S_No: 91,
      Name_of_Student: "Mr. Aditya Singh",
      Topic_of_Credit_Seminar: "Randomized control trial",
      Filter_Class: "PhD.",
      FilterYear: "18-03-2021",
    },
    {
      S_No: 92,
      Name_of_Student: "Miss Harsh Laxkaw",
      Topic_of_Credit_Seminar:
        "Marketing stretegies for horticultural crops in Madhya Pradesh",
      Filter_Class: "PhD.",
      FilterYear: "18-06-2021",
    },
    {
      S_No: 93,
      Name_of_Student: "Mr. Shruti Mishra",
      Topic_of_Credit_Seminar: "Varability",
      Filter_Class: "PhD.",
      FilterYear: "18-06-2021",
    },
    {
      S_No: 94,
      Name_of_Student: "Mr. Vinod Kumar Sharma",
      Topic_of_Credit_Seminar: "Vertical farming in India: SWOT analysis",
      Filter_Class: "M.Sc.",
      FilterYear: "23-11-2021",
    },
    {
      S_No: 95,
      Name_of_Student: "Mr. Prakash Kabiraj",
      Topic_of_Credit_Seminar: "Zero Budget natural farming",
      Filter_Class: "M.Sc.",
      FilterYear: "23-11-2021",
    },
    {
      S_No: 96,
      Name_of_Student: "Miss Turagni Nishad",
      Topic_of_Credit_Seminar: "Contract Farming",
      Filter_Class: "M.Sc.",
      FilterYear: "09-12-2021",
    },
    {
      S_No: 97,
      Name_of_Student: "Mr.  Siddhant Jaiswal",
      Topic_of_Credit_Seminar: "Micro small and medium enterprises (MSME's)",
      Filter_Class: "M.Sc.",
      FilterYear: "09-12-2021",
    },
    {
      S_No: 98,
      Name_of_Student: "Miss Yogita Gupta",
      Topic_of_Credit_Seminar: "Green Economy in India",
      Filter_Class: "M.Sc.",
      FilterYear: "17-12-2021",
    },
    {
      S_No: 99,
      Name_of_Student: "Mr. Mritunjay Das",
      Topic_of_Credit_Seminar:
        "Remote Sensing application and its role in Agriculture",
      Filter_Class: "M.Sc.",
      FilterYear: "22-12-2021",
    },
    {
      S_No: 100,
      Name_of_Student: "Miss Diksha Kumari",
      Topic_of_Credit_Seminar: "Recession in Indian economy",
      Filter_Class: "M.Sc.",
      FilterYear: "22-12-2021",
    },
    {
      S_No: 101,
      Name_of_Student: "Mr. Ravi Dhakad",
      Topic_of_Credit_Seminar:
        "Present status and challenges of Agro-based Industries in India",
      Filter_Class: "M.B.A",
      FilterYear: "04-01-2022",
    },
    {
      S_No: 102,
      Name_of_Student: "Mr.Raju Mandal",
      Topic_of_Credit_Seminar: "Import Export of",
      Filter_Class: "M.B.A",
      FilterYear: "04-01-2022",
    },
    {
      S_No: 103,
      Name_of_Student: "Mr. Puspraj Singh",
      Topic_of_Credit_Seminar: "Economic Contributions of tomato in India",
      Filter_Class: "M.Sc.",
      FilterYear: "05-01-2022",
    },
    {
      S_No: 104,
      Name_of_Student: "Mr. Rakesh Kumar Yadav",
      Topic_of_Credit_Seminar: "Scenerio of cluster bean in Indian Economy",
      Filter_Class: "M.Sc.",
      FilterYear: "05-01-2022",
    },
    {
      S_No: 105,
      Name_of_Student: "Mr. Tarachand Rawal",
      Topic_of_Credit_Seminar:
        "Impact of Inflation on Agriculture Sector in India",
      Filter_Class: "M.Sc.",
      FilterYear: "06-01-2022",
    },
    {
      S_No: 106,
      Name_of_Student: "Mr. Rahul Kaushik",
      Topic_of_Credit_Seminar: "Godhan Nayay Yojana In Chhattisgarh",
      Filter_Class: "M.Sc.",
      FilterYear: "06-01-2022",
    },
    {
      S_No: 107,
      Name_of_Student: "Mr. Harshit Gowda S.",
      Topic_of_Credit_Seminar: "Export Potential of Onion from India",
      Filter_Class: "M.Sc.",
      FilterYear: "06-01-2022",
    },
    {
      S_No: 108,
      Name_of_Student: "Mr. Rahul Raj",
      Topic_of_Credit_Seminar: "E-marketing in Agri-business Management",
      Filter_Class: "M.B.A",
      FilterYear: "07-04-2022",
    },
    {
      S_No: 109,
      Name_of_Student: "Mr.Sagar S. Rajput",
      Topic_of_Credit_Seminar:
        "Status of Agro Food Processing Industry in India",
      Filter_Class: "M.B.A",
      FilterYear: "07-04-2022",
    },
    {
      S_No: 110,
      Name_of_Student: "Mr. Abhishek Kisan",
      Topic_of_Credit_Seminar:
        "Production and Export potential of Turmic in India",
      Filter_Class: "M.B.A",
      FilterYear: "07-04-2022",
    },
    {
      S_No: 111,
      Name_of_Student: "Mr. Imaran Khan",
      Topic_of_Credit_Seminar: "Agriclinic and Agribusiness center Scheme",
      Filter_Class: "M.B.A",
      FilterYear: "10-01-2022",
    },
    {
      S_No: 112,
      Name_of_Student: "Mr.Seemant Patel",
      Topic_of_Credit_Seminar: "Supply Chain Management",
      Filter_Class: "M.B.A",
      FilterYear: "10-01-2022",
    },
    {
      S_No: 113,
      Name_of_Student: "Mr. Kurwa Nagaraju",
      Topic_of_Credit_Seminar:
        "Agriculture Labour Diversification from farm section to Non-farm section",
      Filter_Class: "M.Sc.",
      FilterYear: "10-01-2022",
    },
    {
      S_No: 114,
      Name_of_Student: "Mr. Avdhesh Patel",
      Topic_of_Credit_Seminar: "Economic contribution of Banana in India",
      Filter_Class: "M.Sc.",
      FilterYear: "11-01-2022",
    },
    {
      S_No: 115,
      Name_of_Student: "Mr. Subham Maheshwari",
      Topic_of_Credit_Seminar: "E-Business",
      Filter_Class: "M.B.A",
      FilterYear: "11-01-2022",
    },
    {
      S_No: 116,
      Name_of_Student: "Mr. Lokesh Bhayariya",
      Topic_of_Credit_Seminar: "Supply Chain Management of Chilli",
      Filter_Class: "M.B.A",
      FilterYear: "11-01-2022",
    },
    {
      S_No: 117,
      Name_of_Student: "Miss Munmun Paul",
      Topic_of_Credit_Seminar: "Production and Export Potential of Mango",
      Filter_Class: "M.B.A",
      FilterYear: "11-01-2022",
    },
    {
      S_No: 118,
      Name_of_Student: "Miss Sonum Pandey",
      Topic_of_Credit_Seminar:
        "Block chain technology: Next Generation revolution in Agriculture",
      Filter_Class: "M.Sc.",
      FilterYear: "12-01-2022",
    },
    {
      S_No: 119,
      Name_of_Student: "Mr. Ankit Tiwari",
      Topic_of_Credit_Seminar: "Kisan Rail-Boom for Indian Farmer",
      Filter_Class: "M.B.A",
      FilterYear: "12-01-2022",
    },
    {
      S_No: 120,
      Name_of_Student: "Miss Asha Patel",
      Topic_of_Credit_Seminar: "Export of Spices from India",
      Filter_Class: "M.Sc.",
      FilterYear: "12-01-2022",
    },
    {
      S_No: 121,
      Name_of_Student: "Mr. Satish Chandravanshi",
      Topic_of_Credit_Seminar:
        "Farmer knowledge of MSP in realtion to different Crops",
      Filter_Class: "M.Sc.",
      FilterYear: "12-01-2022",
    },
    {
      S_No: 122,
      Name_of_Student: "Miss Jeeru Naga Sai Tejawani",
      Topic_of_Credit_Seminar:
        "Marketing Information syhstem and marketing research in Agriculture",
      Filter_Class: "M.B.A.",
      FilterYear: "13-01-2022",
    },
    {
      S_No: 123,
      Name_of_Student: "Miss Ananta Baghela",
      Topic_of_Credit_Seminar:
        "Revoluationing Agriculture through smart farming",
      Filter_Class: "M.B.A.",
      FilterYear: "13-01-2022",
    },
    {
      S_No: 124,
      Name_of_Student: "Miss Ratnala Hasan",
      Topic_of_Credit_Seminar: "Marketing of fertilizer in India",
      Filter_Class: "M.B.A.",
      FilterYear: "13-01-2022",
    },
    {
      S_No: 125,
      Name_of_Student: "Mr. Prayag Raj Singh Marawi",
      Topic_of_Credit_Seminar:
        "Indian Dairy Industry-Present status and future prospects",
      Filter_Class: "M.B.A.",
      FilterYear: "13-01-2022",
    },
    {
      S_No: 126,
      Name_of_Student: "Mr. Ravi Dubey",
      Topic_of_Credit_Seminar:
        "Impact of Covid-19 on trade and commernce in India",
      Filter_Class: "M.Sc.",
      FilterYear: "13-01-2022",
    },
    {
      S_No: 127,
      Name_of_Student: "Miss Nikita Gupta",
      Topic_of_Credit_Seminar: "Starup",
      Filter_Class: "M.Sc.",
      FilterYear: "14-01-2022",
    },
    {
      S_No: 128,
      Name_of_Student: "Miss Purvi Vishwakarma",
      Topic_of_Credit_Seminar: "One district one product in M.P.",
      Filter_Class: "M.Sc.",
      FilterYear: "14-01-2022",
    },
    {
      S_No: 129,
      Name_of_Student: "Mr. Vijay Kumar Kumari",
      Topic_of_Credit_Seminar: "Paramparagat Krishi Vikas Yojana",
      Filter_Class: "M.B.A.",
      FilterYear: "14-01-2022",
    },
    {
      S_No: 130,
      Name_of_Student: "Miss Alankrita Awasthi",
      Topic_of_Credit_Seminar: "Green pea in Jabalpur under O.D.O.P. Scheme",
      Filter_Class: "M.Sc.",
      FilterYear: "14-01-2022",
    },
    {
      S_No: 131,
      Name_of_Student: "Mr. Prakash Chandraraj",
      Topic_of_Credit_Seminar: "Rajiv Gandhi Kisan Nyay Yojana",
      Filter_Class: "M.Sc.",
      FilterYear: "14-01-2022",
    },
    {
      S_No: 132,
      Name_of_Student: "Mr. Gaurav Kumar Vani",
      Topic_of_Credit_Seminar: "A reiview of Production and Procurment",
      Filter_Class: "PhD.",
      FilterYear: "14-01-2022",
    },
    {
      S_No: 133,
      Name_of_Student: "Miss Poonam Chaturvedi",
      Topic_of_Credit_Seminar:
        "Status of import and export of Agril. Products in India",
      Filter_Class: "PhD.",
      FilterYear: "19-01-2022",
    },
    {
      S_No: 134,
      Name_of_Student: "Miss Bharti Pendram",
      Topic_of_Credit_Seminar:
        "Production and export perforamnce of speicies in India",
      Filter_Class: "PhD.",
      FilterYear: "06-06-2022",
    },
    {
      S_No: 135,
      Name_of_Student: "Mr. Rakesh Sontale",
      Topic_of_Credit_Seminar:
        "E-finance in development,impact, issues and challenges in India",
      Filter_Class: "PhD.",
      FilterYear: "06-06-2022",
    },
    {
      S_No: 136,
      Name_of_Student: "Miss Anamika Verma",
      Topic_of_Credit_Seminar: "Prebish Singer Hypothesis",
      Filter_Class: "M.Sc.",
      FilterYear: "02-12-2022",
    },
    {
      S_No: 137,
      Name_of_Student: "Miss Monika Jayant",
      Topic_of_Credit_Seminar:
        "Markowitz model and its application in agriculture",
      Filter_Class: "M.Sc.",
      FilterYear: "02-12-2022",
    },
    {
      S_No: 138,
      Name_of_Student: "Mr. Vikrant Singh Rajput",
      Topic_of_Credit_Seminar: "Zero Budget natural farming",
      Filter_Class: "M.Sc.",
      FilterYear: "02-12-2022",
    },
    {
      S_No: 139,
      Name_of_Student: "Mr. Anil Patel",
      Topic_of_Credit_Seminar:
        "Decompostion techniques in agriculture economics",
      Filter_Class: "M.Sc.",
      FilterYear: "05-12-2022",
    },
    {
      S_No: 140,
      Name_of_Student: "Mr. Ashutosh Parihar",
      Topic_of_Credit_Seminar: "Soil health card scheme",
      Filter_Class: "M.Sc.",
      FilterYear: "05-12-2022",
    },
    {
      S_No: 141,
      Name_of_Student: "Miss Deeksha Singh",
      Topic_of_Credit_Seminar: "Technical indicators in commodity market",
      Filter_Class: "M.Sc.",
      FilterYear: "05-12-2022",
    },
    {
      S_No: 142,
      Name_of_Student: "Miss Pooja Patel",
      Topic_of_Credit_Seminar:
        "Micro Irrigation in vegetable crops: economic importance and government support",
      Filter_Class: "M.Sc.",
      FilterYear: "07-12-2022",
    },
    {
      S_No: 143,
      Name_of_Student: "Mr. Sachin Rathore",
      Topic_of_Credit_Seminar: "National food security Mission",
      Filter_Class: "M.Sc.",
      FilterYear: "12-12-2022",
    },
    {
      S_No: 144,
      Name_of_Student: "Mr. Pradeep Jaiwal",
      Topic_of_Credit_Seminar: "Performance of E-NAM in India",
      Filter_Class: "M.Sc.",
      FilterYear: "12-12-2022",
    },
    {
      S_No: 145,
      Name_of_Student: "Mr. Jitendra Kumar Jhakar",
      Topic_of_Credit_Seminar: "Solar Engergy in India",
      Filter_Class: "M.Sc.",
      FilterYear: "12-12-2022",
    },
    {
      S_No: 146,
      Name_of_Student: "Mr. Bhoom Singh",
      Topic_of_Credit_Seminar: "Farmers producer Organizations in India",
      Filter_Class: "M.Sc.",
      FilterYear: "14-12-2022",
    },
    {
      S_No: 147,
      Name_of_Student: "Miss Alka Choudhary",
      Topic_of_Credit_Seminar: "Starup Indian Stand up India",
      Filter_Class: "M.Sc.",
      FilterYear: "16-12-2022",
    },
    {
      S_No: 148,
      Name_of_Student: "Miss Sangeeta Yadav",
      Topic_of_Credit_Seminar:
        "Backward and forward linkages in Agricultural Marketing",
      Filter_Class: "M.Sc.",
      FilterYear: "16-12-2022",
    },
    {
      S_No: 149,
      Name_of_Student: "Miss Mousam Rani Chourasiya",
      Topic_of_Credit_Seminar: "Present Scenario of Nigher production in India",
      Filter_Class: "M.Sc.",
      FilterYear: "16-12-2022",
    },
    {
      S_No: 150,
      Name_of_Student: "Mr. Kalyan Kumar Nayak",
      Topic_of_Credit_Seminar:
        "Agriculture development and income-employment generation",
      Filter_Class: "M.Sc.",
      FilterYear: "20-12-2022",
    },
    {
      S_No: 151,
      Name_of_Student: "Mr. Arun Gunwan",
      Topic_of_Credit_Seminar: "One district one product",
      Filter_Class: "M.B.A.",
      FilterYear: "22-12-2022",
    },
    {
      S_No: 152,
      Name_of_Student: "Mr. Siyaram Dawar",
      Topic_of_Credit_Seminar:
        "Revoluationing Agriculture through high tech and smart farming",
      Filter_Class: "M.B.A.",
      FilterYear: "22-12-2022",
    },
    {
      S_No: 153,
      Name_of_Student: "Mr. Yashwant Singh",
      Topic_of_Credit_Seminar:
        "Nono fetlizer and agricultural and environmental sustainability",
      Filter_Class: "M.B.A.",
      FilterYear: "22-12-2022",
    },
    {
      S_No: 154,
      Name_of_Student: "Miss Kalyani Patel",
      Topic_of_Credit_Seminar: "Use cluster analysis for market segmentation",
      Filter_Class: "M.B.A.",
      FilterYear: "23-12-2022",
    },
    {
      S_No: 155,
      Name_of_Student: "Mr. Shreeram Patidar3",
      Topic_of_Credit_Seminar:
        "Economic benefits of Agri-Tourism development in India",
      Filter_Class: "M.B.A.",
      FilterYear: "23-12-2022",
    },
    {
      S_No: 156,
      Name_of_Student: "Mr. Naveen Kumar Jharia",
      Topic_of_Credit_Seminar: "Eco-labelling",
      Filter_Class: "M.B.A.",
      FilterYear: "26-12-2022",
    },
    {
      S_No: 157,
      Name_of_Student: "Mr. Vikas Rathore",
      Topic_of_Credit_Seminar:
        "Agro processing industry in India growth, status and prospects",
      Filter_Class: "M.B.A.",
      FilterYear: "26-12-2022",
    },
    {
      S_No: 158,
      Name_of_Student: "Miss Nikita Linda",
      Topic_of_Credit_Seminar: "Customar satisfaction",
      Filter_Class: "M.B.A.",
      FilterYear: "26-12-2022",
    },
    {
      S_No: 159,
      Name_of_Student: "Mr. Shiva Siddaih Chintalacheruvu",
      Topic_of_Credit_Seminar: "Green house market",
      Filter_Class: "M.B.A.",
      FilterYear: "27-12-2022",
    },
    {
      S_No: 160,
      Name_of_Student: "Mr. Rambhros Parmar",
      Topic_of_Credit_Seminar: "Agricultural Waste management",
      Filter_Class: "M.B.A.",
      FilterYear: "28-12-2022",
    },
    {
      S_No: 161,
      Name_of_Student: "Mr. Saksham Bhargwa",
      Topic_of_Credit_Seminar: "e-marking for agriculture entrepreneur",
      Filter_Class: "M.B.A.",
      FilterYear: "28-12-2022",
    },
    {
      S_No: 162,
      Name_of_Student: "Miss Divya Rathore",
      Topic_of_Credit_Seminar:
        "Micro-irrigation system Global and Indian Market",
      Filter_Class: "M.B.A.",
      FilterYear: "29-12-2022",
    },
    {
      S_No: 163,
      Name_of_Student: "Mr. Aman Tarvekar",
      Topic_of_Credit_Seminar:
        "Current scenario of Wheat commodity trade in world",
      Filter_Class: "M.B.A.",
      FilterYear: "30-12-2022",
    },
    {
      S_No: 164,
      Name_of_Student: "Mr. Ankit Sahu",
      Topic_of_Credit_Seminar: "Opportunities in Agri-input sector",
      Filter_Class: "M.B.A.",
      FilterYear: "30-12-2022",
    },
    {
      S_No: 165,
      Name_of_Student: "Mr. Aditya Dubey",
      Topic_of_Credit_Seminar: "Customer switching behaviour",
      Filter_Class: "M.B.A.",
      FilterYear: "30-12-2022",
    },
    {
      S_No: 166,
      Name_of_Student: "Mr. Prashantam Gaur",
      Topic_of_Credit_Seminar: "Pesticides status in India",
      Filter_Class: "M.B.A.",
      FilterYear: "30-12-2022",
    },
    {
      S_No: 167,
      Name_of_Student: "Mr. Satish Dhakar",
      Topic_of_Credit_Seminar: "Done in agriculture for better farming",
      Filter_Class: "M.B.A.",
      FilterYear: "30-12-2022",
    },
    {
      S_No: 168,
      Name_of_Student: "Mr. Sarad Lodhi",
      Topic_of_Credit_Seminar: "Supply Chain management",
      Filter_Class: "M.B.A.",
      FilterYear: "30-12-2022",
    },
    {
      S_No: 169,
      Name_of_Student: "Mr. Gyanesh Karode",
      Topic_of_Credit_Seminar:
        "Government schemes and programmes in agriculture",
      Filter_Class: "M.B.A.",
      FilterYear: "30-12-2022",
    },
    {
      S_No: 170,
      Name_of_Student: "Mr. Harshit Bisen",
      Topic_of_Credit_Seminar:
        "The role of foood, land use and agriculture in climate change mitigation by 2050",
      Filter_Class: "M.B.A.",
      FilterYear: "30-12-2022",
    },
    {
      S_No: 171,
      Name_of_Student: "Mr. Saurabh Sanoria",
      Topic_of_Credit_Seminar: "Trend in oilseed industry",
      Filter_Class: "M.B.A.",
      FilterYear: "30-12-2022",
    },
    {
      S_No: 172,
      Name_of_Student: "Miss Neelam Tripathi",
      Topic_of_Credit_Seminar: "Crop diverfication in India",
      Filter_Class: "M.Sc.",
      FilterYear: "10-01-2023",
    },
    {
      S_No: 173,
      Name_of_Student: "Mr. Manish Kumar Devangan",
      Topic_of_Credit_Seminar:
        "Agriculture drone & its application in agriculture",
      Filter_Class: "M.Sc.",
      FilterYear: "24-02-2023",
    },
  ];
  const tabledata = Data.filter((item) => {
    const searchText = search.toLowerCase();
    const name_student =
      item.Name_of_Student.toLowerCase().includes(searchText);
    const topic_seminar =
      item.Topic_of_Credit_Seminar.toLowerCase().includes(searchText);

    return (
      (name_student || topic_seminar) &&
      item.Filter_Class.includes(class_) &&
      item.FilterYear.includes(year)
    );
  });
  const newdata = tabledata.map((item, i) => {
    return (
      <tr key={i}>
        <td>{item.S_No}</td>
        <td>{item.Name_of_Student}</td>
        <td>{item.Topic_of_Credit_Seminar}</td>
        <td>{item.Filter_Class}</td>
        <td>{item.FilterYear}</td>
      </tr>
    );
  });

  return (
    <div>
      <section class="about section">
        <div class="container">
          <div class="row">
            <div class="about-content">
              <h3>Credit Seminar </h3>
              <section class="blog-area">
                <div class="container pt-20">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <div class="finder-journal">
                        <ul>
                          {/* <li>
                            <Select
                              placeholder="Select Teacher "
                              onChange={(e) => {
                                setteacher(e.value);
                              }}
                              options={Teacher}
                            />
                          </li> */}

                          {/* <li class="rating">
                            <Select
                              placeholder="Select Class"
                              onChange={(e) => {
                                setclass(e.value);
                              }}
                              options={class_opt}
                            />
                          </li> */}
                          <li class="rating">
                            <Select
                              placeholder="Select Class"
                              onChange={(e) => {
                                setclass(e.value);
                              }}
                              options={class_opt}
                            />
                          </li>
                          <li class="rating">
                            <Select
                              placeholder="Select Year  "
                              onChange={(e) => {
                                setyear(e.value);
                              }}
                              options={Year_OPT}
                            />
                          </li>
                          <input
                            placeholder="Search "
                            type="text"
                            onChange={(e) => setsearch(e.target.value)}
                          />
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="rac_meeting">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <table
                      style={{
                        width: "100%",
                        marginLeft: "3px",
                        marginTop: "9px",
                      }}
                    >
                      <tr>
                        <th>S.NO</th>
                        <th>Name of Student</th>
                        <th>Topic of Credit Seminar</th>
                        <th>Class</th>
                        <th style={{ width: "10%" }}>Year</th>
                      </tr>
                      {newdata}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button className="btn ">
            <CSVLink data={tabledata} filename="test.csv" headers={header}>
              download
            </CSVLink>
          </button>
        </div>
      </section>
    </div>
  );
}
const header = [
  { label: "S_No", key: "S_No" },
  { label: "Name_of_Student", key: "Name_of_Student" },
  { label: "Topic_of_Credit_Seminar", key: "Topic_of_Credit_Seminar" },
  { label: "Filter_Class", key: "Filter_Class" },
  { label: "FilterYear", key: "FilterYear" },
];
