/* eslint-disable */
import React, { useEffect, useState } from "react";
import A4 from "../assets/PDF FILE/AdvisoryBoardMeeting/A4.pdf";
// import { Document, Page, pdfjs } from "react-pdf";
import "../assets/style.css";
import Image126 from "../assets/PDF FILE/Research/AERC_completed/125 Final.pdf";
import Image116 from "../assets/PDF FILE/Research/AERC_completed/116 IWMP.pdf";
import Image134 from "../assets/PDF FILE/Research/AERC_completed/Report 134 Village Study.pdf";
import Image125 from "../assets/PDF FILE/Research/AERC_completed/125 Final.pdf";
import Image127 from "../assets/PDF FILE/Research/AERC_completed/127 Final.pdf";

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/liclearbs/pdf.js/${pdfjs.version}/pdf.worker.js`;
export default function PdfOperner(props) {
  // console.log("props", props.location.setNameofPdf)
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [propData, setPropsData] = useState("");

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const nextButton = () => {
    setPageNumber(pageNumber + 1);
  };
  const prevButton = () => {
    setPageNumber(pageNumber - 1);
  };
  const PDFViewer = () => {};

  useEffect(() => setPropsData(props.location.setNameofPdf));

  return (
    <div>
      {/* {propData == "134" ?
    <Document file={Image134} onLoadSuccess={onDocumentLoadSuccess}>
  <Page pageNumber={pageNumber} />
</Document>
:
propData == "116" ?
    <Document file={Image116} onLoadSuccess={onDocumentLoadSuccess}>
  <Page pageNumber={pageNumber} />
</Document>
:
propData == "125" ?
    <Document file={Image125} onLoadSuccess={onDocumentLoadSuccess}>
  <Page pageNumber={pageNumber} />
</Document>
:
propData == "126" ?
    <Document file={Image126} onLoadSuccess={onDocumentLoadSuccess}>
  <Page pageNumber={pageNumber} />
</Document>
:
propData == "127" ?
    <Document file={Image127} onLoadSuccess={onDocumentLoadSuccess}>
  <Page pageNumber={pageNumber} />
</Document> */}
      {propData === "134" ? <div>Inside 134 condition</div> : null}

      <p style={{ textAlign: "center", marginBottom: "10px" }}>
        <button onClick={prevButton}>Previous</button> Page {pageNumber} of{" "}
        {numPages} <button onClick={nextButton}>Next</button>
      </p>
    </div>
  );
}
