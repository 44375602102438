/* eslint-disable */
import React from "react";
import Footer from "./footer";
import Header from "./header";
import Slider8 from "../assets/images/slider/8.jpg";
import "../assets/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faPaperclip,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import PDF_IMage from "../assets/images/pdf.png";
import PDF_1 from "../assets/PDF FILE/RAC/1 Mintues Final_3rd RAC Meeting_FINAL 27 Aug 2021.pdf";
import PDF_2 from "../assets/PDF FILE/RAC/2 Minutes of Directors Meeting held on 27th August 2021.pdf";
import PDF_3 from "../assets/PDF FILE/RAC/3 RAC Meeting (Workplan 2019-20).pdf";
import PDF_4 from "../assets/PDF FILE/RAC/4 CCCS meeting 2015.pdf";
import PDF_5 from "../assets/PDF FILE/RAC/5 CCOS Meeting 2013.pdf";

import { useState } from "react";
import Select from "react-select";
import { CSVLink } from "react-csv";

export default function ExternalFunded() {
  const [year, setYear] = useState("");
  const [pi, setpi] = useState("");
  const [search, setsearch] = useState("");

  const Data = [
    {
      S_No: "1",
      Name_of_the_Project:
        "Impact Evaluation of Farmers Producers Organization Projects in Madhya Pradesh",
      Filter_Year: "2020-21",
      PI_and_Co_PI:
        "PI-Dr. Ho.Sharma,  Co-PI-Dr. Deepak Rathi, Co-PI-Dr. H.K.Niranjan,",
      Sponsored_Agency: "NABARD",
      Total_Outlay_Lakh: "10,95,380",
    },
    {
      S_No: "2",
      Name_of_the_Project:
        "Impact of Tejaswani Rural Women Empowerment Programme through SRI, Vegetable Production, Kodo-Kutki Production in Madhya Pradesh",
      Filter_Year: "2017-18",
      PI_and_Co_PI:
        "PI-Dr. Ho.Sharma Co-PI-Dr. S.B.Nahatkar Co-PI-Dr. Deepak Rathi",
      Sponsored_Agency: "Madhya Pradesh Mahila Vitt Vikas Nigam Bhopal",
      Total_Outlay_Lakh: "14,84,650",
    },
    {
      S_No: "2",
      Name_of_the_Project:
        "Impact of Tejaswini rural Woman Empowerment programme on Empowerment of Rural Woman through Vegetable Production in Dindori and Chatarpur district of Madhya Pradesh",
      Filter_Year: "2017-18",
      PI_and_Co_PI:
        "PI-Dr. Deepak Rathi Co-PI-Dr. Ho.Sharma  Co-PI-Dr. S.B.Nahatkar",
      Sponsored_Agency: "Madhya Pradesh Mahila Vitt Vikas Nigam Bhopal",
      Total_Outlay_Lakh: "14,84,650",
    },
    {
      S_No: "2",
      Name_of_the_Project:
        "Impact of Tejaswani Rural Women Empowerment Programme through SRI, Vegetable Production, Kodo-Kutki Production in Madhya Pradesh",
      Filter_Year: "2017-18",
      PI_and_Co_PI:
        "PI-Dr. S.B.Nahatkar Co-PI-Dr. Ho.Sharma Co-PI-Dr. Deepak Rathi",
      Sponsored_Agency: "Madhya Pradesh Mahila Vitt Vikas Nigam Bhopal",
      Total_Outlay_Lakh: "14,84,650",
    },
    {
      S_No: "3",
      Name_of_the_Project:
        "Impact of Pradhan Mantri Krishi Sinchai Yojana (Watershed Development) on Land Use and Cropping Pattern in Madhya Pradesh",
      Filter_Year: "2015-16",
      PI_and_Co_PI: "PI-Dr. Ho.Sharma Co-PI-Dr. Deepak Rathi",
      Sponsored_Agency:
        "Rajiv Gandhi Watershed area Management Mission, Bhopal,Madhya Pradesh",
      Total_Outlay_Lakh: "17,32,935",
    },
    {
      S_No: "4",
      Name_of_the_Project:
        "Pilot Study of Seed, Feed &amp; Wastage Ration of Major Food Grains in Madhya Pradesh",
      Filter_Year: "2014-15",
      PI_and_Co_PI: "PI-Dr. Ho.Sharma Co-PI-Dr. Deepak Rathi",
      Sponsored_Agency: "IASRI, New Delhi",
      Total_Outlay_Lakh: "7,14,000",
    },
    {
      S_No: "5",
      Name_of_the_Project: "RKVY-Monitoring of Agro Economic System",
      Filter_Year: "2012-13",
      PI_and_Co_PI: "PI-Dr. Ho.Sharma Co-PI-Dr. Deepak Rathi",
      Sponsored_Agency:
        "Kishan Kalyan Evam Krishi Vikas Vibhag, Bhopal Madhya Pradesh",
      Total_Outlay_Lakh: "11,49,192",
    },
    {
      S_No: "6",
      Name_of_the_Project:
        "Bringing Green Revolution of eastern India (BGREI) in Madhya Pradesh",
      Filter_Year: "2011-12",
      PI_and_Co_PI: "PI-Dr. Deepak Rathi",
      Sponsored_Agency: "Government of India",
      Total_Outlay_Lakh: "5,00,000",
    },
  ];
  const year_opt = [
    { value: "", label: "Select" },
    { value: "2020-21", label: "2020-21" },
    { value: "2017-18", label: "2017-18" },
    { value: "2015-16", label: "2015-16" },
    { value: "2014-15", label: "2014-15" },
    { value: "2012-13", label: "2012-13" },
    { value: "2011-12", label: "2011-12" },
  ];

  const pi_opt = [
    { value: "", label: "Select" },
    {
      value: "Sharma",
      label: "Sharma",
    },
    {
      value: "Rathi",
      label: "Rathi",
    },
    {
      value: "Niranjan",
      label: "Niranjan",
    },
    {
      value: "Nahatkar",
      label: "Nahatkar",
    },
  ];
  const tabledata = Data.filter((item) => {
    const searchText = search.toLowerCase();
    const sponser = item.Sponsored_Agency.toLowerCase().includes(searchText);
    const name_of_project =
      item.Name_of_the_Project.toLowerCase().includes(searchText);

    return (
      (sponser || name_of_project) &&
      item.Filter_Year.includes(year) &&
      item.PI_and_Co_PI.includes(pi)
    );
  });
  const newdata = tabledata.map((Data) => {
    const {
      S_No,
      Name_of_the_Project,
      Filter_Year,
      PI_and_Co_PI,
      Sponsored_Agency,
      Total_Outlay_Lakh,
    } = Data;
    // console.log(Data);
    return (
      <tr id={S_No}>
        <td>{S_No}</td>
        <td>{Name_of_the_Project}</td>
        <td>{Filter_Year}</td>
        <td>
          {PI_and_Co_PI} <br />{" "}
        </td>
        <td>{Sponsored_Agency}</td>
        <td>{Total_Outlay_Lakh}</td>
      </tr>
    );
  });

  return (
    <div>
      <section class="about section">
        <div class="container">
          <div class="row">
            <div class="about-content">
              <h3> External Funded Projects </h3>
              <section class="blog-area">
                <div class="container pt-20">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <div class="finder-journal">
                        <ul>
                          <li>
                            <Select
                              placeholder="Select Year "
                              onChange={(e) => {
                                setYear(e.value);
                              }}
                              options={year_opt}
                            />
                          </li>

                          <li class="rating">
                            <Select
                              placeholder=" PI and CO-PI  "
                              onChange={(e) => {
                                setpi(e.value);
                              }}
                              options={pi_opt}
                            />
                          </li>
                          {/* <li class="rating">
                            <Select
                              placeholder="Select Session"
                              onChange={(e) => {
                                setsession(e.value);
                              }}
                              options={yearOptions}
                            />
                          </li> */}
                          {/* <li class="rating">
                            <Select
                              placeholder="Search "
                              onChange={(e) => {
                                setsearch(e.value);
                              }}
                            />
                          </li> */}
                          <input
                            placeholder="Search "
                            type="text"
                            onChange={(e) => setsearch(e.target.value)}
                          />
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="rac_meeting">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <table
                      style={{
                        width: "100%",
                        marginLeft: "3px",
                        marginTop: "9px",
                      }}
                    >
                      <tr>
                        <th>S.No</th>
                        <th>Name of the Project</th>
                        <th>Year</th>
                        <th>PI and Co-PI</th>
                        <th>Sponsored Agency</th>
                        <th>Total Outlay (Lakh)</th>
                      </tr>
                      {newdata}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button className="btn ">
            <CSVLink data={tabledata} filename="test.csv" headers={header}>
              download
            </CSVLink>
          </button>
        </div>
      </section>
    </div>
  );
}
const header = [
  { label: "S_no", key: "S_No" },
  { label: "Name_of_the_Project", key: "Name_of_the_Project" },
  { label: "Filter_Year", key: "Filter_Year" },
  { label: "PI_and_Co_PI", key: "PI_and_Co_PI" },
  { label: "Sponsored_Agency", key: "Sponsored_Agency" },
  { label: "Total_Outlay_Lakh", key: "Total_Outlay_Lakh" },
];
