/* eslint-disable */

import React from "react";
import Footer from "./footer";
import Header from "./header";
import Slider8 from "../assets/images/slider/8.jpg";
import "../assets/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faPaperclip,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import PDF_IMage from "../assets/images/pdf.png";
import PDF_1 from "../assets/PDF FILE/RAC/1 Mintues Final_3rd RAC Meeting_FINAL 27 Aug 2021.pdf";
import PDF_2 from "../assets/PDF FILE/RAC/2 Minutes of Directors Meeting held on 27th August 2021.pdf";
import PDF_3 from "../assets/PDF FILE/RAC/3 RAC Meeting (Workplan 2019-20).pdf";
import PDF_4 from "../assets/PDF FILE/RAC/4 CCCS meeting 2015.pdf";
import PDF_5 from "../assets/PDF FILE/RAC/5 CCOS Meeting 2013.pdf";
import a1 from "../assets/Abstract File/Abstract File/a1.pdf";
import a2 from "../assets/Abstract File/Abstract File/a2.pdf";
import a3 from "../assets/Abstract File/Abstract File/a3.pdf";
import a4 from "../assets/Abstract File/Abstract File/a4.pdf";
import a5 from "../assets/Abstract File/Abstract File/a5.pdf";
import a6 from "../assets/Abstract File/Abstract File/a6.pdf";
import a7 from "../assets/Abstract File/Abstract File/a7.pdf";
import a8 from "../assets/Abstract File/Abstract File/a8.pdf";
import a9 from "../assets/Abstract File/Abstract File/a9.pdf";
import a10 from "../assets/Abstract File/Abstract File/a10.pdf";
import a11 from "../assets/Abstract File/Abstract File/a11.pdf";
import a12 from "../assets/Abstract File/Abstract File/a12.pdf";
import a13 from "../assets/Abstract File/Abstract File/a13.pdf";
import a14 from "../assets/Abstract File/Abstract File/a14.pdf";
import a15 from "../assets/Abstract File/Abstract File/a15.pdf";
import a16 from "../assets/Abstract File/Abstract File/a16.pdf";
import a17 from "../assets/Abstract File/Abstract File/a17.pdf";
import a18 from "../assets/Abstract File/Abstract File/a18.pdf";
import a19 from "../assets/Abstract File/Abstract File/a19.pdf";
import a20 from "../assets/Abstract File/Abstract File/a20.pdf";
import a21 from "../assets/Abstract File/Abstract File/a21.pdf";
import a22 from "../assets/Abstract File/Abstract File/a22.pdf";
import a23 from "../assets/Abstract File/Abstract File/a23.pdf";
import a24 from "../assets/Abstract File/Abstract File/a24.pdf";
import a25 from "../assets/Abstract File/Abstract File/a25.pdf";
import a26 from "../assets/Abstract File/Abstract File/a26.pdf";
import a27 from "../assets/Abstract File/Abstract File/a27.pdf";
import a28 from "../assets/Abstract File/Abstract File/a28.pdf";
import a29 from "../assets/Abstract File/Abstract File/a29.pdf";
import a30 from "../assets/Abstract File/Abstract File/a30.pdf";
import a31 from "../assets/Abstract File/Abstract File/a31.pdf";
import a32 from "../assets/Abstract File/Abstract File/a32.pdf";
import a33 from "../assets/Abstract File/Abstract File/a33.pdf";
import a34 from "../assets/Abstract File/Abstract File/a34.pdf";
import a35 from "../assets/Abstract File/Abstract File/a35.pdf";
import a36 from "../assets/Abstract File/Abstract File/a36.pdf";
import a37 from "../assets/Abstract File/Abstract File/a37.pdf";
import { CSVLink } from "react-csv";
import { useId } from "react";

import { useState } from "react";
import Select from "react-select";

export default function Abstract() {
  const [search, setsearch] = useState("");
  const [year, setyear] = useState("");
  const [author, setauthor] = useState("");
  const Year_OPT = [
    { value: "", label: "Select" },
    { value: "2013", label: "2013" },
    { value: "2014", label: "2014" },
    { value: "2015", label: "2015" },
    { value: "2016", label: "2016" },
    { value: "2018", label: "2018" },
    { value: "2019", label: "2019" },
    { value: "2020", label: "2020" },
    { value: "2023", label: "2023" },
  ];
  const author_opt = [
    { value: "", label: "Select" },
    { value: "Awasthi", label: "Awasthi" },
    { value: "Rathi", label: "Rathi" },
    { value: "Sharma", label: "Sharma" },
    { value: "Niranjan.", label: "Niranjan" },
    { value: "Meena", label: "Meena" },
    // { value: "Meena, A.", label: "Meena, A." },
    { value: "Bareliya", label: "Bareliya" },
    { value: "Khan", label: "Khan" },
    { value: "Gupta", label: "Gupta" },
    { value: "Mishra", label: "Mishra" },
    { value: "Chouhan", label: "Chouhan" },
    { value: "Patidar", label: "Patidar" },
    { value: "Dharmendra", label: "Dharmendra" },
    { value: "Birla", label: "Birla" },

    { value: "Pandey", label: "Pandey" },
    { value: "Aske", label: "Aske" },
    { value: "Kuril", label: "Kuril" },
    { value: "Osari", label: "Osari" },
  ];

  const Data = [
    {
      Authors: "Meena, S.C.;  Rathi, Deepak and Sharma, H.O.",
      year_of_publication: "2013",
      Title:
        "Dynamics of Soybean Production in Different Districts of Madhya Pradesh",
      Page_No: "214",
      Name_of_Conference_Organized_by:
        "Agricultural Economics Research Review    Vol.26   (Conference Number)",
      File_code: a1,
    },
    {
      Authors: "Sharma, H.O.; Rathi Deepak and Niranjan, H.K.",
      year_of_publication: "2014",
      Title:
        "मध्य प्रदेश की कृषि आर्थिकय में फसल पद्धति में हुए परिवर्तनों का विश्लेषण.",
      Page_No: "9",
      Name_of_Conference_Organized_by: "रास्ट्रीय विज्ञान हिंदी सम्मलेन भोपाल",
      File_code: a2,
    },
    {
      Authors: "Meena, A. and Bareliya, R. S.",
      year_of_publication: "2014",
      Title:
        "An assessment of Enterpreneurial activities in Potato Cultivation among the farm women in Rewa block of Rewa District",
      Page_No: "97",
      Name_of_Conference_Organized_by:
        "Challeges and Opportunities for Agricultural Crop Productivity under Climate Change, College of Agriculture, Rewa, JNKVV, Jabalpur",
      File_code: a3,
    },
    {
      Authors: "Bareliya, R.S; Khan, N.and Mishra, A.M.",
      year_of_publication: "2014",
      Title:
        "An Economic analysis of Production and Marketing of Potato and Onion Crops in Rewa, District of Madhya Pradesh",
      Page_No: "182",
      Name_of_Conference_Organized_by:
        "College of Agriculture, Rewa, JNKVV, Jabalpur",
      File_code: a4,
    },
    {
      Authors: "Niranjan, H.K. Sharma, H.O. and Rathi Deepak",
      year_of_publication: "2015",
      Title:
        "Impact of National Food Security Mission NFSM on Adoption of Farm Mechanization in Madhya Pradesh.",
      Page_No: "Vol 28 Pp. 305",
      Name_of_Conference_Organized_by:
        "Agricultural Economics Research Review  Annual Conference",
      File_code: a5,
    },
    {
      Authors: "Niranjan, H.K. and Gupta, J. K.",
      year_of_publication: "2016",
      Title:
        "Dynamic of Area Production and Productivity of Gram in Madhya Pradesh",
      Page_No: "170",
      Name_of_Conference_Organized_by: "ISEE National Conference Issue",
      File_code: a6,
    },
    {
      Authors: "Niranjan, H.K. , Chouhan, R.S, Rathi, D. and Sharma, H.O.",
      year_of_publication: "2016",
      Title:
        "Yield gap, adoption pattern and constraint analysis in cultivation of Soybean under rainfed areas of Madhya Pradesh",
      Page_No: "229",
      Name_of_Conference_Organized_by:
        "The International Conference on Bioresource and Stress Management, Jaipur, Rajasthan",
      File_code: a7,
    },
    {
      Authors: "Chouhan R.S. Gupta, J. K., and Niranjan. H.K.",
      year_of_publication: "2018",
      Title:
        "Relationship between Arrivals and Prices of Wheat in different Regulated market of Madhya Pradesh.",
      Page_No: "217",
      Name_of_Conference_Organized_by:
        "International Conference on Global Research Initiatives for Sustainable Agriculture & Allied Sciences",
      File_code: a8,
    },
    {
      Authors:
        "Padmanaban, K; Mishra, P.; Supriya; Niranjan, H.K. and Sahu, P.K",
      year_of_publication: "2018",
      Title: "Instability and Forecasting of Turmeric in India.",
      Page_No: "226",
      Name_of_Conference_Organized_by:
        "Agricultural Economics Research Review Vol. 31 (Conference Number) 2018",
      File_code: a9,
    },
    {
      Authors: "Chouhan,.R.S.; Niranjan, H.K. and Sharma, H.O.",
      year_of_publication: "2018",
      Title:
        "Economics of value-added products of Kodo and Kutki in Madhya Pradesh.",
      Page_No: "201",
      Name_of_Conference_Organized_by:
        "Agricultural Economics Research Review Vol. 31 (Conference Number) 2018",
      File_code: a10,
    },
    {
      Authors: "Niranjan, H.K. , Chouhan,.R.S. and Rathi Deepak",
      year_of_publication: "2018",
      Title:
        "Impact of Neem Coated Urea in Cultivation of Major Kharif and Rabi Crop in Madhya Pradesh.",
      Page_No: "173-174",
      Name_of_Conference_Organized_by:
        "International Conference on Global Research Initiatives for Sustainable Agriculture & Allied Sciences",
      File_code: a11,
    },
    {
      Authors:
        "Niranjan. H.K., Gupta, J. K., Mishra, P. and Chouhan Ravi Singh",
      year_of_publication: "2018",
      Title:
        "Estimation of Total Factor Productivity Growth of Maize Production in Central India",
      Page_No: "174",
      Name_of_Conference_Organized_by:
        "International Conference on Global Research Initiatives for Sustainable Agriculture & Allied Sciences",
      File_code: a12,
    },
    {
      Authors: "Sharma, H.O. Rathi, Deepak & Chouhan Ravi Singh",
      year_of_publication: "2018",
      Title: "Farmer Suicides in Madhya Pradesh",
      Page_No: "44",
      Name_of_Conference_Organized_by:
        "International Conference on Global Research Initiatives for Sustainable Agriculture & Allied Sciences",
      File_code: a13,
    },
    {
      Authors: "Sharma, H.O., Rathi, Deepak and Niranjan H.K.",
      year_of_publication: "2018",
      Title: "Impact of Neem Coated Urea over Normal Urea in Madhya Pradesh.",
      Page_No: "60",
      Name_of_Conference_Organized_by:
        "National Conference on Agro- Economic Research Organized at Institute of Economic Growth, New Delhi",
      File_code: a14,
    },
    {
      Authors: "Patidar, Pradeep Kumar and Dharmendra",
      year_of_publication: "2018",
      Title:
        "Trends of Pulses Production, Consumption and Import Scenario in India",
      Page_No: "-",
      Name_of_Conference_Organized_by:
        "National  on conference for Post Graduate Students (NCPGS-2018) “Techno-Strategic Interventions for Profitable Agriculture” Acharya N.G. Ranga Agricultural University , Agricultural College, Bapatla, Gunture (A.P.)",
      File_code: a15,
    },
    {
      Authors: "Sharma, H.O. Rathi, Deepak & Chouhan Ravi Singh",
      year_of_publication: "2018",
      Title: "Dynamics and Revival of Fallow Land in Madhya Pradesh",
      Page_No: "32-33.",
      Name_of_Conference_Organized_by:
        "National Conference on Agro- Economic Research Organized by the 15 AERCs /Us of the Ministry of Agriculture & Farmers Welfare, GoI at Institute of Economic Growth,",
      File_code: a16,
    },
    {
      Authors: "Sharma, H.O., Rathi, Deepak and Ravi Singh Chouhan",
      year_of_publication: "2018",
      Title:
        "Problems and prospects of production of small millets and their value added products in Madhya Pradesh,",
      Page_No: "75",
      Name_of_Conference_Organized_by:
        "National conference on National Conference on Agro- Economic Research Organized by the 15 Agro-Economic Research Centres /Units of The Ministry of Agriculture & Farmers Welfare, Institute of Economic Growth, New Delhi",
      File_code: a17,
    },
    {
      Authors: "Chouhan, R. S., Sharma, H.O. & Niranjan, H.K.",
      year_of_publication: "2019",
      Title:
        "Constant in production and marketing of milk as perceived by Dairy owners related to Chhattisgarh State Cooperative Dairy Federation",
      Page_No: "28",
      Name_of_Conference_Organized_by:
        "National Conference on Agro- Economic Research Organized by the 15 AERC/Us the Ministry of Agriculture & Farmers Welfare, GoI, New Delhi at Indian Institute of Management, ,",
      File_code: a18,
    },
    {
      Authors: "Sharma, H.O., Chouhan, R. S. & Niranjan, H.K.",
      year_of_publication: "2019",
      Title:
        "Cost and return analysis of milk production in different type of milch animal a case study of Chhattisgarh State Cooperative Dairy Federation",
      Page_No: "29",
      Name_of_Conference_Organized_by:
        "National Conference on Agro- Economic Research Organized by the 15 AERC/Us the Ministry of Agriculture & Farmers Welfare, GoI, New Delhi at Indian Institute of Management, Ahmedabad (IIMA)",
      File_code: a19,
    },
    {
      Authors: "Niranjan, H.K. , Rathi Deepak and Chouhan,.R.S.",
      year_of_publication: "2019",
      Title: "Dynamic of Milk Production in Chhattisgarh State.",
      Page_No: "30",
      Name_of_Conference_Organized_by:
        "National Conference on Agro Economic Policy & Research, IIMA Gujarat",
      File_code: a20,
    },
    {
      Authors: "Sharma, H.O., Niranjan, H.K. & Chouhan, R. S.",
      year_of_publication: "2019",
      Title: "Farmer Suicides in Chhattisgarh : Causes and Consequences",
      Page_No: "48",
      Name_of_Conference_Organized_by:
        "National Conference on Agro- Economic Research Organized by the 15 AERC/Us the Ministry of Agriculture & Farmers Welfare, GoI, New Delhi at Indian Institute of Management, Ahmedabad (IIMA)",
      File_code: a21,
    },
    {
      Authors: "Rathi, Deepak., Niranjan, H.K. & Chouhan, R. S.",
      year_of_publication: "2019",
      Title:
        "Performance of different Insurance Agency under Pradhan Mantri Fasal Bima Yojana in Madhya Pradesh",
      Page_No: "75",
      Name_of_Conference_Organized_by:
        "National Conference on Agro- Economic Research Organized by the 15 AERC/Us the Ministry of Agriculture & Farmers Welfare, GoI, New Delhi at Indian Institute of Management, Ahmedabad (IIMA)",
      File_code: a22,
    },
    {
      Authors: "Chouhan, R. S., Sharma, H.O. & Niranjan, H.K.",
      year_of_publication: "2019",
      Title:
        "Relationship between Arrivals and Price of Onion in Madhya Pradesh",
      Page_No: "85",
      Name_of_Conference_Organized_by:
        "National Conference on Agro- Economic Research Organized by the 15 AERC/Us the Ministry of Agriculture & Farmers Welfare, GoI, New Delhi at Indian Institute of Management, Ahmedabad (IIMA)",
      File_code: a23,
    },
    {
      Authors: "Niranjan, H.K., Rathi, Deepak & Chouhan, R. S.",
      year_of_publication: "2019",
      Title: "Dynamics of Onion Production in Madhya Pradesh",
      Page_No: "86",
      Name_of_Conference_Organized_by:
        "National Conference on Agro- Economic Research Organized by the 15 AERC/Us the Ministry of Agriculture & Farmers Welfare, GoI, New Delhi at Indian Institute of Management, Ahmedabad (IIMA)",
      File_code: a24,
    },
    {
      Authors: "Patidar, Pradeep Kumar Khan, N and Birla Rahul",
      year_of_publication: "2019",
      Title:
        "An Economic Study Of Cost of Cultivation and profitability Of Dry Red Chilli in Khargone District in Madhya Pradesh",
      Page_No: "63",
      Name_of_Conference_Organized_by:
        "Advance and Challenges in Horticulture, National Seminar (2019), Department of Horticulture, College of Agriculture, JNKVV, Jabalpur",
      File_code: a25,
    },
    {
      Authors: "Niranjan, H.K. and Sharma, H.O",
      year_of_publication: "2020",
      Title:
        "Estimating Yield Gaps Among Rice (ORYZA SATIVA) Growers in Bundelkhand Region of Madhya Pradesh: Constraints and Stratigies to Narrow The Gap",
      Page_No: "26",
      Name_of_Conference_Organized_by:
        "Nastional conference on Recent Trend and New Frontiers in Biotechnology, Agriculture, Science and Environment (NCRTNFBASE-2020)",
      File_code: a26,
    },
    {
      Authors: "Bareliya, Rajendra Singh; Birla, Rahul  and  Osari, Bhanwarlal",
      year_of_publication: "2023",
      Title:
        "Cost and Return Analysis of Cultivation of Kodo and Kutki in Madhya Pradesh",
      Page_No: "125",
      Name_of_Conference_Organized_by:
        "National Conference on Production, Processing and Marketing of Millets : Issue and Solutions, JNKVV, Jabalpur",
      File_code: a27,
    },
    {
      Authors: "Rathi, Deepak; Niranjan, H.K. and Patidar, Pradeep Kumar",
      year_of_publication: "2023",
      Title:
        "Economic Analysis of Processing of Kodo and its Value Added Products in Madhya Pradesh",
      Page_No: "126",
      Name_of_Conference_Organized_by:
        "National Conference on Production, Processing and Marketing of Millets : Issue and Solutions, JNKVV, Jabalpur",
      File_code: a28,
    },
    {
      Authors: "Pandey, P.R.  and Kuril, Akhilesh",
      year_of_publication: "2023",
      Title:
        "Processing of Kutki and its Value Added Products in Madhya Pradesh: An Economic Analysis",
      Page_No: "126",
      Name_of_Conference_Organized_by:
        "National Conference on Production, Processing and Marketing of Millets : Issue and Solutions, JNKVV, Jabalpur",
      File_code: a28,
    },
    {
      Authors: "Sharma, H.O.",
      year_of_publication: "2023",
      Title:
        "Benefits & Challenges of Micro Irrigation under Pradhan Mantri Krishi Sinchayee Yojana - Per Drop More Crop in Madhya Pradesh",
      Page_No: "17-18",
      Name_of_Conference_Organized_by:
        "Regional Conference on Recent Innovations in Agricultural Sector: Preliminary Insights and Emerging Perspectives, Organised by Indian Society of Agricultural Economics, Mumbai & Department of Agriculture Economics &FM",
      File_code: a29,
    },
    {
      Authors:
        "Rathi, Deepak,  Sharma, H.O. Niranjan, H.K. and Bareliya, R. S.",
      year_of_publication: "2023",
      Title:
        "Economics of Milk Production & Awareness of Dairying Schemes in Chhattisgarh State",
      Page_No: "50-55",
      Name_of_Conference_Organized_by:
        "Regional Conference on Recent Innovations in Agricultural Sector: Preliminary Insights and Emerging Perspectives, Organised by Indian Society of Agricultural Economics, Mumbai & Department of Agriculture Economics &FM",
      File_code: a30,
    },
    {
      Authors: "Osari, Bhanwarlal, Aske, Sachin; Birla, Rahul  and Rathi,  D.",
      year_of_publication: "2023",
      Title:
        "Effectiveness of seed Mini-kits program of pulses with special reference to Urd in Central India",
      Page_No: "22-23",
      Name_of_Conference_Organized_by:
        "Regional Conference on Recent Innovations in Agricultural Sector: Preliminary Insights and Emerging Perspectives, Organised by Indian Society of Agricultural Economics, Mumbai & Department of Agriculture Economics &FM",
      File_code: a31,
    },
    {
      Authors: "Patidar, P.K.; Niranjan, H.K. and  Sharma, H.O.",
      year_of_publication: "2023",
      Title:
        "Farmers’ Perceptions regarding Decentralized Procurement Scheme for Procurement of Wheat in Madhya Pradesh",
      Page_No: "88",
      Name_of_Conference_Organized_by:
        "Regional Conference on Recent Innovations in Agricultural Sector: Preliminary Insights and Emerging Perspectives 29-30 May, 2023",
      File_code: a32,
    },
    {
      Authors:
        "Pandey, P.R.; Bareliya, R. S..;  Niranjan, H.K. and  Sharma, H.O.",
      year_of_publication: "2023",
      Title:
        "Impact of “Development of Model Village Programme-Soil Health Card” in Damoh district of Madhya Pradesh with special reference in Paddy",
      Page_No: "61-62",
      Name_of_Conference_Organized_by:
        "Regional Conference on Recent Innovations in Agricultural Sector: Preliminary Insights and Emerging Perspectives 29-30 May, 2023",
      File_code: a33,
    },
    {
      Authors: "Birla, Rahul;  Kuril, Akhilesh; Osari , B.L. and Rathi, D.",
      year_of_publication: "2023",
      Title:
        "Impact of NFSM on Area Production & Yield of Moong & Urd Across Agro Cimatic Zones of Central India",
      Page_No: "42-43",
      Name_of_Conference_Organized_by:
        "Regional Conference on Recent Innovations in Agricultural Sector: Preliminary Insights and Emerging Perspectives, Organised by Indian Society of Agricultural Economics, Mumbai & Department of Agriculture Economics &FM",
      File_code: a34,
    },
    {
      Authors: "Niranjan, H.K. and  Deepak, Rathi",
      year_of_publication: "2023",
      Title:
        "Marketing Efficiency of Supply of Seed to Producer Members through FPOs in Madhya Pradesh",
      Page_No: "98",
      Name_of_Conference_Organized_by:
        "Regional Conference on Recent Innovations in Agricultural Sector: Preliminary Insights and Emerging Perspectives, Organised by Indian Society of Agricultural Economics, Mumbai & Department of Agriculture Economics &FM",
      File_code: a35,
    },
    {
      Authors: "Bareliya, R. S.; Niranjan, H.K.; Rathi, D.  and Pandey, P.R.",
      year_of_publication: "2023",
      Title:
        "Policies and Programmes for Dairy Development and Convergence of Schemes with Special Reference to Chhattisgarh state",
      Page_No: "42",
      Name_of_Conference_Organized_by:
        "Regional Conference on Recent Innovations in Agricultural Sector: Preliminary Insights and Emerging Perspectives, Organised by Indian Society of Agricultural Economics, Mumbai & Department of Agriculture Economics &FM",
      File_code: a36,
    },
    {
      Authors:
        "Kuril, Akhilesh;  Osari, Bhanwarlal; Deepak,  Rathi and Aske, Sachin",
      year_of_publication: "2023",
      Title:
        "Procurement of Paddy on MSP Under Decentralize Food Procurement Scheme in Chhattisgarh",
      Page_No: "89-90",
      Name_of_Conference_Organized_by:
        "Regional Conference on Recent Innovations in Agricultural Sector: Preliminary Insights and Emerging Perspectives, Organised by Indian Society of Agricultural Economics, Mumbai & Department of Agriculture Economics &FM",
      File_code: a37,
    },
  ];

  const tabledata = Data.filter((item) => {
    const searchText = search.toLowerCase();
    const Title = item.Title.toLowerCase().includes(searchText);
    const conference =
      item.Name_of_Conference_Organized_by.toLowerCase().includes(searchText);

    return (
      (Title || conference) &&
      item.Authors.includes(author) &&
      item.year_of_publication.includes(year)
    );
  });
  const newdata = tabledata.map((item, i) => {
    return (
      <tr key={i}>
        <td>{item.Authors}</td>
        <td>{item.year_of_publication}</td>
        <td>{item.Title}</td>
        <td>{item.Page_No}</td>
        <td>{item.Name_of_Conference_Organized_by}</td>
        {item.File_code !== "Nill" ? (
          <td style={{ textAlign: "center", verticalAlign: "middle" }}>
            <a href={item.File_code}>
              <img src={PDF_IMage} alt="" />
            </a>
          </td>
        ) : (
          <td>{item.File_code}</td>
        )}
      </tr>
    );
  });

  return (
    <section class="about section">
      <div class="container">
        <div class="row">
          <div class="about-content">
            <h3>Abstract</h3>
            <section class="blog-area">
              <div class="container pt-20">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="finder-journal">
                      <ul>
                        {/* <li>
                            <Select
                              placeholder="Select Teacher "
                              onChange={(e) => {
                                setteacher(e.value);
                              }}
                              options={Teacher}
                            />
                          </li> */}

                        {/* /* <li class="rating">
                            <Select
                              placeholder="Select Class"
                              onChange={(e) => {
                                setclass(e.value);
                              }}
                              options={class_opt}
                            />
                          </li> */}
                        <li class="rating">
                          <Select
                            placeholder="Select Author"
                            onChange={(e) => {
                              setauthor(e.value);
                            }}
                            options={author_opt}
                          />
                        </li>
                        <li class="rating">
                          <Select
                            placeholder="Select Year  "
                            onChange={(e) => {
                              setyear(e.value);
                            }}
                            options={Year_OPT}
                          />
                        </li>
                        <input
                          placeholder="Search "
                          type="text"
                          onChange={(e) => setsearch(e.target.value)}
                        />
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="rac_meeting">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <table
                  //   style={{
                  //     width: "100%",
                  //     marginLeft: "3px",
                  //     marginTop: "9px",
                  //   }}
                  >
                    <tr>
                      <th>Authors</th>
                      <th>Year of publication</th>
                      <th>Title</th>
                      <th>Page No.</th>
                      <th>Name of Conference /Organized by</th>
                      <th
                        style={{
                          width: "10%",
                        }}
                      >
                        PDF
                      </th>
                    </tr>
                    {newdata}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button className="btn ">
          <CSVLink data={tabledata} filename="test.csv" headers={header}>
            download
          </CSVLink>
        </button>
      </div>
    </section>
  );
}
const header = [
  { label: "Authors", key: "Authors" },
  { label: "year_of_publication", key: "year_of_publication" },
  { label: "Title", key: "Title" },
  { label: "Page_No", key: "Page_No" },
  {
    label: "Name_of_Conference_Organized_by",
    key: "Name_of_Conference_Organized_by",
  },
  { label: "File_code", key: "File_code" },
];
