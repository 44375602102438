import React from "react";
import PDF1 from "../assets/PDF FILE/DownloadPDF/PDF1.pdf";
import PDF2 from "../assets/PDF FILE/DownloadPDF/PDF2.pdf";
import PDF3 from "../assets/PDF FILE/DownloadPDF/PDF3.pdf";
import PDF4 from "../assets/PDF FILE/DownloadPDF/PDF4.pdf";
import PDF5 from "../assets/PDF FILE/DownloadPDF/PDF5.pdf";
import PDF6 from "../assets/PDF FILE/DownloadPDF/PDF6.pdf";
import PDF7 from "../assets/PDF FILE/DownloadPDF/PDF7.pdf";
import PDF8 from "../assets/PDF FILE/DownloadPDF/PDF8.pdf";
import PDF9 from "../assets/PDF FILE/DownloadPDF/PDF9.pdf";
import PDF10 from "../assets/PDF FILE/DownloadPDF/PDF10.pdf";
import PDF11 from "../assets/PDF FILE/DownloadPDF/PDF11.pdf";
import PDF12 from "../assets/PDF FILE/DownloadPDF/PDF12.pdf";
import PDF13 from "../assets/PDF FILE/DownloadPDF/PDF13.pdf";
import PDF14 from "../assets/PDF FILE/DownloadPDF/PDF14.pdf";
import PDF15 from "../assets/PDF FILE/DownloadPDF/PDF15.pdf";
import PDF16 from "../assets/PDF FILE/DownloadPDF/PDF16.pdf";
import PDF17 from "../assets/PDF FILE/DownloadPDF/PDF17.pdf";
import PDF18 from "../assets/PDF FILE/DownloadPDF/PDF18.pdf";
import PDF21 from "../assets/PDF FILE/DownloadPDF/PDF19.pdf";
import PDF19 from "../assets/PDF FILE/DownloadPDF/ACT 1963 JNKVV_compressed.pdf";
import PDF20 from "../assets/PDF FILE/DownloadPDF/Statues JNKVV 1964_compressed.pdf";
import PDF22 from "../assets/PDF FILE/DownloadPDF/Revival_filling up posts 5 years MoF.fa3c1100.pdf";
import PDF_IMage from "../assets/images/pdf.png";
import { useState } from "react";

function Download() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const data = [
    {
      particular:
        "1st Permission for Research Associates Against the Post of Research Officer",
      pdf: PDF1,
    },
    {
      particular:
        "Cabinet Approves Continuation of Umbrella Scheme “Green Revolution-Krishonnati Yojna” in Agriculture Sector 2018",
      pdf: PDF2,
    },
    {
      particular:
        "DST Order-Revised Rate of Emoluments for Senior Research Fellow and Research Associates working in the ICAR Funded Scheme 2010",
      pdf: PDF3,
    },
    {
      particular:
        "DST Order-Revised Rate of Emoluments for Senior Research Fellow and Research Associates working in the ICAR Funded Scheme 2014",
      pdf: PDF4,
    },
    {
      particular:
        "HRD Order-Revised Rate of Emoluments for Senior Research Fellow and Research Associates working in the ICAR Funded Scheme 2019",
      pdf: PDF5,
    },
    {
      particular:
        "JNKVV  order-Revised Rate of Emoluments for Senior Research Fellow and Research Associates working in the ICAR Funded Scheme 2015",
      pdf: PDF6,
    },
    {
      particular:
        "MOA with covering letter regarding Seperate Identity of the Centre as a Department (Notification 23 August 1999)_removed",
      pdf: PDF7,
    },
    {
      particular:
        "Revised Rate of Emoluments for Senior Research Fellow and Research Associates working in the ICAR Funded Scheme 2008",
      pdf: PDF8,
    },
    {
      particular:
        "Revised Rate of Emoluments for Senior Research Fellow and Research Associates working in the ICAR Funded Scheme 2015",
      pdf: PDF9,
    },
    {
      particular:
        "Revised Rate of Emoluments for Senior Research Fellow and Research Associates working in the ICAR Funded Scheme 2019",
      pdf: PDF10,
    },
    {
      particular:
        "Revision in the Guidelines for  Senior Research Fellow and Research Associates working in the ICAR Scheme 2014",
      pdf: PDF11,
    },
    {
      particular:
        "Revision in the Guidelines for  Senior Research Fellow and Research Associates working in the ICAR Scheme 2015",
      pdf: PDF12,
    },
    {
      particular:
        "प्रशासनिक वैठक (189वीं) द्वारा रिसर्च एसोसिएट एवं सीनियर रिसर्च फेलो को अध्यन के दौरान ठहरने की पात्रता का अनुमोदन",
      pdf: PDF13,
    },
    {
      particular:
        "राज्य वेतन आयोग की अनुशंसाओं के परिपेक्ष में राज्य शासन के कर्मचारियों को देय यात्रा भत्ते की दरों में संसोधन  2015",
      pdf: PDF14,
    },
    {
      particular:
        "विश्वविद्यालय द्वारा रिसर्च एसोसिएट एवं सीनियर रिसर्च फेलो को राज्य शासन के द्वारा देय यात्रा भत्ते की दरों के अनुसार श्रेणी निर्धारण",
      pdf: PDF15,
    },
    {
      particular:
        "प्रबंध प्रमंडलकी_230वी_बैठक_में_विश्वविद्यालय_द्वारा_अंगीकृत_",
      pdf: PDF16,
    },
    {
      particular:
        "संविदा पर नियुक्त अधिकारीयों एवं कर्मचारियों को नियमित पदों पर नियुक्ति के अवसर प्रदान किय जाने हेतु नीति निर्देश",
      pdf: PDF17,
    },
    {
      particular:
        "संविदा पर नियुक्त अधिकारीयों एवं कर्मचारियों को नियमित पदों पर नियुक्ति के अवसर प्रदान किय जाने हेतु नीति निर्देश",
      pdf: PDF18,
    },
    {
      particular: "ACT 1993",
      pdf: PDF19,
    },
    {
      particular: "The Jawaharlal Nehru Krishi Vishwa Vidyalaya Statutes 1964",
      pdf: PDF20,
    },
    {
      particular:
        "CCS Order related to filling post permision from Government of MP",
      pdf: PDF21,
    },

    {
      particular:
        "Filling up Of Post Lying Vacant For Less Than 5 Years In AERCs/AERUs-regarding",
      pdf: PDF22,
    },
  ];
  const correctUsername = "AERC_JBP";
  const correctPassword = "556055";

  const handleLogin = () => {
    if (username === correctUsername && password === correctPassword) {
      setIsLoggedIn(true);
    } else {
      alert("invalid user");
    }
  };
  const table = data.map((item, index) => {
    return (
      <tr key={index}>
        <td className="text-center">{item.particular}</td>
        {data.pdf !== "Nill" ? (
          <td className="text-center">
            <a href={item.pdf}>
              <img src={PDF_IMage} style={{ cursor: "pointer" }} />
            </a>
          </td>
        ) : (
          <td>{data.pdf}</td>
        )}
      </tr>
    );
  });

  return (
    <>
      <div className="mt-2">
        {isLoggedIn ? (
          <section className="about section">
            <div className="container">
              <div className="">
                <div className="about-content">
                  <h3>Download</h3>
                  <table>
                    <tr>
                      <th>PARTICULAR</th>
                      <th>ATTACHED PDF</th>
                    </tr>
                    {table}
                  </table>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="login-section text-center ">
            <div className="container">
              <div className="login-form">
                <h3>Login</h3>
                <div className="form-group">
                  <label>Username:</label>

                  <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Password:</label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <button className="btn" onClick={handleLogin}>
                  Login
                </button>
              </div>
            </div>
          </section>
        )}
      </div>
      ;
    </>
  );
}

export default Download;
