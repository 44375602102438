/* eslint-disable */

import React from "react";
import { Link } from "react-router-dom";
import JNKVlogoHeader from "../assets/images/Group 2.png";
import JNKVSIDELOGO from "../assets/images/JNKVV-Logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlobe,
  faPaperclip,
  faPhotoFilm,
  faPlay,
  faRadiation,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faGalacticRepublic,
  faGooglePlus,
  faLinkedin,
  faReddit,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import Stucturepdf from "../assets/PDF FILE/Technical_Commety/Technical Committee Structure-pdf.pdf";
import AdvPdf from "../assets/PDF FILE/AdvisoryBoardMeeting/Advisory Body Structure (1).pdf";
// import { Link } from "react-router-dom";

function Header() {
  const reload = async () => {
    window.location.href = "/";
    console.log("reload");
    await window.location.reload();
  };
  return (
    <header id="site-header" className="site-header ">
      {/* <!-- Start Topbar --> */}
      <div className="header-main-top d-none d-md-block">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 text-left">
              <div className="lign-items-center">
                <div
                  className="header-top-library"
                  style={{ backgroundColor: "red" }}
                >
                  {/* <marquee direction="left" scrollamount="15">
                    <p style={{ color: "black", fontSize: "24px" }}>
                      Agro-Economic Research Centre, Jabalpur Organise Governing
                      Body Meeting (GBM) scheduled to be held on 14.02.2024 1st
                      Workshop on finalization of Methodology and Questionnaire
                      of the studies finalised in RAC for the work plan 2023-24
                      at Agro-Economic Research Centre, Jabalpur Madhya Pradesh
                      on 15-16 January 2024.
                    </p>
                  </marquee> */}
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
      <div className="topbar">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12">
              {/* <!-- Text --> */}
              <p>
                <a href="http://www.jnkvv.org/" target="_blank">
                  {" "}
                  <i className="fa  fa-globe"></i> University Portal{" "}
                </a>{" "}
                |
                <a href="#" target="_blank">
                  {" "}
                  <i className="">
                    <FontAwesomeIcon icon={faRadiation} />
                  </i>{" "}
                  News-Event{" "}
                </a>
                |
                <a href="#" target="_blank">
                  {" "}
                  <i className="">
                    <FontAwesomeIcon icon={faPhotoFilm} />
                  </i>{" "}
                  Media Gallery{" "}
                </a>
              </p>
              {/* <!--/ End Text --> */}
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              {/* <!-- Social --> */}
              <ul className="social">
                <li>
                  <a href="#">
                    <i className="fa fa-facebook">
                      <FontAwesomeIcon icon={faFacebook} />
                    </i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-linkedin">
                      <FontAwesomeIcon icon={faLinkedin} />
                    </i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-twitter">
                      <FontAwesomeIcon icon={faTwitter} />
                    </i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-reddit">
                      <FontAwesomeIcon icon={faReddit} />
                    </i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-google-plus">
                      <FontAwesomeIcon icon={faGooglePlus} />
                    </i>
                  </a>
                </li>
              </ul>
              {/* <!--/ End Social --> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!--/ End Topbar --> */}
      {/* <!-- Middle Header --> */}
      <div className="middle-header">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-9 col-12">
              {/* <!-- Logo --> */}

              <div className="logo">
                {" "}
                <a href="index.html">
                  <img src={JNKVlogoHeader} alt="logo" />
                </a>{" "}
              </div>
              {/* <!--/ End Logo --> */}
              <div className="mobile-nav"></div>
              <div></div>
            </div>
            <div className="col-lg-3 col-md-3 col-12">
              {/* <!-- Header Widget --> */}
              <div className="header-widget">
                {/* <!--/ End Single Widget --> */}
                {/* <!-- Single Widget --> */}
                <div className="col-lg-9 col-md-9 col-12">
                  {/* <!-- Logo --> */}
                  <div className="logo">
                    {" "}
                    <a href="index.html">
                      <img
                        src={JNKVSIDELOGO}
                        alt="logo"
                        style={{ width: "173px", height: "123px" }}
                      />
                    </a>{" "}
                  </div>
                  {/* <img src={JNKVSIDELOGO} alt="logo" /></a> </div> */}
                  {/* <!--/ End Logo --> */}
                  <div className="mobile-nav"></div>
                </div>
                {/* <!--/ End Single Widget --> */}
              </div>
              {/* <!--/ End Header Widget --> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Middle Header --> */}
      {/* <!-- Header Bottom --> */}
      <div className="header-bottom">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {/* <!-- Main Menu --> */}
              <div className="main-menu">
                <nav className="navigation">
                  <ul className="nav menu">
                    <li className="" onClick={reload}>
                      <Link to="/">Home</Link>
                      <ul className="dropdown">
                        <li>
                          <Link to="aboutAerc">About AERC </Link>
                        </li>
                        <li>
                          <Link to="mandate">Mandate</Link>
                        </li>
                        <li>
                          <Link to="areaOperation">Area of Operation</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="#">Governance </a>
                      <ul className="dropdown">
                        <li>
                          <a>Advisory Body Meeting </a>
                          <ul className="dropdown-sub">
                            <li>
                              <Link to="ABMStructure">Structure</Link>
                            </li>
                            <li>
                              <Link to="advBodyMeeting">Meetings Detail</Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a href="">Technical Committee Meeting</a>
                          <ul className="dropdown-sub">
                            <li>
                              <Link to="reviewStructure">Structure</Link>
                            </li>
                            <li>
                              <Link to="techBodyMeeting">Meetings Detail </Link>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <Link to="racMeetings">RAC/Review Meeting </Link>
                        </li>
                        <li>
                          <Link to="otherMeeting">Other Meetings </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="#">Organization Structure</a>
                      <ul className="dropdown">
                        <li>
                          <Link to="staffingPattern">
                            Staff Position/Pattern
                          </Link>
                        </li>
                        <li>
                          <Link to="contactUS">Staff Contact Details</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="#">Research </a>
                      <ul className="dropdown">
                        <li>
                          <Link to="completedStudies">
                            AERC Completed Research
                          </Link>
                        </li>
                        <li>
                          <Link to="vilageStudies">Village Studies</Link>
                        </li>
                        <li>
                          <a href="#"> External Funded Studied</a>
                          <ul className="dropdown-sub">
                            <li>
                              <Link to="externalFunded">
                                Detail of External Funded Projects
                              </Link>
                            </li>
                            <li>
                              <Link to="externalFundlist">
                                list of the External Funded studies
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link to="ongoingstudies"> On-Going Studies</Link>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <a href="#">Publication</a>
                      <ul className="dropdown">
                        <li>
                          <Link to="JournalArticle">Journal Article</Link>
                        </li>
                        <li>
                          <Link to="policyalert">
                            Agro Economic Policy Brief/Alerts
                          </Link>
                        </li>
                        <li>
                          <Link to="book_chapter">Book Chapter</Link>
                        </li>

                        <li>
                          <a href="#" target="_blank">
                            Working Paper
                          </a>
                        </li>
                        <li>
                          <a href="#" target="_blank">
                            Magazine Article
                          </a>
                        </li>
                        <li>
                          <Link to="paper">Paper Presented in Seminar</Link>
                        </li>
                        <li>
                          <Link to="abstract">Abstract</Link>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <a href="#">Others Activities</a>
                      <ul className="dropdown">
                        <li>
                          <Link to="student_research">Student Research </Link>
                        </li>
                        <li>
                          <Link to="conference">Conferences/Workshop </Link>
                        </li>
                        <li>
                          <Link to="capacity">
                            Capacity building Programme and trainings
                          </Link>
                        </li>
                        <li>
                          <Link to="achievment">
                            Achievement Award by Faculty Member
                          </Link>
                        </li>
                        <li>
                          <Link to="annual_membership">
                            Annual Membership of the Societies
                          </Link>
                        </li>
                        <li>
                          <a href="#" target="_blank">
                            Visited Dignitary/Person
                          </a>
                        </li>

                        <li>
                          <Link to="credit_seminar">
                            Credit Seminar/ResultSeminar
                          </Link>
                        </li>
                        <li>
                          <Link to="lecture">Guest Lecture </Link>
                        </li>
                        <li>
                          <Link to="course_alloted">Courses Allotted</Link>
                        </li>
                        <li>
                          <Link to="Staff_engaging">
                            Staff attached with institute in other activities
                          </Link>
                        </li>
                        <li>
                          <Link to="human">Human Resource Development </Link>
                        </li>
                        <li>
                          <Link to="research_highlight">
                            Research Highlight{" "}
                          </Link>
                        </li>
                        <li>
                          <Link to="communication">
                            Communication With AERC Division/AERC Institutes{" "}
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="">important</a>
                      <ul className="dropdown">
                        <li>
                          {" "}
                          <Link to="quickLinks">vacncy</Link>
                        </li>
                        <li>
                          <Link to="quickLinks">news</Link>
                        </li>
                        <li>
                          <Link to="Download">download</Link>
                        </li>
                        <li>
                          <Link to="directory">directory</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="quickLinks">Quick Link</Link>
                    </li>
                    <li>
                      <Link to="contactDetails">Contact Us</Link>
                    </li>
                  </ul>
                </nav>
              </div>
              {/* <!--/ End Main Menu --> */}
              {/* <!-- Search Form --> */}

              {/* <!--/ End Search Form --> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!--/ End Header Bottom --> */}
    </header>
  );
}
export default Header;
