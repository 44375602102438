import React, { Component } from "react";

import "../assets/style.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faPaperclip,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import { useState } from "react";

function QuickLinks() {
  // const [selectedOption, setSelectedOption] = useState(null);

  return (
    <div>
      <section class="about section">
        <div class="container">
          <div class="row">
            {/* <div class="col-lg-6 col-md-12 col-sm-12"> */}
            <div class="about-content">
              <h3>
                Quick <span>Link</span>
              </h3>
            </div>
          </div>
        </div>
      </section>
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div className="quick_links">
              {/* <h3 className="abm_table">Advisory Body</h3> */}
              <ul>
                <li>
                  <a href="https://www.nabard.org">
                    <i class="fa fa-caret-right" aria-hidden="true"></i> NABARD
                  </a>
                </li>
                <li>
                  <a href="https://www.niti.gov.in">
                    <i class="fa fa-caret-right" aria-hidden="true"></i> NITI
                    Agog
                  </a>
                </li>
                <li>
                  <a href="https://www.nddb.coop">
                    <i class="fa fa-caret-right" aria-hidden="true"></i>{" "}
                    National Dairy Development Board's NDDB
                  </a>
                </li>
                <li>
                  <a href="https://dahd.nic.in/about-us/divisions/statistics">
                    <i class="fa fa-caret-right" aria-hidden="true"></i> ANIMAL
                    HUSBANDRY STATISTICS
                  </a>
                </li>
                <li>
                  <a href="https://censusindia.gov.in">
                    <i class="fa fa-caret-right" aria-hidden="true"></i> Census
                    India
                  </a>
                </li>
                <li>
                  <a href="http://mpkrishi.mp.gov.in">
                    <i class="fa fa-caret-right" aria-hidden="true"></i> Farmers
                    Welfare and Agriculture Development Department Madhya
                    Pradesh
                  </a>
                </li>
                <li>
                  <a href="https://www.mpdage.org">
                    <i class="fa fa-caret-right" aria-hidden="true"></i>{" "}
                    Directorate of Agricultural Engineering
                  </a>
                </li>
                <li>
                  <a href="http://nhb.gov.in">
                    <i class="fa fa-caret-right" aria-hidden="true"></i>{" "}
                    National Horticulture Board
                  </a>
                </li>
                <li>
                  <a href="https://www.mpssca.org">
                    <i class="fa fa-caret-right" aria-hidden="true"></i> Madhya
                    Pradesh State Seed Certification Society
                  </a>
                </li>
                <li>
                  <a href="https://www.indiaseeds.com">
                    <i class="fa fa-caret-right" aria-hidden="true"></i>{" "}
                    National Seeds Corporation Limited
                  </a>
                </li>
                <li>
                  <a href="http://nsrtc.nic.in">
                    <i class="fa fa-caret-right" aria-hidden="true"></i>{" "}
                    National Seed Research and Training Centre (NSRTC)
                  </a>
                </li>
                <li>
                  <a href="https://seednet.gov.in">
                    <i class="fa fa-caret-right" aria-hidden="true"></i> Seednet
                    India Porta
                  </a>
                </li>
                <li>
                  <a href="https://pfms.nic.in">
                    <i class="fa fa-caret-right" aria-hidden="true"></i> Public
                    Financial Management System
                  </a>
                </li>
                <li>
                  <a href="https://eands.dacnet.nic.in">
                    <i class="fa fa-caret-right" aria-hidden="true"></i>{" "}
                    Directorate Of Economics and Statistics
                  </a>
                </li>
                <li>
                  <a href="http://jnkvv.org">
                    <i class="fa fa-caret-right" aria-hidden="true"></i>{" "}
                    Jawaharlal Nehru Krishi Vishwavidyalaya
                  </a>
                </li>
                <li>
                  <a href="https://www.rvskvv.net">
                    <i class="fa fa-caret-right" aria-hidden="true"></i> RVSKVV
                    Gwalior
                  </a>
                </li>
                <li>
                  <a href="https://igkv.ac.in/web/igkv.aspx">
                    <i class="fa fa-caret-right" aria-hidden="true"></i> Indira
                    Gandhi Agricultural University Public university in Raipur,
                    Chhattisgarh
                  </a>
                </li>
                <li>
                  <a href="https://www.iima.ac.in">
                    <i class="fa fa-caret-right" aria-hidden="true"></i> Indian
                    Institute of Management Ahmedabad (IIMA)
                  </a>
                </li>
                <li>
                  <a href="http://iegindia.org/">
                    <i class="fa fa-caret-right" aria-hidden="true"></i>{" "}
                    Institute Of Economic Growth
                  </a>
                </li>
                <li>
                  <a href="http://www.isec.ac.in/">
                    <i class="fa fa-caret-right" aria-hidden="true"></i>{" "}
                    Institute for Social and Economic Change
                  </a>
                </li>
                <li>
                  <a href="https://icar.org.in/">
                    <i class="fa fa-caret-right" aria-hidden="true"></i> The
                    Indian Council of Agricultural Research (ICAR)
                  </a>
                </li>
                <li>
                  <a href="https://www.nabard.org">
                    <i class="fa fa-caret-right" aria-hidden="true"></i> The
                    National Bank for Agriculture and Rural Development (NABARD)
                  </a>
                </li>
                <li>
                  <a href="https://agmarknet.gov.in">
                    <i class="fa fa-caret-right" aria-hidden="true"></i>{" "}
                    Agricultural Marketing Information Network (AGMARKNET)
                  </a>
                </li>
                <li>
                  <a href="https://pmkisan.gov.in/">
                    <i class="fa fa-caret-right" aria-hidden="true"></i> PM
                    KISAN SAMMAN NIDHI
                  </a>
                </li>
                <li>
                  <a href="https://www.fert.nic.in/home-page">
                    <i class="fa fa-caret-right" aria-hidden="true"></i>{" "}
                    Government Of India Department Of Fertilizers
                  </a>
                </li>
                <li>
                  <a href="https://www.nfsm.gov.in/">
                    <i class="fa fa-caret-right" aria-hidden="true"></i> NFSM
                  </a>
                </li>
                <li>
                  <a href="https://pmksy.gov.in/">
                    <i class="fa fa-caret-right" aria-hidden="true"></i> PMKSY
                  </a>
                </li>
                <li>
                  <a href="https://pmksy.nic.in/">
                    <i class="fa fa-caret-right" aria-hidden="true"></i>{" "}
                    PMKSY(Per Drop More Crop)
                  </a>
                </li>
                <li>
                  <a href="https://soilhealth.dac.gov.in/home">
                    <i class="fa fa-caret-right" aria-hidden="true"></i> Soil
                    Health Card
                  </a>
                </li>
                <li>
                  <a href="https://nhrdf.org/index.php">
                    <i class="fa fa-caret-right" aria-hidden="true"></i>{" "}
                    National Horticultural Research And Development Foundation
                  </a>
                </li>
                <li>
                  <a href="https://mplandrecord.co.in/">
                    <i class="fa fa-caret-right" aria-hidden="true"></i> MP Land
                    Record
                  </a>
                </li>
                <li>
                  <a href="https://nmoop.gov.in">
                    <i class="fa fa-caret-right" aria-hidden="true"></i>NMEO
                  </a>
                </li>
                <li>
                  <a href="https://www.farmer.gov.in/">
                    <i class="fa fa-caret-right" aria-hidden="true"></i>{" "}
                    Farmer's Portal
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Start Clients --> */}
      {/* <Footer /> */}
    </div>
  );
}

export default QuickLinks;
