/* eslint-disable */

import React from "react";
import Footer from "./footer";
import Header from "./header";
import Slider8 from "../assets/images/slider/8.jpg";
import "../assets/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faPaperclip,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import PDF_IMage from "../assets/images/pdf.png";
import PDF_1 from "../assets/PDF FILE/RAC/1 Mintues Final_3rd RAC Meeting_FINAL 27 Aug 2021.pdf";
import PDF_2 from "../assets/PDF FILE/RAC/2 Minutes of Directors Meeting held on 27th August 2021.pdf";
import PDF_3 from "../assets/PDF FILE/RAC/3 RAC Meeting (Workplan 2019-20).pdf";
import PDF_4 from "../assets/PDF FILE/RAC/4 CCCS meeting 2015.pdf";
import PDF_5 from "../assets/PDF FILE/RAC/5 CCOS Meeting 2013.pdf";
import { CSVLink } from "react-csv";

import { useState } from "react";
import Select from "react-select";

export default function Conference_workshop() {
  const [fac_name, setfac_name] = useState("");
  const [year, setyear] = useState("");
  const [month, setmonth] = useState("");
  const fac_opt = [
    { label: "select", value: "" },
    { label: "Niranjan", value: "Niranjan" },
    { label: "Bareliya", value: "Bareliya" },
    { label: "Patidar", value: "Patidar" },
    { label: "Pandey", value: "Pandey" },
    { label: "Sharma", value: "Sharma" },
    { label: "Thakur", value: "Thakur" },
    { label: "Pandey", value: "Pandey" },
    { label: "Patidar", value: "Patidar" },
    { label: "Kuril", value: "Kuril" },
    { label: "Bareliya", value: "Bareliya" },
    { label: "Rathi", value: "Rathi" },
    { label: "Birla", value: "Birla" },
    { label: "Osari", value: "Osari" },
  ];

  const Data = [
    {
      Faculty_Name: "H.K.Niranjan",
      Year: "2010",
      Title:
        "Application of Space Technology for Development of Vindhya Region",
      Organized_by:
        "A.P.S. University, Rewa, (M.P.).\nIndian Society of Remote Sensing, Bhopal Chapter",
      From: "16",
      To: "--",
      Filter_Month: "April",
    },
    {
      Faculty_Name: "H.K.Niranjan",
      Year: "2011",
      Title:
        "राष्ट्रीय संगोष्ठि मध्य प्रदेश का आर्थिक विकास एवं ग्रामीण उद्यमिता % चुनौतिंयां एवं संभावनाएं",
      Organized_by: "विश्वविद्यालय अनुदान आयोग, मध्य क्षेत्रीय कार्यालय, भोपाल",
      From: "2",
      To: "3",
      Filter_Month: "April",
    },
    {
      Faculty_Name: "H.K.Niranjan",
      Year: "2013",
      Title: "Swami VevekanandJiKeSapnoKa Bharat",
      Organized_by: "Deendayal Research Institute, Chitrakoot, Satana (M.P.).",
      From: "11",
      To: "12",
      Filter_Month: "February",
    },
    {
      Faculty_Name: "H.K.Niranjan",
      Year: "2014",
      Title: "राष्ट्रीय हिंदी विज्ञान  सम्मेलन",
      Organized_by: "मध्य प्रदेश प्रोधोगिकी परिषद भोपाल, मध्य प्रदेश",
      From: "1",
      To: "2",
      Filter_Month: "August",
    },
    {
      Faculty_Name: "H.K.Niranjan",
      Year: "2014",
      Title: "Peri-Urban Agriculture for Improoving Livelihood Opportunities",
      Organized_by:
        "Institute of Financial Management Training & Research, Lucknow (U.P.)\nSamagraVikas Welfare Society\nLucknow (U.P.)",
      From: "25",
      To: "26",
      Filter_Month: "November",
    },
    {
      Faculty_Name: "Rajendra Singh Bareliya",
      Year: "2014",
      Title:
        "Challenges and opportunities for agricultural crop productivity under climate change",
      Organized_by: "College of agriculture, Rewa, JNKVV, Jabalpur",
      From: "21",
      To: "22",
      Filter_Month: "September",
    },
    {
      Faculty_Name: "H.K.Niranjan",
      Year: "2015",
      Title:
        "Inter- and Intra- Sectoral Dynamic for Transforming Indian Agriculture",
      Organized_by:
        "Agricultural Economics Research Review, New Delhi\nICAR-IIFE, Mumbai, India",
      From: "2",
      To: "4",
      Filter_Month: "December",
    },
    {
      Faculty_Name: "H.K.Niranjan",
      Year: "2016",
      Title:
        "Information and Communication Management Concerning Climate Smart Agriculture for Sustainable Development and Poverty Alleviation",
      Organized_by:
        "The Indian Society of Extension Education, New Delhi\nRVSKVV, Gwalior (M.P.)",
      From: "28",
      To: "30",
      Filter_Month: "November",
    },
    {
      Faculty_Name: "H.K.Niranjan",
      Year: "2018",
      Title:
        "Global Research initiative for Sustainable Agriculture & Allied Science",
      Organized_by:
        "Rajasthan Agricultural Research Institute Durgapura, Jaipur, Rajasthan\nAshta Foundation. Meerut (U.P.)",
      From: "28",
      To: "30",
      Filter_Month: "Octomber",
    },
    {
      Faculty_Name: "Pradeep Kumar Patidar",
      Year: "2018",
      Title:
        "National conference on Techno- Strategic Interventions for Profitable Agriculture",
      Organized_by:
        "Acharya N.G. Ranga Agricultural University, Agricultural College, Bapatla, Gunture (A.P.)",
      From: "26",
      To: "27",
      Filter_Month: "March",
    },
    {
      Faculty_Name: "Prem Ratan Pandey",
      Year: "2018",
      Title: "Conservation Agriculture",
      Organized_by: "School of Agriculture, ITM University Gwalior",
      From: "22",
      To: "23",
      Filter_Month: "February",
    },
    {
      Faculty_Name: "Satyendra Singh Thakur",
      Year: "2018",
      Title: "Global Peace Confrence",
      Organized_by: "JNKVV, Jabalpur",
      From: "25",
      To: "-",
      Filter_Month: "February",
    },
    {
      Faculty_Name: "Sharma, H. O.",
      Year: "2018",
      Title:
        "Enabling Farmers Profit & Prosper-Indian Agricultural Outlook Forum",
      Organized_by: "IEG New Delhi",
      From: "10",
      To: "11",
      Filter_Month: "September",
    },
    {
      Faculty_Name: "Pradeep Kumar Patidar",
      Year: "2019",
      Title: "National seminar on Advance and Challenges in Horticulture",
      Organized_by: "Department of Horticulture, JNKVV",
      From: "26",
      To: "27",
      Filter_Month: "February",
    },
    {
      Faculty_Name: "Pradeep Kumar Patidar",
      Year: "2019",
      Title:
        "National conference on biotechnology and sustainable agriculture in doubling of farmer’s income by 2022.",
      Organized_by: "AKS University, Satna (M.P.)",
      From: "5",
      To: "7",
      Filter_Month: "September",
    },
    {
      Faculty_Name: "Rajendra Singh Bareliya",
      Year: "2019",
      Title:
        "National conference on biotechnology and sustainable agriculture in doubling of farmer’s income by 2022.",
      Organized_by: "AKS university, Satna Madhya Pradesh",
      From: "5",
      To: "7",
      Filter_Month: "September",
    },
    {
      Faculty_Name: "Rajendra Singh Bareliya",
      Year: "2019",
      Title: "Advancees and Challenges in Horticulture",
      Organized_by:
        "Department of Horticulture, College of Agriculture, Jabalpur",
      From: "26",
      To: "27",
      Filter_Month: "February",
    },
    {
      Faculty_Name: "Satyendra Singh Thakur",
      Year: "2019",
      Title: "Advancees and Challenges in Horticulture",
      Organized_by: "JNKVV, Jabalpur",
      From: "26",
      To: "27",
      Filter_Month: "February",
    },
    {
      Faculty_Name: "H.K.Niranjan",
      Year: "2020",
      Title:
        "Enhancing Soft Skill and Entrepreneurial Traits in Teaching and Learning Methodology",
      Organized_by: "LalaLajpatrai College of Commerce and Economics",
      From: "18",
      To: "20",
      Filter_Month: "May",
    },
    {
      Faculty_Name: "Pradeep Kumar Patidar",
      Year: "2020",
      Title: "Emerging trends of live Stocks in Self reliance of India",
      Organized_by: "MGCGV, Chitrakoot, Satna (M.P.)",
      From: "1",
      To: "-",
      Filter_Month: "June",
    },
    {
      Faculty_Name: "Pradeep Kumar Patidar",
      Year: "2020",
      Title:
        "Approaches Toward Development of Rural and Agriculture Sector in The Present Scenario",
      Organized_by: "JNKVV, College of Agriculture, Tikamgarh (M.P.)",
      From: "8",
      To: "9",
      Filter_Month: "May",
    },
    {
      Faculty_Name: "Pradeep Kumar Patidar",
      Year: "2020",
      Title:
        "Strengthening the immune system against COVID-19 through Agriculture innovations",
      Organized_by:
        "College of Agriculture, (Sri Karan Narendra Agriculture University, Jobner)",
      From: "29",
      To: "-",
      Filter_Month: "July",
    },
    {
      Faculty_Name: "Pradeep Kumar Patidar",
      Year: "2020",
      Title:
        "Agricultural Development And Covid-19: Economic And Social Challenges",
      Organized_by:
        "Department of Agricultural Economics, Udai Pratap College, Varanasi (Autonomous)",
      From: "8",
      To: "-",
      Filter_Month: "June",
    },
    {
      Faculty_Name: "Rajendra Singh Bareliya",
      Year: "2020",
      Title:
        "“Herbal Nutritional Supplements for Immunity Development against Covid-19”.",
      Organized_by: "MGCGV, Chitrakoot, Satna (M.P.)",
      From: "27",
      To: "-",
      Filter_Month: "May",
    },
    {
      Faculty_Name: "Rajendra Singh Bareliya",
      Year: "2020",
      Title: "Webinar on Online Tools for Effective Teaching and Learning.",
      Organized_by: "MGCGV, Chitrakoot, Satna (M.P.)",
      From: "20",
      To: "21",
      Filter_Month: "May",
    },
    {
      Faculty_Name: "Rajendra Singh Bareliya",
      Year: "2020",
      Title:
        "Building Agrienterprenurship skill in students -A way of forward.",
      Organized_by: "Medicap University Indore (M.P.)",
      From: 1,
      To: "-",
      Filter_Month: "August",
    },
    {
      Faculty_Name: "H.K.Niranjan",
      Year: "2021",
      Title:
        "Sustainable pulses production for self sufficiency and nutritional security",
      Organized_by: "Indian Society of Pulses Research & Development & ICAR",
      From: "9",
      To: "11",
      Filter_Month: "February",
    },
    {
      Faculty_Name: "H.K.Niranjan",
      Year: "2023",
      Title:
        "National Conference on Production, Processing and Marketing of Millets : Issue and Solutions",
      Organized_by: "College of Agriculture, JNKVV, Jabalpur",
      From: "1",
      To: "2",
      Filter_Month: "March",
    },
    {
      Faculty_Name: "P.K.Patidar",
      Year: "2023",
      Title:
        "National Conference on Production, Processing and Marketing of Millets : Issue and Solutions",
      Organized_by: "College of Agriculture, JNKVV, Jabalpur",
      From: "1",
      To: "2",
      Filter_Month: "March",
    },
    {
      Faculty_Name: "P.R.Pandey",
      Year: "2023",
      Title:
        "National Conference on Production, Processing and Marketing of Millets : Issue and Solutions",
      Organized_by: "College of Agriculture, JNKVV, Jabalpur",
      From: "1",
      To: "2",
      Filter_Month: "March",
    },
    {
      Faculty_Name: "Akhilesh Kuril",
      Year: "2023",
      Title:
        "National Conference on Production, Processing and Marketing of Millets : Issue and Solutions",
      Organized_by: "College of Agriculture, JNKVV, Jabalpur",
      From: "1",
      To: "2",
      Filter_Month: "March",
    },
    {
      Faculty_Name: "R.S.Bareliya",
      Year: "2023",
      Title:
        "National Conference on Production, Processing and Marketing of Millets : Issue and Solutions",
      Organized_by: "College of Agriculture, JNKVV, Jabalpur",
      From: "1",
      To: "2",
      Filter_Month: "March",
    },
    {
      Faculty_Name: "Deepak Rathi",
      Year: "2023",
      Title:
        "National Conference on Production, Processing and Marketing of Millets : Issue and Solutions",
      Organized_by: "College of Agriculture, JNKVV, Jabalpur",
      From: "1",
      To: "2",
      Filter_Month: "March",
    },
    {
      Faculty_Name: "Rahul Birla",
      Year: "2023",
      Title:
        "National Conference on Production, Processing and Marketing of Millets : Issue and Solutions",
      Organized_by: "College of Agriculture, JNKVV, Jabalpur",
      From: "1",
      To: "2",
      Filter_Month: "March",
    },
    {
      Faculty_Name: "B.L.Osari",
      Year: "2023",
      Title:
        "National Conference on Production, Processing and Marketing of Millets : Issue and Solutions",
      Organized_by: "College of Agriculture, JNKVV, Jabalpur",
      From: "1",
      To: "2",
      Filter_Month: "March",
    },
    {
      Faculty_Name: "H.K.Niranjan",
      Year: "2023",
      Title:
        "Regional Conference on Recent Innovations in Agricultural Sector: Preliminary Insights and Emerging Perspectives",
      Organized_by:
        "Indian Society of Agricultural Economics, Mumbai & Department of Agriculture Economics &FM, JNKVV, Jabalpur",
      From: "29",
      To: "30",
      Filter_Month: "May",
    },
    {
      Faculty_Name: "P.K.Patidar",
      Year: "2023",
      Title:
        "Regional Conference on Recent Innovations in Agricultural Sector: Preliminary Insights and Emerging Perspectives",
      Organized_by:
        "Indian Society of Agricultural Economics, Mumbai & Department of Agriculture Economics &FM, JNKVV, Jabalpur",
      From: "29",
      To: "30",
      Filter_Month: "May",
    },
    {
      Faculty_Name: "P.R.Pandey",
      Year: "2023",
      Title:
        "Regional Conference on Recent Innovations in Agricultural Sector: Preliminary Insights and Emerging Perspectives",
      Organized_by:
        "Indian Society of Agricultural Economics, Mumbai & Department of Agriculture Economics &FM, JNKVV, Jabalpur",
      From: "29",
      To: "30",
      Filter_Month: "May",
    },
    {
      Faculty_Name: "Akhilesh Kuril",
      Year: "2023",
      Title:
        "Regional Conference on Recent Innovations in Agricultural Sector: Preliminary Insights and Emerging Perspectives",
      Organized_by:
        "Indian Society of Agricultural Economics, Mumbai & Department of Agriculture Economics &FM, JNKVV, Jabalpur",
      From: "29",
      To: "30",
      Filter_Month: "May",
    },
    {
      Faculty_Name: "R.S.Bareliya",
      Year: "2023",
      Title:
        "Regional Conference on Recent Innovations in Agricultural Sector: Preliminary Insights and Emerging Perspectives",
      Organized_by:
        "Indian Society of Agricultural Economics, Mumbai & Department of Agriculture Economics &FM, JNKVV, Jabalpur",
      From: "29",
      To: "30",
      Filter_Month: "May",
    },
    {
      Faculty_Name: "Deepak Rathi",
      Year: "2023",
      Title:
        "Regional Conference on Recent Innovations in Agricultural Sector: Preliminary Insights and Emerging Perspectives",
      Organized_by:
        "Indian Society of Agricultural Economics, Mumbai & Department of Agriculture Economics &FM, JNKVV, Jabalpur",
      From: "29",
      To: "30",
      Filter_Month: "May",
    },
    {
      Faculty_Name: "Rahul Birla",
      Year: "2023",
      Title:
        "Regional Conference on Recent Innovations in Agricultural Sector: Preliminary Insights and Emerging Perspectives",
      Organized_by:
        "Indian Society of Agricultural Economics, Mumbai & Department of Agriculture Economics &FM, JNKVV, Jabalpur",
      From: "29",
      To: "30",
      Filter_Month: "May",
    },
    {
      Faculty_Name: "B.L.Osari",
      Year: "2023",
      Title:
        "Regional Conference on Recent Innovations in Agricultural Sector: Preliminary Insights and Emerging Perspectives",
      Organized_by:
        "Indian Society of Agricultural Economics, Mumbai & Department of Agriculture Economics &FM, JNKVV, Jabalpur",
      From: "29",
      To: "30",
      Filter_Month: "May",
    },
    {
      Faculty_Name: "P.R.Pandey",
      Year: "2024",
      Title:
        "Driving Agriculture Growth: Contemporary Issues and Future Perspectives",
      Organized_by:
        "Agricultural Development and Rural Transformation Centre (ADRTC) & The Institute for Social and Economic Change (ISEC), Bengaluru, Karnataka",
      From: "23",
      To: "25",
      Filter_Month: "January",
    },
    {
      Faculty_Name: "Deepak Rathi",
      Year: "2024",
      Title:
        "Driving Agriculture Growth: Contemporary Issues and Future Perspectives",
      Organized_by:
        "Agricultural Development and Rural Transformation Centre (ADRTC) & The Institute for Social and Economic Change (ISEC), Bengaluru, Karnataka",
      From: "23",
      To: "25",
      Filter_Month: "January",
    },
    {
      Faculty_Name: "P.K.Patidar",
      Year: "2024",
      Title:
        "Driving Agriculture Growth: Contemporary Issues and Future Perspectives",
      Organized_by:
        "Agricultural Development and Rural Transformation Centre (ADRTC) & The Institute for Social and Economic Change (ISEC), Bengaluru, Karnataka",
      From: "23",
      To: "25",
      Filter_Month: "January",
    },
  ];
  const year_opt = [
    { value: "", label: "select" },

    { label: "2010", value: "2010" },
    { label: "2011", value: "2011" },
    { label: "2013", value: "2013" },
    { label: "2014", value: "2014" },
    { label: "2015", value: "2015" },
    { label: "2016", value: "2016" },
    { label: "2018", value: "2018" },
    { label: "2019", value: "2019" },
    { label: "2020", value: "2020" },
    { label: "2021", value: "2021" },
    { label: "2023", value: "2023" },
  ];
  const month_opt = [
    { value: "", label: "select" },
    { label: "April", value: "April" },
    { label: "February", value: "February" },
    { label: "August", value: "August" },
    { label: "November", value: "November" },
    { label: "September", value: "September" },
    { label: "December", value: "December" },
    { label: "Octomber", value: "Octomber" },
    { label: "March", value: "March" },
    { label: "May", value: "May" },
    { label: "June", value: "June" },
    { label: "July", value: "July" },
    { label: "August", value: "August" },
  ];

  const tabledata = Data.filter(
    (item) =>
      item.Faculty_Name.includes(fac_name) &&
      item.Year.includes(year) &&
      item.Filter_Month.includes(month)
  );

  const newdata = tabledata.map((item, i) => {
    return (
      <tr key={i}>
        <td>{item.Faculty_Name}</td>
        <td>{item.Year}</td>
        <td>{item.Title}</td>
        <td>{item.Organized_by}</td>
        <td>{item.From}</td>
        <td>{item.To}</td>
        <td>{item.Filter_Month}</td>
      </tr>
    );
  });
  return (
    <div>
      <section class="about section">
        <div class="container">
          <div class="row">
            <div class="about-content">
              <h3>Conference And Workshop</h3>
              <section class="blog-area">
                <div class="container pt-20">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <div class="finder-journal">
                        <ul>
                          <li class="rating">
                            <Select
                              placeholder="Select Faculty "
                              onChange={(e) => {
                                setfac_name(e.value);
                                setmonth("");
                              }}
                              options={fac_opt}
                            />
                          </li>
                          <li>
                            <Select
                              placeholder="Select Year "
                              onChange={(e) => {
                                setyear(e.value);
                              }}
                              options={year_opt}
                            />
                          </li>

                          <li class="rating">
                            <Select
                              placeholder="Select Month"
                              onChange={(e) => {
                                setmonth(e.value);
                                setfac_name("");
                              }}
                              options={month_opt}
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="rac_meeting">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <table
                      style={{
                        width: "100%",
                        marginLeft: "3px",
                        marginTop: "9px",
                      }}
                    >
                      <tr>
                        <th>Faculty Name</th>
                        <th>Year</th>
                        <th>Title</th>
                        <th>Organized by</th>
                        <th colSpan={2}>
                          Duration
                          <tr>
                            <th>FROM</th>
                            <th>To</th>
                          </tr>
                        </th>

                        <th>Month</th>
                      </tr>
                      {newdata}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button className="btn ">
            <CSVLink data={tabledata} filename="test.csv" headers={header}>
              download
            </CSVLink>
          </button>
        </div>
      </section>
    </div>
  );
}
const header = [
  { label: "Faculty_Name", key: "Faculty_Name" },
  { label: "Year", key: "Year" },
  { label: "Title", key: "Title" },
  { label: "Organized_by", key: "Organized_by" },
  { label: "From", key: "From" },
  { label: "To", key: "To" },
  { label: "Filter_Month", key: "Filter_Month" },
];
