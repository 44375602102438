/* eslint-disable */
import React, { Component } from "react";
import Footer from "./footer";
import Header from "./header";
import Slider8 from "../assets/images/slider/8.jpg";
import "../assets/style.css";
import CGMap from "../assets/images/3 CG.png";
import IndiaMap from "../assets/images/1 map_of_India (1).jpg";
import MPMap from "../assets/images/2 MP (1).png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faChevronRight,
  faPaperclip,
  faPlay,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import "./slider-animations.css";

function AreaOperation() {
  const slides = [];

  return (
    <div>
      {/* <Header /> */}

      <section className="about section">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-6 col-md-12 col-sm-12"> */}
            <div className="about-content">
              <h3>
                Area of <span>Operation</span>
              </h3>
              <p>
                The area of operation of the Agro-Economic Research Centre, J.
                N. Krishi Vishwa Vidyalaya University Jabalpur is the states of
                Madhya Pradesh and Chhattisgarh and deals in details,
                Agro-Economic problems related to Madhya Pradesh and
                Chhattisgarh State.
              </p>
              <br></br>
              <br></br>
              <div className="row">
                <>
                  <div className="container">
                    <Slider>
                      <a href="">
                        <img
                          id="ctl00_ContentPlaceHolder1_imgMapSolarSystem"
                          src={IndiaMap}
                          usemap="#ImageMapctl00_ContentPlaceHolder1_imgMapSolarSystem"
                          alt="#"
                        />
                      </a>
                      <a href="#" target="_blank">
                        <img src={MPMap} alt="#" />
                      </a>
                      <a href="#" target="_blank">
                        <img src={CGMap} alt="#" />
                      </a>
                    </Slider>

                    <map
                      name="ImageMapctl00_ContentPlaceHolder1_imgMapSolarSystem"
                      id="ImageMapctl00_ContentPlaceHolder1_imgMapSolarSystem"
                    >
                      <area
                        shape="poly"
                        coords="130, 92, 120, 89, 120, 85, 116, 84, 110, 81, 105, 76, 105, 63, 103, 51, 110, 45, 114, 43, 111, 39, 108, 37, 107, 33, 102, 33, 99, 29, 92, 29, 91, 20, 102, 11, 112, 11, 110, 8, 115, 9, 120, 5, 122, 7, 127, 5, 134, 6, 141, 14, 148, 19, 153, 24, 155, 28, 163, 30, 167, 35, 177, 32, 186, 28, 192, 27, 196, 27, 200, 29, 205, 32, 209, 36, 205, 44, 203, 52, 199, 56, 195, 61, 188, 61, 188, 64, 189, 66, 187, 71, 190, 74, 195, 74, 195, 80, 196, 84, 195, 89, 188, 93, 183, 89, 179, 88, 179, 83, 174, 85, 170, 78, 163, 80, 155, 75, 148, 75, 142, 78, 139, 78, 141, 84, 137, 90"
                        href="javascript:__doPostBack(&#39;ctl00$ContentPlaceHolder1$imgMapSolarSystem&#39;,&#39;0&#39;)"
                        title="Jammu &amp; Kashmir"
                        alt="Jammu &amp; Kashmir"
                      />
                      <area
                        shape="poly"
                        coords="131, 93, 128, 97, 124, 98, 118, 103, 118, 111, 115, 121, 109, 126, 108, 132, 114, 134, 119, 134, 122, 131, 126, 133, 129, 136, 130, 138, 133, 135, 139, 134, 143, 136, 146, 131, 148, 127, 151, 129, 152, 124, 151, 116, 150, 112, 143, 108, 142, 99, 137, 94, 134, 91"
                        href="javascript:__doPostBack(&#39;ctl00$ContentPlaceHolder1$imgMapSolarSystem&#39;,&#39;1&#39;)"
                        title="Punjab"
                        alt="Punjab"
                      />
                      <area
                        shape="poly"
                        coords="108, 132, 117, 134, 120, 139, 119, 145, 127, 148, 132, 151, 135, 158, 139, 164, 140, 170, 146, 174, 147, 169, 151, 170, 155, 169, 157, 175, 163, 175, 165, 180, 169, 186, 172, 191, 166, 196, 156, 200, 150, 206, 148, 212, 153, 217, 161, 216, 162, 220, 155, 225, 158, 229, 153, 231, 154, 239, 150, 235, 145, 236, 142, 236, 135, 242, 131, 241, 137, 237, 138, 230, 133, 228, 129, 228, 129, 225, 125, 223, 121, 224, 121, 228, 117, 232, 119, 236, 121, 242, 120, 246, 115, 250, 116, 255, 111, 257, 107, 252, 102, 250, 99, 245, 96, 241, 95, 237, 91, 236, 91, 229, 86, 232, 78, 227, 68, 226, 60, 226, 57, 226, 53, 221, 51, 213, 51, 211, 47, 210, 41, 204, 43, 198, 43, 195, 38, 191, 35, 185, 37, 180, 44, 173, 50, 167, 58, 171, 69, 170, 92, 150, 82, 157, 97, 143, 100, 136"
                        href="javascript:__doPostBack(&#39;ctl00$ContentPlaceHolder1$imgMapSolarSystem&#39;,&#39;2&#39;)"
                        title="Rajasthan"
                        alt="Rajasthan"
                      />
                      <area
                        shape="poly"
                        coords="185, 110, 185, 101, 181, 94, 180, 89, 179, 84, 173, 85, 170, 79, 164, 80, 157, 77, 151, 76, 144, 77, 139, 79, 140, 83, 141, 87, 137, 91, 137, 96, 141, 100, 142, 106, 146, 109, 149, 110, 152, 115, 154, 117, 159, 121, 162, 124, 168, 125, 169, 119, 173, 113, 178, 109"
                        href="http://aercshimla.in/"
                        target="_blank"
                        title="Himachal Pradesh"
                        alt="Himachal Pradesh"
                      />
                      <area
                        shape="poly"
                        coords="167, 127, 169, 120, 176, 113, 180, 110, 186, 110, 191, 110, 195, 117, 200, 118, 207, 120, 207, 124, 214, 126, 218, 129, 217, 133, 212, 137, 210, 142, 208, 147, 206, 154, 203, 159, 200, 158, 196, 157, 194, 155, 188, 152, 186, 146, 180, 144, 179, 139, 176, 141, 171, 144, 168, 136"
                        href="javascript:__doPostBack(&#39;ctl00$ContentPlaceHolder1$imgMapSolarSystem&#39;,&#39;4&#39;)"
                        title="Uttarakhand"
                        alt="Uttarakhand"
                      />
                      <area
                        shape="poly"
                        coords="167, 127, 168, 138, 170, 144, 172, 145, 177, 141, 183, 146, 187, 152, 197, 157, 204, 160, 205, 158, 212, 159, 217, 163, 222, 167, 222, 166, 229, 171, 238, 174, 244, 177, 251, 179, 261, 180, 267, 181, 268, 185, 272, 189, 272, 193, 267, 195, 271, 198, 268, 200, 277, 207, 274, 210, 269, 211, 265, 215, 262, 218, 258, 222, 261, 228, 261, 236, 258, 242, 252, 243, 251, 237, 251, 229, 246, 229, 243, 229, 240, 225, 232, 222, 226, 221, 223, 225, 219, 222, 214, 222, 210, 217, 205, 219, 200, 223, 195, 222, 194, 221, 191, 221, 188, 215, 182, 218, 184, 226, 186, 234, 181, 234, 176, 230, 178, 222, 181, 213, 188, 202, 189, 195, 185, 190, 179, 190, 174, 190, 169, 189, 168, 182, 164, 179, 162, 173, 164, 163, 161, 155, 161, 144, 161, 135"
                        href="javascript:__doPostBack(&#39;ctl00$ContentPlaceHolder1$imgMapSolarSystem&#39;,&#39;5&#39;)"
                        title="Uttar Pradesh"
                        alt="Uttar Pradesh"
                      />
                      <area
                        shape="poly"
                        coords="159, 122, 162, 126, 165, 126, 163, 130, 159, 136, 160, 153, 157, 157, 156, 161, 160, 164, 164, 164, 164, 171, 160, 174, 156, 174, 155, 168, 151, 170, 147, 167, 144, 172, 141, 171, 140, 165, 135, 159, 132, 150, 129, 146, 123, 145, 119, 144, 120, 139, 119, 134, 124, 132, 130, 138, 140, 136, 150, 129"
                        href="javascript:__doPostBack(&#39;ctl00$ContentPlaceHolder1$imgMapSolarSystem&#39;,&#39;6&#39;)"
                        title="Haryana"
                        alt="Haryana"
                      />
                      <area
                        shape="poly"
                        coords="108, 280, 108, 271, 112, 264, 113, 256, 118, 255, 116, 251, 121, 245, 120, 236, 119, 232, 123, 225, 127, 224, 128, 228, 134, 228, 136, 234, 135, 239, 130, 239, 131, 244, 137, 242, 143, 237, 151, 237, 154, 239, 154, 233, 159, 228, 156, 225, 160, 223, 163, 221, 161, 216, 156, 217, 152, 217, 149, 210, 153, 206, 159, 199, 165, 197, 172, 193, 176, 191, 183, 190, 187, 193, 189, 199, 185, 207, 180, 214, 177, 220, 176, 225, 176, 229, 181, 235, 187, 235, 187, 230, 184, 224, 185, 218, 190, 219, 196, 223, 202, 222, 209, 218, 213, 221, 219, 222, 225, 224, 229, 222, 233, 224, 238, 227, 244, 231, 249, 231, 251, 239, 250, 245, 250, 248, 246, 248, 241, 248, 238, 245, 233, 247, 234, 251, 238, 255, 241, 257, 239, 262, 238, 263, 236, 267, 228, 272, 227, 270, 222, 277, 219, 287, 216, 289, 213, 284, 204, 285, 195, 283, 188, 286, 181, 286, 173, 289, 165, 287, 166, 281, 155, 283, 147, 289, 142, 293, 141, 288, 133, 287, 125, 288, 118, 281, 113, 277"
                        href="javascript:__doPostBack(&#39;ctl00$ContentPlaceHolder1$imgMapSolarSystem&#39;,&#39;7&#39;)"
                        title="Madhya Pradesh"
                        alt="Madhya Pradesh"
                      />
                      <area
                        shape="poly"
                        coords="58, 233, 56, 228, 67, 226, 80, 228, 87, 233, 90, 231, 92, 236, 95, 238, 97, 244, 100, 248, 103, 250, 105, 252, 111, 256, 112, 261, 109, 264, 108, 268, 106, 272, 107, 278, 104, 280, 104, 283, 110, 285, 104, 289, 100, 292, 103, 296, 101, 302, 97, 299, 95, 305, 93, 308, 90, 304, 86, 301, 82, 298, 80, 288, 84, 284, 80, 283, 82, 280, 79, 276, 80, 273, 76, 272, 75, 282, 73, 290, 66, 293, 61, 297, 57, 293, 52, 294, 52, 298, 46, 298, 37, 288, 33, 284, 26, 278, 22, 273, 19, 268, 25, 267, 31, 267, 38, 266, 46, 257, 41, 256, 37, 259, 33, 261, 24, 260, 19, 254, 13, 250, 14, 243, 16, 240, 10, 243, 8, 239, 12, 238, 19, 237, 19, 231, 28, 234, 34, 236, 36, 237, 44, 233, 51, 232"
                        href="https://www.iima.ac.in/web/areas-and-centres/areas-and-groups/cma/"
                        target="_blank"
                        title="Gujarat"
                        alt="Gujarat"
                      />
                      <area
                        shape="poly"
                        coords="93, 387, 88, 374, 88, 361, 84, 346, 82, 335, 84, 330, 80, 328, 80, 318, 80, 311, 86, 312, 94, 309, 97, 301, 101, 302, 103, 299, 101, 293, 109, 287, 105, 284, 107, 279, 113, 279, 116, 282, 122, 287, 130, 290, 140, 290, 144, 293, 151, 287, 161, 283, 164, 288, 172, 289, 185, 286, 194, 283, 203, 284, 213, 285, 216, 291, 215, 296, 218, 304, 214, 308, 214, 313, 215, 317, 219, 322, 216, 326, 212, 333, 206, 335, 205, 325, 199, 319, 193, 321, 185, 320, 182, 317, 174, 324, 170, 325, 168, 331, 165, 337, 162, 342, 159, 339, 153, 345, 149, 349, 146, 351, 141, 356, 143, 358, 139, 358, 134, 357, 131, 359, 129, 363, 124, 364, 119, 364, 117, 367, 110, 370, 106, 370, 108, 377, 105, 384"
                        href="javascript:__doPostBack(&#39;ctl00$ContentPlaceHolder1$imgMapSolarSystem&#39;,&#39;9&#39;)"
                        title="Maharashtra"
                        alt="Maharashtra"
                      />
                      <area
                        shape="poly"
                        coords="160, 340, 163, 344, 165, 349, 161, 354, 166, 356, 161, 362, 161, 369, 159, 374, 163, 376, 162, 382, 156, 382, 154, 386, 152, 390, 155, 395, 149, 398, 149, 404, 153, 412, 162, 412, 164, 420, 169, 420, 175, 425, 180, 429, 176, 436, 170, 437, 165, 441, 162, 446, 165, 450, 162, 453, 155, 455, 148, 457, 143, 456, 136, 451, 128, 449, 123, 444, 119, 438, 114, 435, 111, 426, 108, 410, 103, 400, 105, 394, 105, 386, 109, 379, 109, 370, 119, 366, 130, 364, 131, 358, 142, 358"
                        href="javascript:__doPostBack(&#39;ctl00$ContentPlaceHolder1$imgMapSolarSystem&#39;,&#39;10&#39;)"
                        title="Karnataka"
                        alt="Karnataka"
                      />
                      <area
                        shape="poly"
                        coords="113, 436, 124, 445, 135, 452, 141, 460, 144, 462, 148, 468, 150, 475, 151, 481, 157, 483, 156, 493, 158, 497, 155, 505, 155, 516, 145, 511, 140, 499, 135, 482, 131, 470, 135, 463, 130, 459, 127, 459, 120, 451, 117, 444"
                        href="javascript:__doPostBack(&#39;ctl00$ContentPlaceHolder1$imgMapSolarSystem&#39;,&#39;11&#39;)"
                        title="Kerala"
                        alt="Kerala"
                      />
                      <area
                        shape="poly"
                        coords="209, 425, 212, 434, 208, 441, 204, 448, 201, 452, 198, 456, 201, 460, 203, 466, 199, 470, 200, 475, 204, 476, 203, 480, 195, 480, 190, 488, 188, 493, 188, 499, 179, 499, 174, 504, 171, 510, 165, 517, 158, 518, 156, 510, 157, 501, 159, 493, 157, 491, 156, 482, 152, 480, 150, 473, 145, 464, 137, 452, 145, 457, 157, 458, 166, 450, 163, 446, 166, 439, 178, 442, 184, 434, 193, 434, 197, 430"
                        href="javascript:__doPostBack(&#39;ctl00$ContentPlaceHolder1$imgMapSolarSystem&#39;,&#39;12&#39;)"
                        title="Tamil Nadu"
                        alt="Tamil Nadu"
                      />
                      <area
                        shape="poly"
                        coords="154,384,165,385,180,380,191,374,201,369,214,371,222,363,225,354,243,347,256,337,265,329,280,331,274,343,263,352,254,359,245,366,239,370,239,377,230,379,221,385,217,387,210,395,206,406,211,417,211,426,201,431,188,435,182,439,174,443,177,434,170,427,169,421,162,423,153,422,149,419,148,410,146,401,150,394"
                        href="javascript:__doPostBack(&#39;ctl00$ContentPlaceHolder1$imgMapSolarSystem&#39;,&#39;13&#39;)"
                        title="Andhra Pradesh"
                        alt="Andhra Pradesh"
                      />
                      <area
                        shape="poly"
                        coords="221, 321, 215, 318, 216, 312, 214, 309, 218, 306, 216, 302, 216, 299, 214, 296, 216, 290, 221, 287, 223, 279, 227, 274, 231, 271, 237, 264, 244, 259, 242, 254, 239, 252, 234, 248, 242, 249, 251, 248, 252, 243, 257, 243, 261, 240, 267, 245, 270, 249, 271, 255, 273, 258, 271, 264, 273, 268, 271, 273, 266, 275, 263, 281, 262, 286, 257, 292, 253, 289, 245, 299, 247, 306, 249, 312, 252, 317, 246, 315, 239, 312, 240, 318, 244, 324, 245, 329, 240, 336, 235, 341, 230, 348, 225, 352, 221, 344, 217, 339, 211, 334, 212, 327, 217, 326, 220, 326"
                        href="http://aercshimla.in/"
                        target="_blank"
                        title="Chattisgarh"
                        alt="Chattisgarh"
                      />
                      <area
                        shape="poly"
                        coords="273, 269, 282, 271, 290, 269, 290, 275, 296, 276, 303, 277, 304, 270, 313, 273, 320, 275, 328, 279, 327, 283, 320, 289, 319, 293, 317, 301, 312, 309, 299, 315, 292, 316, 289, 324, 279, 331, 267, 328, 263, 332, 258, 337, 253, 341, 248, 339, 247, 344, 235, 348, 233, 348, 237, 340, 246, 331, 244, 326, 240, 316, 249, 317, 252, 317, 249, 312, 247, 302, 249, 295, 256, 293, 264, 284, 268, 276"
                        href="javascript:__doPostBack(&#39;ctl00$ContentPlaceHolder1$imgMapSolarSystem&#39;,&#39;15&#39;)"
                        title="Odisha"
                        alt="Odisha"
                      />
                      <area
                        shape="poly"
                        coords="266, 179, 272, 180, 276, 183, 280, 187, 289, 191, 295, 192, 301, 192, 306, 196, 311, 193, 315, 197, 325, 196, 327, 200, 328, 205, 330, 209, 327, 211, 327, 219, 321, 221, 319, 229, 315, 231, 311, 235, 306, 231, 302, 229, 298, 231, 290, 234, 284, 235, 278, 236, 274, 232, 266, 232, 261, 230, 260, 223, 267, 216, 276, 210, 278, 209, 274, 205, 269, 202, 272, 198, 269, 195, 273, 192"
                        href="javascript:__doPostBack(&#39;ctl00$ContentPlaceHolder1$imgMapSolarSystem&#39;,&#39;16&#39;)"
                        title="Bihar"
                        alt="Bihar"
                      />
                      <area
                        shape="poly"
                        coords="264, 232, 276, 233, 281, 237, 288, 234, 299, 231, 304, 230, 309, 234, 317, 230, 320, 221, 327, 219, 328, 224, 324, 233, 319, 239, 309, 244, 306, 247, 301, 248, 300, 254, 307, 255, 311, 257, 314, 265, 314, 273, 305, 270, 303, 276, 291, 275, 290, 269, 280, 271, 273, 268, 275, 261, 271, 253, 269, 246, 263, 241, 261, 238"
                        href="javascript:__doPostBack(&#39;ctl00$ContentPlaceHolder1$imgMapSolarSystem&#39;,&#39;17&#39;)"
                        title="Jharkhand"
                        alt="Jharkhand"
                      />
                      <area
                        shape="poly"
                        coords="316, 273, 314, 263, 307, 257, 301, 255, 302, 248, 312, 244, 321, 238, 328, 229, 329, 220, 327, 214, 330, 207, 329, 200, 329, 189, 337, 187, 346, 186, 353, 188, 354, 193, 353, 200, 349, 200, 344, 197, 340, 197, 336, 197, 333, 199, 333, 206, 337, 209, 341, 210, 344, 214, 340, 215, 337, 218, 334, 222, 332, 223, 333, 229, 337, 231, 341, 234, 339, 240, 342, 246, 343, 250, 346, 252, 346, 257, 347, 263, 347, 271, 346, 277, 341, 278, 334, 276, 331, 280, 322, 275"
                        href="javascript:__doPostBack(&#39;ctl00$ContentPlaceHolder1$imgMapSolarSystem&#39;,&#39;18&#39;)"
                        title="West Bengal"
                        alt="West Bengal"
                      />
                      <area
                        shape="poly"
                        coords="329, 188, 326, 182, 328, 176, 329, 172, 328, 168, 330, 166, 336, 163, 338, 167, 338, 171, 339, 176, 340, 183, 349, 186, 338, 188, 333, 187"
                        href="javascript:__doPostBack(&#39;ctl00$ContentPlaceHolder1$imgMapSolarSystem&#39;,&#39;19&#39;)"
                        title="Sikkim"
                        alt="Sikkim"
                      />
                      <area
                        shape="poly"
                        coords="389, 178, 386, 173, 381, 171, 380, 164, 384, 166, 390, 165, 394, 163, 397, 157, 404, 154, 404, 149, 411, 147, 413, 145, 418, 139, 424, 134, 434, 137, 442, 131, 448, 132, 448, 137, 452, 138, 453, 147, 464, 150, 464, 158, 461, 163, 464, 169, 457, 167, 443, 179, 434, 177, 437, 171, 445, 167, 444, 161, 444, 154, 422, 164, 414, 172, 409, 176"
                        href="javascript:__doPostBack(&#39;ctl00$ContentPlaceHolder1$imgMapSolarSystem&#39;,&#39;20&#39;)"
                        title="Arunachal Pradesh"
                        alt="Arunachal Pradesh"
                      />
                      <area
                        shape="poly"
                        coords="357, 206, 355, 198, 356, 186, 363, 184, 369, 186, 382, 184, 389, 182, 392, 178, 398, 177, 403, 178, 410, 177, 416, 169, 421, 164, 429, 162, 436, 157, 443, 154, 442, 159, 445, 165, 437, 169, 434, 173, 424, 181, 420, 185, 417, 194, 411, 199, 410, 205, 408, 220, 403, 224, 400, 228, 396, 228, 394, 221, 397, 216, 396, 211, 398, 207, 395, 203, 392, 199, 388, 197, 387, 194, 373, 200, 365, 199, 360, 201"
                        href="javascript:__doPostBack(&#39;ctl00$ContentPlaceHolder1$imgMapSolarSystem&#39;,&#39;21&#39;)"
                        title="Assam"
                        alt="Assam"
                      />
                      <area
                        shape="poly"
                        coords="358, 208, 359, 203, 362, 200, 368, 199, 376, 201, 383, 198, 385, 195, 388, 202, 392, 202, 396, 208, 395, 212, 388, 214, 382, 216, 372, 215, 365, 216, 357, 214"
                        href="javascript:__doPostBack(&#39;ctl00$ContentPlaceHolder1$imgMapSolarSystem&#39;,&#39;22&#39;)"
                        title="Meghalaya"
                        alt="Meghalaya"
                      />
                      <area
                        shape="poly"
                        coords="393, 227, 389, 230, 382, 234, 379, 240, 381, 248, 382, 253, 384, 249, 388, 253, 390, 246, 392, 239, 396, 239"
                        href="javascript:__doPostBack(&#39;ctl00$ContentPlaceHolder1$imgMapSolarSystem&#39;,&#39;23&#39;)"
                        title="Tripura"
                        alt="Tripura"
                      />
                      <area
                        shape="poly"
                        coords="408, 273, 413, 269, 413, 259, 411, 251, 415, 250, 414, 232, 409, 230, 405, 225, 402, 229, 396, 227, 396, 235, 396, 239, 398, 246, 399, 252, 401, 259, 403, 269"
                        href="javascript:__doPostBack(&#39;ctl00$ContentPlaceHolder1$imgMapSolarSystem&#39;,&#39;24&#39;)"
                        title="Mizoram"
                        alt="Mizoram"
                      />
                      <area
                        shape="poly"
                        coords="411, 208, 411, 200, 416, 194, 420, 187, 425, 179, 433, 174, 438, 180, 436, 186, 436, 195, 431, 204, 431, 199, 427, 203, 422, 201, 416, 208"
                        href="javascript:__doPostBack(&#39;ctl00$ContentPlaceHolder1$imgMapSolarSystem&#39;,&#39;25&#39;)"
                        title="Nagaland"
                        alt="Nagaland"
                      />
                      <area
                        shape="poly"
                        coords="411, 209, 420, 206, 425, 203, 432, 201, 430, 208, 432, 215, 429, 223, 425, 231, 418, 232, 412, 230, 407, 228, 405, 221, 409, 215"
                        href="javascript:__doPostBack(&#39;ctl00$ContentPlaceHolder1$imgMapSolarSystem&#39;,&#39;26&#39;)"
                        title="Manipur"
                        alt="Manipur"
                      />
                      <area
                        shape="poly"
                        coords="158,382,159,366,160,351,159,340,166,331,177,320,190,323,201,324,202,338,210,339,217,342,222,353,224,361,219,371,210,372,200,373,193,377,188,382,182,385,168,389,163,384"
                        href="javascript:__doPostBack(&#39;ctl00$ContentPlaceHolder1$imgMapSolarSystem&#39;,&#39;27&#39;)"
                        title="Telangana"
                        alt="Telangana"
                      />
                    </map>
                  </div>
                </>
              </div>

              <br></br>
              <p></p>
            </div>
            {/* </div> */}
          </div>
        </div>
      </section>
    </div>
  );
}

export default AreaOperation;

// // import "./styles.css";
// import IndiaMap from "../assets/images/1 map_of_India (1).jpg";
// // https://github.com/img-mapper/react-img-mapper
// import ImageMapper from "react-image-mapper";

// const URL =
//   "ttps://helpx.adobe.com/content/dam/helhp/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg";

// export default function AreaOperation() {
//   const [count, setCount] = useState(0);
//   const handleInc = () => {
//     // this print expected value when + button is clicked
//     // but print 0 if highlighted area is clicked
//     console.log(count);
//     setCount((count) => count + 1);
//   };
//   const handleDec = () => {
//     console.log(count);
//     setCount((count) => count - 1);
//   };
//   return (
//     <div className="App">
//       <h1>Hello CodeSandbox</h1>
//       <h2>Start editing to see some magic happen!</h2>
//       <button onClick={handleInc}>+</button>
//       {count}
//       <button onClick={handleDec}>-</button>
// {/* <img src={IndiaMap} /> */}
//       <ImageMapper
//         src={IndiaMap}
//         map={{
//           name: "asdf",
//           areas: [
//             {
//               id: 0,
//               active: true,
//               title: "BB(1-4)",
//               shape: "poly",
//               name: "BB(1-4)",
//               fillColor: "#eab54d4d",
//               strokeColor: "black",
//               coords: [

//               ]
//             }
//           ]
//         }}
//         stayMultiHighlighted
//         onClick={handleInc}
//       />
//     </div>
//   );
// }

// import React from 'react';
// import ImageMapper from 'react-img-mapper';

// import "../assets/style.css";

// function AreaOperation() {
//   const URL = 'https://raw.githubusercontent.com/img-mapper/react-docs/master/src/assets/example.jpg';
//   const MAP = {
//     name: 'my-map',
//     // GET JSON FROM BELOW URL AS AN EXAMPLE
//     areas: [
//       {
//         id: "469f9800-c45a-483f-b13e-bd24f3fb79f4",
//         active: true,
//         title: "Hardwood",
//         shape: "poly",
//         name: "BB(1-4)",
//         fillColor: "eab54d4d",
//         strokeColor: "black",
//         coords: [
//           520.0646766169153,
//       393.0348258706467,
//       85.23880597014923,
//       378.6069651741293,
//       637,
//       479,
//       13.099502487562177,
//       478.10945273631836,
//       11.606965174129343,
//       438.3084577114427
//         ],
//         "polygon": [
//           [
//             520.0646766169153,
//             393.0348258706467
//           ],
//           [
//             85.23880597014923,
//             378.6069651741293
//           ],
//           [
//             637,
//             479
//           ],
//           [
//             13.099502487562177,
//             478.10945273631836
//           ],
//           [
//             11.606965174129343,
//             438.3084577114427
//           ]
//         ]
//       }
//     ]
//   };

//   return <ImageMapper src={URL} map={MAP} />
// }

// export default AreaOperation;
