/* eslint-disable */

import React from "react";
import Footer from "./footer";
import Header from "./header";
import Slider8 from "../assets/images/slider/8.jpg";
import "../assets/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faPaperclip,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import PDF_IMage from "../assets/images/pdf.png";
import PDF_1 from "../assets/PDF FILE/RAC/1 Mintues Final_3rd RAC Meeting_FINAL 27 Aug 2021.pdf";
import PDF_2 from "../assets/PDF FILE/RAC/2 Minutes of Directors Meeting held on 27th August 2021.pdf";
import PDF_3 from "../assets/PDF FILE/RAC/3 RAC Meeting (Workplan 2019-20).pdf";
import PDF_4 from "../assets/PDF FILE/RAC/4 CCCS meeting 2015.pdf";
import PDF_5 from "../assets/PDF FILE/RAC/5 CCOS Meeting 2013.pdf";
import { CSVLink } from "react-csv";
import { useId } from "react";

import { useState } from "react";
import Select from "react-select";

export default function Book_Chapter() {
  const [search, setsearch] = useState("");
  const [year, setyear] = useState("");
  const [author, setauthor] = useState("");
  const Year_OPT = [
    { value: "", label: "Select" },
    { value: "2004", label: "2004" },
    { value: "2005", label: "2005" },
    { value: "2010", label: "2010" },
    { value: "2012", label: "2012" },
    { value: "2018", label: "2018" },
  ];
  const author_opt = [
    { value: "", label: "Select" },
    { value: "Awasthi", label: "Awasthi" },
    { value: "Rathi", label: "Rathi" },
    { value: "Sharma", label: "Sharma" },
  ];

  const Data = [
    {
      Book_Titles:
        "Angels and Ages of Agriculture in Madhya Pradesh. (In) Edited Book “Faces and Phases of Agriculture and Industry in India” pp-57-87.",
      Name_of_Publisher_organizer: "RBSA Publisher, Jaipur (Rajasthan)",
      Published_Year: "2004",
      Authors: "Awasthi, P.K., Rathi, Deepak and Raghuwanshi, N.K.",
      ISBN_No: "",
    },
    {
      Book_Titles:
        "Maize production in Madhya Pradesh : Golden grain in transition. (In) Edited Book “Feel Good & Bad Factors in Different Sectors. pp-261-296.",
      Name_of_Publisher_organizer: "RBSA Publisher, Jaipur (Rajasthan)",
      Published_Year: "2005",
      Authors: "Awasthi, P.K., Rathi, Deepak and Gupta, J.K.",
      ISBN_No: "",
    },
    {
      Book_Titles:
        "Growth and performance of financial institutions in augmenting agricultural production and employment in Jabalpur district of Madhya Pradesh. (In) Edited Book “Feel Good & Bad Factors in Different Sectors. pp-297-337",
      Name_of_Publisher_organizer: "RBSA Publisher, Jaipur (Rajasthan).",
      Published_Year: "2005",
      Authors: "Rathi, Deepak, Awasthi, P.K. and Gupta, J.K.",
      ISBN_No: "",
    },
    {
      Book_Titles:
        "Impact study of soil testing analysis in Madhya Pradesh. (In) Edited Book “Glimpses of Indian Agriculture. pp-261-264",
      Name_of_Publisher_organizer: "Glimpses of Indian Agriculture",
      Published_Year: "2018",
      Authors: "Sharma, H. O. and Rathi, Deepak",
      ISBN_No: "13:978-0-19-948883-4 & 10:0-19-948883-5",
    },
    {
      Book_Titles:
        "An economic analysis of Chickpea and its value added products in Agri-Export Zones for Pulses in Madhya Pradesh. 407-414",
      Name_of_Publisher_organizer: "Glimpses of Indian Agriculture",
      Published_Year: "2018",
      Authors: "Sharma, H. O. and Rathi, Deepak",
      ISBN_No: "",
    },
    {
      Book_Titles:
        "Question Bank on (a) Agricultural Economics & Farm Management. pp-51-99",
      Name_of_Publisher_organizer:
        "International Science Development Group, Jaipur, Rajasthan.",
      Published_Year: "2010",
      Authors: "Rathi, Deepak",
      ISBN_No: "",
    },
    {
      Book_Titles: "Question Bank on (b) Agricultural Extension. pp-100-120",
      Name_of_Publisher_organizer:
        "International Science Development Group, Jaipur, Rajasthan.",
      Published_Year: "2010",
      Authors: "Rathi, Deepak",
      ISBN_No: "",
    },
    {
      Book_Titles:
        "Compiled and edited Question Bank on “Agricultural Economics & Farm Management” pp-98",
      Name_of_Publisher_organizer: "",
      Published_Year: "2012",
      Authors: "Rathi, Deepak",
      ISBN_No: "",
    },
  ];
  const tabledata = Data.filter((item) => {
    const searchText = search.toLowerCase();
    const book_Title = item.Book_Titles.toLowerCase().includes(searchText);
    const name_PublishOrganizer =
      item.Name_of_Publisher_organizer.toLowerCase().includes(searchText);

    return (
      (book_Title || name_PublishOrganizer) &&
      item.Published_Year.includes(year) &&
      item.Authors.includes(author)
    );
  });
  const newdata = tabledata.map((item, i) => {
    return (
      <tr key={i}>
        <td>{item.Book_Titles}</td>
        <td>{item.Name_of_Publisher_organizer}</td>
        <td>{item.Published_Year}</td>
        <td>{item.Authors}</td>
        <td>{item.ISBN_No}</td>
      </tr>
    );
  });

  return (
    <div>
      <section class="about section">
        <div class="container">
          <div class="row">
            <div class="about-content">
              <h3>Book Chapter </h3>
              <section class="blog-area">
                <div class="container pt-20">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <div class="finder-journal">
                        <ul>
                          {/* <li>
                            <Select
                              placeholder="Select Teacher "
                              onChange={(e) => {
                                setteacher(e.value);
                              }}
                              options={Teacher}
                            />
                          </li> */}

                          {/* /* <li class="rating">
                            <Select
                              placeholder="Select Class"
                              onChange={(e) => {
                                setclass(e.value);
                              }}
                              options={class_opt}
                            />
                          </li> */}
                          <li class="rating">
                            <Select
                              placeholder="Select Author"
                              onChange={(e) => {
                                setauthor(e.value);
                              }}
                              options={author_opt}
                            />
                          </li>
                          <li class="rating">
                            <Select
                              placeholder="Select Year  "
                              onChange={(e) => {
                                setyear(e.value);
                              }}
                              options={Year_OPT}
                            />
                          </li>
                          <input
                            placeholder="Search "
                            type="text"
                            onChange={(e) => setsearch(e.target.value)}
                          />
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="rac_meeting">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <table
                      style={{
                        width: "100%",
                        marginLeft: "3px",
                        marginTop: "9px",
                      }}
                    >
                      <tr>
                        <th>Book Titles</th>
                        <th>Name of Publisher/organizer</th>
                        <th>Published Year</th>
                        <th>Authors</th>
                        <th>ISBN No.</th>
                      </tr>
                      {newdata}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button className="btn ">
            <CSVLink data={tabledata} filename="test.csv" headers={header}>
              download
            </CSVLink>
          </button>
        </div>
      </section>
    </div>
  );
}
const header = [
  { label: "Book_Titles", key: "Book_Titles" },
  { label: "Name_of_Publisher_organizer", key: "Name_of_Publisher_organizer" },
  { label: "Published_Year", key: "Published_Year" },
  { label: "Authors", key: "Authors" },
  { label: "ISBN_No", key: "ISBN_No" },
];
