/* eslint-disable */
import React, { Component } from "react";
import Slider8 from "../assets/images/slider/8.jpg";
import "../assets/style.css";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faPaperclip,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import PDF_IMage from "../assets/images/pdf.png";
import PDF_1 from "../assets/PDF FILE/otherOnlineMeeting/1 Minutes Ramesh Yadav.docx";
import PDF_2 from "../assets/PDF FILE/otherOnlineMeeting/2 Minutes-18 Nove. 2021 DCP IEG.docx";
import PDF_3 from "../assets/PDF FILE/otherOnlineMeeting/3 Minutes Dairy Study.doc";
import PDF_5 from "../assets/PDF FILE/otherOnlineMeeting/5 Minutes  24Feb2022 with AERCUs.pdf";
import PDF_6 from "../assets/PDF FILE/otherOnlineMeeting/6 Minutes  28 Feb2022.pdf";
import PDF_7 from "../assets/PDF FILE/otherOnlineMeeting/7 Minuts  06th May 2022.pdf";
import PDF_8 from "../assets/PDF FILE/otherOnlineMeeting/8 Minuts 12th May 2022.pdf";
import PDF_10 from "../assets/PDF FILE/otherOnlineMeeting/10 Minuts Review Meeting 18 May.pdf";
import PDF_11 from "../assets/PDF FILE/otherOnlineMeeting/11 Minuts 02 June 22 (1).pdf";
import { useState } from "react";

function ABMStructure() {
  const [year, setYear] = useState("");
  const [unit, setUnit] = useState("");
  // const [selectedOption, setSelectedOption] = useState(null);
  const data = [
    {
      designation: "Vice chancellor,J. N. Krishi Vishva Vidyalaya, Jabalpur ",
      composition: "Chairman",
    },
    {
      designation:
        "Economic & Statistical Advisor, Ministry of Agriculture, Govt. of India or his Nominee",
      composition: "Member",
    },
    {
      designation:
        "Joint Secretary (PC) Min of Agriculture,	Govt. of India or his Nominee ",
      composition: "Member",
    },
    {
      designation:
        "Financial advisor,	Min of Agriculture, Govt. of India or his Nominee",
      composition: "Member",
    },
    {
      designation: "The Registrar, J.N.K V.V., Jabalpur",
      composition: "Member",
    },
    {
      designation: "The Comptroller, (Financial Officer) J.N.K V.V., Jabalpur ",
      composition: "Member",
    },
    {
      designation:
        "One Representative from Academic Staff Dean, CoA, J.N.K V.V., Jabalpur",
      composition: "Member",
    },
    {
      designation:
        "One Representative from Administrative  Staff Director Research Services, J.N.K V.V., Jabalpur",
      composition: "Member",
    },
    {
      designation:
        "Head of the Department of Agril. Economics J.N.K V.V., Jabalpur	Member",
      composition: "Member",
    },
    {
      designation:
        "One Expert from the Discipline of Agricultural Economics	Member",
      composition: "Member",
    },
    {
      designation: "One Expert from the Discipline of Economics	Member",
      composition: "Member",
    },
    {
      designation: "Head/Director (AERC, J.N.K V.V., Jabalpur",
      composition: "Convenor",
    },
  ];

  const newdata = data.map((item, i) => {
    return (
      <tr key={i}>
        <td className="text-center">{item.designation}</td>
        <td className="text-center">{item.composition}</td>
      </tr>
    );
  });

  return (
    <div>
      <section class="about section">
        <div class="container">
          <div class="row">
            {/* <div class="col-lg-6 col-md-12 col-sm-12"> */}
            <div class="about-content">
              <h3>
                Advisory <span>Body</span>
              </h3>
            </div>
          </div>
        </div>
      </section>
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div className="abm_table">
              {/* <h3 className="abm_table">Advisory Body</h3> */}
              <p>
                The advisory body is constituted to guide the centre in the
                matters of research, administration and finance.
              </p>

              {/* <table>
                <tr>
                  <td>1</td>
                  <td>
                    Vice chancellor<br></br>
                    J. N. Krishi Vishva Vidyalaya, Jabalpur
                  </td>
                  <td>Chairman</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>
                    Economic & statistical Advisor <br></br>
                    Ministry of Agriculture, Govt. of India or his Nominee
                  </td>
                  <td>Member</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>
                    Joint Secretary (PC) Min of Agriculture <br></br>
                    Govt. of India or his Nominee
                  </td>
                  <td>Member</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>
                    Financial advisor <br></br>
                    Min of Agriculture, Govt. of India or his Nominee
                  </td>
                  <td>Member</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>
                    {" "}
                    Director Research Services <br></br>
                    J.N.K V.V., Jabalpur, (Representative from Administrative
                    staff)
                  </td>
                  <td>Chairman</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>
                    Director Instructions, <br></br>
                    J.N.K V.V., Jabalpur, (Representative from Academic staff)
                  </td>
                  <td>Member</td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>
                    The Registrar, <br></br>
                    J.N.K V.V., Jabalpur
                  </td>
                  <td>Member</td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>
                    {" "}
                    The Comptroller,(Financial Officer), <br></br>
                    J.N.K V.V., Jabalpur
                  </td>
                  <td>Member</td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>
                    {" "}
                    Professor & Head <br></br>
                    Department of Agricultural Economics and Farm Management,
                    <br></br>
                    J.N.K V.V., Jabalpur
                  </td>
                  <td>Member</td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>
                    Professor & Head <br></br>
                    Department of Extension Education, J.N.K V.V., Jabalpur
                  </td>
                  <td>Member</td>
                </tr>
                <tr>
                  <td>11</td>
                  <td>
                    Principal Scientist, Agriculture Economics <br></br>
                    (Expert from the Disciplines of Agricultural Economics){" "}
                  </td>
                  <td>Member</td>
                </tr>
                <tr>
                  <td>12</td>
                  <td>
                    Director / Head <br></br>
                    Agro-Economic Research Centre, J.N.K V.V., Jabalpur
                  </td>
                  <td>Convenor</td>
                </tr>
              </table> */}
              <table>
                <thead>
                  <tr>
                    <th style={{ width: "80%" }}>Designation</th>
                    <th>Composition</th>
                  </tr>
                </thead>
                <tbody>{newdata}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Start Clients --> */}
      {/* <Footer /> */}
    </div>
  );
}

export default ABMStructure;
