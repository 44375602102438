/* eslint-disable */

import React from "react";
import Footer from "./footer";
import Header from "./header";
import Slider8 from "../assets/images/slider/8.jpg";
import "../assets/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faPaperclip,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import PDF_IMage from "../assets/images/pdf.png";
import PDF_1 from "../assets/PDF FILE/RAC/1 Mintues Final_3rd RAC Meeting_FINAL 27 Aug 2021.pdf";
import PDF_2 from "../assets/PDF FILE/RAC/2 Minutes of Directors Meeting held on 27th August 2021.pdf";
import PDF_3 from "../assets/PDF FILE/RAC/3 RAC Meeting (Workplan 2019-20).pdf";
import PDF_4 from "../assets/PDF FILE/RAC/4 CCCS meeting 2015.pdf";
import PDF_5 from "../assets/PDF FILE/RAC/5 CCOS Meeting 2013.pdf";
import { CSVLink } from "react-csv";
import { useId } from "react";

import { useState } from "react";
import Select from "react-select";

export default function Research_highlight() {
  //   const [teacher, setteacher] = useState("");
  //   const [fil_class, setclass] = useState("");
  //   const [session, setsession] = useState("");
  const [search, setsearch] = useState("");

  const Data = [
    {
      S_No: "102",
      Title: "Impact of Soil Testing analysis in Madhya Pradesh ",
      Author: "Sharma, Hari Om",
      Year: "2012 & 62p",
      Research_studies:
        "The study was conducted for 2 soil testing laboratories of Madhya Pradesh, The 100 farmers of Sagar and Dhar districts (50 in each districts) randomly selected for the investigation. Study revealed that the infrastructure available for soil testing in the state was found very poor. On an average one laboratory serves 66000 farmers and 51000 hectares of cultivated area. Out of the total respondents only 71 received their soil testing report, out of which only 49 (69%) adopted recommendations provided by Soil Testing laboratory. Although per hectare expenditure on seed, fertilizer and plant protection measures of adopted farmers increased for all crops after adopting soil testing analysis recommendation. Per hectare expenditure on labour was also found increased in all crops except in soybean. The cost of cultivation and cost of production of all the crops reduced drastically, while cost-benefit ratio was found to be increased after adaption of recommendation of soil testing. The lack of knowledge about soil testing technology (70%) non-availability of soil testing report (62%), less co operation from officers of agriculture department (46%) and complicated method of testing soil sample (30%) were found the main constraints in adoption of soil testing recommendations. Thus, there is an ample scope for improvement the analyzing capacity as well as dissemination ability of the soil testing laboratories. If this, coupled with professional management through proper linkages, can bring radical changes in the soil testing service in the state to extent the farmers’ satisfaction.",
    },
    {
      S_No: "103",
      Title:
        "End-term Evaluation Study/ Appraisal in respect of the Implementation of the Bringing Green Revolution to Eastern India (BGREI) Programme",
      Author: "Rathi, Deepak and Sharma, Hari Om",
      Year: "2013 & 71p",
      Research_studies:
        "The study was conducted in Bastar, Durg and Bilaspur districts of Chhattisgarh. It was observed from the study that the project not got so much success in the study area; although the paddy growers replaced their local seed of paddy with high yielding and hybrid varieties of paddy but still there was found a considerable yield gap over the potential yield of the rice. The cost of micro nutrients and other inputs including fertilizer gave negative but non significant response over the yield of rice in the study area revealed that state government in-testified their efforts in this regards. In spite of seed, better package of practice of rice including balance use of fertilizer and other inputs should also be demonstrated in the field of paddy growers. All the ecological regions viz; shallow low land, hybrid and irrigated traditional have significant impact on the productivity of the crop. This calls for implementing ecology specific technologies under the programme for enhancement of the productivity level of rice to reach to its full potential. There should also need to develop managerial skills in the paddy growers so that they will be able to develop themselves as a manager of their farms.",
    },
    {
      S_No: "104",
      Title:
        "Impact of NREGA on Wage Rates, Food Security and Rural Urban Migration in Chhattisgarh",
      Author: "Sharma, Hari Om and Rathi, Deepak",
      Year: "2012 & 75p",
      Research_studies:
        "The study comprises of 200 NREGA house hold as 50 non NREGA house hold on different districts of Chhattisgarh. The study revealed that out of the total man day’s generated employment, an increasing trend was observed to other caste while decreasing trend was notice in case of Schedule Castes and Schedule Tribes in the year 2010-11 as compare to 2008-09. Women got higher employment opportunity in NREGA than those of men. A decreasing trend was noticed in case of house hold completed 100 days employment. This might be due to the fact that bottom level planner do not have clear-cut vision of how to generate employment. Hence, there is need to involve agricultural scientist, thinkers and planers in policy implication. This program should tune up with Comprehensive District Agriculture Plan for effective and efficient implementation.",
    },
    {
      S_No: "105",
      Title:
        "Impact of NREGA on Wage Rates, Food Security and Rural Urban Migration in Madhya Pradesh ",
      Author: "Sharma, Hari Om and Rathi, Deepak",
      Year: "2012 & 89p",
      Research_studies:
        "The study comprises of 200 beneficiaries (NREGA households) and 50 non-beneficiaries (others households) of different districts (Morena, Chhindwara, Dhar, Sagar and Sidhi) of Madhya Pradesh. The study revealed that the level of engagement of the Gram Sabha is very low as far as planning for NREGA is concerned. Nevertheless plans are being prepared for the scheme in each Panchayat. Inadequate planning is leading to inability of the Panchayats to take up more activities resulting deficient response (in terms of number of days of work provided or delay in providing works) to the demand for work raised by the community. There is also a need to look at the plans from an integrated perspective where the works of NREGA can be converged with other line departments. As of now, convergence is driven only by the directives from the state government. There is also need to involve agricultural scientist, thinkers and planers in policy implication at bottom level. This program should tune up with Comprehensive District Agriculture Plan for effective and efficient implementation.",
    },
    {
      S_No: "106",
      Title: "Hulling and Milling Ratio for Paddy in Chhattisgarh ",
      Author: "Sharma, Hari Om and Rathi, Deepak",
      Year: "2012 & 57p",
      Research_studies:
        "The study comprises of 40 modern rice millers and 40 traditional rice millers (hullers) of two top rice growing districts (Raipur and Durg) of C.G. The study revealed that the paddy to rice conversion ratio was found to be higher in parboiled rice (61-67 %) as compared to non parboiled rice (51-57 %). The huller of the study area processed area non parboiled rice on the bases of custom hiring basis.",
    },
    {
      S_No: "107",
      Title: "Hulling and Milling Ratio for Paddy in Madhya Pradesh",
      Author: "Sharma, Hari Om and Rathi, Deepak",
      Year: "2012 & 41p",
      Research_studies:
        "The study comprises of 40 modern rice millers and 40 traditional rice millers (hullers) of two top rice growing districts (Balaghat and Katani) of M.P. The study revealed that the paddy to rice conversion ratio was found to be higher in parboiled rice (61-67 %) as compared to non parboiled rice (51-57 %). The huller of the study area processed area non parboiled rice on the bases of custom hiring basis.",
    },
    {
      S_No: "108",
      Title:
        "Spread of New Varieties of Hybrid Rice and their Impact on the Overall Production and Productivity in Madhya Pradesh ",
      Author: "Sharma, Hari Om and Rathi, Deepak",
      Year: "2013 & 85p",
      Research_studies:
        "The study comprises of 80 Hybrid Rice adopters and 20 HYV non-adopters of two districts (Rewa and Mandla NFSM districts) of Madhya Pradesh. The study revealed that the expenses on seed (0.152***), chemical fertilizer (0.082***), human labour (0.243***) were positive and highly significant, which reveals that if all things remains constant and at the present level of technological adoption an additional expense of Rs. 1/- each on seed, chemical fertilizer and human labour will be able to increase the yield of hybrid rice up to 0.152, 0.082 and 0.243 kg/ha respectively. The expenses on manures, pesticides, machine labour were found to be positive but non significant, which shows the need to provide extra attention while using these crucial inputs at their farms. There is also a need to provide skill oriented training and demonstration to them at their field.",
    },
    {
      S_No: "109",
      Title:
        "Assessment of pre and post harvest losses of wheat and soybean in Madhya Pradesh  ",
      Author: "Sharma, Hari Om and Rathi, Deepak",
      Year: "2013 & 86p",
      Research_studies:
        "The study comprises of 160 wheat growers and 160 soybean growers of different agro climatic regions of M.P. The study revealed that that the majority of respondents had lack of technical knowhow of post harvest technology specially storage techniques. They never found to be followed sun drying, admixing with ash, smoking and other pest control measures in their storage structure. Even they were not found to be followed rat guard and removed infested grain from their storage grain. Hence, efforts should be made to popularize post harvest technology amongst the farmers so that they could able to take advantage of time place form and possession utility of the product and earn more by reducing the pre and post harvest losses occurred in their products.",
    },
    {
      S_No: "110",
      Title:
        "Assessment of marketable and marketed surplus of wheat, gram & tur in Madhya Pradesh",
      Author: "Sharma, Hari Om and Rathi, Deepak",
      Year: "2014 & 105p",
      Research_studies:
        "The study comprises of 100 wheat growers, 100 gram growers and 100 tur growers of Hosangabad, Vidisha and Narshingpur districts of M.P. An average HH was found to be sold their 82.56 per cent (wheat), 88.43 per cent (gram) and 93.14 per cent (tur) of the total production in the market. The maximum of quantity of grains were found to be sold in month of March and April just first after the harvested of the crop. It is also observed from the data that more than 90.00 per cent of total marketed surplus has been sold in regulated market/cooperative society and remaining 10.00 per cent had been found to be sold to private traders or to agricultural/professional money lenders present in the villages. As the size of holding increased the marketed surplus sold to govt. agencies has been found to be increased, while sold to private trader/money lender has been found to be decreased.",
    },
    {
      S_No: "111",
      Title: "Problems and Prospects of Oilseeds Production in Madhya Pradesh ",
      Author: "Sharma, Hari Om and Rathi, Deepak",
      Year: "2014 & 92p",
      Research_studies:
        "The study comprises 240 soybean grower of Chhindawara, Khandwa and Narshingpur districts and 120 mustard growers of Mandla, Morena and Chhatarpur districts of Madhya Pradesh. The study reveals that   oilseeds production has increase tremendously due to successful implementation of TMO and ISOPOM programme in the state. The cultivation of soybean and mustard was also found profitable over competing crops i.e. maize and wheat. There is still found remarkable scope for increasing yield of these crops in the state as farmers were found to be harvested yield below the potential of the area under study.",
    },
    {
      S_No: "114",
      Title:
        "Adoption of recommended doses of fertilizer on soil test basis by the farmers",
      Author: "Sharma, Hari Om and Rathi, Deepak",
      Year: "2015 & 81p",
      Research_studies:
        "The study comprises 240 soil test and 120 control respondents of Shajapur & Ujjain, and Hoshangabad and Vidisha districts for soybean and wheat respectively in Madhya Pradesh.  The positive impact of soil testing on productivity of soybean and wheat was observed in the area under study on an overall basis an average farmer obtained 24.4 & 20.2 per cent more income and 20.2 & 15.4 per cent more yield than the control farmers in production of soybean and wheat crop respectively. It was also observed that the yield of soybean and wheat at overall level was found to be increased by 10.20 and 8.30 per cent respectively after adoption of recommended doses of fertilizer by soil test farmers. Amongst different size of farmers the increase in yield was found maximum in marginal (17.9%) followed by large (10.5%), medium (10.0%) and small (2.5%) farmers in case of soybean, while in case of wheat it was found to be maximum in marginal (17.0%) followed by small (6.1%), medium (5.7%) and large (4.8%) farms.",
    },
    {
      S_No: "115",
      Title:
        "Impact of NFSM on input use, production, yield and income in Madhya Pradesh ",
      Author: "Rathi, Deepak and Sharma, Hari Om ",
      Year: "2015 & 65p",
      Research_studies:
        "The study comprises 300 beneficiaries and 100 Non-beneficiaries respondents of Harda and Balaghat districts respectively for wheat in Madhya Pradesh. The impact of NFSM in Madhya Pradesh was found to be excellent due to untiring efforts through efficient and effective implementation of the programme. The drastic change has been observed in the area, production and yield of wheat in NFSM districts of the state. The beneficiaries of NSFM got more income and employment over non-beneficiaries from adoption of different interventions on their farms in the area under study.",
    },
    {
      S_No: "116",
      Title:
        "Impact of Pradhan Mantri Krishi Sinchai Yojana (Watershed Development) on Land Use and Cropping Pattern in Madhya Pradesh",
      Author: "Sharma, Hari Om and Rathi, Deepak",
      Year: "2017 & 45p",
      Research_studies:
        "This study contains an analysis of the primary data collected from 1285 farmers across 33 IWMPs of 32 districts of the State encompassing various dimensions such as physical, social and institutional among others. In the present study, the efforts have been made to assess the impact of IWMPs in the context of effectiveness of the programme, identification of issues and lacunae in project implementation across the states. The study noticed changes in ground water level, surface water, irrigation facility, water regeneration capacity, land use pattern, cropping pattern, etc. These changes are observed in all IWMPs with certain variations. But the changes like land use pattern, cropping pattern, crop productivity; diversification, etc. are more prominent in the watershed regions. Some areas need further attention such as greater involvement of the communities during implementation & post implementation phases, training & capacity building, social audit, women participation. The programmes have been examined from the structural and functional dimensions and their effectiveness has been measured from the benefits accrued to various stakeholders over the period of time.",
    },
    {
      S_No: "117",
      Title:
        "Assessment of the Impact of Neem Coated UREA on Production, Productivity and Soil Health in Madhya Pradesh ",
      Author: "Sharma, Hari Om and Rathi, Deepak",
      Year: "2017 & 45p",
      Research_studies:
        "The study comprises 200 paddy and 200 soybean growers of Balaghat & Seoni and Khargone & Dhar districts respectively. The positive impact of NCU was observed on yield and probability of crops. The yield of paddy and soybean obtained by NCU respondents was found to be higher than NU respondents. The use of NCU reduced the expenditure of applying nitrogen in both the crops and found economically feasible as well.",
    },
    {
      S_No: "118",
      Title: "Farmer Suicides in Madhya Pradesh",
      Author: "Sharma, Hari Om and Rathi, Deepak",
      Year: "2017 & 40p",
      Research_studies:
        "	The study comprises 50 Households related to victims’ family of Rewa district of Madhya Pradesh. The study revealed that due to the effective implementation of the various agricultural and rural development programmes of the Central and State Government, farmers’ suicide in Madhya Pradesh were found to be reduced from 1445 (2003) to 826 (2014) with the magnitude of –44.34 person per year. The present intensity of farmers’ suicide (2014-15) was found to be only 5.36 suicides per lakh ha of net sown area & 3.43 suicides per lakh ha of gross cropped area, and only 8.39 farmer suicides over per lakh farming family.",
    },
    {
      S_No: "119",
      Title: "Farmer Suicides in Chhattisgarh",
      Author: "Rathi, Deepak and Sharma, H. O.",
      Year: "2017 & 38p",
      Research_studies:
        "The study comprises 50 Households related to victims’ family of Surajpur district of Chhattisgarh. The study revealed that due to the effective implementation of the various agricultural and rural development programmes of the Central and State Government, farmers’ suicides in Chhattisgarh was found to be reduced from 1452 (2001-02) to 854 (2015-16) with the magnitude of –87.82 person per year. The intensity of farmers’ suicide was found to be only 20.49 suicides per lakh ha of net sown area and 16.74 suicide per lakh ha. of gross cropped area while 23.95 farmers suicides were recorded over per lakh farming families in Chhattisgarh during the year 2014-15.",
    },
    {
      S_No: "120",
      Title:
        "Impact of Soil Health Card Scheme on Production, Productivity and Soil Health in Madhya Pradesh",
      Author: "Rathi, Deepak and Sharma, H. O.",
      Year: "2017 & 42p",
      Research_studies:
        "The study comprises 30 soil tested farmers and 30 control farmers of Jabalpur & Sehore districts of Madhya Pradesh. The study revealed that the 63.30 per cent were found to be aware to SHC scheme and 25.0 per cent have knowledge of INM due to wider campaign put in place by the State government. Only 15 per of HHs experienced that use of INM curtailed fertilizer consumption. Only 3 (control) – 4 (soil tested) per cent of HHs were found to be aware about grid system under the scheme. There was also found the positive impact of Soil Health Card Scheme was observed on yield and probability of crops.",
    },
    {
      S_No: "121",
      Title:
        "Problems and prospects of production of millets and their value added products in Madhya Pradesh.",
      Author: "Sharma, Hari Om and Rathi, Deepak",
      Year: "2017 & 45p",
      Research_studies:
        "The study comprises 60 kodo and 60 kutki growers of Mandla & Dindori and Dindori & Chindwara districts respectively. Low productivity of kodo/kutki was observed in farmers’ fields as compared to RPP, as these crops were found to predominantly grown with in  shallow  soils with  low  water holding capacity and under rain-fed conditions. The farmers were not found to adopt improved crop production practices due to lack of knowledge about RPP in cultivation of these crops. Their poor socio economic conditions and non-availability of HYVs seed also hampered the production of small millets. Lack of suitable extension and development support for production and research on genetic improvement in small millets was not given utmost importance as compared to other food crops troubled the production of small millet. Huge price spread (minimum of 200%) and absence of Minimum Support Price (MSP) forced famers to distress sell of their products at un-remunerative price.",
    },
    {
      S_No: "122",
      Title: "Dynamics and Revival of Fallow Land in Madhya Pradesh",
      Author: "Sharma, Hari Om and Rathi, Deepak",
      Year: "2017 & 60p",
      Research_studies:
        "The study comprises 120 fallow land farmers of Mandla & Bhopal districts of Madhya Pradesh. The study revealed that with the result of significant reduction of area under barren and uncultivated land permanent pasture (-12.61 thousand ha/year), cultivated waste land (-14.61) thousand ha/year), fallow land other than current fallow (-8.97 thousand ha/year) and current fallow (-27.55 thousand ha/year), the  area under forest , non-agricultural uses, net area sown was increased with the magnitude of 1.51, 21.16,45.34 thousand ha /year respectively and found highly significant.",
    },
    {
      S_No: "123",
      Title:
        "Assessment of the Status of Dairying and Potential to Improve Socio-Economic Status of the Milk Producers and Convergence of all Central and State Schemes at District Level in Chhattisgarh",
      Author: "Sharma, Hari Om and Rathi, Deepak",
      Year: "2017 & 126p",
      Research_studies:
        "The study comprises of 240, 120 DCS and 120 NDCS respondents of Durg, Bilashpur, Raipur and Rajnandgaon districts respectively. It is observed from the study that Chhattisgarh State occupied pivotal position in terms of goat population contributing nearly 60 per cent population of the country and still unorganized in the State. Hence, efforts are required to be made to organize this as an industry through cooperative or producers companies as goat milk has tremendous advantageous and better than the cow milk.",
    },
    {
      S_No: "124",
      Title:
        "Performance Evaluation of Pradhan Mantri Fasal Bima Yojana (PMFBY) in Madhya Pradesh",
      Author: "Sharma, Hari Om and Rathi, Deepak",
      Year: "2018 & 44p",
      Research_studies:
        "The study comprises of 90 loanee, 30 non-loanee and 30 uninsured (control) farmers of Jabalpur, Umaria and Sagar districts of Madhya Pradesh. It is observed from the study that Pradhan Mantri Fasal Bima Yojna was not implemented with its all aspects in the State because still crop area is being notified by the Govt. of Madhya Pradesh in State Gadget , which is not covering all the crops grown by the cultivators in their field. This makes major hindrance in introducing new crops, which may be more profitable over the existing traditional crops. One should feel secure for trying innovation in the field of agriculture looking to the competitive world in the present WTO era.",
    },
    {
      S_No: "125",
      Title:
        "Impact of Tejaswini rural Woman Empowerment programme on Empowerment of Rural Woman through Cultivation, Processing, and Marketing of Kodo- kutki in Dindori district of Madhya Pradesh",
      Author: "Sharma H.O.; Nahatkar, S. B. and Rathi, Deepak",
      Year: "2018 & 51p",
      Research_studies:
        "The study confined to 120 respondents comprises of 60 beneficiaries and 60 non -beneficiaries of Mandla and Balaghat Districts of Madhya Pradesh. It is observed from the study that Cost incurred and return obtained in production of rice through SRI on beneficiaries farm was found to be identical when compared with recomn1ended packages of practices this indicate SRI method of rice cultivation e1npo.vering rural won1an of the study area.",
    },
    {
      S_No: "126",
      Title:
        "Impact of Tejaswini rural Woman Empowerment programme on Empowerment of Rural Woman through Vegetable Production in Dindori and Chatarpur district of Madhya Pradesh",
      Author: "Rathi, Deepak, Sharma, Hari Om and Nahatkar, S. B.",
      Year: "2018 & 51p",
      Research_studies:
        "The study comprises 105 Beneficiaries and 105 Non- Beneficiaries vegetable growers of Dindori and Chhatarpur districts. The study reveals that although vegetable production is found to be profitable in beneficiary's farms, but due to lack of storage facilities they were found to be sold their products on non- remunerative prices. Hence, adequate storage facilities should be developed in the area under study",
    },
    {
      S_No: "127",
      Title:
        "Impact of Tejaswini rural Woman Empowerment programme on Empowerment of Rural Woman through System of Rice Intensification (SRI) in Mandla and Balaghat district of Madhya Pradesh (External Funded)",
      Author: "Nahatkar, S. B.; Sharma, Hari Om and Rathi, Deepak",
      Year: "2018 & 31p",
      Research_studies:
        "The study confined to 120 respondents comprises of 60 beneficiaries and 60 non -beneficiaries of Mandla and Balaghat Districts of Madhya Pradesh. It is observed from the study that Cost incurred and return obtained in production of rice through SRI on beneficiaries farm was found to be identical when compared with recomn1ended packages of practices this indicate SRI method of rice cultivation Empowering rural woman of the study area.",
    },
    {
      S_No: "128",
      Title:
        "Strategies to BridgeYield Gap of Major Crops in Bundelkhand Region of Madhya Pradesh",
      Author: "Sharma H.O.; Rathi D. and Niranjan H. K.",
      Year: "2020 & 54p",
      Research_studies:
        "The study comprises 180 farmers of Datia, Chhatarpur, Tikamgarh Panna, & Damoh districts of Madhya Pradesh related to the major crops of viz. wheat, gram and soybean of Bundelkhand Region of Madhya Pradesh. The study revealed that the maximum yield gap between potential and average farm yield was found in cultivation of gram (43.59%) followed by soybean (38.87%) and wheat (29.86%). The yield gap II was found to be more than yield gap I in cultivation of wheat, gram and soybean denotes that the recommended technologies for cultivation of these crops have been reached to farmer's field but farmers are could not adopted these technologies due to several socio-economic constraints. It is also observed that factors like use of high yielding varieties (HYVs) seed, improved method of sowing, seed replacement (purchase seed), consumption of fertilizers as per soil test recommendation, treatment with fungicide and bio-fertilizers (Rhizobium, Azotobacter and Phosphate Solubilizing Bacteria), increase educational status, proper seed rate, increase consumption of DAP were found to be positively related to yield of all the major crops. ",
    },
    {
      S_No: "129",
      Title:
        "Distribution Efficiency of Seed Mini-Kits of Pulses in Madhya Pradesh",
      Author: "Sharma, H.O.; Rathi, D.; Niranjan, H. K. and Thakur, S. S.",
      Year: "2020 & 75p (2020) Relevance and",
      Research_studies:
        "The study comprises 200 beneficiaries and 100 non-beneficiaries respondents of seed minikits of viz. chickpea, black gram and lentil of Datia & Sagor districts of Madhya Pradesh. The seed minikit programme was found to be successful in the study area. Out of total seed minikits distributed to HHs majority of respondents were found to marginal and small. The minikits also distributed to SC/ST and women respondents. The varieties of different pulses provided in seed minikits were of good quality & short duration, more yielded and fetches more prices as compared to local varieties and majority of HHs were able to identified the varieties.",
    },
    {
      S_No: "130",
      Title:
        "Strategies to Bridge Yield Gap of Major Crops in Bundelkhand Region of India",
      Author: "Sharma H.O.; Rathi D. and Niranjan H. K.",
      Year: "2020 & 130p",
      Research_studies:
        "The consolidated study comprises of 180 (Bundelkhand Region of Madhya Pradesh) and 120 (Bundelkhand Region of Uttar Pradesh) constituting 300 respondents were selected from Bundelkhand Region of India related to the major crops of viz. rice, wheat, gram and soybean. The study reveals that their was found 23.33, 29.48, 43.59 and 38.87 per cent yield gap-III in rice, wheat, gram and soybean respectively, between potential and average farm yield of the farmers. The yield gap II was found to be more than yield gap I in cultivation of rice, wheat, gram and Soybean respectively, denotes that the recommended technologies for cultivation of these crops have been reached to farmers field but farmers could not adopted these technologies due to several socio- economic constraints. The major constraint which was reported by the majority of respondents was found to be high cost of input followed by unavailability of desired variety, of seed, lack of knowledge about method of seed treatment, proper doses of fertilizers, unavailability of capital and lack of labour during peak operational periods across all the regions of Bundelkhand in adoption of recommended technology of wheat cultivation",
    },
    {
      S_No: "131",
      Title:
        "Improving Water Use	Efficiency in India's Agriculture: The Impact, Benefits and Challenges of Micro-Irrigation under the Pradhan Mantri Krishi Sinchayee Yojana - Per Drop More Crop (PMKSY-PDMC) in Madhya Pradesh",
      Author: "Sharma H.O.; Rathi D. and Patidar, P. K.",
      Year: "2020 & 75p",
      Research_studies:
        "The study comprises 96 beneficiaries and 24 non-beneficiaries respondents of PMKSY- PDMC of Dhar & Sagor districts of Madhya Pradesh. The impact of PMKSY-PDMC was found to be positive on water conservation, participation of women, upper caste, lower caste, rural youth, upland and low land farmers with improvement of overall environment in the area under study through optimal utilization of scare and limited water and land resources, fertigation and water use efficiency of farmers field.",
    },
    {
      S_No: "132",
      Title: "Market Imperfections and Farm Profitability in Madhya Pradesh",
      Author: "Sharma H.O.; Rathi D. and Niranjan H. K.",
      Year: "2020 & 149p",
      Research_studies:
        "The study comprises 400 households of four major crops (wheat, rice, soybean and chickpea) producing districts viz. Hoshanbad, Vidisha, Ujjain and Balaghat of Madhya Pradesh. The study reveals that as per producers' point of view, the input as well as output market of farm products was found towards perfection in the State as more than 60 per cent of sample HHs of the study area were found to be satisfied with the disposal of their crop and livestock products. They all were reported that price of various inputs was found to be reasonable and its quality was also found to be fair enough. It became possible due to introduction of various schemes and their effective implementation for increasing production and procurement of farm products by the Government of Madhya Pradesh. Along with this the State Government also provided handsome bonus over end above the MSP to the farmers for procurement of food grains.",
    },
    {
      S_No: "133",
      Title:
        "Impact Evaluation of 15 Farmers Producers’ Organization Projects in Madhya Pradesh",
      Author:
        "Sharma H.O.; Rathi D.; Niranjan, H.K; Thakur S.S.; Pandey P.R.; Bareliya R.; Kuril A.; Patidar P.K. and Aske S.",
      Year: "2021 & 65p",
      Research_studies:
        "The  study  comprises  of  918  producer  members of  15  FPOs  sponsored by National  Bank  for  Agriculture  and Rural  Development. It  is  observed  from  the  study  that  an  average  FPO  was  found  to  earn  a  market margin of Rs 31.86  from different marketing activities over the marketing cost of Rs. 1.00, which ranged between 1:1.43 (production and marketing of  vermi-compost)  to  1:161.63  (processing  and  marketing of  spices).  The 41.26,  22.38,  21.35, 34.86, 12.76 and 10.38 per cent producer members were found to be benefited through supply of seed, fertilizers  &  Bio-fertilizers,  plant  protection  &  growth  regulator ,  supply  of  cattle  &  cattle  feed procurement  of  grains  and  procurement  of  fruits  and  other  products  respectively.",
    },
    {
      S_No: "134",
      Title: "Village Survey Study in Madhya Pradesh (Piprodh Village)",
      Author: "Sharma H.O.; Rathi D and Pandey, P.R.",
      Year: "2021 & 117p",
      Research_studies:
        "The   study   was based on both primary and secondary data. It   is observed from the study that the net area sown was found to be increased only by 3.76 per cent in the year 2019 (180.37 ha) over the year 1972 ( 173.84 ha) . The area under current fallow (-48.14%), barren land  (-85.05%), forest (-34.35%), pasture & grazing land (-1.96%), cultivable waste land (-15.77%) was found to be decreased, while the area under net irrigated area (13.25%), gross cropped area (19.77%) was found to be increased during the period under study.  The number of land holdings were found to be increased by 105.56% in  the year 2019 (185) over the year 1972 (90) in the village. Due   to   introduction   of   irrigation   the   change   in   area   under   oil  seeds (123.67%) was found to be more as compared to pulses (3.03%) and cereals (0.36%)",
    },
    {
      S_No: "135",
      Title:
        "Farmers Perception regarding Production and Marketing of Quality Seed in Madhya Pradesh",
      Author: "Sharma H.O.; Rathi D and Niranjan H. K.",
      Year: "2022, 1-45",
      Research_studies:
        "	The study comprises 120 selected quality seed producers of Wheat, Gram and Soybean of Hoshangabad, Dewas and Ujjain Districts, respectively. The study revealed that the quality seed producer were found  to preferred production of seed over grain as they obtained more than an  additional Rs. 1.00 income over the additional investment of Rs. 1.00, which was found to be more in wheat (Rs.8.81) as compared to gram (Rs 1.17) and soybean (Rs.1.51). The quality seed production of wheat was found to be more profitable as compared to gram and soybean as quality seed producers obtained maximum return per Rs. Investment in case of wheat (3.19) as compared to gram (2.03) and soybean (1.22).",
    },
    {
      S_No: "136",
      Title: "Land Titling and Agricultural Productivity in Madhya Pradesh",
      Author: "Sharma H.O.; Rathi D, Patidar, P.K. and Niranjan H. K",
      Year: "2022, 1-76",
      Research_studies:
        "	The study comprises 120 land titled respondents of Ratlam and Damoh districts of Madhya Pradesh. All the farmers are titled and got various advantages right from institutional credit, to procurement of farm product through direct benefit transfer under different Govt. schemes running for agricultural and rural development. Loan of Rs. 88498.90 was found to be taken by an average HH from the institutional sources during last 5 years, crop loan (97.43%) was found to be prominent as compared to consumption (1.89%) and animal husbandry (0.69%) loan. The maximum HH used to borrow money from institutional sources (37.5%) as compared to non-institutional sources (15%).  The yield of an average soybean and wheat growers was found to be 5.22 & 13.69q/acre with benefit cost ration of 1.56 & 1.70. ",
    },
    {
      S_No: "137",
      Title:
        "Impact Evaluation of Pilot Project “Development of Model Villages under Soil Health Card Scheme’’ in Madhya Pradesh",
      Author: "Sharma H.O.; Rathi D, Niranjan H. K. and Patidar, P.K",
      Year: "2022, 1-57",
      Research_studies:
        "	The study comprises 360 land titled respondents of Jabalpur, Damoh and Bhind districts of Madhya Pradesh. At overall level the majority of beneficiaries were found to have moderate (71.39%) followed by high (23.61%) and low (5.00%) knowledge of MVP-SHC scheme in the area under study. None of the beneficiaries related to MVP-SHC reported their participation in demonstration related to SHC in the area under study. The majority of beneficiaries related to MVP-SHC reported that the SHC help in improved soil health, determine the optimal dose of NPK in the field. It also reduce input cost and provide better information on soil. It also help in selection of crops and increase crops’ productivity ",
    },
    {
      S_No: "138",
      Title:
        "Cost of Milk production and Gross Returns to Milk Producers in Madhya Pradesh",
      Author: "Sharma H.O.; Rathi D, Bareliya R.S. and Niranjan H. K",
      Year: "2022, 1-56",
      Research_studies:
        "	The study comprises 240 selected milk producers of Mandsour, Alirajpur, Sheopur, Chhatarpur, Katni and Mandla Districts, respectively. It is observed from the study that the cropping pattern of the milk producers was not found to be tuned with fodder production and it is clear that cost of feed and fodder play an important role in cost of production of milk. It is also found in the course of investigation that none of the selected respondent cultivates fodder in a scientific manner as they have lack of knowledge about the package and practices of fodder cultivation in the area under study. Hence, efforts should be made to popularize the recent fodder technology to ultimate milk producer because without fodder development a dairy industry will not get its proper shape in the State.",
    },
  ];

  const tabledata = Data.filter((item) => {
    const searchText = search.toLowerCase();
    const year = item.Year.toLowerCase().includes(searchText);
    const author = item.Author.toLowerCase().includes(searchText);
    const title = item.Title.toLocaleLowerCase().includes(searchText);

    return year || author || title;
  });
  const newdata = tabledata.map((item, i) => {
    return (
      <tr key={i}>
        <td>{item.S_No}</td>
        <td>{item.Title}</td>
        <td>{item.Author}</td>
        <td>{item.Year}</td>
        <td>{item.Research_studies}</td>
      </tr>
    );
  });

  return (
    <div>
      <section class="about section">
        <div class="container">
          <div class="row">
            <div class="about-content">
              <h3>Research Highlights </h3>
              <section class="blog-area">
                <div class="container pt-20">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <div class="finder-journal">
                        <ul>
                          {/* <li>
                            <Select
                              placeholder="Select Teacher "
                              onChange={(e) => {
                                setteacher(e.value);
                              }}
                              options={Teacher}
                            />
                          </li> */}

                          {/* <li class="rating">
                            <Select
                              placeholder="Select Class"
                              onChange={(e) => {
                                setclass(e.value);
                              }}
                              options={class_opt}
                            />
                          </li> */}
                          {/* <li class="rating">
                            <Select
                              placeholder="Select Session"
                              onChange={(e) => {
                                setsession(e.value);
                              }}
                              options={yearOptions}
                            />
                          </li> */}
                          {/* <li class="rating">
                            <Select
                              placeholder="Search "
                              onChange={(e) => {
                                setsearch(e.value);
                              }}
                            />
                          </li> */}
                          <input
                            placeholder="Search "
                            type="text"
                            onChange={(e) => setsearch(e.target.value)}
                          />
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="rac_meeting">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <table
                      style={{
                        width: "100%",
                        marginLeft: "3px",
                        marginTop: "9px",
                      }}
                    >
                      <tr>
                        <th>S.No</th>
                        <th>Title</th>
                        <th>Author</th>
                        <th>Year/Page No.</th>
                        <th>Research</th>
                      </tr>
                      {newdata}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button className="btn ">
            <CSVLink data={tabledata} filename="test.csv" headers={header}>
              download
            </CSVLink>
          </button>
        </div>
      </section>
    </div>
  );
}
const header = [
  { label: "S_No", key: "S_No" },
  { label: "Title", key: "Title" },
  { label: "Author", key: "Author" },
  { label: "Year", key: "Year" },
  { label: "Research_studies", key: "Research_studies" },
];
