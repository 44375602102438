/* eslint-disable */

import React, { Component } from "react";
import Select from "react-select";

import Footer from "./footer";
import Header from "./header";
import Slider8 from "../assets/images/slider/8.jpg";
// import "../assets/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip, faUpload } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import PDF_IMage from "../assets/images/pdf.png";
import pdf from "../assets/PDF FILE/Technical_Commety/A Minutes of the Technical Advisory  Committee Meeting held on.pdf";

function TechBordMeeting() {
  const year_option = [{ value: "2019", label: "2019" }];
  return (
    <div>
      {/* <Header /> */}

      <section class="about section">
        <div class="container">
          <div class="row">
            {/* <div class="col-lg-6 col-md-12 col-sm-12"> */}
            <div class="about-content">
              <h3>
                Meetings <span>Detail</span>
              </h3>
              <Select className="mt-1 mb-1 w-50" options={year_option} />

              <table style={{ width: "100%" }}>
                <tr>
                  <th>Held on Dated</th>
                  <th>Hosting /Chairman</th>
                  <th>Guest Person</th>
                  <th>Attached PDF Files</th>
                </tr>
                <tr>
                  <td>03/07/2019</td>
                  <td>Professor P. K. Mishra</td>
                  <td>
                    <span className="guest_name">
                      Mr. Hasib Ahmad and Dr. H.C. Malviya{" "}
                    </span>
                    , (AERC, Allahabad)
                    <span className="guest_name">Vikas Arora</span>, Training
                    Support Specialist, SurveyCTO (Dobility India Pvt Ltd)
                  </td>
                  <td>
                    <a href={pdf}>
                      <img src={PDF_IMage} />
                    </a>
                  </td>
                </tr>
              </table>
            </div>
            {/* </div> */}
          </div>
        </div>
      </section>

      {/* <!-- Start Clients --> */}
      {/* <Footer /> */}
    </div>
  );
}

export default TechBordMeeting;
