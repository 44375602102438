/* eslint-disable */
// import React from "react";
import React, { useState, useEffect } from "react";
import Header from "../header";
import Footer from "../footer";
import axios from "axios";
import { Modal } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip, faUpload } from "@fortawesome/free-solid-svg-icons";

function AdminPanel() {
  const [show, setShow] = useState(false);
  const [pdffile, setPdffile] = useState("");

  function handleFileChange(e) {
    const files = e.target.files[0];
    setPdffile(files);
    console.log("Guru4666", pdffile);
  }
  const SubmitPDF = () => {
    const formData = new FormData();
    formData.append("dataFile", pdffile);
    axios({
      method: "post",
      url: "http://127.0.0.1:3004/upload-avatar",
      data: formData,
    }).then((res) => {
      console.log("REs", res);
    });
  };

  //     }

  return (
    <>
      {/* <Header /> */}
      <div className="container">
        <div className="admin_panel">
          <h1>Admin Panel</h1>
        </div>
        <p>Upload your pdf with title</p>
        <div className="pdf_upload">
          <i className="fa fa-paperclip" aria-hidden="true">
            <FontAwesomeIcon icon={faPaperclip} />
          </i>
          Please Upload your file here...
          <i className="fa fa-upload" aria-hidden="true">
            <FontAwesomeIcon icon={faUpload} onClick={() => setShow(!show)} />
          </i>
        </div>

        <div className="pdf_upload">
          <i className="fa fa-paperclip" aria-hidden="true">
            <FontAwesomeIcon icon={faPaperclip} />
          </i>
          Please Upload your file here...
          <i className="fa fa-upload" aria-hidden="true">
            <FontAwesomeIcon icon={faUpload} />
          </i>
        </div>

        <div className="pdf_upload">
          <i className="fa fa-paperclip" aria-hidden="true">
            <FontAwesomeIcon icon={faPaperclip} />
          </i>
          Please Upload your file here...
          <i className="fa fa-upload" aria-hidden="true">
            <FontAwesomeIcon icon={faUpload} />
          </i>
        </div>
        <div className="add_impformation">
          <Modal
            show={show}
            onHide={() => this.handleModal2()}
            id="fullHeightModalRightfour"
            className="modal fade right"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title w-100" id="myModalLabel">
                  Upload PDF
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setShow(!show)}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="educatio_info">
                  <div className="form-group form_aside">
                    <input
                      type="text"
                      className="form-control"
                      name="awardTitle"
                      placeholder="PDF Title"
                      //   value={this.state.awardTitle}
                      //   onChange={this.handleChange}
                      required
                    />
                  </div>
                  <div className="form-group form_aside">
                    <input
                      type="file"
                      className="form-control"
                      id="files"
                      name="PDF"
                      onChange={handleFileChange}
                      accept="application/pdf"
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-center model_new_btn">
                <button type="button" className="btn" onClick={SubmitPDF}>
                  Submit
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}
export default AdminPanel;
