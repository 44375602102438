/* eslint-disable */

import React, { Component } from "react";
import { Link } from "react-router-dom";
import HO from "../assets/images/Teachers/HO.jpg";
import DR from "../assets/images/Teachers/DR.jpeg";
import HK from "../assets/images/Teachers/HK.jpg";
import PP from "../assets/images/Teachers/PP.jpeg";
import R from "../assets/images/Teachers/R.jpg";
import PK from "../assets/images/Teachers/PK.jpg";
import A from "../assets/images/Teachers/A.jpeg";
import RB from "../assets/images/Teachers/RB.jpg";
import O from "../assets/images/Teachers/O.jpg";
import SA from "../assets/images/Teachers/SA.jpg";
import SL from "../assets/images/Teachers/SL.jpg";

function ContactUs() {
  return (
    <div>
      {/* <Header /> */}
      {/* <div class="breadcrumbs overlay" data-stellar-background-ratio="0.7">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="content">
                <h2>Staff Contact Details</h2>
                <ul class="list">
                  <li>
                  <Link to="/">Home</Link>
                  </li>
                  <li>
                    <a href="#">Pages</a>
                  </li>
                  <li>
                  <Link to="contactUS">Staff Contact Details</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <section class="about section">
        <div class="container">
          <div class="row">
            {/* <div class="col-lg-6 col-md-12 col-sm-12"> */}
            <div class="about-content">
              <h3>
                Staff Contact <span>Details</span>
              </h3>
              <table style={{ width: "100%", marginLeft: "75px" }}>
                <tr>
                  <th>Name of the Employee</th>
                  <th>Nature of Appointment</th>
                  <th>Contact No.</th>
                  <th>Email Id</th>
                  <th>Photos</th>
                </tr>

                <tr>
                  <td>Dr. Deepak Rathi (Director)</td>
                  <td>Regular</td>
                  <td>9424601211</td>
                  <td>aerc_jbp@yahoo.co.in (O-Primary)</td>
                  <td>
                    <img className="teacher_photo" src={DR} alt="" />
                  </td>
                </tr>
                <tr>
                  <td>Dr. H.K. Niranjan </td>
                  <td>contractual</td>
                  <td>7898822558</td>
                  <td>aercjbp21@gmail.com</td>
                  <td>
                    <img className="teacher_photo" src={HK} alt="" />
                  </td>
                </tr>
                <tr>
                  <td>Dr. P.R. Pandey</td>
                  <td>contractual</td>
                  <td>9575092843</td>
                  <td>pandeypremratan83@gmail.com</td>
                  <td>
                    <img className="teacher_photo" src={PP} alt="" />
                  </td>
                </tr>
                <tr>
                  <td>Mr. Pradeep K. Patidar</td>
                  <td>contractual</td>
                  <td>8878680275</td>
                  <td>2015pkpatidar@gmail.com</td>
                  <td>
                    <img className="teacher_photo" src={PK} alt="" />
                  </td>
                </tr>
                <tr>
                  <td>Mr. R.S. Bareliya</td>
                  <td>contractual</td>
                  <td>8103009853</td>
                  <td>bareliyarajendra1m@gmail.com</td>
                  <td>
                    <img className="teacher_photo" src={R} alt="" />
                  </td>
                </tr>

                <tr>
                  <td>Mr. Akhilesh Kuril</td>
                  <td>contractual</td>
                  <td>9039242090</td>
                  <td>akhikuril35@gmail.com</td>
                  <td>
                    <img className="teacher_photo" src={A} alt="" />
                  </td>
                </tr>
                <tr>
                  <td>Mr. Rahul Birla</td>
                  <td>contractual</td>
                  <td>7999519403</td>
                  <td>Rahulbirla7777@gmail.com</td>
                  <td>
                    <img className="teacher_photo" src={RB} alt="" />
                  </td>
                </tr>
                <tr>
                  <td>Mr. Bhanwar Lal Osari</td>
                  <td>contractual</td>
                  <td>9644066527</td>
                  <td>osaribhanwar@gmail.com</td>
                  <td>
                    <img className="teacher_photo" src={O} alt="" />
                  </td>
                </tr>
                <tr>
                  <td>Mr. Sachin Aske</td>
                  <td>Regular</td>
                  <td>9575763899</td>
                  <td>sachinaske1992@gmail.com</td>
                  <td>
                    <img className="teacher_photo" src={SA} alt="" />
                  </td>
                </tr>
                <tr>
                  <td>Mr. Sushil Kumar Lodhi</td>
                  <td>Regular</td>
                  <td>9981344505</td>
                  <td>Nil</td>
                  <td>
                    <img className="teacher_photo" src={SL} alt="" />
                  </td>
                </tr>
              </table>
            </div>
            {/* </div> */}
          </div>
        </div>
      </section>

      {/* <!-- Start Clients --> */}
      {/* <Footer /> */}
    </div>
  );
}

export default ContactUs;
