/* eslint-disable */

import React from "react";
import Footer from "./footer";
import Header from "./header";
import Slider8 from "../assets/images/slider/8.jpg";
import "../assets/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faPaperclip,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import PDF_IMage from "../assets/images/pdf.png";
import PDF_1 from "../assets/PDF FILE/RAC/1 Mintues Final_3rd RAC Meeting_FINAL 27 Aug 2021.pdf";
import PDF_2 from "../assets/PDF FILE/RAC/2 Minutes of Directors Meeting held on 27th August 2021.pdf";
import PDF_3 from "../assets/PDF FILE/RAC/3 RAC Meeting (Workplan 2019-20).pdf";
import PDF_4 from "../assets/PDF FILE/RAC/4 CCCS meeting 2015.pdf";
import PDF_5 from "../assets/PDF FILE/RAC/5 CCOS Meeting 2013.pdf";
import { CSVLink } from "react-csv";

import { useState } from "react";
import Select from "react-select";

export default function Paper() {
  const [presented, setpresented] = useState("");
  const [year, setyear] = useState("");

  const yearOptions = [
    { value: "", label: "select" },
    { value: "2014", label: "2014" },
    { value: "2015", label: "2015" },
    { value: "2016", label: "2016" },
    { value: "2018", label: "2018" },
    { value: "2023", label: "2023" },
  ];

  const Data = [
    {
      Title_of_Papers:
        "मध्य प्रदेश की कृषि आर्थिकीय में फसल पद्धति में हुए परिवर्तनों का विश्लेषण",
      Authors: "Hariom Sharma, Deepak Rathi and H.K.Niranjan",
      Presented_By: "Dr.H.K.Niranjan",
      presentation: "Oral",
      Year: "2014",
      Mode: "Off-line",
      Title_of_Conferences: "Rastriy Hindi Vigyan Sammelan 1-2 Aug. 2014",
      Organized_by: "विज्ञान भारती,भोपाल,\nमध्यप्रदेश",
    },
    {
      Title_of_Papers:
        "Impact of National Food Security Mission (NFSM) on Adoption of Farm Mechanization in Madhya Pradesh.",
      Authors: "H.K.Niranjan, H. O Sharma, Deepak Rathi",
      Presented_By: "Dr.H.K.Niranjan",
      presentation: "Oral",
      Year: "2015",
      Mode: "Off-line",
      Title_of_Conferences:
        "Inter and Intra sectoral dynamics for transforming Indian Agriculture, 2-4 December 2015",
      Organized_by: "Agricultural Economics Research Review, New Delhi",
    },
    {
      Title_of_Papers:
        "Dynamic of Area Production and Productivity of Gram in Madhya Pradesh",
      Authors: "H.K.Niranjan and J.K.Gupta",
      Presented_By: "Dr.H.K.Niranjan",
      presentation: "Oral",
      Year: "2016",
      Mode: "Off-line",
      Title_of_Conferences:
        "Information and communication Management concerning climate smart Agriculture for sustainable development and povertyalleviation 28-30 Nov 2016",
      Organized_by: "Indian Society of Extension Education, New Delhi",
    },
    {
      Title_of_Papers:
        "Estimation of Total Factor Productivity Growth of Maize Production in Central India.",
      Authors: "H.K.Niranjan, J.K.Gupta, P.Mishra and R.S.Chouhan",
      Presented_By: "Dr.H.K.Niranjan",
      presentation: "Poster",
      Year: "2018",
      Mode: "Off-line",
      Title_of_Conferences:
        "Global Research Initiatives for Sustainable Agriculture & Allied Science (GRISAAS 2018) 20-30 Oct 2018",
      Organized_by:
        "Society for Scientific Development in Agriculture & Technology. Meerut (U.P.)",
    },
    {
      Title_of_Papers:
        "Cost and Return Analysis of Cultivation of Kodo and Kutki in Madhya Pradesh",
      Authors: "Rajendra Singh Bareliya, Rahul Birla and B.L.Osari",
      Presented_By: "Mr. Rajendra Singh Bareliya",
      presentation: "Poster",
      Year: "2023",
      Mode: "Off-line",
      Title_of_Conferences:
        "National Conference on Production, Processing and Marketing of Millets : Issue and Solutions 1-2 March 2023",
      Organized_by: "College of Agriculture, JNKVV, Jabalpur",
    },
    {
      Title_of_Papers:
        "Economic Analysis of Processing of Kodo and its Value Added Products in Madhya Pradesh",
      Authors: "Deepak Rathi, H.K.Niranjan and P.K.Patidar",
      Presented_By: "Dr.Deepak Rathi",
      presentation: "Poster",
      Year: "2023",
      Mode: "Off-line",
      Title_of_Conferences:
        "National Conference on Production, Processing and Marketing of Millets : Issue and Solutions 1-2 March 2023",
      Organized_by: "College of Agriculture, JNKVV, Jabalpur",
    },
    {
      Title_of_Papers:
        "Processing of Kutki and its Value Added Products in Madhya Pradesh: An Economic Analysis",
      Authors: "P.R.Pandey and Akhilesh Kuril",
      Presented_By: "Dr.P.R.Pandey",
      presentation: "Poster",
      Year: "2023",
      Mode: "Off-line",
      Title_of_Conferences:
        "National Conference on Production, Processing and Marketing of Millets : Issue and Solutions 1-2 March 2023",
      Organized_by: "College of Agriculture, JNKVV, Jabalpur",
    },
    {
      Title_of_Papers:
        "Economics of Milk Production & Awareness of Dairying Schemes in Chhattisgarh State",
      Authors: "Deepak Rathi, H.O.Sharma, H.K.Niranjan & R.S.Bareliya",
      Presented_By: "Dr.Deepak Rathi",
      presentation: "Lead Paper",
      Year: "2023",
      Mode: "Off-line",
      Title_of_Conferences:
        "Regional Conference on Recent Innovations in Agricultural Sector: Preliminary Insights and Emerging Perspectives 29-30 May, 2023",
      Organized_by:
        "Indian Society of Agricultural Economics, Mumbai & Department of Agriculture Economics &FM",
    },
    {
      Title_of_Papers:
        "Benefits & Challenges of Micro Irrigation under Pradhan Mantri Krishi Sinchayee Yojana - Per Drop More Crop in Madhya Pradesh",
      Authors: "Hari Om Sharma",
      Presented_By: "Dr. Hari Om Sharma",
      presentation: "Lead Paper",
      Year: "2023",
      Mode: "Off-line",
      Title_of_Conferences:
        "Regional Conference on Recent Innovations in Agricultural Sector: Preliminary Insights and Emerging Perspectives 29-30 May, 2023",
      Organized_by:
        "Indian Society of Agricultural Economics, Mumbai & Department of Agriculture Economics &FM",
    },
    {
      Title_of_Papers:
        "Procurement of Paddy on MSP Under Decentralize Food Procurement Scheme in Chhattisgarh",
      Authors: "Akhilesh Kuril, Bhanwarlal Osari, Deepak Rathi and Sachin Aske",
      Presented_By: "Mr. Akhilesh Kuril",
      presentation: "Poster",
      Year: "2023",
      Mode: "Off-line",
      Title_of_Conferences:
        "Regional Conference on Recent Innovations in Agricultural Sector: Preliminary Insights and Emerging Perspectives 29-30 May, 2023",
      Organized_by:
        "Indian Society of Agricultural Economics, Mumbai & Department of Agriculture Economics &FM",
    },
    {
      Title_of_Papers:
        "Effectiveness of seed Mini-kits program of pulses with special reference to Urd in Central India",
      Authors: "B.L.Osari, Sachin Aske, Rahul Birla and D. Rathi",
      Presented_By: "Mr. B.L.Osari",
      presentation: "Poster",
      Year: "2023",
      Mode: "Off-line",
      Title_of_Conferences:
        "Regional Conference on Recent Innovations in Agricultural Sector: Preliminary Insights and Emerging Perspectives 29-30 May, 2023",
      Organized_by:
        "Indian Society of Agricultural Economics, Mumbai & Department of Agriculture Economics &FM",
    },
    {
      Title_of_Papers:
        "Impact of “Development of Model Village Programme-Soil Health Card” in Damoh district of Madhya Pradesh with special reference in Paddy",
      Authors: "P. R. Pandey, R. S. Bareliya, H. K. Niranjan, H. O. Sharma",
      Presented_By: "Dr.P.R.Pandey",
      presentation: "Poster",
      Year: "2023",
      Mode: "Off-line",
      Title_of_Conferences:
        "Regional Conference on Recent Innovations in Agricultural Sector: Preliminary Insights and Emerging Perspectives 29-30 May, 2023",
      Organized_by:
        "Indian Society of Agricultural Economics, Mumbai & Department of Agriculture Economics &FM",
    },
    {
      Title_of_Papers:
        "Farmers’ Perceptions regarding Decentralized Procurement Scheme for Procurement of Wheat in Madhya Pradesh",
      Authors:
        "Pradeep Kumar Patidar, Hemant Kumar Niranjan, Rahul, H.O. Sharma",
      Presented_By: "Mr. Pradeep Kumar Patidar",
      presentation: "Poster",
      Year: "2023",
      Mode: "Off-line",
      Title_of_Conferences:
        "Regional Conference on Recent Innovations in Agricultural Sector: Preliminary Insights and Emerging Perspectives 29-30 May, 2023",
      Organized_by:
        "Indian Society of Agricultural Economics, Mumbai & Department of Agriculture Economics &FM",
    },
    {
      Title_of_Papers:
        "Policies and Programmes for Dairy Development and Convergence of Schemes with Special Reference to Chhattisgarh state",
      Authors: "R.S. Bareliya, H.K. Niranjan, D. Rathi and P.R. Pandey",
      Presented_By: "Mr. R.S. Bareliya",
      presentation: "Poster",
      Year: "2023",
      Mode: "Off-line",
      Title_of_Conferences:
        "Regional Conference on Recent Innovations in Agricultural Sector: Preliminary Insights and Emerging Perspectives 29-30 May, 2023",
      Organized_by:
        "Indian Society of Agricultural Economics, Mumbai & Department of Agriculture Economics &FM",
    },
    {
      Title_of_Papers:
        "Impact of NFSM on Area Production & Yield of Moong & Urd Across Agro Cimatic Zones of Central India",
      Authors: "Rahul Birla, Akhilesh Kuril, B.L. Osari and D. Rathi",
      Presented_By: "Mr. Rahul Birla",
      presentation: "Poster",
      Year: "2023",
      Mode: "Off-line",
      Title_of_Conferences:
        "Regional Conference on Recent Innovations in Agricultural Sector: Preliminary Insights and Emerging Perspectives 29-30 May, 2023",
      Organized_by:
        "Indian Society of Agricultural Economics, Mumbai & Department of Agriculture Economics &FM",
    },
  ];
  const presentedby_opt = [
    { label: "select", value: "" },
    { value: "Niranjan", label: "Niranjan" },
    {
      label: "Bareliya",
      value: "Bareliya",
    },
    { label: "Rathi", value: "Rathi" },
    { label: "Pandey", value: "Pandey" },
    { label: "Sharma", value: "Sharma" },
    { label: "Kuril", value: "Kuril" },
    { label: "Osari", value: "Osari" },
    { label: "Patidar", value: "Patidar" },

    { label: "Birla", value: "Birla" },
  ];
  const fac_opt = [
    { label: "select", value: "" },
    { label: "Mr. R.S.Bareliya", value: "Mr. R.S.Bareliya" },
    { label: "Dr. P.R. Pandey", value: "Dr. P.R. Pandey" },
    { label: "Dr. H.K.Niranjan", value: "Dr. H.K.Niranjan" },
  ];

  const tabledata = Data.filter(
    (item) => item.Year.includes(year) && item.Presented_By.includes(presented)
  );

  const newdata = tabledata.map((item, i) => {
    return (
      <tr key={i}>
        <td>{item.Title_of_Papers}</td>
        <td>{item.Authors}</td>
        <td>{item.Presented_By}</td>
        <td>{item.presentation}</td>
        <td>{item.Year}</td>
        <td>{item.Mode}</td>
        <td>{item.Title_of_Conferences}</td>
        <td>{item.Organized_by}</td>
      </tr>
    );
  });
  return (
    <div>
      <section class="about section">
        <div class="container">
          <div class="row">
            <div class="about-content">
              <h3> Paper Presented In Seminar </h3>
              <section class="blog-area">
                <div class="container pt-20">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <div class="finder">
                        <ul>
                          <li>
                            <Select
                              placeholder="Presented By "
                              onChange={(e) => {
                                setpresented(e.value);
                              }}
                              options={presentedby_opt}
                            />
                          </li>

                          <li class="rating">
                            <Select
                              placeholder="Select Year"
                              onChange={(e) => {
                                setyear(e.value);
                              }}
                              options={yearOptions}
                            />
                          </li>

                          {/* <li class="rating">
                            <Select
                              placeholder="Search "
                              onChange={(e) => {
                                setsearch(e.value);
                              }}
                            />
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="rac_meeting">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <table
                      style={{
                        width: "100%",
                        marginLeft: "3px",
                        marginTop: "9px",
                      }}
                    >
                      <tr>
                        <th style={{ width: "25%" }}>Title of Papers</th>
                        <th>Authors</th>
                        <th>Presented By</th>
                        <th>Presentation (Filter- Oral/Poster/Lead Paper)</th>
                        <th>Year</th>
                        <th>Mode</th>
                        <th>Title of Conferences</th>
                        <th>Organized by</th>
                      </tr>
                      {newdata}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button className="btn ">
            <CSVLink data={tabledata} filename="test.csv" headers={header}>
              download
            </CSVLink>
          </button>
        </div>
      </section>
    </div>
  );
}
const header = [
  { label: "s_no", key: "s_no" },
  { label: "society_institute", key: "society_institute" },
  { label: "Date", key: "Date" },
  { label: "recipt_no", key: "recipt_no" },
  { label: "type_of_membership", key: "type_of_membership" },
  { label: "faculty_name", key: "faculty_name" },
];
