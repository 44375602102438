/* eslint-disable */
import React, { Component } from "react";
import ReactDOM from "react-dom";
// import React from "react";

import { BrowserRouter, Route, Switch } from "react-router-dom";

import "./assets/style.css";
import "./assets/css/nice-select.css";
import "./assets/css/bootstrap.css";
import "./assets/css/datepicker.css";
import "./assets/css/jquery-ui.css";
import "./assets/css/jquery.fancybox.min.css";
import "./assets/css/magnific-popup.min.css";
// import "./assets/css/font-awesome.css"
import "./assets/css/icofont.min.css";
import "./assets/css/owl-carousel.css";
import "./assets/css/slicknav.min.css";
import "./assets/css/animate.css";
// <!-- Travelia StyleSheet -->
import "./assets/css/reset.css?id=21";
import "./assets/style.css?id=21";
import "./assets/css/responsive.css";

import "./assets/js/owl-carousel";
import "./assets/js/slicknav.min.js";

import AdminPanel from "./components/Admin_Panel/admin_panel";
import App from "./App";
import Login from "./components/Admin_Panel/admin_login";
import AboutAerc from "./components/aboutAERC";
import AreaOperation from "./components/areaOperation";
import Mandate from "./components/mandate";
import ContactUs from "./components/contactUs";
import AdvBordMeeting from "./components/advMeetingDetail";
import TechBordMeeting from "./components/technicalMeetingDetail";
import OtherMeeting from "./components/other_meeting";
import Header from "./components/header";
import Footer from "./components/footer";
import ABMStructure from "./components/ABM_Structur";
import StaffingPatter from "./components/staffing_position";
import ReviewStructure from "./components/review_structur";
import QuickLinks from "./components/quick_links";
import ContactDetails from "./components/contact_details";
import CompletedStudies from "./components/research_vilage_studies";
import VilageStudies from "./components/research_vilage_studies";
import AERCCompleted from "./components/research_completed_studies";
import RACMeeting from "./components/rac_meetings";
import ExternalFunded from "./components/externalFunded";
import OngoingStudies from "./components/OngoingStudies";
import PdfOperner from "./components/pdf_opener";
import JournalArticle from "./components/JournalArticle";
import ExternalFundedList from "./components/ExternalFundList";
import Directory from "./components/Directory";
import Policy_Brief_Alert from "./components/Policy_Brief_Alert";
import Download from "./components/Download";
import Capacity_building from "./components/Capacity_building";
import Course_alloted from "./components/Course_alloted";
import Annual_membership from "./components/Annual_membership";
import Achievment_award from "./components/Achievment_award";
import Student_research from "./components/Student_research";
import Conference_workshop from "./components/Conference_workshop";
import Paper from "./components/Paper";
import Lecture from "./components/Lecture";
import Human_resource from "./components/Human_resource";
import Book_Chapter from "./components/Book_Chapter";
import Credit_Seminar from "./components/Credit_Seminar";
import Abstract from "./components/Abstract";
import Research_highlight from "./components/Research_highlight";
import Communication_AERC from "./components/Communication_AERC";
import Staff_engaging from "./components/Staff_engaging.js";

class Root extends React.Component {
  render() {
    return (
      <>
        <BrowserRouter basename={"/"}>
          <Header />
          <Switch>
            <Route exact={true} path="/" component={App} />
            <Route exact={true} path="/admin_panel" component={AdminPanel} />
            <Route exact={true} path="/login" component={Login} />
            <Route exact={true} path="/aboutAerc" component={AboutAerc} />
            <Route
              exact={true}
              path="/areaOperation"
              component={AreaOperation}
            />
            <Route exact={true} path="/mandate" component={Mandate} />
            <Route exact={true} path="/contactUS" component={ContactUs} />
            <Route
              exact={true}
              path="/advBodyMeeting"
              component={AdvBordMeeting}
            />
            <Route
              exact={true}
              path="/techBodyMeeting"
              component={TechBordMeeting}
            />
            <Route exact={true} path="/otherMeeting" component={OtherMeeting} />
            <Route exact={true} path="/ABMStructure" component={ABMStructure} />
            <Route
              exact={true}
              path="/staffingPattern"
              component={StaffingPatter}
            />
            <Route
              exact={true}
              path="/reviewStructure"
              component={ReviewStructure}
            />
            <Route exact={true} path="/quickLinks" component={QuickLinks} />
            <Route
              exact={true}
              path="/contactDetails"
              component={ContactDetails}
            />
            <Route
              exact={true}
              path="/completedStudies"
              component={AERCCompleted}
            />
            <Route
              exact={true}
              path="/vilageStudies"
              component={VilageStudies}
            />
            <Route exact={true} path="/racMeetings" component={RACMeeting} />
            <Route
              exact={true}
              path="/externalFunded"
              component={ExternalFunded}
            />
            <Route
              exact={true}
              path="/externalFundlist"
              component={ExternalFundedList}
            />
            <Route
              exact={true}
              path="/ongoingstudies"
              component={OngoingStudies}
            />
            <Route
              exact={true}
              path="/JournalArticle"
              component={JournalArticle}
            />
            <Route
              exact={true}
              path="/policyalert"
              component={Policy_Brief_Alert}
            />
            <Route exact={true} path="/directory" component={Directory} />
            <Route exact={true} path="/pdf" component={PdfOperner} />
            <Route exact={true} path="/Download" component={Download} />
            <Route
              exact={true}
              path="/capacity"
              component={Capacity_building}
            />
            <Route
              exact={true}
              path="/course_alloted"
              component={Course_alloted}
            />
            <Route
              exact={true}
              path="/annual_membership"
              component={Annual_membership}
            />
            <Route
              exact={true}
              path="/achievment"
              component={Achievment_award}
            />
            <Route
              exact={true}
              path="/student_research"
              component={Student_research}
            />
            <Route
              exact={true}
              path="/conference"
              component={Conference_workshop}
            />
            <Route exact={true} path="/paper" component={Paper} />
            <Route exact={true} path="/lecture" component={Lecture} />
            <Route exact={true} path="/human" component={Human_resource} />
            <Route exact={true} path="/book_chapter" component={Book_Chapter} />
            <Route
              exact={true}
              path="/credit_seminar"
              component={Credit_Seminar}
            />
            <Route exact={true} path="/abstract" component={Abstract} />
            <Route
              exact={true}
              path="/research_highlight"
              component={Research_highlight}
            />
            <Route
              exact={true}
              path="/communication"
              component={Communication_AERC}
            />
            <Route
              exact={true}
              path="/Staff_engaging"
              component={Staff_engaging}
            />
          </Switch>
          <Footer />
        </BrowserRouter>
      </>
    );
  }
}
ReactDOM.hydrate(<Root />, document.getElementById("root"));
