/* eslint-disable */

import React from "react";
import Footer from "./footer";
import Header from "./header";
import Slider8 from "../assets/images/slider/8.jpg";
import "../assets/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faPaperclip,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import PDF_IMage from "../assets/images/pdf.png";
import PDF_1 from "../assets/PDF FILE/RAC/1 Mintues Final_3rd RAC Meeting_FINAL 27 Aug 2021.pdf";
import PDF_2 from "../assets/PDF FILE/RAC/2 Minutes of Directors Meeting held on 27th August 2021.pdf";
import PDF_3 from "../assets/PDF FILE/RAC/3 RAC Meeting (Workplan 2019-20).pdf";
import PDF_4 from "../assets/PDF FILE/RAC/4 CCCS meeting 2015.pdf";
import PDF_5 from "../assets/PDF FILE/RAC/5 CCOS Meeting 2013.pdf";
import { CSVLink } from "react-csv";
import { useId } from "react";

import { useState } from "react";
import Select from "react-select";

export default function Human_resource() {
  //   const [teacher, setteacher] = useState("");
  //   const [fil_class, setclass] = useState("");
  //   const [session, setsession] = useState("");
  const [search, setsearch] = useState("");

  const Data = [
    {
      Name_of_Employee: "Mr. Dushyant Dhakar",
      Position_in_AER_Centre: "Research Associate",
      Position_in_Present: "Deputy Director of Agriculture",
      Place: "Raisen, Madhya Pradesh",
      Year_of_Leaving: "2014",
    },
    {
      Name_of_Employee: "Mr. Arvindra Dangi",
      Position_in_AER_Centre: "Senior Research Fellow",
      Position_in_Present: "Technical Assistant, Department of Agriculture",
      Place: "Mahoba, Uttar Pradesh",
      Year_of_Leaving: "2014",
    },
    {
      Name_of_Employee: "Dr. Ravi Singh Chouhan",
      Position_in_AER_Centre: "Research Associate",
      Position_in_Present: "Technical Assistant, Cost of Cultivation Scheme",
      Place: "Jabalpur, Madhya Pradesh",
      Year_of_Leaving: "2019",
    },
    {
      Name_of_Employee: "Dr. Shiv Charan Meena",
      Position_in_AER_Centre: "Senior Research Fellow",
      Position_in_Present: "Assistant Advisor, NITI Ayog",
      Place: "New Delhi",
      Year_of_Leaving: "2017",
    },
    {
      Name_of_Employee: "Mr. Satyendra Singh Thakur",
      Position_in_AER_Centre: "Research Associate",
      Position_in_Present: "Teacher (Gr-I)",
      Place: "MP TRIBA Education Department, Betul",
      Year_of_Leaving: "2022",
    },
    {
      Name_of_Employee: "Mr. Khadak Chandra Birla",
      Position_in_AER_Centre: "Senior Research Fellow",
      Position_in_Present: "Bank Manager, Union Bank of India",
      Place: "Ratalam, Madhya Pradesh",
      Year_of_Leaving: "2012",
    },
    {
      Name_of_Employee: "Shri Harishankar Patel",
      Position_in_AER_Centre: "Senior Research Fellow",
      Position_in_Present: "Teacher (Gr-I)",
      Place: "MP TRIBA Education Department, Itarsi",
      Year_of_Leaving: "2018",
    },
  ];

  const tabledata = Data.filter((item) => {
    const searchText = search.toLowerCase();
    const emp_name = item.Name_of_Employee.toLowerCase().includes(searchText);
    const position_in_center =
      item.Position_in_AER_Centre.toLowerCase().includes(searchText);
    const position_in_present =
      item.Position_in_Present.toLowerCase().includes(searchText);
    const place = item.Place.toLowerCase().includes(searchText);
    const year_of_leaving =
      item.Year_of_Leaving.toLowerCase().includes(searchText);

    return (
      emp_name ||
      position_in_center ||
      position_in_present ||
      place ||
      year_of_leaving
    );
  });
  const newdata = tabledata.map((item, i) => {
    return (
      <tr key={i}>
        <td>{item.Name_of_Employee}</td>
        <td>{item.Position_in_AER_Centre}</td>
        <td>{item.Position_in_Present}</td>
        <td>{item.Place}</td>
        <td>{item.Year_of_Leaving}</td>
      </tr>
    );
  });

  return (
    <div>
      <section class="about section">
        <div class="container">
          <div class="row">
            <div class="about-content">
              <h3>Human Resource Development </h3>
              <section class="blog-area">
                <div class="container pt-20">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <div class="finder-journal">
                        <ul>
                          {/* <li>
                            <Select
                              placeholder="Select Teacher "
                              onChange={(e) => {
                                setteacher(e.value);
                              }}
                              options={Teacher}
                            />
                          </li> */}

                          {/* <li class="rating">
                            <Select
                              placeholder="Select Class"
                              onChange={(e) => {
                                setclass(e.value);
                              }}
                              options={class_opt}
                            />
                          </li> */}
                          {/* <li class="rating">
                            <Select
                              placeholder="Select Session"
                              onChange={(e) => {
                                setsession(e.value);
                              }}
                              options={yearOptions}
                            />
                          </li> */}
                          {/* <li class="rating">
                            <Select
                              placeholder="Search "
                              onChange={(e) => {
                                setsearch(e.value);
                              }}
                            />
                          </li> */}
                          <input
                            placeholder="Search "
                            type="text"
                            onChange={(e) => setsearch(e.target.value)}
                          />
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="rac_meeting">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <table
                      style={{
                        width: "100%",
                        marginLeft: "3px",
                        marginTop: "9px",
                      }}
                    >
                      <tr>
                        <th>Name of Employee</th>
                        <th>Position in AER Centre</th>
                        <th>Position in Present</th>
                        <th>Place</th>
                        <th>Year of Leaving</th>
                      </tr>
                      {newdata}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button className="btn ">
            <CSVLink data={tabledata} filename="test.csv" headers={header}>
              download
            </CSVLink>
          </button>
        </div>
      </section>
    </div>
  );
}
const header = [
  { label: "Filter-Class", key: "Filter_Class" },
  { label: "Code", key: "Code" },
  { label: "Subject Name", key: "Subject_Name" },
  { label: "Credit", key: "Credit" },
  { label: "Filter-Teacher", key: "Filter_Teacher" },
  { label: "Filter-Session", key: "Filter_Session" },
  { label: "Search", key: "Search" },
];
