/* eslint-disable */
import {
  faFacebook,
  faGooglePlus,
  faLinkedin,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import JNKVlogo from "../assets/images/JNKVV-Logo.png";
import logoDigitalIndia from "../assets/images/logo-digital-india.png";
import makeInIndiaLogo from "../assets/images/logo-make-in-india.png";
import swatchhBharatLogo from "../assets/images/logo-swach-bharat-abhiyan.png";
import Azadi from "../assets/images/Azadi.png";

function Footer() {
  return (
    <>
      <div id="clients" class="clients section">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="clients-slider">
                {/* <!-- Single Clients --> */}
                <div class="single-clients">
                  {" "}
                  <a href="#" target="_blank">
                    <img src={logoDigitalIndia} alt="#" />
                  </a>{" "}
                </div>
                {/* <!--/ End Single Clients --> */}
                {/* <!-- Single Clients --> */}
                <div class="single-clients active">
                  {" "}
                  <a href="#" target="_blank">
                    <img src={makeInIndiaLogo} alt="#" />
                  </a>{" "}
                </div>
                {/* <!--/ End Single Clients --> */}
                {/* <!-- Single Clients --> */}
                <div class="single-clients">
                  {" "}
                  <a href="#" target="_blank">
                    <img src={swatchhBharatLogo} alt="#" />
                  </a>{" "}
                </div>
                {/* <!--/ End Single Clients --> */}
                {/* <!-- Single Clients --> */}
                <div class="single-clients">
                  <a href="#" target="_blank">
                    <img src={Azadi} alt="#" />
                  </a>
                </div>
                {/* <!--/ End Single Clients --> */}
                {/* <!-- Single Clients --> */}
                <div class="single-clients active">
                  <a href="#" target="_blank">
                    <img src={makeInIndiaLogo} alt="#" />
                  </a>{" "}
                </div>
                {/* <!--/ End Single Clients --> */}
                {/* <!-- Single Clients --> */}
                <div class="single-clients">
                  {" "}
                  <a href="#" target="_blank">
                    <img src={swatchhBharatLogo} alt="#" />
                  </a>{" "}
                </div>
                {/* <!--/ End Single Clients --> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer class="footer">
        {/* <!-- Footer Top --> */}
        <div class="footer-top">
          <div class="container">
            <div class="row">
              {/* <!-- Single Widget --> */}
              <div class="col-lg-3 col-md-6 col-12">
                <div class="single-widget about">
                  {" "}
                  <img src={JNKVlogo} alt="#" />
                  <p>Please Subscribe our newslatter to get regular updates.</p>
                  <div class="newslatter-form">
                    <div class="form">
                      <input type="email" placeholder="Enter your email" />
                      <button type="text" value="send">
                        Subscribe
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--/ End Single Widget --> */}
              {/* <!-- Single Widget --> */}
              <div class="col-lg-3 col-md-6 col-12">
                <div class="single-widget">
                  <h2>Quick Link</h2>
                  <ul>
                    <li>
                      <a href="#">About Us</a>
                    </li>
                    <li>
                      <a href="#">Governance</a>
                    </li>
                    <li>
                      <a href="#">Organization Structure</a>
                    </li>
                    <li>
                      <a href="#">Research </a>
                    </li>
                    <li>
                      <a href="#">Publication</a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <!--/ End Single Widget --> */}
              {/* <!-- Single Widget --> */}
              <div class="col-lg-3 col-md-6 col-12">
                <div class="single-widget">
                  <h2>Some Info</h2>
                  <ul>
                    <li>
                      <a href="#">About Us</a>
                    </li>
                    <li>
                      <a href="#">Governance</a>
                    </li>
                    <li>
                      <a href="#">Organization Structure</a>
                    </li>
                    <li>
                      <a href="#">Research </a>
                    </li>
                    <li>
                      <a href="#">Publication</a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <!--/ End Single Widget --> */}
              {/* <!-- Single Widget --> */}
              <div class="col-lg-3 col-md-6 col-12">
                <div class="single-widget contact-info">
                  <h2>Contact Information</h2>
                  <ul class="contact-inner">
                    <li>
                      <p>
                        <span>Location:</span>2nd Floor College of Agriculture,
                        J.N.K.V.V., Jabalpur 482004 Madhya Pradesh
                      </p>
                    </li>
                    <li>
                      <p>
                        <span>Email:</span>aerc_jbp@yahoo.co.in
                      </p>
                    </li>
                    <li>
                      <p>
                        <span>Phone:</span>+91-0761-2680315
                      </p>
                    </li>
                  </ul>
                  {/* <!-- Social --> */}
                  <ul class="social">
                    <li>
                      <a href="#">
                        <i class="fa fa-facebook">
                          <FontAwesomeIcon icon={faFacebook} />
                        </i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fa fa-linkedin">
                          <FontAwesomeIcon icon={faLinkedin} />
                        </i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fa fa-twitter">
                          <FontAwesomeIcon icon={faTwitter} />
                        </i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fa fa-google-plus">
                          <FontAwesomeIcon icon={faGooglePlus} />
                        </i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fa fa-youtube">
                          <FontAwesomeIcon icon={faYoutube} />
                        </i>
                      </a>
                    </li>
                  </ul>
                  {/* <!--/ End Social --> */}
                </div>
              </div>
              {/* <!--/ End Single Widget --> */}
            </div>
          </div>
        </div>
        {/* <!--/ End Footer Top -->
    <!-- Footer Bottom --> */}
        <div class="footer-bottom">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="bottom-inner">
                  <div class="row">
                    <div class="col-12">
                      {/* <!-- Copyright --> */}
                      <div class="copyright">
                        <p>
                          Copyright &#9400; 2022 by <a href="#">AERC</a> All
                          Right Reserved.
                        </p>
                      </div>
                      {/* <!--/ End Copyright --> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--/ End Footer Bottom --> */}
      </footer>
    </>
  );
}
export default Footer;
