import React from "react";
import PDF_IMage from "../assets/images/pdf.png";
import pdf from "../assets/PDF FILE/OngoingStudies/For Web Matter.pdf";
function OngoingStudies() {
  // const data = [
  //   {
  //     s_no: "1",
  //     title:
  //       "Farmer Perception Regarding Production and Marketing of Quality Seed in Madhya Pradesh",
  //     unit: "AERC, Jabalpur",
  //     study: "AER Centre, Jabalpur",
  //     state: "Madhya Pradesh",
  //     district_coverd: "3",
  //     name_of_dist:
  //       " Hoshangabad for Wheat, Dewas for Gram and Ujjain for Soybean",
  //     sample_size: "120",
  //   },
  //   {
  //     s_no: "2",
  //     title:
  //       "Estimating and Bridging the Yield Gaps in Soybean in Madhya Pradesh for Atam Nirbhar Bharat",
  //     unit: "AERC, Delhi",
  //     study: "AERC, Delhi",
  //     state: "Madhya Pradesh",
  //     district_coverd: "2",
  //     name_of_dist: "Ujjain, Rajgarh",
  //     sample_size: "180",
  //   },
  //   {
  //     s_no: "3",
  //     title:
  //       "Cost of Milk Production and Gross Returns to Milk Producers in Madhya Pradesh",
  //     unit: "AERC, ANAND Gujarat",
  //     study:
  //       "Dairy Division, DAHD, Ministry of Fishries, Animal Husbandary and Dairying",
  //     state: "Madhya Pradesh",
  //     district_coverd: "6",
  //     name_of_dist: "Alirajpur, Mandsour, Seopur, Katni, Chhatarpur and Mandla",
  //     sample_size: "300",
  //   },
  //   {
  //     s_no: "4",
  //     title:
  //       "Decentralized Procurement Scheme for Procurement of Wheat under Minimum Support Price(MSP) in Madhya Pradesh",
  //     unit: "IEG, New Delhi",
  //     study:
  //       "Department of Food and Public Distribution, Ministory of Consumer Affairs, Food and Public Distribution",
  //     state: "Madhya Pradesh",
  //     district_coverd: "2",
  //     name_of_dist: "	Hoshangabad and Vidisha",
  //     sample_size: "180",
  //   },
  //   {
  //     s_no: "5",
  //     title:
  //       "	Decentralized Procurement Scheme for Procurement of Paddy under Minimum Support Price(MSP) in Chhattisgarh",
  //     unit: "IEG, New Delhi",
  //     study:
  //       "Department of Food and Public Distribution, Ministory of Consumer Affairs, Food and Public Distribution",
  //     state: "Chhattisgarh",
  //     district_coverd: "2",
  //     name_of_dist: "Janjgir-Champa and Rajnandgaon",
  //     sample_size: "180",
  //   },
  //   {
  //     s_no: "6",
  //     title:
  //       "An Analysis of Factors Contributing to the Production and Productivity of Pulses in Madhya Pradesh- The study of Urd and Moong bean based on the successful experience of Myanmar.",
  //     unit: "IIM Ahemadabad Gujarat",
  //     study: "NFSM Cell, Crops & PHMF Division, DA&FW",
  //     state: "Madhya Pradesh",
  //     district_coverd: "1",
  //     name_of_dist: "Satna for Urd and Moong",
  //     sample_size: "200",
  //   },
  //   {
  //     s_no: "7",
  //     title: "Land Titling and Agricultural Productivity in Madhya Pradesh",
  //     unit: "ADRTC, ISEC Bengluru, Karnataka",
  //     study: "ADRTC, ISEC Bengluru, Karnataka",
  //     state: "Madhya Pradesh",
  //     district_coverd: "2",
  //     name_of_dist: "Ratlam and Damoh",
  //     sample_size: "240",
  //   },
  //   {
  //     s_no: "8",
  //     title:
  //       "Impact Evaluation of Pilot Project “Development of Model Villages under Soil Health Card Scheme’’ in Madhya Pradesh",
  //     unit: "ADRTC, ISEC Bengluru, Karnataka",
  //     study: "Fertilizer use Cell/INM Division, DA&FW",
  //     state: "Madhya Pradesh",
  //     district_coverd: "3",
  //     name_of_dist: "Ujjain, Rajgarh",
  //     sample_size: "180",
  //   },
  //   {
  //     s_no: "9",
  //     title:
  //       "Farmers Suicide: Causes and Policy Prescription in Madhya Pradesh",
  //     unit: "ADRTC, ISEC Bengluru, Karnataka",
  //     study: "Policy, Division, DA&FW",
  //     state: "Madhya Pradesh",
  //     district_coverd: "2",
  //     name_of_dist: "Rewa-Re-Survey Alirajpur-New Survey",
  //     sample_size: "120",
  //   },
  //   {
  //     s_no: "10",
  //     title: "Farmers Suicide: Causes and Policy Prescription in Chhattisgarh",
  //     unit: "ADRTC, ISEC Bengluru, Karnataka",
  //     study: "Policy, Division, DA&FW",
  //     state: "Chhattisgarh",
  //     district_coverd: "2",
  //     name_of_dist: "Surajpur-Re-Survey Bilashpur-New Survey",
  //     sample_size: "120",
  //   },
  //   {
  //     s_no: "11",
  //     title: "Consumption Pattern of Edible Oils in Madhya Pradesh",
  //     unit: "IEG, New Delhi",
  //     study: "Oilseeds Division, DA&FW",
  //     state: "Madhya Pradesh",
  //     district_coverd: "5",
  //     name_of_dist: "Jabalpur, Satna, Sheopur, Ratlam, Betul",
  //     sample_size: "500",
  //   },
  //   {
  //     s_no: "11",
  //     title: "Consumption Pattern of Edible Oils in Madhya Pradesh",
  //     unit: "IEG, New Delhi",
  //     study: "Oilseeds Division, DA&FW",
  //     state: "Madhya Pradesh",
  //     district_coverd: "5",
  //     name_of_dist: "Jabalpur, Satna, Sheopur, Ratlam, Betul",
  //     sample_size: "500",
  //   },
  // ];
  // const NewData = data.map((item, i) => (
  //   <tr key={i}>
  //     <td>{item.s_no}</td>
  //     <td>{item.title}</td>
  //     <td>{item.unit}</td>
  //     <td>{item.study}</td>
  //     <td>{item.state}</td>
  //     <td>{item.district_coverd}</td>
  //     <td>{item.name_of_dist}</td>
  //     <td>{item.sample_size}</td>
  //   </tr>
  // ));

  const Data = [
    {
      study: "AERC, Pune",
      assign: "Agriculture Infrastructure Fund (AIF )",
      sample: "130",
      status: "Done Before 4 Oct.2024",
    },
    {
      study: "AERC Prayagraj",
      assign: "Pradhan Mantri KISAN SAMMAN NIDHI Yojna (PM-KISAN)",
      sample: "300 (MP) 200 (CG)",
      status: "Done Before 20 may 2024",
    },
    {
      study: "CMA- IIMA",
      assign: "Pradhan Mantri Fasal Bima Yojna (PMFBY )",
      sample: "256 (MP)184 (CG)",
      status: "-",
    },
    {
      study: "AERC V V Nagar",
      assign: "Agricultural Credit (KCC )",
      sample: "292",
      status: "-",
    },
    {
      study: "AERC Jorhat",
      assign: "Sub-Mission on Agricultural Mechanization ( SMAM )",
      sample: "80 (MP) 80 (CG)",
      status: "-",
    },
  ];
  const newData = Data.map((item, i) => {
    return (
      <tr key={i}>
        <td>{item.study}</td>
        <td>{item.assign}</td>
        <td>{item.sample}</td>
        <td>{item.status}</td>
      </tr>
    );
  });
  const Data1 = [
    {
      study: "AERC Ludhiana",
      assign: "Mission for Integrated Development of Horticulture (MIDH )",
      sample: "100",
      status: "-",
    },
    {
      study: "IEG, Delhi",
      assign: "National Agriculture Market (e-NAM )",
      sample: "42 (MP)48 (CG)",
      status: "-",
    },
    {
      study: "AERC Delhi",
      assign: "National Food Security Mission (NFSM )",
      sample: "100 ",
      status: "-",
    },
    {
      study: "AERC Jabalpur",
      assign: "Pradhan Mantri Krishi Sinchai Yojna (PMKSY-PDMC)",
      sample: "80  ",
      status: "-",
    },
    {
      study: "ISEC, Bangalore",
      assign: "Soil Health Card Scheme (SHC )",
      sample: "210(MP) 210 (CG)",
      status: "-",
    },
  ];

  const Data2 = [
    {
      SNo: 1,
      title:
        "Pradhan Mantri Kisan Samman Nidhi (PM KISAN): Problems Prospects and needed improvements",
      cordinateBy: "AERC Delhi & AERU IEG Delhi",
      State_cover: "Madhya Pradesh",
      Selected_District: "Katni, Raisen, Niwari and Sagar (75 each)",
      Sample_size: 300,
      Status: "Done\n(May)",
      Selection_base: "Max. Beneficiaries",
    },
    {
      SNo: 2,
      title:
        "Status of KCC in India with special reference to animal husbandry and fisheries: A realistic Assessment",
      cordinateBy: "AERC Jorhat, Assam Agri. University",
      State_cover: "Madhya Pradesh",
      Selected_District: "Vidisha and Balaghat (80 each)",
      Sample_size: 160,
      Status: "Is in Progress",
      Selection_base: "Max. Beneficiaries",
    },
    {
      SNo: 3,
      title:
        "Rejuvenating Rice fallow land: An All India study using Remote Sensing and GIS",
      cordinateBy: "AERU ADRTC ISEC Bengaluru",
      State_cover: "Madhya Pradesh",
      Selected_District: "Awaited",
      Sample_size: 132,
      Status: "",
      Selection_base: "",
    },
    {
      SNo: 4,
      title:
        "An Empirical Study of Farming Community Development under Aspirational Districts Programme of India",
      cordinateBy: "AERU ADRTC ISEC Bengaluru",
      State_cover: "Madhya Pradesh",
      Selected_District: "Awaited",
      Sample_size: 240,
      Status: "",
      Selection_base: "",
    },
    {
      SNo: 5,
      title:
        "A Detailed Analysis of Millets Value Chain in India- The Production, Processing, Consumption and Export",
      cordinateBy: "AERU CMA IIM-A",
      State_cover: "Madhya Pradesh",
      Selected_District: "Awaited",
      Sample_size: 150,
      Status: "",
      Selection_base: "",
    },
    {
      SNo: 6,
      title:
        "Pradhan Mantri Kisan Samman Nidhi (PM KISAN): Problems Prospects and needed improvements",
      cordinateBy: "AERC Delhi & AERU IEG Delhi",
      State_cover: "Chhattisgarh",
      Selected_District: "Shakti, Sarangarh, Jashpur and Balod (50 each)",
      Sample_size: 200,
      Status: "Done\n(May)",
      Selection_base: "Max. Beneficiaries",
    },
    {
      SNo: 7,
      title:
        "Rejuvenating Rice fallow land: An All India study using Remote Sensing and GIS",
      cordinateBy: "AERU ADRTC ISEC Bengaluru",
      State_cover: "Chhattisgarh",
      Selected_District: "Awaited",
      Sample_size: 132,
      Status: "",
      Selection_base: "",
    },
    {
      SNo: 8,
      title:
        "An Empirical Study of Farming Community Development under Aspirational Districts Programme of India",
      cordinateBy: "AERU ADRTC ISEC Bengaluru",
      State_cover: "Chhattisgarh",
      Selected_District: "Awaited",
      Sample_size: 240,
      Status: "",
      Selection_base: "",
    },
    {
      SNo: 9,
      title: "Comprehensive impact assessment study for PMFBY/RWBCIS",
      cordinateBy: "AERU CMA IIM-A",
      State_cover: "Chhattisgarh",
      Selected_District: "Awaited",
      Sample_size: 88,
      Status: "",
      Selection_base: "",
    },
    {
      SNo: 10,
      title:
        "A Detailed Analysis of Millets Value Chain in India- The Production, Processing, Consumption and Export",
      cordinateBy: "AERU CMA IIM-A",
      State_cover: "Chhattisgarh",
      Selected_District: "Awaited",
      Sample_size: 150,
      Status: "",
      Selection_base: "",
    },
  ];
  const newData1 = Data1.map((item, i) => {
    return (
      <tr key={i}>
        <td>{item.study}</td>
        <td>{item.assign}</td>
        <td>{item.sample}</td>
        <td>{item.status}</td>
      </tr>
    );
  });
  const newData2 = Data2.map((item, i) => {
    return (
      <tr key={i}>
        <td>{item.SNo}</td>
        <td>{item.title}</td>
        <td>{item.cordinateBy}</td>
        <td>{item.State_cover}</td>
        <td>{item.Selected_District}</td>
        <td>{item.Sample_size}</td>
        <td>{item.Status}</td>
        <td>{item.Selection_base}</td>
      </tr>
    );
  });

  return (
    <div>
      <section class="about section">
        <div class="container">
          <div class="row">
            <div class="about-content">
              <h3>
                Concurrent Evaluation of Flagship Programs for the work Plan
                2023-24
              </h3>
              <div class="ongoing_table">
                {/* <table style={{ width: "100%" }}>
                  <tr>
                    <th>S. No.</th>
                    <th>Title of Research Studies</th>
                    <th>Coordinating Unit</th>
                    <th>Study Proposed by</th>
                    <th>State Covered</th>
                    <th>District Covered</th>
                    <th>Name of District Covered</th>
                    <th>Sample Size (Total Households Nos)</th>
                  </tr>
                  {NewData}

                  <tr>
                    <td colspan="5" style={{ textAlign: "center" }}>
                      {" "}
                      Total
                    </td>
                    <td style={{ textAlign: "center" }}>30</td>
                    <td></td>
                    <td style={{ textAlign: "center" }}>2500</td>
                  </tr>
                </table> */}
                <table>
                  <tr>
                    <th>Study Co-ordinators</th>
                    <th>Assigned Scheme </th>
                    <th>Sample Size</th>
                    <th>Status</th>
                  </tr>
                  <tr>
                    <th colSpan={4}>Phase-I</th>
                  </tr>
                  {newData}
                  <tr>
                    <th colSpan={4}>Under User Acceptance Test (UAT)</th>
                  </tr>
                  {newData1}
                </table>
                <table>
                  <tr>
                    {" "}
                    <th colSpan={8}>
                      {" "}
                      Studie allocated by the of AER section, ES&E Division,
                      MoA, GoI, New Delhi (Work Plan 2023-24 - Work start from
                      April 2024)
                    </th>
                  </tr>
                  <tr>
                    <th>S.No</th>
                    <th>Title of the Studies</th>
                    <th>Co-Ordinate by</th>
                    <th>State Covered</th>
                    <th>Selected District</th>
                    <th>Sample Size</th>
                    <th>Status</th>
                    <th>Selection Base</th>
                  </tr>
                  {newData2}
                </table>
                <table>
                  <tr>
                    <th>Archive </th>
                    <th>PDF</th>
                  </tr>
                  <tr>
                    <td className="text-center">
                      {" "}
                      Studies Completed during the Work Plan
                    </td>
                    <td className="text-center">
                      <a href={pdf}>
                        <img src={PDF_IMage} />
                      </a>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default OngoingStudies;
