/* eslint-disable */

import React from "react";

import { Link } from "react-router-dom";

function Mandate() {
  // useEffect()
  return (
    <div>
      {/* <Header /> */}

      <section className="about section">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-6 col-md-12 col-sm-12"> */}
            <div className="about-content">
              <h3>
                <span>Mandate</span>
              </h3>
              {/* <div className="about-img overlay image_float">
                <div className="button">
                  <a href={Slider8} className="video video-popup mfp-iframe">
                    <i className="fa fa-play"></i>
                  </a>
                </div>
                <img src={Slider8} alt="#" />
              </div> */}
              <br></br>
              <ul>
                <li className="list_item">
                  To conduct investigation into specific agro-economic problems
                  which are of special interest to the Ministry either at micro
                  or macro level.
                </li>
                <li className="list_item">
                  To carry out research works on structural changes and
                  fundamental problems of Agricultural Economics and Rural
                  Development of the country{" "}
                </li>
                <li className="list_item">
                  To give technical advice to the Union Government and States on
                  such issues as with mutual agreement may be referred to them.
                </li>
              </ul>
              {/* <br></br> */}
              <p>
                Besides, the Centre will also conduct ad-hoc investigations into
                problems in which the State or Central Government is specially
                interested, and create data banks for Madhya Pradesh and
                Chhattisgarh.
              </p>
              {/* <p>sunt in culpa qui officiccaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. sed ut perspiciatis unde sunt in culpa qui officia deserunt mollitm id est laborum. sed ut perspi. sunt in culpa
                            qui officia deserunt mollit anim id est laborum. sed ut perspiciatis Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. sed ut perspi.</p> */}

              {/* </div> */}
              {/* <div className="col-lg-6 col-md-12 col-sm-12"> */}
            </div>
            {/* </div> */}
          </div>
        </div>
      </section>

      {/* <!-- Start Clients --> */}
      {/* <Footer /> */}
    </div>
  );
}

export default Mandate;
