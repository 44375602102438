// import Select from "react-select";
// import villageFiles from "../assets/PDF FILE/Research/133 Final.pdf";
// // import villageFiles from "../assets/PDF FILE/Research/133 Village Study PDF less than 25 MB.pdf";
// import PDF_IMage from "../assets/images/pdf.png";
// import PDF_1 from "../assets/PDF FILE/RAC/1 Mintues Final_3rd RAC Meeting_FINAL 27 Aug 2021.pdf";
// import PDF_2 from "../assets/PDF FILE/RAC/2 Minutes of Directors Meeting held on 27th August 2021.pdf";
// import PDF_3 from "../assets/PDF FILE/RAC/3 RAC Meeting (Workplan 2019-20).pdf";
// import PDF_4 from "../assets/PDF FILE/RAC/4 CCCS meeting 2015.pdf";
// import PDF_5 from "../assets/PDF FILE/RAC/5 CCOS Meeting 2013.pdf";
// import { useState } from "react";

// function VilageStudies() {
//   const [selectedOption, setSelectedOption] = useState(null);

//   const Data = [
//     {
//       id: 26,
//       name: "Socio-Economics Resurvey of a Village in Jabalpur District of Madhya Pradesh (Piprodh)",
//       workplan: "1972",
//       meet_name: "-",
//       Time_line: "-",
//       Date_Completion: "12.2.1975",
//       pdf: "",
//     },
//     {
//       id: 133,
//       name: "Village Survey Study in Madhya Pradesh (Piprodh Village)",
//       workplan: "2019-20",
//       meet_name: "11 April 2019 (RAC Meeting)",
//       Time_line: "June 20",
//       Date_Completion: "20.10.2020",
//       pdf: villageFiles,
//     },
//   ];

//   const options = [
//     { label: "Option 1", value: "option1" },
//     { label: "Option 2", value: "option2" },
//     { label: "Option 3", value: "option3" },
//   ];
//   return (
//     <div>
//       <section className="about section">
//         <div className="container">
//           <div className="row">
//             <div className="about-content">
//               <h3>Village Studies</h3>
//               {/* <section className="blog-area">
//                 <div className="container pt-20">
//                   <div className="row">
//                     <div className="col-lg-12 col-md-12 col-sm-12">
//                       <div className="finder">
//                         <ul>
//                           <li>
//                             <Select placeholder="Year" />
//                           </li>
//                         </ul>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </section> */}
//               <Select
//                 options={options}
//                 value={selectedOption}
//                 onChange={setSelectedOption}
//               />
//               <table style={{ width: "100%", marginTop: "35px" }}>
//                 <tr>
//                   <th>
//                     Report No./
//                     <br />
//                     Fil e No.
//                   </th>
//                   <th>Name of Study</th>
//                   <th>work plan</th>
//                   <th>Study allotted on Dated</th>
//                   <th>Time line given for completion of study</th>
//                   <th>Date of Completion</th>
//                   <th>Pdf Attached </th>
//                 </tr>
//                 {Data.map((item) => (
//                   <tr>
//                     <td>{item.id}</td>
//                     <td>{item.name}</td>
//                     <td>{item.workplan}</td>
//                     <td>{item.meet_name}</td>
//                     <td>{item.Time_line}</td>
//                     <td>{item.Date_Completion}</td>
//                     {item.pdf == "" ? (
//                       <td>{item.pdf}</td>
//                     ) : (
//                       <td>
//                         <a href={item.pdf}>
//                           <img src={PDF_IMage} />
//                         </a>
//                       </td>
//                     )}
//                   </tr>
//                 ))}
//               </table>
//               {/* <table style={{ width: "100%" }}>
//                 <tr>
//                   <th>
//                     Report No./
//                     <br />
//                     Fil e No.
//                   </th>
//                   <th>Name of Study</th>
//                   <th>work plan</th>
//                   <th>Study allotted on Dated</th>
//                   <th>Time line given for completion of study</th>
//                   <th>Date of Completion</th>
//                   <th>Pdf Attached </th>
//                 </tr>
//                 <tr>
//                   <td>26</td>
//                   <td>
//                     Socio-Economics Resurvey of a Village in Jabalpur District
//                     of Madhya Pradesh (Piprodh)
//                   </td>
//                   <td>1972</td>
//                   <td>-</td>
//                   <td>-</td>
//                   <td>12.2.1975</td>
//                   <td></td>
//                 </tr>
//                 <tr>
//                   <td>133</td>
//                   <td>
//                     Village Survey Study in Madhya Pradesh (Piprodh Village)
//                   </td>
//                   <td>2019-20</td>
//                   <td>
//                     11 April 2019 <br />
//                     (RAC Meeting)
//                   </td>
//                   <td>June 20</td>
//                   <td>20.10.2020</td>
//                   <td>
//                     <a href={villageFiles}>
//                       <img src={PDF_IMage} alt="" />
//                     </a>
//                   </td>
//                 </tr>
//               </table> */}
//             </div>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// }

// export default VilageStudies;
import React from "react";
import { CSVLink } from "react-csv";

import Select from "react-select";
import villageFiles from "../assets/PDF FILE/Research/133 Final.pdf";
// import villageFiles from "../assets/PDF FILE/Research/133 Village Study PDF less than 25 MB.pdf";
import PDF_IMage from "../assets/images/pdf.png";
import PDF_1 from "../assets/PDF FILE/RAC/1 Mintues Final_3rd RAC Meeting_FINAL 27 Aug 2021.pdf";
import PDF_2 from "../assets/PDF FILE/RAC/2 Minutes of Directors Meeting held on 27th August 2021.pdf";
import PDF_3 from "../assets/PDF FILE/RAC/3 RAC Meeting (Workplan 2019-20).pdf";
import PDF_4 from "../assets/PDF FILE/RAC/4 CCCS meeting 2015.pdf";
import PDF_133 from "../assets/PDF FILE/Research/AERC_completed/PDF_133.pdf";
import { useState } from "react";

function VilageStudies() {
  const [selectedOption, setSelectedOption] = useState(null);
  const options = [
    { label: "select", value: "" },
    { label: "2019", value: "2019" },
    { label: "1972", value: "1972" },
  ];
  const [Year, setYear] = useState("");
  const [search, setsearch] = useState("");
  const [unit, setUnit] = useState("");

  const Data = [
    {
      id: 26,
      name: "Socio-Economics Resurvey of a Village in Jabalpur District of Madhya Pradesh (Piprodh)",
      workplan: "1972",
      meet_name: "-",
      Time_line: "-",
      Date_Completion: "12.2.1975",
      pdf: "",
    },
    {
      id: 133,
      name: "Village Survey Study in Madhya Pradesh (Piprodh Village)",
      workplan: "2019",
      meet_name: "11 April 2019 (RAC Meeting)",
      Time_line: "June 20",
      Date_Completion: "20.10.2020",
      pdf: PDF_133,
    },
  ];

  const tableData = Data.filter((item) => {
    const searchText = search.toLowerCase();
    const name = item.name.toLowerCase().includes(searchText);
    const meetName = item.meet_name.toLowerCase().includes(searchText);
    return (name || meetName) && item.workplan.includes(Year);
  });
  const new_data = tableData.map((Data) => {
    const { id, name, workplan, meet_name, Time_line, Date_Completion, pdf } =
      Data;
    return (
      <tr id={id}>
        <td>{id}</td>
        <td>{name}</td>
        <td>{workplan}</td>
        <td>{meet_name}</td>
        <td>{Time_line}</td>
        <td>{Date_Completion}</td>
        {pdf !== "" ? (
          <td>
            <a href={pdf}>
              <img src={PDF_IMage} />
            </a>
          </td>
        ) : (
          <td>{Data.pdf}</td>
        )}
      </tr>
    );
  });
  return (
    <div>
      <section className="about section">
        <div className="container">
          <div className="row">
            <div className="about-content">
              <h3>Village Studies</h3>
              {/* <div className="finder-journal">
                <Select
                  options={options}
                  value={options.find((option) => option.value === Year)}
                  onChange={(e) => {
                    setYear(e.value);
                  }}
                  placeholder="Work Plan"
                />
                <input
                  placeholder="Search "
                  type="text"
                  // onChange={(e) => setsearch(e.target.value)}
                />
              </div> */}
              <section class="blog-area">
                <div class="container pt-20">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <div class="finder-journal">
                        <ul>
                          <li class="rating">
                            <Select
                              options={options}
                              value={options.find(
                                (option) => option.value === Year
                              )}
                              onChange={(e) => {
                                setYear(e.value);
                              }}
                              placeholder="Work Plan"
                            />
                          </li>
                          <input
                            placeholder="Search study "
                            type="text"
                            onChange={(e) => setsearch(e.target.value)}
                          />
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <table style={{ width: "100%", marginTop: "35px" }}>
                <tr>
                  <th>
                    Report No./
                    <br />
                    Fil e No.
                  </th>
                  <th>Name of Study</th>
                  <th>work plan</th>
                  <th>Study allotted on Dated</th>
                  <th>Time line given for completion of study</th>
                  <th>Date of Completion</th>
                  <th>Pdf Attached </th>
                </tr>
                {new_data}
              </table>
              <button className="btn">
                <CSVLink data={tableData} headers={head}>
                  Download
                </CSVLink>
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
const head = [
  { label: "Report/No", key: "id" },
  { label: "Name of study", key: "name" },
  { label: "work plan", key: "name" },
  { label: "Name of study", key: "name" },
];
export default VilageStudies;
