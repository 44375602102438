/* eslint-disable */

import React from "react";
import Footer from "./footer";
import Header from "./header";
import Slider8 from "../assets/images/slider/8.jpg";
import "../assets/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faPaperclip,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import PDF_IMage from "../assets/images/pdf.png";
import PDF_1 from "../assets/PDF FILE/RAC/1 Mintues Final_3rd RAC Meeting_FINAL 27 Aug 2021.pdf";
import PDF_2 from "../assets/PDF FILE/RAC/2 Minutes of Directors Meeting held on 27th August 2021.pdf";
import PDF_3 from "../assets/PDF FILE/RAC/3 RAC Meeting (Workplan 2019-20).pdf";
import PDF_4 from "../assets/PDF FILE/RAC/4 CCCS meeting 2015.pdf";
import PDF_5 from "../assets/PDF FILE/RAC/5 CCOS Meeting 2013.pdf";
import { CSVLink } from "react-csv";

import { useState } from "react";
import Select from "react-select";

export default function Annual_membership() {
  const [membership, setmembership] = useState("");
  const [faculty, setfaculty] = useState("");

  const [search, setsearch] = useState("");

  const Data = [
    {
      s_no: "1",
      society_institute: "Krishak vandana",
      Date: "28-06-2021",
      recipt_no: "8464",
      type_of_membership: "Annual",
      faculty_name: "Mr. R.S.Bareliya",
    },
    {
      s_no: "2",
      society_institute: "Krishak vandana",
      Date: "Augt. 2020",
      recipt_no: "",
      type_of_membership: "Annual",
      faculty_name: "Dr. P.R. Pandey",
    },
    {
      s_no: "3",
      society_institute: "Krishak Doot",
      Date: "6/25/2021",
      recipt_no: "17304",
      type_of_membership: "Annual",
      faculty_name: "Mr. R.S.Bareliya",
    },
    {
      s_no: "4",
      society_institute: "Society of Life Science, Satna,Madhya Pradesh",
      Date: "13-01-2016",
      recipt_no: "194",
      type_of_membership: "Life",
      faculty_name: "Dr. H.K.Niranjan",
    },
    {
      s_no: "5",
      society_institute:
        "Society of Economics and Development, Ludhiana, Punjab",
      Date: "22-01-2016",
      recipt_no: "708",
      type_of_membership: "Life",
      faculty_name: "Dr. H.K.Niranjan",
    },
    {
      s_no: "6",
      society_institute:
        "Society for Scientific Development in Agriculture and Technology, Uttar Pradesh",
      Date: "28-10-2018",
      recipt_no: "1639",
      type_of_membership: "Life",
      faculty_name: "Dr. H.K.Niranjan",
    },
  ];
  const mem_opt = [
    { label: "select", value: "" },
    { value: "Annual", label: "Annual" },
    { label: "Life", value: "Life" },
  ];
  const fac_opt = [
    { label: "select", value: "" },
    { label: "Bareliya", value: "Bareliya" },
    { label: "Pandey", value: "Pandey" },
    { label: "Niranjan", value: "Niranjan" },
  ];

  const tabledata = Data.filter(
    (item) =>
      item.faculty_name.includes(faculty) &&
      item.type_of_membership.includes(membership) &&
      item.society_institute.includes(search)
  );

  const newdata = tabledata.map((item, i) => {
    return (
      <tr key={i}>
        <td>{item.s_no}</td>
        <td>{item.society_institute}</td>
        <td>{item.Date}</td>
        <td>{item.recipt_no}</td>
        <td>{item.type_of_membership}</td>
        <td>{item.faculty_name}</td>
      </tr>
    );
  });
  return (
    <div>
      <section class="about section">
        <div class="container">
          <div class="row">
            <div class="about-content">
              <h3> Annual Membership of Society </h3>
              <section class="blog-area">
                <div class="container pt-20">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <div class="finder">
                        <ul>
                          <li>
                            <Select
                              placeholder="Select Membership "
                              onChange={(e) => {
                                setmembership(e.value);
                              }}
                              options={mem_opt}
                            />
                          </li>

                          <li class="rating">
                            <Select
                              placeholder="Select Faculty"
                              onChange={(e) => {
                                setfaculty(e.value);
                              }}
                              options={fac_opt}
                            />
                          </li>

                          {/* <li class="rating">
                            <Select
                              placeholder="Search "
                              onChange={(e) => {
                                setsearch(e.value);
                              }}
                            />
                          </li> */}
                          {/* <input
                            placeholder="Search "
                            type="text"
                            onChange={(e) => setsearch(e.target.value)}
                          /> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="rac_meeting">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <table
                      style={{
                        width: "100%",
                        marginLeft: "3px",
                        marginTop: "9px",
                      }}
                    >
                      <tr>
                        <th style={{ width: "10%" }}>S.No.</th>
                        <th>Societies/Institute/Organization Name</th>
                        <th>Date</th>
                        <th>Receipt No.</th>
                        <th>Type of Membership</th>
                        <th>Faculty Name</th>
                      </tr>
                      {newdata}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button className="btn ">
            <CSVLink data={tabledata} filename="test.csv" headers={header}>
              download
            </CSVLink>
          </button>
        </div>
      </section>
    </div>
  );
}
const header = [
  { label: "s_no", key: "s_no" },
  { label: "society_institute", key: "society_institute" },
  { label: "Date", key: "Date" },
  { label: "recipt_no", key: "recipt_no" },
  { label: "type_of_membership", key: "type_of_membership" },
  { label: "faculty_name", key: "faculty_name" },
];
