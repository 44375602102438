/* eslint-disable */
import React, { Component } from "react";

import logo from "./logo.svg";

import DirectorImage from "./assets/images/HO_Sharma.jpg";
import logoDigitalIndia from "./assets/images/logo-digital-india.png";
import makeInIndiaLogo from "./assets/images/logo-make-in-india.png";
import swatchhBharatLogo from "./assets/images/logo-swach-bharat-abhiyan.png";

import img_1 from "./assets/images/Photo for Website zip/Img_2.JPG";
// import img_2 from "./assets/images/Photo for Website zip/AER Cent Visited by Dignitories.JPG";
import img_3 from "./assets/images/Photo for Website zip/Img_3.JPG";
import img_4 from "./assets/images/Photo for Website zip/Img_4.JPG";
import img_5 from "./assets/images/Photo for Website zip/Img_5.JPG";
import img_6 from "./assets/images/Photo for Website zip/Img_6.JPG";
import img_7 from "./assets/images/Photo for Website zip/Img_7.JPG";
import img_8 from "./assets/images/Photo for Website zip/Img_8.JPG";
import img_9 from "./assets/images/Photo for Website zip/Img_9.JPG";
import img_10 from "./assets/images/Photo for Website zip/Img_10.JPG";
import img_11 from "./assets/images/Photo for Website zip/Img_11.JPG";
import img_12 from "./assets/images/Photo for Website zip/Img_12.JPG";
import img_13 from "./assets/images/Photo for Website zip/Img_13.JPG";
import img_14 from "./assets/images/Photo for Website zip/Img_1.JPG";
import img_15 from "./assets/images/Photo for Website zip/Img_15.JPG";
import img_16 from "./assets/images/Photo for Website zip/Img_16.JPG";
import img_17 from "./assets/images/Photo for Website zip/Img_17.JPG";
import img_18 from "./assets/images/Photo for Website zip/Img_18.JPG";
import img_19 from "./assets/images/Photo for Website zip/Img_20.JPG";
import img_20 from "./assets/images/Photo for Website zip/Img_21.JPG";
import img_21 from "./assets/images/Photo for Website zip/Img_22.JPG";
// import img_22 from "./assets/images/Photo for Website zip/img_22.JPG";
// import img_23 from "./assets/images/Photo for Website zip/img_23.JPG";
// import img_24 from "./assets/images/Photo for Website zip/img_24.JPG";
// import img_25 from "./assets/images/Photo for Website zip/img_25.JPG";
// import img_26 from "./assets/images/Photo for Website zip/img_26.JPG";
// import img_27 from "./assets/images/Photo for Website zip/img_27.JPG";
// import img_28 from "./assets/images/Photo for Website zip/img_28.JPG";
// import img_29 from "./assets/images/Photo for Website zip/img_29.JPG";
// import img_30 from "./assets/images/Photo for Website zip/img_30.JPG";
// import img_31 from "./assets/images/Photo for Website zip/img_31.JPG";
// import img_32 from "./assets/images/Photo for Website zip/img_32.JPG";
// import img_33 from "./assets/images/Photo for Website zip/img_33.JPG";
// import img_34 from "./assets/images/Photo for Website zip/img_34.JPG";
import dr from "./assets/images/Teachers/DR.jpeg";
import VC from "./assets/images/Teachers/vc.jpeg";
import HC from "./assets/images/Teachers/HE.jpeg";
import mam from "./assets/images/Teachers/mam.jfif";
import "./assets/css/card.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Header from "./components/header";
import Footer from "./components/footer";
import "./assets/js/niceselect";
import { faRoad } from "@fortawesome/free-solid-svg-icons";

function App() {
  // const images = [
  //   img_1,
  //   img_14,
  //   img_2,
  //   img_3,
  //   img_4,
  //   img_5,
  //   img_6,
  //   img_7,
  //   img_8,
  //   img_9,
  //   img_10,
  //   img_11,
  //   img_12,
  //   img_13,

  //   img_15,
  //   img_16,
  //   img_17,
  //   img_18,
  //   img_19,
  //   img_20,
  //   img_21,
  //   img_22,
  //   img_23,
  //   img_24,
  //   img_25,
  //   img_26,
  //   img_27,
  //   img_28,
  //   img_29,
  //   img_30,
  //   img_31,
  //   img_32,
  //   img_33,
  //   img_34,
  // ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000, // Adjust the autoplay speed in milliseconds
  };
  return (
    <div className="container">
      {/* <section id="hero-area" className="hero-area overlay">
        <Slider {...settings}>
          {images.map((image, index) => (
            <div
              key={index}
              className="single-slider overlay"
              style={{ backgroundImage: `url(${image})` }}
            >
              <div className="row">
                <div className="col-12">
                  <div className="hero-inner"></div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </section> */}
      <section id="hero-area" className="  hero-area overlay">
        <div class="slider-active">
          <div
            class="single-slider overlay"
            style={{ backgroundImage: `url(${img_14})` }}
          >
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="hero-inner"></div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="single-slider overlay"
            style={{ backgroundImage: `url(${img_1})` }}
          >
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="hero-inner"></div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="single-slider overlay"
            style={{ backgroundImage: `url(${img_3})` }}
          >
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="hero-inner"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="single-slider overlay"
            style={{ backgroundImage: `url(${img_4})` }}
          >
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="hero-inner"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="single-slider overlay"
            style={{ backgroundImage: `url(${img_5})` }}
          >
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="hero-inner"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="single-slider overlay"
            style={{ backgroundImage: `url(${img_6})` }}
          >
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="hero-inner"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="single-slider overlay"
            style={{ backgroundImage: `url(${img_7})` }}
          >
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="hero-inner"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="single-slider overlay"
            style={{ backgroundImage: `url(${img_8})` }}
          >
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="hero-inner"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="single-slider overlay"
            style={{ backgroundImage: `url(${img_9})` }}
          >
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="hero-inner"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="single-slider overlay"
            style={{ backgroundImage: `url(${img_10})` }}
          >
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="hero-inner"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="single-slider overlay"
            style={{ backgroundImage: `url(${img_11})` }}
          >
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="hero-inner"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="single-slider overlay"
            style={{ backgroundImage: `url(${img_12})` }}
          >
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="hero-inner"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="single-slider overlay"
            style={{ backgroundImage: `url(${img_13})` }}
          >
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="hero-inner"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="single-slider overlay"
            style={{ backgroundImage: `url(${img_1})` }}
          >
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="hero-inner"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="single-slider overlay"
            style={{ backgroundImage: `url(${img_15})` }}
          >
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="hero-inner"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="single-slider overlay"
            style={{ backgroundImage: `url(${img_16})` }}
          >
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="hero-inner"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="single-slider overlay"
            style={{ backgroundImage: `url(${img_17})` }}
          >
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="hero-inner"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="single-slider overlay"
            style={{ backgroundImage: `url(${img_18})` }}
          >
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="hero-inner"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="single-slider overlay"
            style={{ backgroundImage: `url(${img_19})` }}
          >
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="hero-inner"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="single-slider overlay"
            style={{ backgroundImage: `url(${img_20})` }}
          >
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="hero-inner"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="single-slider overlay"
            style={{ backgroundImage: `url(${img_21})` }}
          >
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="hero-inner"></div>
                </div>
              </div>
            </div>
          </div>
          {/* <div
            class="single-slider overlay"
            style={{ backgroundImage: `url(${img_22})` }}
          >
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="hero-inner"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="single-slider overlay"
            style={{ backgroundImage: `url(${img_23})` }}
          >
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="hero-inner"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="single-slider overlay"
            style={{ backgroundImage: `url(${img_24})` }}
          >
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="hero-inner"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="single-slider overlay"
            style={{ backgroundImage: `url(${img_25})` }}
          >
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="hero-inner"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="single-slider overlay"
            style={{ backgroundImage: `url(${img_26})` }}
          >
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="hero-inner"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="single-slider overlay"
            style={{ backgroundImage: `url(${img_27})` }}
          >
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="hero-inner"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="single-slider overlay"
            style={{ backgroundImage: `url(${img_28})` }}
          >
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="hero-inner"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="single-slider overlay"
            style={{ backgroundImage: `url(${img_29})` }}
          >
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="hero-inner"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="single-slider overlay"
            style={{ backgroundImage: `url(${img_30})` }}
          >
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="hero-inner"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="single-slider overlay"
            style={{ backgroundImage: `url(${img_31})` }}
          >
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="hero-inner"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="single-slider overlay"
            style={{ backgroundImage: `url(${img_32})` }}
          >
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="hero-inner"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="single-slider overlay"
            style={{ backgroundImage: `url(${img_33})` }}
          >
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="hero-inner"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="single-slider overlay"
            style={{ backgroundImage: `url(${img_34})` }}
          >
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="hero-inner"></div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      {/* <!--/ End Hero Area Slider --> */}

      {/* <!-- Why Choose --> */}
      <section id="why-choose" class="why-choose section">
        <div class="container">
          <div class="row">
            <div class="col-12 wow zoomIn" data-wow-delay="0.4s">
              <div class="title-line center">
                <h2>
                  <span> Welcome To </span> AERC
                </h2>
                <p>
                  Way back in 1952 i.e., almost with the inception of planning
                  in India, the idea was mooted to establish Agro-Economic
                  Research Centre for each state to serve as the catalyst of
                  region specific agricultural transformation. It was one of the
                  earliest institutional efforts to propagate and interweave
                  knowledge based farming to a specific region. The year 1954
                  was a landmark in the history of agricultural economics in
                  India as in that year the Ministry of Food and Agriculture,
                  Government of India, established 4 Agro- Economic Research
                  Centres at Delhi, Pune, Chennai and Santiniketan. 2 more
                  centres were established in 1958, one at Jorhat (Assam) and
                  another at Sehore (Madhya Pradesh). In 1961 one more Agro
                  Economic Research Centre was established at
                  VallabhVidyaNagar.In the later years three more centres were
                  established at Allahabad, Waltair and Shimla bringing the
                  total number of centres in the country to 10. In 1966 two more
                  centres were established.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Why Choose --> */}
      <div className="row">
        <div className="col-3">
          <div>
            {" "}
            <div className="head">
              <h5
                style={{ fontSize: 18, marginBottom: "inherit" }}
                className="title"
              >
                Hon. Chancellor &amp; HE Governor of MP
              </h5>
            </div>
            <div style={{ marginTop: "-11px" }} className="context msg-context">
              <div
                style={{ height: "141px", width: "274px" }}
                className="message"
              >
                <p>
                  <img
                    src={HC}
                    alt="Governor"
                    align="top"
                    width="62"
                    height="71px"
                  />
                  <strong>Shri Mangubhai Chhaganbhai Patel</strong>
                  <br />
                  <span style={{ letterSpacing: "normal" }}>
                    H.E. Governor of Madhya Pradesh
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-3">
          {" "}
          <div className="head">
            <h5
              style={{ fontSize: 18, marginBottom: "inherit" }}
              className="title"
            >
              Vice Chancellor of JNKVV
            </h5>
          </div>
          <div style={{ marginTop: "12px" }} className="context msg-context">
            <div
              style={{ height: "141px", width: "274px" }}
              className="message"
            >
              <p>
                <img
                  src={VC}
                  alt="Vice Chancellor"
                  align="top"
                  width="62"
                  height="71px"
                />
                <strong>Prof. P.K. Mishra</strong> <br />
                <span style={{ letterSpacing: "normal" }}>
                  {" "}
                  Vice Chancellor of JNKVV, Jabalpur
                </span>
                <p>
                  Ph: (0761) 2681706 (O) , <br />{" "}
                  <p style={{ marginLeft: "60px" }}> fax: 0761-2681389 ,</p>{" "}
                  <p style={{ marginLeft: "59px" }}>
                    {" "}
                    e-mail: mishravcjnkvv@gmail.com
                  </p>{" "}
                </p>
              </p>
            </div>
          </div>
        </div>
        <div className="col-3">
          {" "}
          <div className="head">
            <h5 style={{ fontSize: 18 }} className="title">
              Advisor Of AERC
            </h5>
          </div>
          <div style={{ marginTop: "12px" }} className="context msg-context">
            <div
              style={{ height: "141px", width: "274px" }}
              className="message"
            >
              <p>
                <img
                  src={mam}
                  alt="Vice Chancellor"
                  align="top"
                  width="62"
                  height="71px"
                />
                <strong>Dr. (Smt) Promo diya Satish</strong>
                <br />
                Advisor Of AERC, Jabalpur
                <br />
                {/* <span>
                  Ph: 9424601211, e-mail: aerc_jbp@yahoo.co.in (O-Primary){" "}
                </span> */}
              </p>
            </div>
          </div>
        </div>
        <div className="col-3">
          {" "}
          <div className="head">
            <h5
              style={{ fontSize: 18, marginBottom: "inherit" }}
              className="title"
            >
              Director of AERC
            </h5>
          </div>
          <div style={{ marginTop: "9px" }} className="context msg-context">
            <div
              style={{ height: "141px", width: "274px" }}
              className="message"
            >
              <p>
                <img
                  src={dr}
                  alt="Vice Chancellor"
                  align="top"
                  width="62"
                  height="71px"
                />
                <strong>Dr. Deepak Rathi</strong>
                <br />
                Director Of AERC, Jabalpur
                <br />
                <p>Ph: 9424601211,</p> <p>e-mail:aerc_jbp@yahoo.co.in</p>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* >>>>>>>>>>>>>>>>>>>>>>>> */}
      <div className="row">
        {/* <div className="col-6">
          <div className="five columns">
            <div className="head">
              <span className="title">
                Hon. Chancellor &amp; HE Governor of MP
              </span>
            </div>
            <div className="context msg-context">
              <div className="message">
                <p>
                  <img
                    src={HC}
                    alt="Governor"
                    align="top"
                    width="62"
                    height="71px"
                  />
                  <strong>Shri Mangubhai Chhaganbhai Patel</strong>
                  <br />
                  H.E. Governor of Madhya Pradesh
                </p>
              </div>
            </div>
            <div className="head">
              <span className="title">Vice Chancellor of JNKVV</span>
            </div>
            <div className="context msg-context">
              <div className="message">
                <p>
                  <img
                    src={VC}
                    alt="Vice Chancellor"
                    align="top"
                    width="62"
                    height="71px"
                  />
                  <strong>Prof. P.K. Mishra</strong>
                  <br />
                  Vice Chancellor of JNKVV, Jabalpur
                  <br />
                  <span>
                    Ph: (0761) 2681706 (O) , fax: 0761-2681389 , e-mail:
                    mishravcjnkvv@gmail.com{" "}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="col-6">
          <section
            id="testimonials"
            class="testimonials overlay section wow fadeInUp"
            data-wow-delay="0.4s"
            data-stellar-background-ratio="0.5"
          >
            <div class="container">
              <div>
                <div>
                  <div class="testimonial-slider-two">
                    <div class="single-slider">
                      <div class="author">
                        {" "}
                        <img src={dr} alt="#" />
                        <h2>
                          <i class="icofont-quote-left"></i>Director Message
                          <i class="icofont-quote-right"></i>{" "}
                        </h2>
                      </div>
                      <div class="t-content">
                        <p>
                          {" "}
                          The Agro-Economic Research Centre for the States of
                          Madhya Pradesh and Chhattisgarh was established at the
                          Jawaharlal Nehru Krishi Vishwa Vidyalaya, Jabalpur
                          (M.P.) in 1st April 1965 at by the Directorate of
                          Economics and Statistics
                        </p>
                      </div>
                    </div>
                    <div class="single-slider">
                      <div class="author">
                        {" "}
                        <img src={VC} alt="#" />
                        <h2>
                          <i class="icofont-quote-left"></i>Prof. P.K. Mishra
                          <i class="icofont-quote-right"></i>{" "}
                        </h2>
                      </div>
                      <div class="t-content">
                        <p>
                          <span style={{ color: "white" }}>
                            Vice Chancellor of JNKVV
                          </span>
                          <span style={{ color: "white" }}>
                            Ph.: (0761) 2681706 (O){" "}
                          </span>
                          <span style={{ color: "white" }}>
                            fax: 0761-2681389
                          </span>
                          <span style={{ color: "white" }}>
                            e-mail: mishravcjnkvv@gmail.com{" "}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div> */}
      </div>

      {/* <!-- Testimonials --> */}
    </div>
  );
}

export default App;
