/* eslint-disable */

import React from "react";
import Footer from "./footer";
import Header from "./header";
import Slider8 from "../assets/images/slider/8.jpg";
import "../assets/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faPaperclip,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import PDF_IMage from "../assets/images/pdf.png";
import PDF_1 from "../assets/PDF FILE/RAC/1 Mintues Final_3rd RAC Meeting_FINAL 27 Aug 2021.pdf";
import PDF_2 from "../assets/PDF FILE/RAC/2 Minutes of Directors Meeting held on 27th August 2021.pdf";
import PDF_3 from "../assets/PDF FILE/RAC/3 RAC Meeting (Workplan 2019-20).pdf";
import PDF_4 from "../assets/PDF FILE/RAC/4 CCCS meeting 2015.pdf";
import PDF_5 from "../assets/PDF FILE/RAC/5 CCOS Meeting 2013.pdf";
import PDF_6 from "../assets/PDF FILE/RAC/6 4th RAC_Minutes of Meeting 2023.pdf";
import PDF_7 from "../assets/PDF FILE/RAC/10 Minutes of Meeting 4th RAC 2023.pdf";
import { CSVLink } from "react-csv";

import { useState } from "react";
import Select from "react-select";

export default function RACMeeting() {
  const [year, setYear] = useState("");
  const [unit, setUnit] = useState("");

  const Data = [
    {
      id: 1,
      heldOn: "28-05-2021",
      chaimanship: "Shri Sanjay Agarwal, Secretary (DA&FW)",
      meet_name: "Research Advisory Committee (RAC)",
      pdf: PDF_1,
    },
    {
      id: 2,
      heldOn: "27-08-2021",
      chaimanship:
        "Sh. Lal Sanglur, Sr. Economic and Statistical Adviser, Directorate of Economics & Statistics, DA&FW, Ministry of Agriculture and Farmers",
      meet_name: "Directors Meeting",
      pdf: PDF_2,
    },
    {
      id: 3,
      heldOn: "27-02-2019",
      chaimanship: "Shri Sanjay Agarwal, Secretary (DA&FW)",
      meet_name: "Research Advisory Committee (RAC)",
      pdf: PDF_3,
    },
    {
      id: 6,
      heldOn: "16-09-2013",
      chaimanship: "Details Awaited",
      meet_name: "Research Advisory Committee (RAC)/CCOS",
      pdf: PDF_5,
    },
    {
      id: 4,
      heldOn: "17/11/2015",
      chaimanship:
        "Prof. S. M. Jharwal, Chancellor, Indira Gandhi National Tribal Univeresity, Amarkantak (M.P.) and Chairman of Review Committee of AERC functioning under Plan Scheme of DES, MoA, GoI, New Delhi",
      meet_name: "Review Committee",
      pdf: "Nill",
    },
    {
      id: 5,
      heldOn: "18-11-2015",
      chaimanship:
        "Shri P.C. Bodh, Adviser, Agro Economic Research Division, Directorate of Economics and Statistics (DES)",
      meet_name: "Research Advisory Committee (RAC)/CCOS",
      pdf: PDF_4,
    },

    {
      id: 7,
      heldOn: "19/07/2023",
      chaimanship:
        "Shri Manoj Ahuja, Secretary (DA&FW), Ministry of Agriculture and Farmers' New Delhi",
      meet_name: "4th Research Advisory Committee (RAC)",
      pdf: PDF_7,
    },
  ];
  const yearOptions = [
    { value: "", label: "Select" },
    { value: "2021", label: "2021" },
    { value: "2013", label: "2013" },
    { value: "2015", label: "2015" },
    { value: "2019", label: "2019" },
  ];

  const unitOptions = [
    { value: "", label: "Select" },
    {
      value: "Review Committee",
      label: "Review Committee",
    },
    {
      value: "Directors Meeting",
      label: "Directors Meeting",
    },
    {
      value: "Research Advisory Committee (RAC)",
      label: "Research Advisory Committee (RAC)",
    },
    {
      value: "Research Advisory Committee (RAC)/CCOS",
      label: "Research Advisory Committee (RAC)/CCOS",
    },
  ];

  const tableData = Data.filter(
    (item) => item.heldOn.includes(year) || item.meet_name.includes(year)
  );
  const new_data =
    tableData &&
    tableData.map((Data) => (
      <tr id={Data.id}>
        <td className="">{Data.heldOn}</td>
        <td>{Data.chaimanship}</td>
        <td>{Data.meet_name}</td>
        {Data.pdf !== "Nill" ? (
          <td>
            <a href={Data.pdf}>
              <img src={PDF_IMage} />
            </a>
          </td>
        ) : (
          <td>{Data.pdf}</td>
        )}
      </tr>
    ));
  return (
    <div>
      <section class="about section">
        <div class="container">
          <div class="row">
            <div class="about-content">
              <h3>
                RAC/Review <span>Meetings</span>
              </h3>
              <section class="blog-area">
                <div class="container pt-20">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <div class="finder">
                        <ul>
                          <li>
                            <Select
                              placeholder="Select year"
                              onChange={(e) => {
                                setYear(e.value);
                              }}
                              options={yearOptions}
                            />
                          </li>

                          <li class="rating">
                            <Select
                              placeholder="Select meeting"
                              onChange={(e) => {
                                setYear(e.value);
                              }}
                              options={unitOptions}
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="rac_meeting">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <table
                      style={{
                        width: "100%",
                        marginLeft: "3px",
                        marginTop: "9px",
                      }}
                    >
                      <tr>
                        <th style={{ width: "10%" }}>Held on</th>
                        <th>Chairmanship</th>
                        <th>Name of Meetings</th>
                        <th>Attached PDF Files</th>
                      </tr>
                      {new_data}
                    </table>
                    <p>
                      * Coordination Committee for Organization of Studies
                      (CCOS)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button className="btn ">
            <CSVLink data={tableData} filename="test.csv" headers={header}>
              download
            </CSVLink>
          </button>
        </div>
      </section>
    </div>
  );
}
const header = [
  { label: "heldon", key: "heldOn" },
  { label: "Chairmanship", key: "chaimanship" },
  { label: "Name of Meetings", key: "meet_name" },
];

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
