/* eslint-disable */

import React, { useEffect } from "react";
import { CSVLink } from "react-csv";

import { useState } from "react";
import Select from "react-select";

export default function Capacity_building() {
  const [selectedAuthor, setSelectedAuthor] = useState("");

  const [author, setauthor] = useState("");
  const [duration, setduration] = useState("");
  const [year, setyear] = useState("");
  const [below5, setBelow5] = useState([]);
  const [betwen, setBetwen] = useState([]);
  const [above, setAbove] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  // const [selectedAuthor, setSelectedAuthor] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const Year = [
    { label: "select", value: "" },
    { label: "2015", value: "2015" },
    { label: "2017", value: "2017" },
    { label: "2020", value: "2020" },
    { label: "2021", value: "2021" },
    { label: "2013", value: "2013" },
    { label: "2014", value: "2014" },
    { label: "2012", value: "2012" },
    { label: "2023", value: "2023" },
    { label: "2022", value: "2022" },
  ];
  const Duration = [
    { value: "", label: "select" },
    // { value: "2", label: "2" },
    // { value: "20", label: "20" },
    // { value: "10", label: "10" },
    // { value: "7", label: "7" },
    // { value: "4", label: "4" },
    // { value: "18", label: "18" },
    // { value: "5", label: "5" },
    // { value: "1", label: "1" },
    // { value: "21", label: "21" },
    { label: "Below 5 Days", value: "Below 5 Days" },
    { label: "5 To 7 Days", value: "5 To 7 Days" },
    { label: "Above 7 Days", value: "Above 7 Days" },
  ];
  const Author = [
    {
      value: "",
      label: "select",
    },
    {
      value: "Mr.R.S.Bareliya",
      label: "Mr.R.S.Bareliya",
    },
    {
      value: "Dr. Deepak Rathi",
      label: "Dr. Deepak Rathi",
    },
    {
      value: "Mr.S.S. Thakur",
      label: "Mr.S.S. Thakur",
    },
    {
      value: "Dr.H. K. Niranjan",
      label: "Dr.H. K. Niranjan",
    },
  ];
  const Data = [
    {
      s_no: "1",
      Filter_Authors_Name: "Mr.R.S.Bareliya",
      Title:
        "Impact of Rajiv Gandhi Integrated Watershed area Management Mission on land use and cropping pattern in Madhya Pradesh",
      Organization:
        "Agro-Economic Research Center, Department of Agricultural Economics and Farm Management, JNKVV, Jabalpur",
      Mode_of_Training: "off-Line",
      Place: "Jabalpur",
      FROM: "20 Aug.",
      To: "21 Aug.",
      Filter_Duration_Days: "2",
      Filter_Year: "2015",
    },
    {
      s_no: "2",
      Filter_Authors_Name: "Dr. Deepak Rathi",
      Title:
        "Winter School on “Advanced Analytics in Developing Market Intelligence",
      Organization:
        "G.B. Pant University of Agriculture & Technology, Pabtnagar (Uttranchal).",
      Mode_of_Training: "off-Line",
      Place: "Pabtnagar, Uttranchal",
      FROM: "14 Nov.",
      To: "04 Dec.",
      Filter_Duration_Days: "20",
      Filter_Year: "2017",
    },
    {
      s_no: "3",
      Filter_Authors_Name: "Dr. Deepak Rathi",
      Title: "Business Planning for Developing New Agro-Technology Enterprises",
      Organization:
        "ICAR-Central Tuber Crops Research Institute, Sreekariyam,.",
      Mode_of_Training: "off-Line",
      Place: "Thiruvanathapuram, Kerala ",
      FROM: "02 SEP.",
      To: "11 SEP.",
      Filter_Duration_Days: "10",
      Filter_Year: "2015",
    },
    {
      s_no: "4",
      Filter_Authors_Name: "Dr. Deepak Rathi",
      Title:
        "Capacity Building Programme for University Teachers on Essential Teaching Skills",
      Organization: "G.B. Pant University of Agriculture & Technology",
      Mode_of_Training: "off-Line",
      Place: "Pabtnagar, Uttranchal ",
      FROM: "13 JULY.",
      To: "19 JULY.",
      Filter_Duration_Days: "7",
      Filter_Year: "2015",
    },
    {
      s_no: "5",
      Filter_Authors_Name: "Dr. Deepak Rathi",
      Title: "Exploring opportunities for agriculture under WTO",
      Organization:
        "National Institute of Agricultural Extension Management (MANAGE)",
      Mode_of_Training: "off-Line",
      Place: "Hyderabad, Telangana ",
      FROM: "12 JUNE.",
      To: "15 JUNE.",
      Filter_Duration_Days: "4",
      Filter_Year: "2017    ",
    },
    {
      s_no: "7",
      Filter_Authors_Name: "Mr.S.S. Thakur",
      Title:
        "Impact of Rajiv Gandhi Integrated Watershed area Management Mission on land use and cropping pattern in Madhya Pradesh",
      Organization:
        "Agro-Economic Research Center, Department of Agricultural Economics and Farm Management, JNKVV, Jabalpur",
      Mode_of_Training: "off-Line",
      Place: "Jabalpur",
      FROM: "20 AUG.",
      To: "21 AUG.",
      Filter_Duration_Days: "2",
      Filter_Year: "2015",
    },
    {
      s_no: "10",
      Filter_Authors_Name: "Dr. Deepak Rathi",
      Title:
        "Remote Sensing & GIS Technology and Applications for University Teachers & Government Officials",
      Organization: "Indian Institute of Remote Sensing (IIRS), ISRO Dehradun ",
      Mode_of_Training: "Online",
      Place: "Dehradun",
      FROM: "13 JUNE.",
      To: "01-JULY.",
      Filter_Duration_Days: "18",
      Filter_Year: "2020",
    },
    {
      s_no: "11",
      Filter_Authors_Name: "Dr.H. K. Niranjan",
      Title: "T4D: Digital Data Collection for Monitoring & Evaluation",
      Organization: "FICCI Aditya Birla CSR Centre For Excellence",
      Mode_of_Training: "Online",
      Place: "FICCI Federation House, Tansen Marg New Delhi",
      FROM: "27 SEP.",
      To: "01 OCT.",
      Filter_Duration_Days: "5",
      Filter_Year: "2021",
    },
    {
      s_no: "12",
      Filter_Authors_Name: "Dr.H. K. Niranjan",
      Title: "Mobile Data Collection Using Survey CTO",
      Organization: "Trestle Research and Consulting Pvt. Ltd.",
      Mode_of_Training: "Online",
      Place: "SurveyCTO, Dobility India, Ahmadabad",
      FROM: "22 JUNE.",
      To: "26 JUNE.",
      Filter_Duration_Days: "5",
      Filter_Year: "2020",
    },
    {
      s_no: "13",
      Filter_Authors_Name: "Dr.H. K. Niranjan",
      Title: "Teaching Tools to Knockdown the Lockdown",
      Organization: "College of Agriculture, Balaghat",
      Mode_of_Training: "Online",
      Place: "JNKVV, Jabalpur (M.P.)",
      FROM: "16 MAY.",
      To: "20 MAY.",
      Filter_Duration_Days: "5",
      Filter_Year: "2020",
    },
    {
      s_no: "14",
      Filter_Authors_Name: "Dr.H. K. Niranjan",
      Title:
        "Impact of Rajiv Gandhi Integrated Watershed Area Management Mission on Land Use and Cropping Pattern in Madhya Pradesh",
      Organization:
        "Agro-Economic Research Centre, Deptt. of Agricultural Economics",
      Mode_of_Training: "Off-line",
      Place: "JNKVV, Jabalpur (M.P.)",
      FROM: "20 AUG.",
      To: "21 AUG.",
      Filter_Duration_Days: "2",
      Filter_Year: "2015",
    },
    {
      s_no: "15",
      Filter_Authors_Name: "Dr.H. K. Niranjan",
      Title: "Microwave (SAR) Remote Sensing for Natural Resource",
      Organization: "Department of space, Govt. of India, Dehradun",
      Mode_of_Training: "Off-line",
      Place: "JNKVV, Jabalpur (M.P.)",
      FROM: "03 FEB.",
      To: "29 MARCH.",
      Filter_Duration_Days: "",
      Filter_Year: "2014",
    },
    {
      s_no: "16",
      Filter_Authors_Name: "Dr.H. K. Niranjan",
      Title: "Mathematical Modeling",
      Organization: "MPCST, Bhopal",
      Mode_of_Training: "Off-line",
      Place: "MGCGVV,Chitrakoot, Satna (M.P)",
      FROM: "02 MARCH.",
      To: "3 MARCH.",
      Filter_Duration_Days: "2",
      Filter_Year: "2013",
    },
    {
      s_no: "17",
      Filter_Authors_Name: "Dr.H. K. Niranjan",
      Title: "Commodity Futures Market",
      Organization: "Deptt. of Agricultural Economics",
      Mode_of_Training: "Off-line",
      Place: "JNKVV, Jabalpur (M.P.)",
      FROM: "24 FEB.",
      To: "25 FEB.",
      Filter_Duration_Days: "1",
      Filter_Year: "2012",
    },
    {
      s_no: "18",
      Filter_Authors_Name: "Dr.H. K. Niranjan",
      Title: "spatial data analysis using R and GeoDA",
      Organization:
        "Department of Agricultural Economics and Farm Management and Department of Extension Education,  JNKVV, Jabalpur",
      Mode_of_Training: "Off-line",
      Place: "JNKVV, Jabalpur (M.P.)",
      FROM: "24 JAN.",
      To: "14 FEB",
      Filter_Duration_Days: "21",
      Filter_Year: "2023",
    },
    {
      s_no: "19",
      Filter_Authors_Name: "Dr.H. K. Niranjan",
      Title: "Hands-on Training on RS and GIS using QGIS",
      Organization: "College of Agricultural Engineering. JNKVV, Jabalpur",
      Mode_of_Training: "Online",
      Place: "JNKVV, Jabalpur (M.P.)",
      FROM: "14 FEB.",
      To: "16 MARCH",
      Filter_Duration_Days: "21",
      Filter_Year: "2022",
    },
    {
      s_no: "20",
      Filter_Authors_Name: "Mr. R.S. Bareliya",
      Title: "Two-day workshop onCapacity Building",
      Organization: "Indian Institute of Management Ahmedabad",
      Mode_of_Training: "Offline",
      Place: " Ahmedabad,Gujarat",
      FROM: "4 JAN",
      To: "5 JAN",
      Filter_Duration_Days: "2",
      Filter_Year: "2024",
    },
    {
      s_no: "21",
      Filter_Authors_Name: "Mr. Akhilesh Kuril ",
      Title: "Two-day workshop onCapacity Building",
      Organization: "Indian Institute of Management Ahmedabad",
      Mode_of_Training: "Offline",
      Place: " Ahmedabad,Gujarat",
      FROM: "4 JAN",
      To: "5 JAN",
      Filter_Duration_Days: "2",
      Filter_Year: "2024",
    },
    {
      s_no: "22",
      Filter_Authors_Name: "Mr. Rahul Birla ",
      Title: "Two-day workshop onCapacity Building",
      Organization: "Indian Institute of Management Ahmedabad",
      Mode_of_Training: "Offline",
      Place: " Ahmedabad,Gujarat",
      FROM: "4 JAN",
      To: "5 JAN",
      Filter_Duration_Days: "2",
      Filter_Year: "2024",
    },
  ];

  useEffect(() => {
    setUpdateData(Data);
  }, []);

  useEffect(() => {
    let filteredData = Data;

    if (selectedAuthor) {
      filteredData = filteredData.filter(
        (item) => item.Filter_Authors_Name === selectedAuthor
      );
    }

    if (year) {
      filteredData = filteredData.filter((item) => item.Filter_Year === year);
    }

    if (duration === "Below 5 Days") {
      filteredData = filteredData.filter(
        (item) => item.Filter_Duration_Days < 5
      );
    } else if (duration === "5 To 7 Days") {
      filteredData = filteredData.filter(
        (item) =>
          item.Filter_Duration_Days >= 5 && item.Filter_Duration_Days <= 7
      );
    } else if (duration === "Above 7 Days") {
      filteredData = filteredData.filter(
        (item) => item.Filter_Duration_Days > 7
      );
    }

    setFilteredData(filteredData);
  }, [selectedAuthor, year, duration]);

  const handleAuthorChange = (selectedOption) => {
    setSelectedAuthor(selectedOption.value);
  };

  const handleYearChange = (selectedOption) => {
    setyear(selectedOption.value);
  };

  const handleDurationChange = (selectedOption) => {
    setduration(selectedOption.value);
  };

  // const filterAuthor = ({ e }) => {
  //   // setSelectedAuthor(e.value);
  //   if (e.value !== "") {
  //     let filteredData = Data.filter(
  //       (item) => item.Filter_Authors_Name === e.value
  //     );
  //     setUpdateData(filteredData);
  //   } else {
  //     setUpdateData(Data);
  //   }
  // };
  // const filterYear = ({ e }) => {
  //   setyear(e.value);
  //   if (e.value !== "") {
  //     let filteredData = Data.filter((item) => item.Filter_Year === e.value);
  //     setUpdateData(filteredData);
  //   } else {
  //     setUpdateData(Data);
  //   }
  // };

  // const filterdata = ({ e }) => {
  //   setduration(e.value);
  //   if (e.value == "Below 5 Days") {
  //     let below = Data.filter((item) => item.Filter_Duration_Days < 5);
  //     setUpdateData(below);
  //   } else if (e.value == "5 To 7 Days") {
  //     let betwen = Data.filter(
  //       (item) =>
  //         item.Filter_Duration_Days >= 5 && item.Filter_Duration_Days <= 7
  //     );
  //     setUpdateData(betwen);
  //   } else if (e.value == "Above 7 Days") {
  //     let aboveL = Data.filter((item) => item.Filter_Duration_Days > 7);
  //     setUpdateData(aboveL);
  //   } else {
  //   }
  // };

  const newdata = filteredData.map((item, i) => {
    return (
      <tr key={i}>
        <td>{item.s_no}</td>
        <td>{item.Filter_Authors_Name}</td>
        <td>{item.Title}</td>
        <td>{item.Organization}</td>
        <td>{item.Mode_of_Training}</td>
        <td>{item.Place}</td>
        <td>{item.FROM}</td>
        <td>{item.To}</td>
        <td>{item.Filter_Duration_Days}</td>
        <td>{item.Filter_Year}</td>
      </tr>
    );
  });
  return (
    <div>
      <section class="about section">
        <div class="container">
          <div class="row">
            <div class="about-content">
              <h3>CAPACITY BUILDING</h3>
              <section class="blog-area">
                <div class="container pt-20">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <div class="finder-Funded">
                        <ul>
                          <li>
                            <Select
                              placeholder="Select Author"
                              // onChange={(e) => {
                              //   filterAuthor({ e });
                              // }}
                              // options={Author}
                              value={Author.find(
                                (a) => a.value === selectedAuthor
                              )}
                              onChange={handleAuthorChange}
                              options={Author}
                            />
                          </li>

                          <li class="rating">
                            <Select
                              placeholder="Select Duration "
                              // onChange={(e) => {
                              //   filterdata({ e });
                              // }}
                              // options={Duration}
                              value={Duration.find((d) => d.value === duration)}
                              onChange={handleDurationChange}
                              options={Duration}
                            />
                          </li>
                          <li class="rating">
                            <Select
                              placeholder="Select Year "
                              // onChange={(e) => {
                              //   filterYear({ e });
                              // }}
                              // options={Year}
                              value={Year.find((y) => y.value === year)}
                              onChange={handleYearChange}
                              options={Year}
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="rac_meeting">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <table
                      style={{
                        width: "100%",
                        marginLeft: "3px",
                        marginTop: "9px",
                      }}
                    >
                      <tr>
                        <th>S.No.</th>
                        <th>Authors Name</th>
                        <th style={{ width: "25%" }}>Title</th>
                        <th>Organization</th>
                        <th>Mode of Training</th>
                        <th>Place</th>
                        <th colSpan={2}>
                          Duration
                          <tr>
                            <th>FROM</th>
                            <th>To</th>
                          </tr>
                        </th>
                        <th>Duration Days</th>
                        <th>Year</th>
                      </tr>
                      {newdata}
                      {/* {filterData()} */}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button className="btn ">
            <CSVLink data={filteredData} filename="test.csv" headers={header}>
              download
            </CSVLink>
          </button>
        </div>
      </section>
    </div>
  );
}
const header = [
  { label: "s_no", key: "s_no" },
  { label: "Filter_Authors_Name", key: "Filter_Authors_Name" },
  { label: "Title", key: "Title" },
  { label: "Organization", key: "Organization" },
  { label: "Mode_of_Training", key: "Mode_of_Training" },
  { label: "Place", key: "Place" },
  { label: "FROM", key: "FROM" },
  { label: "To", key: "To" },
  { label: "Filter_Duration_Days", key: "Filter_Duration_Days" },
  { label: "Filter_Year", key: "Filter_Year" },
];
